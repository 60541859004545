export interface CanDoWhat {
  readSingle: boolean;
  read: boolean;
  add: boolean;
  edit: boolean;
  delete: boolean;
  output: boolean;
  execute: boolean;
  full: boolean;
}

export class CheckResult {
  passed: boolean = false;
  message: string = "";
  trace: string[] = [];
  subjectType: "modules" | "features" | "permissions" | "roles" | "groups" | "other" | "unknown" = "unknown";
  subjectScenario: string = "";
  subject: any = null;
}
