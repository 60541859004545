<ib-modal-common-title [options]="options"
                       [modal]="modal"
                       [cancelData]="null"
                       (cancel)="options.cancel">
</ib-modal-common-title>
<div class="modal-body">
  <form #f="ngForm">
    <fieldset [disabled]="( disabled || mode === 'view' ) ? 'disabled' : null">
      <ul ngbNav
          #nav='ngbNav'
          class="{{isMobile ? 'nav-pills' : 'nav-tabs'}}"
          [destroyOnHide]="false">
        <!--(navChange)="onNavChange($event)"-->

        <li ngbNavItem
            title="Info">
          <a ngbNavLink>Info</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-text name="Id"
                               label="Id"
                               [readonly]="true"
                               [(ngModel)]="data.Description"></ib-input-text>
                <ib-input-checkbox name="Enabled"
                                   label="Enabled"
                                   [(ngModel)]="data.Enabled"></ib-input-checkbox>
                <ib-input-text name="Title"
                               label="Title"
                               [(ngModel)]="data.Title"></ib-input-text>
              </div>
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-select name="Column Resize Mode"
                                 label="Column Resize Mode"
                                 [(ngModel)]="data.ColumnResizeMode"
                                 [optionsPickList]="columnResizeModePickList"
                                 [optionsIncludeNone]="false">
                </ib-input-select>
                <ib-input-checkbox name="Responsive"
                                   label="Responsive"
                                   [(ngModel)]="data.Responsive"></ib-input-checkbox>
                <ib-input-select name="Theme"
                                 label="Theme"
                                 [(ngModel)]="data.Theme"
                                 [optionsPickList]="themePickList"
                                 [optionsIncludeNone]="false">
                </ib-input-select>
                <ib-input-checkbox name="Show Loader"
                                   label="Show Loader"
                                   [(ngModel)]="data.ShowLoader"></ib-input-checkbox>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem
            title="Paging">
          <a ngbNavLink>Paging</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-number name="Rows Per Page"
                                 label="Rows Per Page"
                                 type="integer"
                                 [(ngModel)]="data.RowsPerPage"></ib-input-number>
                <ib-input-wrapper label="Rows Per Page Options">
                  <ib-input-string-list name="RowsPerPageOptions"
                                        headerText=""
                                        addButtonIcon="plus"
                                        [canBulkAdd]="true"
                                        [canBulkEdit]="true"
                                        itemLabel="Rows Per Page"
                                        [itemRows]="1"
                                        [(ngModel)]="data.RowsPerPageOptions">
                  </ib-input-string-list>
                </ib-input-wrapper>
              </div>
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-checkbox name="Show Rows Per Page Selector"
                                   label="Show Rows Per Page Selector"
                                   [(ngModel)]="data.RowsPerPageSelector"></ib-input-checkbox>
                <ib-input-checkbox name="Show Row Count Message"
                                   label="Show Row Count Message"
                                   [(ngModel)]="data.RowCountMessage"></ib-input-checkbox>
                <ib-input-checkbox name="Show Refresh Option"
                                   label="Show Refresh Option"
                                   [(ngModel)]="data.ShowRefreshOption"></ib-input-checkbox>
                <ib-input-checkbox name="Allow Paging"
                                   label="Allow Paging"
                                   [(ngModel)]="data.Paging"></ib-input-checkbox>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem
            title="Filter & Sort">
          <a ngbNavLink>Filter & Sort</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-checkbox name="Allow Search"
                                   label="Allow Search"
                                   [(ngModel)]="data.GlobalSearch"></ib-input-checkbox>
                <ib-input-checkbox name="Allow Saved Filters"
                                   label="Allow Saved Filters"
                                   [(ngModel)]="data.FilterAllowSaved"></ib-input-checkbox>
                <ib-input-checkbox name="Allow Filter Builder"
                                   label="Allow Filter Builder"
                                   [(ngModel)]="data.FilterAllowBuilder"></ib-input-checkbox>
                <ib-input-checkbox name="Allow Advanced Filters"
                                   label="Allow Advanced Filters"
                                   [(ngModel)]="data.FilterAllowAdvanced"></ib-input-checkbox>
                <ib-input-checkbox name="Allow Column Filters"
                                   label="Allow Column Filters"
                                   [(ngModel)]="data.FilterColumns"></ib-input-checkbox>
                <ib-input-select name="Default Date Range"
                                 label="Default Date Range"
                                 [(ngModel)]="data.DateRange"
                                 [optionsPickList]="dateRangePickList"
                                 [optionsIncludeNone]="true">
                </ib-input-select>
              </div>
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-select name="Sort Mode"
                                 label="Sort Mode"
                                 [(ngModel)]="data.SortMode"
                                 [optionsPickList]="sortModePickList"
                                 [optionsIncludeNone]="false">
                </ib-input-select>
                <ib-input-text name="Sort"
                               label="Sort"
                               [(ngModel)]="data.Sort"></ib-input-text>
                <ib-input-text name="Expand"
                               label="Expand"
                               [(ngModel)]="data.Expand"></ib-input-text>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem
            title="Click & Actions">
          <a ngbNavLink>Click & Actions</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-text name="Header Click URL"
                               label="Header Click URL"
                               [(ngModel)]="data.HeaderClickUrl"></ib-input-text>
                <ib-input-checkbox name="Header Click Open In New Window"
                                   label="Header Click Open In New Window"
                                   [(ngModel)]="data.HeaderClickUrlNewWindow"></ib-input-checkbox>
                <ib-input-text name="Row Click URL"
                               label="Row Click URL"
                               [(ngModel)]="data.RowClickUrl"></ib-input-text>
                <ib-input-checkbox name="Row Click Open In New Window"
                                   label="Row Click Open In New Window"
                                   [(ngModel)]="data.RowClickUrlNewWindow"></ib-input-checkbox>
              </div>
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-select name="Row Action Display Mode"
                                 label="Row Action Display Mode"
                                 [(ngModel)]="data.RowActionDisplayMode"
                                 [optionsPickList]="rowActionDisplayModePickList"
                                 [optionsIncludeNone]="false">
                </ib-input-select>
                <ib-input-multiselect name="Row Actions For Icon Display"
                                      label="Row Actions For Icon Display"
                                      [(ngModel)]="data.RowActionsForIconDisplay"
                                      [optionsPickList]="rowActionIdsPickList"
                                      [optionsIncludeNone]="false">
                </ib-input-multiselect>
                <ib-input-simple-object-list name="Row Action Config"
                                             label="Row Action Config"
                                             [objectProperties]="rowActionProperties"
                                             [standalone]="true"
                                             [data]="data.RowActions"
                                             (change)="onRowActionChange($event)">
                </ib-input-simple-object-list>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem
            title="Row Style">
          <a ngbNavLink>Row Style</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.fullWidth}}">
                <ib-input-textarea name="Row Style"
                                   label="Row Style"
                                   [vertical]="true"
                                   [rows]="4"
                                   [(ngModel)]="data.RowStyle"></ib-input-textarea>
              </div>
            </div>
            <div class="row">
              <div class="{{Constants.Layout.fullWidth}}">
                <p class="mb-0">Row Style Function</p>
                <ib-code-editor name="Row Style Function"
                                height="300px"
                                fileType="js"
                                [actions]="getRowStyleEditorActions"
                                [(ngModel)]="data.GetRowStyle"></ib-code-editor>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem
            title="Columns">
          <a ngbNavLink>Columns</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.fullWidth}}">
                <ib-native-table [options]="columnsTableOptions"
                                 [data]="data.Columns"
                                 [reloadCount]="columnsTableReloadCount"
                                 (rowSelect)="onRowSelected($event)">
                </ib-native-table>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem
            title="Advanced">
          <a ngbNavLink>Advanced</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-checkbox name="Remember Layout"
                                   label="Remember Layout"
                                   [(ngModel)]="data.RememberLayout"></ib-input-checkbox>
                <ib-input-checkbox name="Remember Sort"
                                   label="Remember Sort"
                                   [(ngModel)]="data.RememberSort"></ib-input-checkbox>
              </div>
              <div class="{{Constants.Layout.split2column}}">
                <ib-input-checkbox name="Remember Filter"
                                   label="Remember Filter"
                                   [(ngModel)]="data.RememberFilter"></ib-input-checkbox>
                <ib-input-checkbox name="Remember Paging"
                                   label="Remember Paging"
                                   [(ngModel)]="data.RememberPaging"></ib-input-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="{{Constants.Layout.split2column}}"
                   *ngIf="data.TableConfigurationId">
                <ib-button icon="times"
                           label="Delete"
                           color="danger"
                           (click)="onDelete($event)"></ib-button>
              </div>
              <div class="{{Constants.Layout.split2column}}"
                   *ngIf="canDownloadUpload">
                <ib-button icon="download"
                           label="Download"
                           color="secondary"
                           buttonClasses="me-2"
                           (click)="onDownload($event)"></ib-button>
                <ib-button icon="upload"
                           label="Upload"
                           color="secondary"
                           buttonClasses="me-2"
                           (click)="showFileUploadArea = !showFileUploadArea;"></ib-button>
                <ib-button icon="save"
                           label="Save To Menu"
                           color="secondary"
                           (click)="onSaveViewButtonClick($event)"></ib-button>
              </div>
            </div>
            <div class="row"
                 *ngIf="showFileUploadArea">
              <div class="{{Constants.Layout.fullWidth}}">
                <ib-file-upload [upload]="false"
                                uploadType="Other"
                                [showStats]="false"
                                acceptedFileTypes=".json"
                                (file)="onUpload($event)"></ib-file-upload>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem
            title="Comments">
          <a ngbNavLink>Comments</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="{{Constants.Layout.fullWidth}}">
                <ib-input-textarea name="Comments"
                                   label="Comments"
                                   placeholder="Comments"
                                   width="full"
                                   [rows]="15"
                                   [fullWidth]="true"
                                   [(ngModel)]="data.Comments"></ib-input-textarea>
              </div>
            </div>
          </ng-template>
        </li>

      </ul>
      <div [ngbNavOutlet]="nav"
           class="mt-2"></div>
    </fieldset>
  </form>
  <ib-form-status-error-output [formStatus]="formStatus"
                               classes="float-end mb-1"></ib-form-status-error-output>
</div>
<ib-modal-common-buttons [options]="options"
                         [modal]="modal"
                         [okData]="data"
                         [cancelData]="null"
                         (ok)="options.ok"
                         (cancel)="options.cancel">
</ib-modal-common-buttons>