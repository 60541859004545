import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Log } from 'projects/core-lib/src/lib/helpers/helper';
declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { Observable, of } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationHelperService extends BaseService {

  /**
  The native language.
  */
  public nativeLanguage: string = "en";
  /**
  An array of available languages.
  */
  public supportedLanguages: string[] = ["en"];
  /**
  The default language.
  */
  public defaultLanguage: string = "en";
  /**
  The browser language
  */
  public get browserLanguage(): string { return this.translate.getBrowserLang(); }
  /**
  The language (if any) associated with a user saved preference.
  */
  private _preferredLanguage: string = "";
  public get preferredLanguage(): string { return this._preferredLanguage; }
  public set preferredLanguage(language: string) {
    // Set our selected language which is one of the possible values returned
    // by the currentLanguage getter.
    this._preferredLanguage = language;
    // If we don't have a selected language then use the preferred language with our translation service.
    // If we do have a selected language that is always more important than preferred language.
    if (!this._selectedLanguage) {
      // Now set this in the translation service, if it isn't available, it will use the current loader to get it
      this.translate.use(language);
    }
    // Update our ApiService with language it should use for api requests
    this.apiService.language = this.currentLanguage;
  }
  /**
  The language (if any) actively selected by user input.  This can be used
  to make sure a user preference doesn't override specific user selection.
  */
  private _selectedLanguage: string = "";
  public get selectedLanguage(): string { return this._selectedLanguage; }
  public set selectedLanguage(language: string) {
    // Set our selected language which is one of the possible values returned
    // by the currentLanguage getter.
    this._selectedLanguage = language;
    // Now set this in the translation service, if it isn't available, it will use the current loader to get it
    this.translate.use(language);
    // Update our ApiService with language it should use for api requests
    this.apiService.language = this.currentLanguage;
  }
  /**
  The current language being used.  This may be from user selection or user
  preference or by default.
  */
  public get currentLanguage(): string {

    // First option is explicitly selected language
    if (this._selectedLanguage) {
      return this._selectedLanguage;
    }

    // Second option is preferred language if it's one of our available languages.
    if (this._preferredLanguage) { // && this.availableLanguages && this.availableLanguages.includes(this._preferredLanguage)) {
      return this._preferredLanguage;
    }

    // Last option is the default language
    return this.defaultLanguage;

  }

  constructor(
    protected translate: TranslateService,
    protected apiService: ApiService) {

    super();

    // Exceptions inside constructors for classes that get injected cause hard to
    // trace errors so try/catch to make sure any errors are clearly reported.

    // Get default and supported languages from our configuration file.
    try {
      if (AppConfig.languageDefault) {
        this.defaultLanguage = AppConfig.languageDefault;
      }
      if (AppConfig.languagesSupported && AppConfig.languagesSupported.length > 0) {
        this.supportedLanguages = AppConfig.languagesSupported;
      } else {
        this.supportedLanguages = [this.defaultLanguage];
      }
    } catch (err) {
      Log.errorMessage("Error applying language settings from app config.");
      Log.errorMessage(err);
    }

    // Update our ApiService with language it should use for api requests
    try {
      this.apiService.language = this.currentLanguage;
    } catch (err) {
      Log.errorMessage("Error setting language for api service.");
      Log.errorMessage(err);
    }

    // Set available languages
    try {
      this.translate.addLangs(this.supportedLanguages);
    } catch (err) {
      Log.errorMessage("Error setting default language for translation engine.");
      Log.errorMessage(err);
    }

    // This language will be used as a fallback when a translation isn't found in the current language
    // although we typically have use default set to false so our missing translation handler gets
    // called which will submit the fact that we're missing a translation to the api so the translation
    // table can capture all the text that needs translating.
    try {
      this.translate.setDefaultLang(this.defaultLanguage);
    } catch (err) {
      Log.errorMessage("Error setting default language for translation engine.");
      Log.errorMessage(err);
    }

  }

  /**
   * Get a translation for the specified text using provided interpolate parameters (if any).
   * @param nativeText
   * @param data
   */
  getTranslation(nativeText: string, data: any = {}): string {
    if (!nativeText) {
      return "";
    }
    // If the current language is the native language and no interpolation is called for then no translation is needed
    if (this.currentLanguage === this.nativeLanguage && !nativeText.includes("{{")) {
      return nativeText;
    }
    return this.translate.instant(nativeText, data);
  }


  /**
  * Get a translation observable for the specified text using provided interpolate parameters (if any).
  * @param nativeText
  * @param data
  */
  getTranslationObservable(nativeText: string, data: any = {}): Observable<string> {
    if (!nativeText) {
      return of("");
    }
    // If the current language is the native language and no interpolation is called for then no translation is needed
    if (this.currentLanguage === this.nativeLanguage && !nativeText.includes("{{")) {
      return of(nativeText);
    }
    return this.translate.get(nativeText, data);
  }


}
