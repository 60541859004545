<ng-container *ngIf="mode==='list' && editorOptions">
  <ib-data-editor-header [mode]="mode"
                         [options]="editorOptions"></ib-data-editor-header>
  <ib-standard-table [options]="tableOptions"
                     [data]="data"
                     [scope]="scope"
                     [reloadCount]="tableReloadCount"
                     (rowSelect)="onRowSelected($event)">
  </ib-standard-table>
</ng-container>

<ng-container *ngIf="mode==='add' && editorOptions">
  <ib-data-editor-header [mode]="mode"
                         [options]="editorOptions"
                         [formStatus]="addFormStatus"
                         [working]="working"
                         [data]="addData"
                         (save)="onAddFormSave($event)">
  </ib-data-editor-header>
  <ib-form-render [data]="addData"
                  [saveCount]="formResetCount"
                  [formResetCount]="formResetCount"
                  [formModel]="formModel"
                  groupScope="A"
                  (status)="onAddFormStatusChange($event)">
  </ib-form-render>
  <ib-form-save-close-buttons [formStatus]="addFormStatus"
                              [working]="working"
                              (save)="onAddFormSave($event)"
                              (close)="onAddFormClose($event)">
  </ib-form-save-close-buttons>
</ng-container>

<ng-container *ngIf="( mode==='edit' || mode==='view' ) && editorOptions">
  <div class="loading-container">
    <ib-data-editor-header [mode]="mode"
                           [options]="editorOptions"
                           [formStatus]="editFormStatus"
                           [objectDescription]="getObjectDescription(editData)"
                           [working]="working"
                           [data]="editData"
                           (save)="onEditFormSave($event)">
    </ib-data-editor-header>
    <ib-form-render [data]="editData"
                    [saveCount]="formResetCount"
                    [formResetCount]="formResetCount"
                    [formModel]="formModel"
                    groupScope="E"
                    (status)="onEditFormStatusChange($event)">
    </ib-form-render>
    <ib-form-save-close-buttons [formStatus]="editFormStatus"
                                [working]="working"
                                [showSave]="mode==='edit'"
                                (save)="onEditFormSave($event)"
                                (close)="onEditFormClose($event)">
    </ib-form-save-close-buttons>
    <ngx-loading [show]="loading"
                 [config]="{animationType: 'none'}"
                 [template]="loadingTemplate"></ngx-loading>
    <ng-template #loadingTemplate>
      <div class="loading-template">
        <h3><i class="{{Constants.Layout.loadingIcon}}"></i>&nbsp;&nbsp;{{Constants.Layout.loadingText}}</h3>
      </div>
    </ng-template>
  </div>
</ng-container>
