import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from '../helpers/helper';

@Pipe({
  name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {

  transform(value: any, length: number, ellipses: boolean = false): any {
    if (!value) {
      return "";
    }
    return Helper.left(value.toString(), length, ellipses);
  }

}
