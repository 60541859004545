<div class="btn-toolbar" role="toolbar" aria-label="Toolbar">
  <div class="btn-group me-2" role="group" aria-label="Toolbar Options">
    <button type="button"
            ngbTooltip="Word Wrap"
            (click)="toggleWordwrap()"
            class="btn btn-secondary btn-sm"
            [ngClass]="{'active': wordwrap}">
      <i class="far fa-exchange"></i>
    </button>
    <button type="button"
            ngbTooltip="Highlight Current Line"
            (click)="toggleHighlightCurrentLine()"
            class="btn btn-secondary btn-sm"
            [ngClass]="{'active': highlightCurrentLine}">
      <i class="far fa-arrow-to-right"></i>
    </button>
  </div>
  <div class="btn-group me-2" role="group" aria-label="Toolbar Options 2">
    <button type="button"
            ngbTooltip="Show Line Numbers"
            (click)="toggleShowLineNumbers()"
            class="btn btn-secondary btn-sm"
            [ngClass]="{'active': showLineNumbers}">
      <i class="far fa-list-ol"></i>
    </button>
    <button type="button"
            ngbTooltip="Show Hidden Characters"
            (click)="toggleShowHiddenCharacters()"
            class="btn btn-secondary btn-sm"
            [ngClass]="{'active': showHiddenCharacters}">
      <i class="far fa-paragraph"></i>
    </button>
  </div>
  <div *ngIf="actions" class="btn-group me-2" role="group" aria-label="Toolbar Options 3">
    <ib-action-button [button]="actions"></ib-action-button>
  </div>
</div>
<div id="{{editorControlId}}" style="min-height: 100px; width:100%; overflow: auto;" [ngStyle]="{'height': height}"></div>
<small class="form-text input-invalid" *ngFor="let error of errors; let i = index;">
  {{error}}
</small>
<!--<textarea id="{{editorControlId}}-hidden-textarea" hidden="hidden" name="{{editorControlId}}-hidden-textarea" [(ngModel)]="value" #inputControl="ngModel" #hiddentextarea></textarea>-->
