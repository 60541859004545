import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventModel, EventElementModel, ButtonItem } from 'projects/common-lib/src/lib/ux-models';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { UxService } from '../../services/ux.service';
import { BaseComponent } from '../../../../../core-lib/src/lib/helpers/base-component';
import { FormStatusModel } from 'projects/core-lib/src/lib/models/form-status-model';

@Component({
  selector: 'ib-modal-common-buttons',
  templateUrl: './modal-common-buttons.component.html',
  styleUrls: ['./modal-common-buttons.component.css']
})
export class ModalCommonButtonsComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() public options: ModalCommonOptions = new ModalCommonOptions();
  @Input() public modal: NgbActiveModal;
  /**
  The data to pass through on the ok event.  This is most frequently assigned to "true" for simple
  modals and the data object for complex form modals.
  */
  @Input() public okData: any;
  /**
  The data to pass through on the cancel event.  This is most frequently assigned to "false" for simple
  modals and null for complex form modals.
  */
  @Input() public cancelData: any;
  /**
  The data to pass through on custom button events.  If null or undefined the custom button action id will be submitted.
  */
  @Input() public data: any;
  /**
  An array of error messages to present when ok is attempted.  If not empty then ok cannot be completed.
  The modal component can pass this through here via input attribute.
  */
  @Input() public errors: string[] = [];

  // Null by default so we know whether a parent passed in a formStatus or not. If it did, we need to
  // be able to decipher between a clean or dirty form. If not, we don't want isPristine to be true by
  // default or it will disrupt the flow of fireOk()
  @Input() public formStatus: FormStatusModel = null;


  @Output() public ok: EventEmitter<EventModel> = new EventEmitter();
  @Output() public cancel: EventEmitter<EventModel> = new EventEmitter();
  @Output() public click: EventEmitter<EventModel> = new EventEmitter();



  constructor(
    public appService: AppService,
    protected uxService: UxService) {
    super();
  }

  //ngOnInit() {
  //  super.ngOnInit();
  //}

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.configure();
  }

  protected configure() {
    if (!this.options) {
      this.options = new ModalCommonOptions();
    }
  }

  public fireOk(event) {

    // If we have custom validation then check that now
    if (this.options.validate) {
      if (!this.options.validate(this.options, this.okData, this.errors, this.modal)) {
        return;
      }
    }

    // If we have errors then alert to that now
    if (this.errors && this.errors.length > 0) {
      let message: string = "<ul>";
      this.errors.forEach((error) => {
        message += `<li>${error}</li>`;
      })
      message += "</ul>";
      // Show the errors
      this.uxService.modal.alertDanger("Errors", message);
      // Not ok when we have errors
      return;
    }

    // Emit the ok event, call the ok method, and close the modal.
    const payload: EventModel = new EventModel("click", event, this.okData, new EventElementModel("modal", null, "OkButton", this.options.okButtonText, this.options.okButtonIcon));
    payload.cargo = { ok: true, cancel: false, actionId: "ok", button: null };
    this.ok.emit(payload);
    this.click.emit(payload);
    // Now execute ModalCommonOptions.ok (if any)
    if (this.options.ok) {
      this.options.ok(payload);
    }

    // We need to call dismiss() if the form is clean because the close() function will lead to marking
    // the form as dirty, even if the user hits 'Ok' without changing anything in a modal like
    // role-detail-form-modal    https://ng-bootstrap.github.io/#/components/modal/api
    if (this.formStatus && this.formStatus.isPristine && this.options.whenPristineDismissInsteadOfClose) {
      this.modal.dismiss(payload);
      return;
    }

    if (this.modal) {
      this.modal.close(payload);
    }
  }

  public fireCancel(event) {
    const payload: EventModel = new EventModel("click", event, this.cancelData, new EventElementModel("modal", null, "CancelButton", this.options.cancelButtonText, this.options.cancelButtonIcon));
    payload.cargo = { ok: false, cancel: true, actionId: "cancel", button: null };
    this.cancel.emit(payload);
    this.click.emit(payload);
    // Now execute ModalCommonOptions.cancel (if any)
    if (this.options.cancel) {
      this.options.cancel(payload);
    }
    this.modal.dismiss(payload);
  }

  public fireCustom(event, button: ButtonItem) {

    // If we have custom validation then check that now
    if (this.options.validate) {
      if (!this.options.validate(this.options, this.okData, this.errors, this.modal)) {
        return;
      }
    }

    // If we have errors then alert to that now
    if (this.errors && this.errors.length > 0) {
      let message: string = "<ul>";
      this.errors.forEach((error) => {
        message += `<li>${error}</li>`;
      })
      message += "</ul>";
      // Show the errors
      this.uxService.modal.alertDanger("Errors", message);
      // Not ok when we have errors
      return;
    }

    const payload: EventModel = new EventModel("click", event, this.data || button?.actionId, new EventElementModel("modal", button?.actionId, button?.actionId, button?.label, button?.icon));
    payload.cargo = { ok: false, cancel: false, actionId: button?.actionId, button: button };
    this.click.emit(payload);
    // Now execute button action (if any)
    if (button?.action) {
      button.action(payload);
    }
    this.modal.close(payload);

  }

}
