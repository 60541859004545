import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { takeUntil } from 'rxjs/operators';
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { StateIcon } from '../../image/icon/icon.component';

@Component({
  selector: 'ib-modal-common-title',
  templateUrl: './modal-common-title.component.html',
  styleUrls: ['./modal-common-title.component.css']
})
export class ModalCommonTitleComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() public options: ModalCommonOptions = new ModalCommonOptions();
  @Input() public modal: NgbActiveModal;
  @Input() public cancelData: any;
  @Input() public showCloseIcon: boolean = false;
  @Output() public cancel: EventEmitter<EventModel> = new EventEmitter();

  public helpTopic: m5web.HelpLinkEditViewModel = null;


  constructor(protected appService: AppService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.configure();
  }

  protected configure() {
    if (!this.options) {
      this.options = new ModalCommonOptions();
    }
    if (this.options.helpContext) {
      this.appService.helpLinkFind(this.options.helpContext, this.options.helpTag)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(result => {
          if (result) {
            this.helpTopic = result;
          }
        });
    }
  }

  public fireCancel(event) {
    let payload: EventModel = new EventModel("click", event, this.cancelData, new EventElementModel("modal", null, "CloseIcon", "Close", "x"));
    payload.cargo = { ok: false, cancel: true };
    this.cancel.emit(payload);
    // Now execute ModalCommonOptions.cancel
    if (this.options.cancel) {
      this.options.cancel(payload);
    }
    this.modal.dismiss(payload);
  }

}
