import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
declare const AppConfig: IAppConfig;
import { AppConfigHelpLinkOther, IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { UxService } from '../../services/ux.service';
import { ModalCommonOptions } from '../../modal/modal-common-options';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { takeUntil } from 'rxjs/operators';
import { textChangeRangeIsUnchanged } from 'typescript';
import Tourguide from "tourguidejs";
import { HelpService } from '../../services/help.service';


@Component({
  selector: 'ib-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.css'],
  encapsulation: ViewEncapsulation.None // Use to disable CSS Encapsulation for this component
})
export class HelpButtonComponent extends BaseComponent implements OnInit {


  /**
   * The help topic to use for the help menu.  Note that we have this passed in as an input
   * property instead of just listening to this.appService.helpLinkMonitor() because this
   * button is not only used in our nav header but also on modals, etc. where we may have
   * a specific help topic assigned to us.
   */
  @Input() helpTopic: m5web.HelpLinkEditViewModel = null;

  @Input() dropDownWrapperClass: string = "";
  @Input() dropDownButtonClass: string = "";

  public get isRemoteHelpEnabled(): boolean {
    try {
      if (AppConfig.enableRemoteHelp) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  helpOtherLinks: AppConfigHelpLinkOther[] = [];

  public get showHelpIndex(): boolean {
    return this.helpService.showHelpIndex(this.appService.helpLink);
  }

  public get helpIndexLink(): string {
    return this.helpService.getHelpIndexLink();
  }

  public get showHelpOtherLinks(): boolean {
    return this.helpService.showHelpOtherLinks(this.appService.helpLink);
  }

  public get showHelpContactSupport(): boolean {
    return this.helpService.showHelpContactSupport(this.appService.helpLink);
  }

  public get helpContactSupportLink(): string {
    return this.helpService.getHelpContactSupportLink();
  }


  /**
   * True if we have help links for the current context.  This is updated when helpTopic is assigned.
   */
  public hasHelpLinks: boolean = false;

  /**
   * Helps us know if help badges should be displayed.
   */
  public now: Date = new Date();
  public helpSpotlight: boolean = false;


  constructor(
    protected appService: AppService,
    protected helpService: HelpService,
    protected uxService: UxService) {

    super();

  }

  ngOnInit(): void {
    super.ngOnInit();
    this.helpOtherLinks = this.helpService.buildOtherHelpLinks();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.helpTopic) {
      // helpTopic may be null but our config method anticipates that
      this.configHelpTopic();
    }
  }

  // ngAfterViewInit() {
  //   super.ngAfterViewInit();
  // }


  protected configHelpTopic(): void {

    this.now = new Date();
    this.helpSpotlight = false;

    if (!this.helpTopic) {
      this.hasHelpLinks = false;
      return;
    } else if (!this.helpTopic.Links) {
      this.hasHelpLinks = false;
      return;
    }

    this.hasHelpLinks = (this.helpTopic.Links.length > 0);
    if (!this.hasHelpLinks) {
      return;
    }

    // See if we have any auto-open help links or if we want to spotlight help changes
    let openedMessage: boolean = false;
    let openedTour: boolean = false;
    this.helpTopic.Links.forEach((link: m5web.HelpLinkItemEditViewModel) => {
      if (link.Description) {
        link.Description = this.appService.labelMacroSubstitution(link.Description);
      }
      if (link.BadgeUntil) {
        // Normalize string to date object for html template ngIf date compare
        link.BadgeUntil = Helper.getDateFromString(link.BadgeUntil, this.helpTopic.TimeZone);
      }
      if (link.HelpMenuSpotlightUntil && Helper.getDateFromString(link.HelpMenuSpotlightUntil, this.helpTopic.TimeZone) > this.now) {
        this.helpSpotlight = true;
      }
      let open: boolean = link.AutoOpen;
      if (!open && link.AutoOpenWhenUrlContains) {
        if (Helper.contains(location.toString(), link.AutoOpenWhenUrlContains, true)) {
          open = true;
        }
      }
      if (link.AutoOpenCanBeDisabled) {
        // See if this user disabled auto open for this link
        if (this.helpService.userHelpDisableAutoOpen[`${this.helpTopic.Context}${link.HelpLinkItemId}`]) {
          open = false;
        }
      }
      if (open) {
        if (Helper.equals(link.HelpLinkItemType, "Message", true)) {
          if (openedMessage) {
            Log.errorMessage(`Auto open of message ${link.Description} was requested but we already have one open message for help context ${this.helpTopic.Context}.`);
          } else {
            this.onMessageShow(link, null);
            openedMessage = true;
          }
        } else if (Helper.equals(link.HelpLinkItemType, "Tour", true)) {
          if (openedTour) {
            Log.errorMessage(`Auto open of tour ${link.Description} was requested but we already have one open tour for help context ${this.helpTopic.Context}.`);
          } else {
            this.onTourShow(link, null, true);
            openedTour = true;
          }
        }
      }
    });

  }


  onMessageShow(link: m5web.HelpLinkItemEditViewModel, $event) {
    this.helpService.showMessage(this.helpTopic, link, true);
  }


  onTourShow(link: m5web.HelpLinkItemEditViewModel, $event, autoOpen?: boolean) {
    this.helpService.showTour(this.helpTopic, link, autoOpen);
  }


  /**
   * Allows the user to start a RemoteJs session.
   */
  openRemoteHelpActivationModal() {

    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();

    options.title = "Remote Help";
    options.message = `<a href="https://remotejs.com/"target="_blank">RemoteJs <i class="far fa-external-link"></i></a> ` +
      `is utilized as a tool to allow support to connect to your session remotely, capture screenshots, see browser messages, and interact with the application. ` +
      `<b>Note that this is a great tool for providing you with better support but you are solely responsible ` +
      `for following your organizational policies with regards to information and screen sharing.` +
      `</b><br><br>You may end the session at any time by refreshing your browser.`;

    options.size = "large";
    options.okButtonText = "Start Session";

    const promise = this.uxService.modal.alert("", "", null, options);
    promise.then(() => {

      // Disable task status queries and app.json to cut down on the remote console noise.
      // The user is instructed to refresh the browser to end the session so these will
      // go back to normal once that happens.
      this.appService.taskStatusEnabled = false;
      this.appService.status.enableHeartbeat = false;

      this.openRemoteHelpConfirmationModal();

    }, (reason) => {
      // User clicked 'Close' so do nothing
    });
  }


  /**
   * Confirms the session is active and provides the user with further instructions as well
   * as the 'password' they can give to support. It seems a bit more secure to just give the
   * sessionId to the user instead of the full link.
   */
  protected openRemoteHelpConfirmationModal() {

    const sessionId = Helper.createBase36Guid();

    Helper.copyToClipboard(sessionId);
    this.startRemoteHelpSession(sessionId);

    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();

    options.title = "Remote Help Session is Active";
    options.message = `<b>You may end the session at any time by refreshing the browser.</b> ` +
      `When you have finished with support you should refresh the browser to sever the remote help connection.` +
      `<br><br>Your session password is: <b>${sessionId}</b> and has been copied to your clipboard. Please share ` +
      `this password with support so they may connect to your remote help session.`;

    options.size = "large";
    options.ok = null;
    options.cancelButtonText = 'Close';

    const promise = this.uxService.modal.alert("", "", null, options);
    promise.then(() => {
      // We dont need to do anything here. The session is active, we just needed a modal to give
      // further instructions to the user.
    }, (reason) => {

    });
  }


  /**
   * Uses the guid to create a RemoteJs session.
   * https://remotejs.com/
   * @param sessionId a guid that is used to give a unique session
   */
  protected startRemoteHelpSession(sessionId: string) {

    if (this.isRemoteHelpEnabled) {
      var s = document.createElement("script");
      s.src = "https://remotejs.com/agent/agent.js";
      s.setAttribute("data-consolejs-channel", sessionId);
      document.head.appendChild(s);
    }
  }
}
