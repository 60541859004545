import { Component, ElementRef, EventEmitter, NgZone, OnInit, Input, Output, SimpleChanges } from '@angular/core';
import { create, supported } from '../lib';
import { IDokaInstance, IDokaOptions }  from '../lib/doka';

// We test if Doka is supported on the current client
const isSupported = supported();

// Methods not made available on the component
const filteredComponentMethods: string[] = [
  'setOptions',
  'on',
  'off',
  'onOnce',
  'appendTo',
  'insertAfter',
  'insertBefore',
  'isAttachedTo',
  'replaceElement',
  'restoreElement',
  'destroy'
];

const outputs: string[] = [
  'oninit', 
  'onconfirm', 
  'oncancel', 
  'onclose',
  'onload', 
  'onloaderror', 
  'ondestroy', 
  'onupdate'
];

@Component({
  selector: 'lib-doka-overlay',
  template: `
    <ng-content></ng-content>
    <div>
        <div></div>
    </div>
  `,
  styles: [`
    :host {
      display: block;
      position: relative;
      overflow: hidden;
    }
    
    :host /deep/ img {
      display: block;
      width: 100%;
      height: auto;
    }
    
    :host > div {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    `]
})

export class AngularDokaOverlayComponent implements OnInit {

  private root: ElementRef;
  private zone: NgZone;
  private doka: IDokaInstance;
  private handleEvent: EventHandlerNonNull = (e:CustomEvent) => {
    const output = this[`on${e.type.split(':')[1]}`];
    const event = {...e.detail};
    delete event.doka;
    output.emit(event);
  };

  @Input() src: string|File|Blob|HTMLImageElement|HTMLCanvasElement = null;
  @Input() options: IDokaOptions = {};
  @Input() enabled: boolean = false;

  @Output() oninit: EventEmitter<any> = new EventEmitter();
  @Output() onconfirm: EventEmitter<any> = new EventEmitter();
  @Output() oncancel: EventEmitter<any> = new EventEmitter();
  @Output() onclose: EventEmitter<any> = new EventEmitter();
  @Output() onload: EventEmitter<any> = new EventEmitter();
  @Output() onloaderror: EventEmitter<any> = new EventEmitter();
  @Output() ondestroy: EventEmitter<any> = new EventEmitter();
  @Output() onupdate: EventEmitter<any> = new EventEmitter();

  constructor(root: ElementRef, zone: NgZone) {
    this.root = root;
    this.zone = zone;
  }

  ngOnInit() {}

  ngAfterViewInit() {

    // no sufficient features supported in this browser
    if (!isSupported) return;

    this.update();
  }

  ngOnChanges(changes: SimpleChanges) {
    // no need to handle first change
    if (changes.firstChange) return;

    // update!
    this.update(changes);
  }

  ngOnDestroy() {
    this.hide();
  }

  show(changes?: SimpleChanges) {

    if (this.doka) {

      // use new options object as base ( or if not available, use current options )
      const options = changes.options ? changes.options.currentValue : this.options;
      
      // update source
      if (changes.src) options.src = changes.src.currentValue;

      // set new options
      this.doka.setOptions(options);

      return;
    }

    // will block angular from listening to events inside doka
    this.zone.runOutsideAngular(() => {

      // get host child <div>
      const inner = this.root.nativeElement.querySelector('div').firstChild;

      // create instance
      this.doka = create(inner, {
        // source from slot
        src: this.src,

        // our options
        ...this.options,

        // always preview mode
        styleLayoutMode: 'preview',
        outputData: true
      });

    });

    // route events
    const dokaRoot:HTMLElement = this.doka.element;
    outputs.forEach(event => dokaRoot.addEventListener(`Doka:${event.substr(2)}`, this.handleEvent));

    // Copy instance method references to component instance
    Object.keys(this.doka)

      // remove unwanted methods
      .filter(key => filteredComponentMethods.indexOf(key) === -1)
      
      // set method references from the component instance to the doka instance
      .forEach(key => this[key] = this.doka[key]);
  }

  hide() {

    // no doka instance available
    if (!this.doka) return;

    // detach events
    const dokaRoot:HTMLElement = this.doka.element;
    outputs.forEach(event => dokaRoot.removeEventListener(`Doka:${event.substr(2)}`, this.handleEvent));

    // we done!
    this.doka.destroy();
    this.doka = null;
  }

  update(changes?: SimpleChanges) {
    if (this.enabled) {
        this.show(changes);
    }
    else {
        this.hide();
    }
  }

}
