<!--<style type="text/css" *ngIf="form.FormCss">
  {{formModel.FormCss}}
</style>-->
<form #f="ngForm">
  <div class="container-fluid p-0 loading-container"
       [ngClass]="formModel.FormClasses"
       [style]="formModel.FormStyles | safe : 'style'">
    <ng-container *ngFor="let groupModel of formModel.Groups; let i = index; trackBy: groupTrackByFn">
      <!-- *ngIf="showGroup(groupModel)" -->
      <ib-form-group-render *ngIf="groupModel.MetaData?.Properties?.Visible"
                            [formModel]="formModel"
                            [groupModel]="groupModel"
                            [groupType]="groupType"
                            [mode]="mode"
                            [data]="data"
                            [saveCount]="saveCount"
                            [dataChangeCount]="dataChangeCount"
                            [formIsReadOnly]="formIsReadOnly"
                            [loading]="loading"
                            [working]="working"
                            [contextResourceType]="contextResourceType"
                            [contextResourceId]="contextResourceId"
                            [contextResourceId2]="contextResourceId2"
                            [context]="context"
                            (controlEvent)="onControlEvent($event)"
                            (change)="onChange($event)"
                            (status)="onStatusChange($event)"
                            (addSave)="onAddSave($event)"
                            (addClose)="onAddClose($event)"
                            (editSave)="onEditSave($event)"
                            (editClose)="onEditClose($event)">
      </ib-form-group-render>
    </ng-container>
    <ngx-loading [show]="loading"
                 [config]="{animationType: 'none'}"
                 [template]="loadingTemplate"></ngx-loading>
    <ng-template #loadingTemplate>
      <div class="loading-template">
        <h3><i class="{{Constants.Layout.loadingIcon}}"></i>&nbsp;&nbsp;{{Constants.Layout.loadingText}}</h3>
      </div>
    </ng-template>
  </div>
  <div class="clearfix"></div>
</form>