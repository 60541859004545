import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { TranslationHelperService } from 'projects/core-lib/src/lib/services/translation-helper.service';
import { FormStatusModel } from 'projects/core-lib/src/lib/models/form-status-model';
import { ContactListViewModel, ContactWorkScheduleEditViewModel } from 'projects/core-lib/src/lib/models/ngModels5';

@Component({
  selector: 'ib-form-status-error-output',
  templateUrl: './form-status-error-output.component.html',
  styleUrls: ['./form-status-error-output.component.css']
})
export class FormStatusErrorOutputComponent implements OnInit, OnChanges {

  @Input() formStatus: FormStatusModel;
  @Input() classes: string;

  @Input() errorMessageRequiredSingle: string = "{{ControlNames}} is required.";
  @Input() errorMessageRequiredMultiple: string = "These items are required: {{ControlNames}}.";
  @Input() errorMessageMinimumLengthSingle: string = "{{ControlNames}} is too short.";
  @Input() errorMessageMinimumLengthMultiple: string = "These items are too short: {{ControlNames}}.";
  @Input() errorMessageMaximumLengthSingle: string = "{{ControlNames}} is too long.";
  @Input() errorMessageMaximumLengthMultiple: string = "These items are too long: {{ControlNames}}.";
  @Input() errorMessageInvalidFormatSingle: string = "{{ControlNames}} is not in a valid format.";
  @Input() errorMessageInvalidFormatMultiple: string = "These items are not in a valid format: {{ControlNames}}.";
  @Input() errorMessageOtherSingle: string = "{{ControlNames}} has error {{OtherErrorMessage}}.";
  @Input() errorMessageOtherMultiple: string = "These items have error {{OtherErrorMessage}}: {{ControlNames}}.";

  /**
  We build errors into this array based on message templates and errors found in status object.
  */
  errors: string[] = [];

  constructor(protected translate: TranslationHelperService) { }

  ngOnInit() {
    this.configure();
  }

  ngOnChanges() {
    this.configure();
    //console.error(this.formStatus);
  }

  configure() {
    this.defaultMessages();
    this.buildErrorMessages();
  }

  defaultMessages() {

    // Make sure we have some kind of error message not blank so user knows why a form is failing
    if (!this.errorMessageRequiredSingle) {
      this.errorMessageRequiredSingle = "{{ControlNames}} is required.";
    }
    if (!this.errorMessageRequiredMultiple) {
      this.errorMessageRequiredMultiple = "These items are required: {{ControlNames}}.";
    }
    if (!this.errorMessageMinimumLengthSingle) {
      this.errorMessageMinimumLengthSingle = "{{ControlNames}} is too short.";
    }
    if (!this.errorMessageMinimumLengthMultiple) {
      this.errorMessageMinimumLengthMultiple = "These items are too short: {{ControlNames}}.";
    }
    if (!this.errorMessageMaximumLengthSingle) {
      this.errorMessageMaximumLengthSingle = "{{ControlNames}} is too long.";
    }
    if (!this.errorMessageMaximumLengthMultiple) {
      this.errorMessageMaximumLengthMultiple = "These items are too long: {{ControlNames}}.";
    }
    if (!this.errorMessageInvalidFormatSingle) {
      this.errorMessageInvalidFormatSingle = "{{ControlNames}} is not in a valid format.";
    }
    if (!this.errorMessageInvalidFormatMultiple) {
      this.errorMessageInvalidFormatMultiple = "These items are not in a valid format: {{ControlNames}}.";
    }
    if (!this.errorMessageOtherSingle) {
      this.errorMessageOtherSingle = "{{ControlNames}} has error {{OtherErrorMessage}}.";
    }
    if (!this.errorMessageOtherMultiple) {
      this.errorMessageOtherMultiple = "These items have error {{OtherErrorMessage}}: {{ControlNames}}.";
    }

  }

  /**
   * Take information from our status object and build error messages in our target language.
   */
  buildErrorMessages() {

    //console.error("buildErrorMessages", this.formStatus);

    if (!this.formStatus) {
      return;
    }

    // Temp hold our errors here and when done we'll update the component error collection.
    let errors: string[] = [];

    if (this.formStatus.propertiesByErrorType) {

      // Get list of our various error types
      let keys: string[] = this.formStatus.propertiesByErrorType.keys();

      // Now step through each error type to build a message for that type
      keys.forEach(key => {

        try {

          // Get array of properties that have this error type
          let properties: string[] = this.formStatus.propertiesByErrorType.item(key);
          // Translate each of the properties as they'll be used as inputs to the error message which we'll also translate with a properties placeholder
          let translatedProperties: string[] = [];
          properties.forEach(property => {
            translatedProperties.push(this.translate.getTranslation(property));
          });

          //console.error("key", key, "values", translatedProperties);

          // Now based on the error type pick the right error message to use and translate that passing in the translated property names.
          // Known key types: required, min, max, format ... there may also be custom types which we'll translate as a message even
          // though it may not be in a friendly format and pass along so we can at least see it and then perhaps render it better moving forward.
          let translatedError: string = "";
          if (Helper.equals(key, "required")) {
            if (translatedProperties.length === 1) {
              translatedError = this.translate.getTranslation(this.errorMessageRequiredSingle, { ControlNames: translatedProperties[0] });
            } else {
              translatedError = this.translate.getTranslation(this.errorMessageRequiredMultiple, { ControlNames: translatedProperties.join(", ") });
            }
          } else if (Helper.equals(key, "min")) {
            if (translatedProperties.length === 1) {
              translatedError = this.translate.getTranslation(this.errorMessageMinimumLengthSingle, { ControlNames: translatedProperties[0] });
            } else {
              translatedError = this.translate.getTranslation(this.errorMessageMinimumLengthMultiple, { ControlNames: translatedProperties.join(", ") });
            }
          } else if (Helper.equals(key, "max")) {
            if (translatedProperties.length === 1) {
              translatedError = this.translate.getTranslation(this.errorMessageMaximumLengthSingle, { ControlNames: translatedProperties[0] });
            } else {
              translatedError = this.translate.getTranslation(this.errorMessageMaximumLengthMultiple, { ControlNames: translatedProperties.join(", ") });
            }
          } else if (Helper.equals(key, "format")) {
            if (translatedProperties.length === 1) {
              translatedError = this.translate.getTranslation(this.errorMessageInvalidFormatSingle, { ControlNames: translatedProperties[0] });
            } else {
              translatedError = this.translate.getTranslation(this.errorMessageInvalidFormatMultiple, { ControlNames: translatedProperties.join(", ") });
            }
          } else {
            let translatedErrorType = this.translate.getTranslation(key);
            if (translatedProperties.length === 1) {
              translatedError = this.translate.getTranslation(this.errorMessageOtherSingle, { ControlNames: translatedProperties[0], OtherErrorMessage: translatedErrorType });
            } else {
              translatedError = this.translate.getTranslation(this.errorMessageOtherMultiple, { ControlNames: translatedProperties.join(", "), OtherErrorMessage: translatedErrorType });
            }
          }

          // If we have an error push it into our list
          if (translatedError) {
            errors.push(translatedError);
          }

        } catch (err) {
          Log.errorMessage(err);
        }

      });
    }

    // Now if we have any custom error messages then translate and push them into our errors.
    if (this.formStatus.customErrorMessages && this.formStatus.customErrorMessages.length > 0) {
      this.formStatus.customErrorMessages.forEach(error => {
        let translatedCustomError = this.translate.getTranslation(error);
        errors.push(translatedCustomError);
      });
    }

    // Update the component error list
    this.errors = [...errors];

  }

}
