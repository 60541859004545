<div class="{{inputWrapperClass}} {{wrapperClasses}}" [style]="wrapperStyles | safe : 'style'">
  <span *ngIf="required && includeRequiredIcon">
    <ib-icon icon="exclamation-circle (solid)" ngbTooltip="Required"></ib-icon>&nbsp;
  </span>
  <span *ngIf="prefixText || prefixIcon"
        class="input-group-text"
        ngbTooltip="{{prefixTooltip | translate}}"
        [ngClass]="{'clickable': isPrefixClickable()}"
        (click)="firePrefixClick($event)">
    {{prefixText | translate}}
    <ib-icon icon="{{prefixIcon}}"></ib-icon>
  </span>
  <input type="{{inputType}}" class="form-control {{inputSize}} {{inputClasses}} {{controlClass}}"
    [style]="inputStyles | safe : 'style'" id="{{inputControlId}}" name="{{name}}"
    placeholder="{{placeholder | translate}}" ngbTooltip="{{tooltip | translate}}" [ngbTypeahead]="typeaheadSearch"
    pattern="{{pattern}}" [maxlength]="maxlength" [minlength]="minlength" [autofocus]="autofocus ? 'autofocus' : null"
    [disabled]="disabled ? 'disabled' : null" [readonly]="readonly ? 'readonly' : null"
    [required]="required ? 'required' : null" (focus)="fireFocus($event, inputControl)"
    (blur)="fireBlur($event, inputControl)" (keyup)="fireKeyUp($event, inputControl)"
    (change)="fireChange($event, inputControl)" [(ngModel)]="value" [ngModelOptions]="{standalone: standalone}"
    #inputControl="ngModel" #rawControl />

  <span *ngIf="suffixShow && ( suffixText || suffixIcon )"
        class="input-group-text"
        ngbTooltip="{{suffixTooltip | translate}}"
        [ngClass]="{'clickable': isSuffixClickable()}"
        (click)="fireSuffixClick($event)">
    {{suffixText | translate}}
    <ib-icon icon="{{suffixIcon}}"></ib-icon>
  </span>

  <span *ngIf="suffixShow2 && ( suffixText2 || suffixIcon2 )"
        class="input-group-text {{suffixClass2}}"
        ngbTooltip="{{( suffixTooltip2 | translate ) || suffixTooltip2}}"
        [ngClass]="{'clickable': isSuffixClickable2()}"
        (click)="fireSuffixClick2($event)">
    {{( suffixText2 | translate ) || suffixText2}}
    <ib-icon icon="{{suffixIcon2}}"></ib-icon>
  </span>

  <div *ngIf="inputControl.invalid && (inputControl.dirty || inputControl.touched)">
    <small *ngFor="let errorMessage of errorMessages" class="form-text input-invalid input-error-message">
      {{errorMessage | translate:inputInformationValues}}
    </small>
  </div>
</div>
