import { Component, OnInit } from '@angular/core';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiProperties, ApiOperationType, IApiResponseWrapper, IApiResponseWrapperTyped, ApiCall } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { ApiModuleSecurity } from 'projects/core-lib/src/lib/api/Api.Module.Security';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { AlertItemType } from 'projects/common-lib/src/lib/alert/alert-manager';

@Component({
  selector: 'ib-multi-factor-authentication-step-two',
  templateUrl: './multi-factor-authentication-step-two.component.html',
  styleUrls: ['./multi-factor-authentication-step-two.component.css']
})
export class MultiFactorAuthenticationStepTwoComponent extends BaseComponent implements OnInit {

  public user: m5sec.AuthenticatedUserViewModel;
  public mfaReason: m.StandardResultCode;
  public data: m5sec.MultiFactorAuthenticationRequestViewModel;
  public apiProperties: ApiProperties;
  public apiCall: ApiCall;
  public errorMessage: string = "";
  public returnUrl: string = "";
  public rememberMe: boolean = false;
  public title: string = "Verify Your Identity";
  public selectedOptionDescription: string = "";
  public authenticationCode: string = "";

  constructor(
    protected appService: AppService,
    protected apiService: ApiService,
    protected route: ActivatedRoute,
    protected router: Router) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    this.user = Helper.sessionStorageGetObject<m5sec.AuthenticatedUserViewModel>(Constants.LocalStorage.UserObjectPending, null);
    this.data = new m5sec.MultiFactorAuthenticationRequestViewModel();
    this.apiProperties = ApiModuleSecurity.SecurityMultiFactorAuthentication();
    this.apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Call);

    // See if we have a return url to redirect to after successful login
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"];
    this.rememberMe = this.route.snapshot.queryParams["rememberMe"] || false;

    if (this.user) {
      this.mfaReason = (<any>this.user).LoginResultCode;
      if (this.user.MultiFactorAuthenticationTarget) {
        this.selectedOptionDescription = "";
        if (Helper.startsWith(this.user.MultiFactorAuthenticationTarget.Method, "E", true)) {
          this.selectedOptionDescription = `Email to: ${this.user.MultiFactorAuthenticationTarget.Target}`;
        } else if (Helper.startsWith(this.user.MultiFactorAuthenticationTarget.Method, "S", true)) {
          this.selectedOptionDescription = `Text message to: ${this.user.MultiFactorAuthenticationTarget.Target}`;
        } else if (Helper.startsWith(this.user.MultiFactorAuthenticationTarget.Method, "P", true)) {
          this.selectedOptionDescription = `Voice call to: ${this.user.MultiFactorAuthenticationTarget.Target}`;
        } else if (Helper.startsWith(this.user.MultiFactorAuthenticationTarget.Method, "T", true)) {
          this.selectedOptionDescription = `Authenticator Application`;
        } else {
          this.selectedOptionDescription = this.user.MultiFactorAuthenticationTarget.Target;
        }
      }
    } else {
      Log.errorMessage(`The MFA process could not find user object ${Constants.LocalStorage.UserObjectPending} in session storage.`);
      const error: string = "We were unable to find the required information for multi-factor authentication.  Please try again.  If you continue to see this error please contact your system administrator.";
      this.returnToLogin(error);
    }

  }


  returnToLogin(error: string) {
    if (error) {
      // Our login component clears alert messages so use session storage for a sticky login error
      Helper.sessionStorageSave(Constants.LocalStorage.LoginErrorMessage, error);
    }
    const params: any = {};
    if (this.returnUrl) {
      params.returnUrl = this.returnUrl;
    }
    if (this.rememberMe) {
      params.rememberMe = this.rememberMe;
    }
    this.router.navigate(["/", "login"], { queryParams: params });
  }


  returnToStep1() {
    const params: any = { retry: true };
    if (this.returnUrl) {
      params.returnUrl = this.returnUrl;
    }
    if (this.rememberMe) {
      params.rememberMe = this.rememberMe;
    }
    this.router.navigate(["/", "login", "mfa-step-1"], { queryParams: params });
  }


  registerApp() {
    const params: any = {};
    if (this.returnUrl) {
      params.returnUrl = this.returnUrl;
    }
    if (this.rememberMe) {
      params.rememberMe = this.rememberMe;
    }
    this.router.navigate(["/", "login", "mfa-app-registration"], { queryParams: params });
  }

  next($event) {
    this.errorMessage = "";
    if (!this.authenticationCode) {
      this.errorMessage = "You must enter an authentication code in order to continue.";
      return;
    }
    this.data = new m5sec.MultiFactorAuthenticationRequestViewModel();
    this.data.ContactId = this.user.ContactId;
    this.data.Login = this.user.Login;
    if (this.user.MultiFactorAuthenticationTarget) {
      this.data.Method = this.user.MultiFactorAuthenticationTarget.Method;
      this.data.Target = this.user.MultiFactorAuthenticationTarget.Target;
      this.data.Source = this.user.MultiFactorAuthenticationTarget.Source;
    }
    this.data.Value = this.authenticationCode;
    this.apiService.call(this.apiCall, this.data).subscribe((response: IApiResponseWrapperTyped<m5sec.AuthenticatedUserViewModel>) => {
      if (response.Data.Success) {
        // Success here means login was successful
        if (response.Data.Data.Token) {
          this.appService.userSet(response.Data.Data, this.rememberMe);
          if (this.returnUrl) {
            // Go to the page that we were on when we got redirected to login.
            // It's tempting in this situation to want to manipulate the history
            // so the user hitting a form back button goes to table view instead
            // of this login page but history cannot be manipulated leaving the
            // best option to be doing window.location.replace() as a hack but
            // that doesn't help with multi-step logins (e.g. MFA) so we'll just
            // live with the fact that "back" button does browser back even if
            // that's not preferred.
            this.router.navigateByUrl(this.returnUrl);
          } else {
            // Go to home page
            this.appService.redirectToHome();
          }
        } else {
          if (response.Data.Message) {
            this.errorMessage = response.Data.Message;
          } else {
            Log.errorMessage("Response reported success but was missing mandatory token value.")
            Log.errorMessage(response.Data);
            this.errorMessage = "Unknown error.";
          }
        }
      } else {
        if (response.Data.Message) {
          this.errorMessage = response.Data.Message;
        } else {
          Log.errorMessage(response.Data);
          this.errorMessage = "Unknown error.";
        }
      }
    });
  }


}
