<div class="row" style="padding-top:20px;">
  <div class="{{Constants.Layout.percent25Offset}} {{Constants.Layout.split2column}}">
    <div class="card">
      <h5 class="card-header">Reset Password</h5>
      <div class="card-body">
        <ib-input-text name="Login" label="Login" [autofocus]="true" (keyUpIsEnter)="sendResetPasswordLink($event)" [(ngModel)]="data.Login"></ib-input-text>
        <ib-input-wrapper label="">
          <ib-button color="success" label="Reset Password" (click)="sendResetPasswordLink($event)"></ib-button>
          <a [routerLink]="['/login']" class="ms-4">Return to login</a>
        </ib-input-wrapper>
        <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
          {{errorMessage}}
        </div>
        <div *ngIf="responseMessage" class="alert alert-warning" role="alert">
          {{responseMessage}}
        </div>
      </div>
    </div>
  </div>
</div>
