import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, ChangeDetectionStrategy, ElementRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as Enumerable from 'linq';
import { UxService } from '../../services/ux.service';
import { ReportCompilerLibraryEntryScoreCalculationModel } from 'projects/core-lib/src/lib/models/ngModelsReportCompiler5';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputPlainComponent),
  multi: true
};

@Component({
  selector: 'ib-input-plain',
  templateUrl: './input-plain.component.html',
  styleUrls: ['./input-plain.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputPlainComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  @Input() public maxlength: number = 524288; // default
  @Input() public minlength: number;

  @Input() public wrapperClasses: string = "";
  @Input() public wrapperStyles: string = "";
  @Input() public inputClasses: string = "";
  @Input() public inputStyles: string = "";

  @Input() public type: string = "text";
  public inputType: string = "text";


  @ViewChild("rawControl", { static: false }) public rawControl: ElementRef;


  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
    // Flag we are doing plain input to limit what wrapper classes get included
    this.plainInput = true;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }


  ngAfterViewInit() {
    //super.ngAfterViewInit();
    // console.error("plain text input id", this.inputControlId);
    // console.error("raw control", this.rawControl);
    // console.error("autofocus", this.autofocus);
    if (this.rawControl && this.autofocus) {
      //console.error("setting focus on native input");
      this.rawControl.nativeElement.focus();
    }
  }

  public configure() {

    // Call the base class configure method to handle a lot of this
    super.configure();

    if (this.type) {
      this.inputType = this.type;
    } else {
      this.inputType = "text";
    }

    // Forms created from database may have 0 for max length which should be interpreted as no max
    if (!this.maxlength) {
      this.maxlength = 524288; // default for html input
    }

  }


  typeaheadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : Enumerable.from(this.pickList).where(v => Helper.startsWith(v.Value, term, true)).take(10).select(s => s.Value).toArray())
    );

}
