import { Input, ViewChild, OnInit, OnChanges, OnDestroy, AfterViewInit, SimpleChanges, Output, EventEmitter, Directive } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { Log } from 'projects/core-lib/src/lib/helpers/helper';
import { NgForm } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { FormStatusModel } from 'projects/core-lib/src/lib/models/form-status-model';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';

@Directive()
export abstract class DataEditorFormModalWrapperBaseClass<T> extends BaseComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() public options: ModalCommonOptions = new ModalCommonOptions();
  @Input() public data: T;
  @Input() public disabled: boolean = false;
  @Input() public mode: "add" | "edit" | "view" = "edit";

  /**
   * Form status of the form we are hosting in this modal.  This enables us to show errors in the modal,
   * know if we are allowed to click ok button or not, etc.
   */
  public formStatus: FormStatusModel = new FormStatusModel();

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validateParameters();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.validateParameters();
  }

  // ngOnDestroy() {
  //   super.ngOnDestroy();
  // }

  // ngAfterViewInit() {
  //   super.ngAfterViewInit();
  // }


  /**
   * Validate input parameters to make sure they are supported values.
   */
  protected validateParameters() {

    if (!this.options) {
      this.options = new ModalCommonOptions();
    }

    // We need text or icon on at least one of our buttons
    if (!this.options.okButtonText && !this.options.okButtonIcon && !this.options.cancelButtonText && !this.options.cancelButtonIcon) {
      this.options.okButtonText = "Ok";
    }

    //console.error(this.form);
    //console.error(this.data);

  }

  onFormStatusChange($event) {
    this.formStatus = $event.data;
  }

}
