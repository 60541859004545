<div class="form-group {{inputFormGroupClass}} {{outerClass}}">
  <label for="{{inputControlId}}"
         class="{{inputLabelClass}}">
    {{labelPrefix}}{{( label | translate ) || label}}{{labelSuffix}}
    <div *ngIf="suffixShow && ( suffixText || suffixIcon )"
         style="display:inline;"
         [ngClass]="{'clickable': isSuffixClickable()}">
      <span class="{{suffixClass}}"
            ngbTooltip="{{( suffixTooltip | translate ) || suffixTooltip}}"
            (click)="fireSuffixClick($event)">
        {{(suffixText | translate ) || suffixText}}
        <ib-icon icon="{{suffixIcon}}"></ib-icon>
      </span>
    </div>
  </label>
  <div class="{{inputWrapperClass}} {{controlClass}}"
       style="padding-top: 5px;">
    <ng-content></ng-content>
  </div>
</div>
