import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputTextareaComponent),
  multi: true
};

@Component({
  selector: 'ib-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputTextareaComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  @Input() rows: number = 5; // default
  @Input() maxRows: number = 10; // this is used if autoExpand is enabled
  @Input() fullWidth: boolean = false;
  @Input() wrap: string = "soft";
  @Input() autoExpand: boolean = false;
  @Input() wordWrap: boolean = true;

  constructor(
    protected apiService: ApiService,
    protected uxService: UxService,
    protected sanitizer: DomSanitizer) {
    super(apiService, uxService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }

  public configure() {

    // Call the base class configure method to handle a lot of this
    super.configure();

    // If we're full width then reset some CSS
    if (this.fullWidth) {
      this.inputWrapperClass = this.Constants.Layout.fullWidth;
      // If we have prefix and/or suffix we have additional wrapper class to insert
      if (this.prefixText || this.prefixIcon || this.suffixText || this.suffixIcon) {
        this.inputWrapperClass += " input-group";
      }
    }

    // Forms created from database may have 0 for max length which should be interpreted as no max
    if (!this.maxlength) {
      this.maxlength = Number.MAX_SAFE_INTEGER;
    }

  }


}
