import { Component } from '@angular/core';
import { NavService } from 'projects/common-lib/src/lib/ux/nav/nav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  constructor(public nav: NavService) {
  }

}
