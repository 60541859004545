<div class="pull-left" *ngIf="showStats">
  <span *ngIf="counters.countUploading || counters.countPending" class="badge bg-info" style="font-size:100%; margin-right:10px;">
    <i class="fal fa-spinner fa-spin"></i>&nbsp;
    {{counters.countUploading | i18nPlural : {'=0': 'Uploading no files', '=1': 'Uploading one file', 'other': 'Uploading # files'} }}
    {{counters.countPending | i18nPlural : {'=0': '', '=1': ' (one file pending)', 'other': ' (# files pending)'} }}
  </span>
  <span *ngIf="counters.countSuccess" class="badge bg-success" style="font-size:100%; margin-right:10px;">
    <i class="fal fa-check"></i>&nbsp;
    {{counters.countSuccess | i18nPlural : {'=0': 'No files uploaded', '=1': 'One file uploaded', 'other': '# files uploaded'} }}
  </span>
  <span *ngIf="counters.countError" class="badge bg-danger" style="font-size:100%; margin-right:10px;">
    <i class="fal fa-times"></i>&nbsp;
    {{counters.countError | i18nPlural : {'=0': 'No errors', '=1': 'One error', 'other': '# errors'} }}
  </span>
</div>
