<ib-modal-common-title [options]="options"
                       [modal]="modal"
                       [cancelData]="null"
                       (cancel)="options.cancel">
</ib-modal-common-title>
<div class="modal-body">
  <ib-standard-table [options]="tableOptions"
                     [reloadCount]="tableReloadCount"
                     [headerData]="selectedContacts"
                     (rowSelect)="onRowSelected($event)">
  </ib-standard-table>
</div>
<ib-modal-common-buttons [options]="options"
                         [modal]="modal"
                         [okData]="data"
                         [cancelData]="null"
                         (ok)="options.ok"
                         (cancel)="options.cancel">
</ib-modal-common-buttons>
