import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AppCacheService } from 'projects/core-lib/src/lib/services/app-cache.service';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { CacheLevel, ApiProperties, ApiCall, ApiOperationType, Query, IApiResponseWrapperTyped, IApiResponseWrapper } from 'projects/core-lib/src/lib/api/ApiModels';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { Observable, of, Subject } from 'rxjs';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { map, takeUntil } from 'rxjs/operators';
import * as Enumerable from 'linq';
import { AssetSelectionRuleOptions } from 'projects/core-lib/src/lib/models/model-helpers';
import { Router } from '@angular/router';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { Dictionary } from '../models/dictionary';
import { BaseService } from './base.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { SystemService } from './system.service';
import { CodeEditorService } from './code-editor.service';
import { differenceInMinutes, format, startOfDay } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class AssetService extends BaseService {

  protected apiProperties: ApiProperties = Api.Asset();
  protected apiCallList: ApiCall;
  protected apiCallFull: ApiCall;

  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected codeEditorService: CodeEditorService,
    protected cache: AppCacheService,
    protected sanitizer: DomSanitizer,
    protected router: Router,
    protected ngbModalService: NgbModal) {

    super();
    this.apiCallList = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.List);
    this.apiCallList.silent = true;
    this.apiCallFull = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Get);
    this.apiCallFull.silent = true;

  }



  public selectionRulesEnableDropDownGrouping(options: AssetSelectionRuleOptions) {
    // If we're using a pick list and it has groups then turn on grouping so we can render in our ui
    if (options.value01UsePickList) {
      options.value01UsePickListGroups = options.value01PickList.some(x => x.GroupText !== "");
    }
    if (options.value02UsePickList) {
      options.value02UsePickListGroups = options.value02PickList.some(x => x.GroupText !== "");
    }
    if (options.value03UsePickList) {
      options.value03UsePickListGroups = options.value03PickList.some(x => x.GroupText !== "");
    }
    if (options.value04UsePickList) {
      options.value04UsePickListGroups = options.value04PickList.some(x => x.GroupText !== "");
    }
    if (options.value05UsePickList) {
      options.value05UsePickListGroups = options.value05PickList.some(x => x.GroupText !== "");
    }
    if (options.value06UsePickList) {
      options.value06UsePickListGroups = options.value06PickList.some(x => x.GroupText !== "");
    }
    if (options.value07UsePickList) {
      options.value07UsePickListGroups = options.value07PickList.some(x => x.GroupText !== "");
    }
    if (options.value08UsePickList) {
      options.value08UsePickListGroups = options.value08PickList.some(x => x.GroupText !== "");
    }
    if (options.value09UsePickList) {
      options.value09UsePickListGroups = options.value09PickList.some(x => x.GroupText !== "");
    }
    if (options.value10UsePickList) {
      options.value10UsePickListGroups = options.value10PickList.some(x => x.GroupText !== "");
    }
  }


  public selectionRulesPopulateCascadingDropDowns(data: m5.AssetSelectionEditViewModel, options: AssetSelectionRuleOptions) {
    if (!data || !options) {
      return;
    }
    //console.error("populate cascade ddl", data, options);
    // Initialize cascading drop downs with our initial values
    // Step through the 10 properties
    for (let sourceRuleNumber: number = 1; sourceRuleNumber <= 10; sourceRuleNumber++) {
      // Get the value used for property/value for source
      const paddedSourceRuleNumber = Helper.pad(sourceRuleNumber, 2, "0");
      const sourceValue = data[`Value${paddedSourceRuleNumber}`];
      // Step through the 10 properties
      for (let targetRuleNumber: number = 1; targetRuleNumber <= 10; targetRuleNumber++) {
        const paddedTargetRuleNumber = Helper.pad(targetRuleNumber, 2, "0");
        // Check if the target cascades from the source
        if (options[`value${paddedTargetRuleNumber}PickListCascadeFrom`] === sourceRuleNumber) {
          // Populate the target pick list with the children of the source pick list selected value or if there
          // was no source pick list selected value and we have a substitute list for that scenario then use that.
          if (!sourceValue && options[`value${paddedTargetRuleNumber}PickListWhenCascadeFromHasEmptyValue`]) {
            // TODO is deep copy needed here?!?!?
            //options[`value${paddedTargetRuleNumber}PickList`] = Helper.deepCopy(options[`value${paddedTargetRuleNumber}PickListWhenCascadeFromHasEmptyValue`]);
            options[`value${paddedTargetRuleNumber}PickList`] = options[`value${paddedTargetRuleNumber}PickListWhenCascadeFromHasEmptyValue`];
          } else {
            // options[`value${paddedTargetRuleNumber}PickList`] = Enumerable.from<m5core.PickListSelectionViewModel>(options[`value${paddedSourceRuleNumber}PickList`])
            //   .firstOrDefault(x => x.Value === sourceValue, new m5core.PickListSelectionViewModel()).Children;
            options[`value${paddedTargetRuleNumber}PickList`] =
              (options[`value${paddedSourceRuleNumber}PickList`]
                .find(x => x.Value === sourceValue) ??
                new m5core.PickListSelectionViewModel()
              ).Children;
            //console.error("source", sourceRuleNumber, sourceValue, "target", targetRuleNumber, options[`value${paddedTargetRuleNumber}PickList`]);
          }
        }
      }
    }
    // Our cascade choices may have impacted grouping being on or off
    this.selectionRulesEnableDropDownGrouping(options);
  }

  public selectionRulesApplyCascadingDropDownChange(data: m5.AssetSelectionEditViewModel, options: AssetSelectionRuleOptions, ruleThatChanged: number) {

    const paddedRuleThatChanged = Helper.pad(ruleThatChanged, 2, "0");

    // Get the value of the property/value that changed
    const valueThatChanged = data[`Value${paddedRuleThatChanged}`];

    // We can have cascading to cascading to cascading lists so keep a list of all rules impacted by this one change
    const resetList: number[] = [];

    // Step through the 10 properties
    for (let targetRuleNumber: number = 1; targetRuleNumber <= 10; targetRuleNumber++) {
      // Check if the target cascades from the rule that was changed
      if (options[`value${Helper.pad(targetRuleNumber, 2, "0")}PickListCascadeFrom`] === ruleThatChanged) {
        // Push into our rules to reset list
        resetList.push(targetRuleNumber);
      }
    }

    // Now check next level cascade to see if we have even more rules to reset ... do this a few times
    for (let i: number = 0; i < 4; i++) {
      const max = resetList.length - 1;
      for (let sourceResetSlot: number = 0; sourceResetSlot <= max; sourceResetSlot++) {
        // Step through the 10 properties
        for (let targetRuleNumber: number = 1; targetRuleNumber <= 10; targetRuleNumber++) {
          // Check if the target cascades from the rule that was changed
          if (options[`value${Helper.pad(targetRuleNumber, 2, "0")}PickListCascadeFrom`] === resetList[sourceResetSlot]) {
            // Push into our rules to reset list
            if (resetList.indexOf(targetRuleNumber) === -1) {
              resetList.push(targetRuleNumber);
            }
          }
        }
      }
    }

    // Debug
    //console.error("rule that changed = " + ruleThatChanged);
    //console.error("selected value = " + value);
    //console.error("reset list = " + JSON.stringify(resetList));

    // Now step through all of the rules that need to be reset and clear them
    let max = resetList.length - 1;
    for (let sourceResetSlot: number = 0; sourceResetSlot <= max; sourceResetSlot++) {
      // Step through the 10 properties
      for (let targetRuleNumber: number = 1; targetRuleNumber <= 10; targetRuleNumber++) {
        const paddedTargetRuleNumber = Helper.pad(targetRuleNumber, 2, "0");
        // Check if the target cascades from the rule that was changed
        if (options[`value${paddedTargetRuleNumber}PickListCascadeFrom`] === resetList[sourceResetSlot]) {
          // Clear the current value and pick list
          data[`Value${paddedTargetRuleNumber}`] = "";
          // Populate the target pick list with list to use when cascade parent is empty (unless we cascade from the rule that changed) or with an empty array
          if (options[`value${paddedTargetRuleNumber}PickListCascadeFrom`] !== ruleThatChanged && options[`value${paddedTargetRuleNumber}PickListWhenCascadeFromHasEmptyValue`]) {
            // TODO is deep copy needed here?!?!?
            //options[`value${paddedTargetRuleNumber}PickList`] = Helper.deepCopy(options[`value${paddedTargetRuleNumber}PickListWhenCascadeFromHasEmptyValue`]);
            options[`value${paddedTargetRuleNumber}PickList`] = options[`value${paddedTargetRuleNumber}PickListWhenCascadeFromHasEmptyValue`];
          } else {
            options[`value${paddedTargetRuleNumber}PickList`] = [];
          }
        }
      }
    }

    // We are now finally ready to load the pick list for those that cascade from the rule that was actually changed
    // Step through the 10 properties
    for (let targetRuleNumber: number = 1; targetRuleNumber <= 10; targetRuleNumber++) {
      // Check if the target cascades from the rule that was changed then load up that pick list
      const paddedTargetRuleNumber = Helper.pad(targetRuleNumber, 2, "0");
      if (options[`value${paddedTargetRuleNumber}PickListCascadeFrom`] === ruleThatChanged) {
        // Populate the target pick list with the children of the source pick list selected value
        // Populate the target pick list with the children of the source pick list selected value or if there
        // was no source pick list selected value and we have a substitute list for that scenario then use that.
        if (!valueThatChanged && options[`value${paddedTargetRuleNumber}PickListWhenCascadeFromHasEmptyValue`]) {
          // TODO is deep copy needed here?!?!?
          //options[`value${paddedTargetRuleNumber}PickList`] = Helper.deepCopy(options[`value${paddedTargetRuleNumber}PickListWhenCascadeFromHasEmptyValue`]);
          options[`value${paddedTargetRuleNumber}PickList`] = options[`value${paddedTargetRuleNumber}PickListWhenCascadeFromHasEmptyValue`];
        } else {
          // options[`value${paddedTargetRuleNumber}PickList`] = Enumerable.from<m5core.PickListSelectionViewModel>(options[`value${paddedRuleThatChanged}PickList`])
          //   .firstOrDefault(x => x.Value === valueThatChanged, new m5core.PickListSelectionViewModel()).Children;
          options[`value${paddedTargetRuleNumber}PickList`] =
            (options[`value${paddedRuleThatChanged}PickList`]
              .find(x => x.Value === valueThatChanged) ??
              new m5core.PickListSelectionViewModel()
            ).Children;
        }
      }
    }

    // Our cascade choices may have impacted grouping being on or off
    this.selectionRulesEnableDropDownGrouping(options);

  }


  public selectionRulesApplyNewPickListValue(data: m5.AssetSelectionEditViewModel,
    options: AssetSelectionRuleOptions,
    ruleThatChanged: number,
    newPickListValue: m5core.PickListValueEditViewModel) {

    //console.error("ready to add", data, options, ruleThatChanged, newPickListValue);

    // Translate edit view model to selection view model
    const newPickListSelection: m5core.PickListSelectionViewModel = Helper.pickListValueModelToSelectionModel(newPickListValue);

    const paddedRuleThatChanged = Helper.pad(ruleThatChanged, 2, "0");

    // Assign the value to the data
    data[`Value${paddedRuleThatChanged}`] = newPickListValue.Value;

    // Push the new pick list value into the pick list for this rule
    const pickListSelections: m5core.PickListSelectionViewModel[] = options[`value${paddedRuleThatChanged}PickList`];
    //console.error("ready to add value to ", pickListSelections);
    if (!pickListSelections.some(x => x.PickListValueId === newPickListValue.PickListValueId)) {
      pickListSelections.push(newPickListSelection);
    }
    const pickListSelectionsAll: m5core.PickListSelectionViewModel[] = options[`value${paddedRuleThatChanged}PickListWhenCascadeFromHasEmptyValue`];
    //console.error("ready to add value to ALL ", pickListSelectionsAll);
    if (!pickListSelectionsAll.some(x => x.PickListValueId === newPickListValue.PickListValueId)) {
      pickListSelectionsAll.push(newPickListSelection);
    }

    // Find the parent list that we need to push the new pick list value into
    if (newPickListValue.ParentPickListId) {
      const cascadeFrom = options[`value${paddedRuleThatChanged}PickListCascadeFrom`];
      //console.error("parent pick list id", newPickListValue.ParentPickListId, `Rule ${ruleThatChanged} cascades from ${cascadeFrom}.`);
      if (cascadeFrom) {
        const paddedCascadeFrom = Helper.pad(cascadeFrom, 2, "0");
        const parentPickListSelections: m5core.PickListSelectionViewModel[] = options[`value${paddedCascadeFrom}PickList`];
        if (parentPickListSelections && parentPickListSelections.length > 0) {
          const parentPickListSelection = parentPickListSelections.find(x => x.PickListValueId === newPickListValue.ParentPickListValueId);
          //console.error("parent pick list selection that needs child added", parentPickListSelection);
          if (parentPickListSelection) {
            if (!parentPickListSelection.Children) {
              parentPickListSelection.Children = [];
            }
            if (!parentPickListSelection.Children.some(x => x.PickListValueId === newPickListValue.PickListValueId)) {
              parentPickListSelection.Children.push(newPickListSelection);
            }
          }
        }
        const parentPickListSelectionsAll: m5core.PickListSelectionViewModel[] = options[`value${paddedCascadeFrom}PickListWhenCascadeFromHasEmptyValue`];
        if (parentPickListSelectionsAll && parentPickListSelectionsAll.length > 0) {
          const parentPickListSelectionFromAll = parentPickListSelectionsAll.find(x => x.PickListValueId === newPickListValue.ParentPickListValueId);
          if (parentPickListSelectionFromAll) {
            //console.error("parent pick list selection ALL that needs child added", parentPickListSelectionFromAll);
            if (!parentPickListSelectionFromAll.Children) {
              parentPickListSelectionFromAll.Children = [];
            }
            if (!parentPickListSelectionFromAll.Children.some(x => x.PickListValueId === newPickListValue.PickListValueId)) {
              parentPickListSelectionFromAll.Children.push(newPickListSelection);
            }
          }
        }
      }
    }

    // Finally we now update cascading drop down data sources
    this.selectionRulesPopulateCascadingDropDowns(data, options);

  }



  getSelectionRuleObject(options: AssetSelectionRuleOptions, assetId: number): m5.AssetSelectionEditViewModel {
    const rule = new m5.AssetSelectionEditViewModel();
    if (options) {
      rule.Property01 = options.property01;
      rule.Property02 = options.property02;
      rule.Property03 = options.property03;
      rule.Property04 = options.property04;
      rule.Property05 = options.property05;
      rule.Property06 = options.property06;
      rule.Property07 = options.property07;
      rule.Property08 = options.property08;
      rule.Property09 = options.property09;
      rule.Property10 = options.property10;
    }
    if (assetId) {
      rule.AssetId = assetId;
    }
    return rule;
  }


  public getAssetLicenses(cacheIgnore: boolean = false, reportErrors: boolean = true): Observable<m5.AssetLicenseViewModel[]> {
    const apiProp = Api.AssetLicense();
    const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.List);
    apiCall.silent = true;
    apiCall.cacheUseStorage = true;
    apiCall.cacheIgnoreOnRead = cacheIgnore;
    const query = new Query();
    return this.apiService.execute(apiCall, query).pipe(
      map((result: IApiResponseWrapperTyped<m5.AssetLicenseViewModel[]>) => {
        if (result.Data.Success && result.Data.Data && result.Data.Data.length > 0) {
          return result.Data.Data;
        } else {
          if (reportErrors) {
            this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
          }
          return [];
        }
      }),
      takeUntil(this.ngUnsubscribe));
  }



  public getAssetListObject(assetId: number, cacheIgnore: boolean = false): Observable<m5.AssetListViewModel> {
    const query: Query = new Query();
    query.Filter = `AssetId == ${assetId}`;
    this.apiCallList.cacheIgnoreOnRead = cacheIgnore;
    return this.apiService.execute(this.apiCallList, query).pipe(
      map((result: IApiResponseWrapperTyped<m5.AssetListViewModel[]>) => {
        if (result.Data.Success && result.Data.Data && result.Data.Data.length > 0) {
          return result.Data.Data[0];
        } else {
          return null;
        }
      }),
      takeUntil(this.ngUnsubscribe));
  }

  public getAssetFullObject(assetId: number, cacheIgnore: boolean = false): Observable<m5.AssetEditViewModel> {
    this.apiCallFull.cacheIgnoreOnRead = cacheIgnore;
    return this.apiService.execute(this.apiCallFull, assetId).pipe(
      map((result: IApiResponseWrapperTyped<m5.AssetEditViewModel>) => {
        if (result.Data.Success && result.Data.Data) {
          return result.Data.Data;
        } else {
          return null;
        }
      }),
      takeUntil(this.ngUnsubscribe));
  }


  public fromEditToListObject(obj: any): any {
    // Our asset edit object is missing a couple of properties we have in our list object so map a couple of properties that we want to see
    obj.AddedDateTime = obj.MetaData.AddedDateTime;
    obj.AddedByContactId = obj.MetaData.AddedByContactId;
    obj.AddedByContactName = obj.MetaData.AddedByContactName;
    obj.LastEditedDateTime = obj.MetaData.LastEditedDateTime;
    return obj;
  }


  /**
   * Check if a file extension or asset type indicates this is plain text.
   * @param extension - The file extension.
   * @param assetType - The asset type.
   * @returns True if the file extension and asset type indicates plain text; otherwise, false.
   */
  public isPlainText(extension: string, assetType: string): boolean {
    if (this.isFile(assetType)) {
      return false;
    }
    return Helper.isFileTypeText(extension);
  }

  /**
   * Check if asset type indicates this is a file.
   * @param assetType - The asset type.
   * @returns True if the asset type indicates a file; otherwise, false.
   */
  public isFile(assetType: string): boolean {
    return (assetType === "D" || assetType === "I" || assetType === "V" || assetType === "A");
  }

  /**
   * Check if asset type indicates this is a script.
   * @param assetType - The asset type.
   * @returns True if the asset type indicates a script; otherwise, false.
   */
  public isScript(assetType: string): boolean {
    return (assetType === "P");
  }

  /*
   * Check if a file extension or asset type indicates this is viewable in the browser.
   * @param extension - The file extension.
   * @param assetType - The asset type.
   * @returns True if the file extension indicates this is viewable in the browser; otherwise, false.
   */
  public isViewable(extension: string, assetType: string): boolean {
    // Don't attempt to view external assets
    if (assetType === "W" || assetType === "U") {
      return false;
    }
    // Images are viewable
    if (Helper.isFileTypeImage(extension)) {
      return true;
    }
    return Helper.isFileTypeMatch(extension, ["pdf", "xml", "htm", "html", "txt"]);
  }

  /**
   * Check if a file extension or asset type indicates this can be downloaded.
   * @param extension - The file extension.
   * @param assetType - The asset type.
   * @returns True if the file extension indicates this is viewable in the browser; otherwise, false.
   */
  public isDownloadable(extension: string, assetType: string): boolean {
    // Don't attempt to download external assets
    if (assetType === "W" || assetType === "U") {
      return false;
    }
    // TODO don't download other types of assets or files????
    return true;
  }


  previewRenderMethod(extension: string, assetType: string): "html" | "text" | "image" | "iframe" | "icon" {
    if (Helper.isFileTypeHtml(extension) && !this.isFile(assetType)) {
      return "html";
    } else if (this.isPlainText(extension, assetType) && !this.isFile(assetType)) {
      return "text";
    } else if (Helper.isFileTypeImage(extension)) {
      return "image";
    } else if (this.isViewable(extension, assetType)) {
      return "iframe";
    } else if (!this.isViewable(extension, assetType)) {
      return "icon";
    } else {
      return "icon";
    }
  }


  /**
   * Determine the icon to use for an asset based on the file extension and asset type.
   * @param extension - The file extension.
   * @param assetType - The asset type.
   * @returns The name of the icon to use.
   */
  public assetIcon(extension: string, assetType: string): string {
    if (Helper.isFileTypeImage(extension)) {
      return "file-image";
    }
    if (!extension) {
      extension = "";
    } else {
      extension = extension.toLowerCase();
    }
    if (extension === "xls" || extension === "xlsx" || extension === "xlsm") {
      return "file-excel";
    } else if (extension === "pdf") {
      return "file-pdf";
    } else if (extension === "doc" || extension === "docx" || extension === "docm") {
      return "file-word";
    } else if (extension === "ppt" || extension === "pptx") {
      return "file-powerpoint";
    } else if (extension === "zip" || extension === "gz" || extension === "7z") {
      return "file-archive";
    } else if (extension === "csv") {
      return "file-csv";
    } else if (extension === "txt") {
      return "file-alt";
    } else if (extension === "xml" || extension === "htm" || extension === "html") {
      return "file-code";
    } else if (extension === "link") {
      return "external-link";
    } else if (assetType === "I") {
      return "file-image";
    } else if (assetType === "A") {
      return "file-audio";
    } else if (assetType === "V") {
      return "file-video";
    } else if (assetType === "W" || assetType === "U") {
      return "external-link";
    } else {
      return "file";
    }
  }

  /**
   * Get a description of the size of an asset.
   * @param extension - The file extension.
   * @param assetType - The asset type.
   * @param sizeBytes - The size of the asset in bytes.
   * @param sizeOther - The size of the asset in duration, pages, etc.
   * @param sizeInformation - A textual description of the size of the asset.
   * @param width - The width of an asset.
   * @param height - The height of an asset.
   * @param template - The template to use for building the asset size description.
   * @returns A description of the size of the asset.
   */
  public getSizeDescription(extension: string, assetType: string, sizeBytes: number, sizeOther: number, sizeInformation: string, width: number, height: number, template: string = ""): string {

    let size: string = "";

    // Start with any size information provided
    if (sizeInformation && sizeInformation !== "") {
      size += sizeInformation;
    }

    // If no asset type was specified see if we can tell from extension
    if (!assetType) {
      if (Helper.isFileTypeImage(extension)) {
        assetType = "I";
      } else if (Helper.isFileTypeVideo(extension)) {
        assetType = "V";
      } else if (Helper.isFileTypeAudio(extension)) {
        assetType = "A";
      } else if (Helper.isFileTypeDocument(extension)) {
        assetType = "D";
      }
    }

    // For audio and video use duration if provided
    if ((assetType === "A" || assetType === "V") && sizeOther && sizeOther > 0) {
      const duration = format(startOfDay(new Date()).setSeconds(sizeOther), 'H:mm:ss');
      size += (size && size !== "") ? "; " : "";
      size += duration;
    }

    // For documents use pages if provided
    if (assetType === "D" && sizeOther && sizeOther > 0) {
      size += (size && size !== "") ? "; " : "";
      size += sizeOther.toString() + " pages";
    }

    // For images and video use resolution if provided
    if ((assetType === "I" || assetType === "V") && width && height) {
      size += (size && size !== "") ? "; " : "";
      size += width.toString() + "x" + height.toString();
    }

    // Always use the actual disk space size if provided
    if (sizeBytes && sizeBytes > 0) {
      const sizeKb: number = (sizeBytes / 1024);
      const sizeMb: number = (sizeBytes / 1024 / 1024);
      size += (size && size !== "") ? "; " : "";
      if (sizeMb && Math.round(sizeMb) >= 1) {
        size += sizeMb.toFixed(1) + " MB";
      } else if (sizeKb && Math.round(sizeKb) >= 1) {
        size += sizeKb.toFixed(1) + " KB";
      } else {
        size += sizeBytes + " bytes";
      }
    }

    // Tell when we don't know anything
    if (!size || size === "") {
      size = "unknown size";
    }

    return size;

  }


  /**
   * Redirect to the edit url for the specified asset.
   */
  public editAsset(editRouteBase: string, assetId: number, description: string): void {

    if (!editRouteBase) {
      editRouteBase = "/asset/edit";
      Log.warningMessage(`No edit base route specified for edit so defaulting to '${editRouteBase}'.`);
    }
    if (!assetId) {
      Log.errorMessage("No asset id specified for edit.");
      return;
    }
    if (!description) {
      description = `asset ${assetId}`;
    }

    this.router.navigate([editRouteBase, assetId, Helper.encodeURISlug(description)]);

  }

  /**
   * Redirect to the view url for the specified asset.
   */
  public viewAsset(viewRouteBase: string, assetId: number, description: string, viewInNewWindow: boolean = false): void {

    if (!viewRouteBase) {
      viewRouteBase = "/asset/viewer";
      Log.warningMessage(`No view base route specified for view so defaulting to '${viewRouteBase}'.`);
    }
    if (!assetId) {
      Log.errorMessage("No asset id specified for view.");
      return;
    }
    if (!description) {
      description = `asset ${assetId}`;
    }

    const apiProp: ApiProperties = Api.AssetActionValidate();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
    apiCall.silent = true;

    this.apiService.execute(apiCall, { AssetId: assetId, FriendlyName: Helper.encodeURISlug(description) }).subscribe((result: IApiResponseWrapper) => {
      if (!result.Data.Success) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      } else {
        if (viewInNewWindow) {
          // Opening in a new window will need our full url not just the route
          if (Helper.startsWith(viewRouteBase, "/")) {
            viewRouteBase = viewRouteBase.substring(1);
          }
          let url = `${window.location.protocol}//${window.location.host}/${viewRouteBase}/${assetId}/${Helper.encodeURISlug(description)}`;
          Helper.openUrl(url, true);
        } else {
          this.router.navigate([viewRouteBase, assetId, Helper.encodeURISlug(description)]);
        }
      }
    });

  }

  /**
   * Builds a URL for file upload for the specified asset.
   * @param assetId - the asset id.
   * @returns The url to use for uploading.
   */
  public buildFileUploadUrl(assetId: number, returnAsTrustedUrl: boolean = true): string | SafeUrl {
    const urls: AssetUrls = this.getUrls(assetId);
    if (!urls) {
      return "";
    }
    let url: string = urls.uploadUrl;
    url = ApiHelper.addQueryStringToUrl(url, `token=${urls.token}`);
    if (returnAsTrustedUrl) {
      return this.sanitizer.bypassSecurityTrustUrl(url);
    }
    return url;
  }

  /**
   * Builds a URL for file download for the specified asset.
   * @param assetId - the asset id.
   * @param friendlyName - a friendly name to use for the asset to make the url more descriptive.
   * @param incrementHitCounter - when true the hit counter is incremented on download.  Some access from within the asset UI should not spam the hit counter.
   * @returns The url to use for downloading.
   */
  public buildFileDownloadUrl(assetId: number, friendlyName: string, fileType: string, incrementHitCounter: boolean = true, returnAsTrustedUrl: boolean = true): string | SafeUrl {
    const urls: AssetUrls = this.getUrls(assetId, friendlyName, fileType);
    if (!urls) {
      return "";
    }
    let url: string = urls.downloadUrl;
    url = ApiHelper.addQueryStringToUrl(url, `token=${urls.token}`);
    // See if we want to increment our hit counter.  Sometimes requests from inside the asset UI are silent
    if (!incrementHitCounter) {
      url = ApiHelper.addQueryStringToUrl(url, `silent=true`);
    }
    if (returnAsTrustedUrl) {
      return this.sanitizer.bypassSecurityTrustUrl(url);
    }
    return url;
  }

  /**
   * Builds a URL for asset viewing for the specified asset.
   * @param assetId - the asset id.
   * @param friendlyName - a friendly name to use for the asset to make the url more descriptive.
   * @param incrementHitCounter - when true the hit counter is incremented on download.  Some access from within the asset UI should not spam the hit counter.
   * @param includeAccessToken - when true the current user access token is included as part of the url.  When false only public urls will function.
   * @param returnAsTrustedResourceUrl - when true return the results of $sce.trustAsResourceUrl() on this url.  This returns an object that is trusted
   * by angular for use in specified strict contextual escaping contexts (such as ng-bind-html, ng-include, any src attribute interpolation, any dom
   * event binding attribute interpolation such as for onclick, etc.) that uses the url.
   * @returns The url to use for viewing.
   */
  public buildFileViewUrl(assetId: number, friendlyName: string, fileType: string, incrementHitCounter: boolean = true, includeAccessToken: boolean = true, returnAsTrustedResourceUrl: boolean = true): string | SafeResourceUrl {
    const urls: AssetUrls = this.getUrls(assetId, friendlyName, fileType);
    if (!urls) {
      return "";
    }
    let url: string = urls.viewUrl;
    if (includeAccessToken) {
      url = ApiHelper.addQueryStringToUrl(url, `token=${urls.token}`);
    }
    // See if we want to increment our hit counter.  Sometimes requests from inside the asset UI are silent
    if (!incrementHitCounter) {
      url = ApiHelper.addQueryStringToUrl(url, `silent=true`);
    }
    if (returnAsTrustedResourceUrl) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    return url;
  }


  /**
   After verifying the download is allowed this method downloads the specified asset.
   @param {number} assetId - the asset id.
   @param {string} friendlyName - a friendly name to use for the asset to make the url more descriptive.
   @param {boolean} incrementHitCounter - when true the hit counter is incremented on download.  Some access from within the asset UI should not spam the hit counter.
   */
  public download(assetId: number, friendlyName: string, fileType: string, incrementHitCounter: boolean = true, onDownload: (assetId: number) => any = null): void {

    const apiProp: ApiProperties = Api.AssetActionValidate();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
    apiCall.silent = true;

    this.apiService.execute(apiCall, { AssetId: assetId, FriendlyName: friendlyName }).subscribe((result: IApiResponseWrapper) => {
      //console.error(result);
      if (!result.Data.Success) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      } else {
        const url: string = <string>this.buildFileDownloadUrl(assetId, friendlyName, fileType, incrementHitCounter, false);
        //console.error(url);
        window.location.href = url;
        // If we asked for an asset refresh then give 2 seconds for the download to start and then call that refresh callback.
        if (onDownload) {
          setTimeout(onDownload, 2000, assetId);
        }
      }
    });

  }


  /**
   Link to an external asset
   @param {number} assetId - the asset id.
   @param {string} url - the url of the external asset.
   @param {boolean} incrementHitCounter - when true the hit counter is incremented.
   */
  public link(assetId: number, url: string, incrementHitCounter: boolean = true): void {
    // TODO call API to increment the hit counter
    // If we don't have a url type default to http://
    if (!Helper.contains(url, ":")) {
      url = "http://" + url;
    }
    window.location.href = url;
  }



  public logAccess(assetId: number, signature: any): void {

    // Validate inputs
    if (!assetId) {
      return;
    }

    const apiProp: ApiProperties = Api.AssetAccessLog();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Add);
    apiCall.silent = true; // silent api call

    const log = new m5.AssetAccessLogEditViewModel();
    log.AssetId = assetId;
    log.AccessDateTime = new Date();
    log.AccessedByContactId = this.appService.userOrDefault.ContactId;
    log.SignatureType = "D"; // Data
    log.Signature = JSON.stringify(signature);

    this.apiService.execute(apiCall, log)
      .subscribe((result: IApiResponseWrapperTyped<m5.AssetAccessLogEditViewModel>) => {
        if (result.Data.Success) {
          // No action
        } else {
          console.error(result);
        }
      });

    return;

  }



  protected urlCache: Dictionary<AssetUrls> = new Dictionary<AssetUrls>();
  protected getUrls(assetId?: number, friendlyName?: string, fileType?: string, asset?: m5.AssetListViewModel | m5.AssetEditViewModel): AssetUrls {

    // Validate inputs
    if (!asset && !assetId) {
      return null;
    }
    if (!assetId) {
      assetId = asset.AssetId;
    }
    if (!friendlyName) {
      if (asset) {
        friendlyName = Helper.getFirstDefinedString(asset.FriendlyName, asset.Title, "asset") + "." + asset.FileType;
      } else {
        friendlyName = "asset";
      }
    }

    // Check cache
    if (this.urlCache.containsKey(assetId.toString())) {
      // Get the cache object
      const urls = this.urlCache.item(assetId.toString());
      // See if too old
      if (urls && urls.asOf) {
        const then = urls.asOf;
        const now = new Date();
        const duration = differenceInMinutes(now, then);
        if (duration > 60) {
          this.urlCache.remove(assetId.toString());
        } else {
          return urls;
        }
      }
    }

    // Get URLs
    const urls: AssetUrls = {};
    urls.assetId = assetId;
    urls.friendlyName = friendlyName;
    urls.fileType = fileType;
    urls.asOf = new Date();

    // Upload
    let apiProp: ApiProperties = Api.AssetFileActionUpload();
    let apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Call);
    urls.uploadUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, { AssetId: assetId });

    // Save our token // TOOD dump this cache upon logout?
    urls.token = apiCall.token;

    // Download
    apiProp = Api.AssetActionDownload();
    apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
    urls.downloadUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, { AssetId: assetId, FriendlyName: friendlyName });

    // View
    apiProp = Api.AssetActionView();
    apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
    const slug = Helper.encodeURISlug(friendlyName);
    urls.viewUrl = ApiHelper.buildApiAbsoluteUrl(apiCall, { AssetId: assetId, FriendlyName: slug });

    // Add to cache
    this.urlCache.add(assetId.toString(), urls);

    return urls;

  }


  updateAssetRatingInformation(asset: m5.AssetListViewModel | m5.AssetListViewModel[] | m5.AssetEditViewModel, rating: AssetRatingInformation | m5.AssetListViewModel | m5.AssetEditViewModel) {

    if (!asset || !rating) {
      return;
    }

    let one: m5.AssetListViewModel | m5.AssetEditViewModel = null;
    if (Helper.isArray(asset)) {
      one = Helper.firstOrDefault(<m5.AssetListViewModel[]>asset, x => x.AssetId === rating.AssetId);
      if (!one) {
        console.error(`Unable to find asset id ${rating.AssetId} in array of asset list objects so no rating information updated.`);
        return;
      }
    } else {
      one = <m5.AssetListViewModel>asset;
    }

    one.HitCount = rating.HitCount;
    one.LastHitDateTime = rating.LastHitDateTime;
    one.HelpedYes = rating.HelpedYes;
    one.HelpedYesPercent = rating.HelpedYesPercent;
    one.HelpedNo = rating.HelpedNo;
    one.HelpedNoPercent = rating.HelpedNoPercent;
    one.HelpedNa = rating.HelpedNa;
    one.HelpedNaPercent = rating.HelpedNaPercent;
    one.HelpedTotal = rating.HelpedTotal;
    one.Rating01 = rating.Rating01;
    one.Rating01Percent = rating.Rating01Percent;
    one.Rating02 = rating.Rating02;
    one.Rating02Percent = rating.Rating02Percent;
    one.Rating03 = rating.Rating03;
    one.Rating03Percent = rating.Rating03Percent;
    one.Rating04 = rating.Rating04;
    one.Rating04Percent = rating.Rating04Percent;
    one.Rating05 = rating.Rating05;
    one.Rating05Percent = rating.Rating05Percent;
    one.RatingAverage = rating.RatingAverage;
    one.RatingTotal = rating.RatingTotal;

  }


  getScript(type: string, languageType: "server" | "client" = "server", template: string = ""): m5core.ScriptViewModel {
    if (Helper.equals(type, "CustomScript", true)) {
      return this.getScriptCustom();
    } else if (Helper.equals(type, "TextBuilder", true)) {
      return this.getScriptTextBuilder();
    } else if (Helper.equals(type, "WebhookProcessor", true)) {
      return this.getScriptWebhookProcessor();
    } else if (Helper.equals(type, "WebAppJsLib", true)) {
      return this.getWebAppJsLib();
    } else if (Helper.equals(type, "WebApp", true)) {
      if (Helper.equals(template, "Empty", true)) {
        return this.getWebAppEmpty();
      } else if (Helper.equals(template, "HelloWorldVue3", true)) {
        return this.getWebAppHelloWorldVue3();
      } else if (Helper.contains(template, "HelloWorld", true)) {
        return this.getWebAppHelloWorldPlain();
      } else {
        return this.getWebAppEmpty();
      }
    } else if (Helper.equals(type, "SearchFilterBuilderScript", true)) {
      const script = this.codeEditorService.getDefaultScript("SearchFilterBuilder", "Script", languageType);
      script.Code[0].SourceCode = "function BuildFilter( searchSettings ) { \n" +
        "\n" +
        "\t// Use the contents of the searchSettings object to build a string used for the search filter.\n" +
        "\tlet filter = \"\";\n" +
        "\n" +
        "\t// TODO build filter expression here\n" +
        "\n" +
        "\n" +
        "\n" +
        "\treturn filter;\n" +
        "\n" +
        "}";
      return script;
    } else {
      Log.errorMessage(`Unable to get script for unknown script type "${type}".`);
      const className: string = `Script_${Helper.formatDateTime(new Date(), "yyyy_MM_dd")}`;
      return this.codeEditorService.getDefaultScript(className, `Script`, languageType);
    }
  }



  getScriptCustom(): m5core.ScriptViewModel {
    const className: string = `CustomScript_${Helper.formatDateTime(new Date(), "yyyy_MM_dd")}`;
    const script = this.codeEditorService.getDefaultScript(className, `CustomScript`);

    script.FullyQualifiedTypeName = `IB.Data.Service.Assets.${className}`;
    script.Interface = `ICustomScript`;

    let source: string =
      `namespace IB.Data.Service.Assets
{

	public class #ClassNamePlaceHolder# : ICustomScript
	{


        /// <summary>
        /// The method to call to execute a custom script.
        /// </summary>
        /// <param name="dataInfo">The current <see cref="DatabaseInfo"/> object.</param>
        /// <param name="contextResourceType">A string representing the context resource type.</param>
        /// <param name="contextResourceId">A integer representing the context resource id.</param>
        /// <param name="contextResourceId2">A string representing the context resource id2.</param>
        /// <param name="context">A dynamic object representing other context information.</param>
        /// <param name="properties">A dynamic object of properties for the script most commonly used to hold settings.</param>
        /// <returns>A <see cref="Result"/> object.</returns>
        public Result Execute( DatabaseInfo dataInfo , string contextResourceType , long? contextResourceId , string contextResourceId2 , dynamic context , dynamic properties )
        {

            // If there is an error in the custom script the result object should have a result code other than StandardResultCode.Success.
            Result result = new Result( StandardResultCode.Success , id: "CustomScript" );

            // For example:
            // result.PostResult( StandardResultCode.RequiredValueEmpty , "Some required data is missing." );
            // return result;

            // If one or more properties are required it is wise to make sure properties were supplied
            // if ( properties == null )
            // {
            //     result.PostResult( StandardResultCode.RequiredValueEmpty , "This script requires X, Y, and Z to be configured in the properties." );
            //     return result;
            // }

            // Debug that shows input properties
            // result.PostFeedback( Result.FeedbackDelimiter , "Input Properties" , ((object) properties).TryToJson() , Result.FeedbackDelimiter );

            // Get the properties we need to use
            // long keyId = 0;
            // try
            // {
            //     keyId = properties.KeyId;
            //     result.PostFeedback( $"Key Id set to {keyId}" );
            // }
            // catch ( Exception ex )
            // {
            //     result.PostResult( StandardResultCode.RequiredValueEmpty , "This script requires KeyId to be configured in the properties." );
            //     return result;
            // }

            // Processing logic can go here...




            return result;

        }

    }

}
`;

    source = Helper.replaceAll(source, "#ClassNamePlaceHolder#", className);
    script.Code[0].SourceCode = source;

    return script;

  }


  getScriptTextBuilder(): m5core.ScriptViewModel {

    const className: string = `TextBuilder_${Helper.formatDateTime(new Date(), "yyyy_MM_dd")}`;
    const script = this.codeEditorService.getDefaultScript(className, `TextBuilder`);

    script.FullyQualifiedTypeName = `IB.Data.Service.Assets.${className}`;
    script.Interface = `ITextBuilder`;

    let source: string =
      `namespace IB.Data.Service.Assets
{

	public class #ClassNamePlaceHolder# : ITextBuilder
	{


        /// <summary>
        /// The method to call to build custom text.
        /// </summary>
        /// <param name=""dataInfo"">The current <see cref=""DatabaseInfo""/> object.</param>
        /// <param name=""contextResourceType"">A string representing the context resource type.</param>
        /// <param name=""contextResourceId"">A integer representing the context resource id.</param>
        /// <param name=""contextResourceId2"">A string representing the context resource id2.</param>
        /// <param name=""context"">A dynamic object representing other context information.</param>
        /// <param name=""properties"">A dynamic object of properties for the script most commonly used to hold settings.</param>
        /// <returns>A <see cref=""Result{String}""/> object.</returns>
        public Result<string> Build( DatabaseInfo dataInfo , string contextResourceType , long? contextResourceId , string contextResourceId2 , dynamic context , dynamic properties )
        {

            // If there is an error in building the desired text the result object should have a result code other than StandardResultCode.Success.
            Result<string> result = new Result<string>( StandardResultCode.Success , id: "TextBuilder" );

            // For example:
            // result.PostResult( StandardResultCode.RequiredValueEmpty , "Some required data is missing." );
            // return result;

            // If one or more properties are required it is wise to make sure properties were supplied
            // if ( properties == null )
            // {
            //     result.PostResult( StandardResultCode.RequiredValueEmpty , "This script requires X, Y, and Z to be configured in the properties." );
            //     return result;
            // }

            // Debug that shows input properties
            // result.PostFeedback( Result.FeedbackDelimiter , "Input Properties" , ((object) properties).TryToJson() , Result.FeedbackDelimiter );

            // Get the properties we need to use
            // long keyId = 0;
            // try
            // {
            //     keyId = properties.KeyId;
            //     result.PostFeedback( $"Key Id set to {keyId}" );
            // }
            // catch ( Exception ex )
            // {
            //     result.PostResult( StandardResultCode.RequiredValueEmpty , "This script requires KeyId to be configured in the properties." );
            //     return result;
            // }

            StringBuilder output = new StringBuilder();

            // Processing logic can go here...

            // For example:
            // output.AppendLine( "here is some sample text" );



            // Put the final text in result.Value
            result.Value = output.ToString();
            return result;

        }

    }

}
`;

    source = Helper.replaceAll(source, "#ClassNamePlaceHolder#", className);
    script.Code[0].SourceCode = source;

    return script;

  }


  getScriptWebhookProcessor(): m5core.ScriptViewModel {

    const className: string = `WebhookProcessor_${Helper.formatDateTime(new Date(), "yyyy_MM_dd")}`;
    const script = this.codeEditorService.getDefaultScript(className, `WebhookProcessor`);

    script.FullyQualifiedTypeName = `IB.Data.Service.Webhooks.${className}`;
    script.Interface = `IWebhookProcessor`;

    let source: string =
      `namespace IB.Data.Service.Webhooks
{

	public class #ClassNamePlaceHolder# : IWebhookProcessor
	{

        /// <summary>
        /// The method to call to process custom logic for a webhook event.
        /// </summary>
        /// <param name="dataInfo">The current <see cref="DatabaseInfo"/> object.</param>
        /// <param name="webhookEvent">The <see cref="WebhookEventModel"/> for the webhook event.</param>
        /// <param name="webhook">The <see cref="WebhookModel"/> associated with the webhook event.</param>
        /// <param name="properties">A dynamic object of properties for the script most commonly used to hold settings.</param>
        /// <returns>A <see cref="Result"/> object.</returns>
        public Result Process( DatabaseInfo dataInfo , WebhookEventModel webhookEvent , WebhookModel webhook , dynamic properties )
        {

            // If there is an error in processing the webhook event the result object should have a result code other than StandardResultCode.Success.
            Result result = new Result( StandardResultCode.Success , id: "ProcessWebhookEvent" );

            // If one or more properties are required it is wise to make sure properties were supplied
            // if ( properties == null )
            // {
            //     result.PostResult( StandardResultCode.RequiredValueEmpty , "This script requires X, Y, and Z to be configured in the properties." );
            //     return result;
            // }

            // Debug that shows input properties
            // result.PostFeedback( Result.FeedbackDelimiter , "Input Properties" , ((object) properties).TryToJson() , Result.FeedbackDelimiter );

            // Get the properties we need to use
            // long keyId = 0;
            // try
            // {
            //     keyId = properties.KeyId;
            //     result.PostFeedback( $"Key Id set to {keyId}" );
            // }
            // catch ( Exception ex )
            // {
            //     result.PostResult( StandardResultCode.RequiredValueEmpty , "This script requires KeyId to be configured in the properties." );
            //     return result;
            // }

            // webhookEvent.EventData is a string representation of the data for the webhook event
            // For outgoing webhooks this is the data being sent.
            // For incoming webhooks this is the data that was received in WebRequestModel format.
            if ( String.IsNullOrWhiteSpace( webhookEvent?.EventData ) )
            {
                result.PostResult( StandardResultCode.RequiredValueEmpty , "There is no event data for this webhook event." );
                return result;
            }

            // Parse the data into any desired format and perform any needed actions on it.  For example:
            if ( webhook.Incoming )
            {
                // Trace information can be included using result.PostFeedback().  For example:
                // result.PostFeedback( "Incoming Webhook" );
                WebRequestModel request = webhookEvent.EventData.TryFromJson<WebRequestModel>( null );
                // Now decide what to do based on WebRequestModel value.  For example:
                // var logResult = IB.Core.Logging.Log.DumpToFile( $"~\\incoming-web-hook-{DateTime.Now:yyyyMMddHHmmss}.log" , request.TryToJson() );
                // result.PostInnerResultsPlusErrorIfFailure( logResult );
                // ... or ...
                //if ( !result.PostResultIfFailure( logResult ) )
                //{
                //    return result;
                //}

            }
            else
            {
                // Trace information can be included using result.PostFeedback().  For example:
                // result.PostFeedback( "Outgoing Webhook" );
                dynamic response = new ExpandoObject();
                response = webhookEvent.EventData.TryFromJson<ExpandoObject>( new ExpandoObject() );
                // Now decide what to do based on dynamic response value

            }

            // Other processing logic can go here...



            return result;

        }

    }

}
`;

    source = Helper.replaceAll(source, "#ClassNamePlaceHolder#", className);
    script.Code[0].SourceCode = source;
    script.Code[0].Usings.push("using IB.Core.Web;");

    return script;

  }

  getWebAppJsLib(): m5core.ScriptViewModel {
    const className: string = `WebAppJsLib_${Helper.formatDateTime(new Date(), "yyyy_MM_dd")}`;
    const script = this.codeEditorService.getDefaultScript(className, `WebAppJsLib`, "client", "WebAppJsLib");

    const source: string =
      `/**
 * Libraries have to make themselves visible to web apps that utilize them.  One common way to do that is by
 * declaring a self-executing function that has optional private properties and functions inside the function
 * and returns an object with public properties, getters, setters, and functions.  That returned object is
 * assigned to a variable that is accessible via a global variable.
 *
 * Web apps that use this library should list it as one of the referenced libraries.  Referenced libraries
 * can be public URLs or can be a reference to another asset in the format:
 * The keyword "lib" followed by colon and keyword "asset" followed by colon and either the asset id or external asset id.
 * Libraries should have any dependencies listed first and, if there is a dependency, should be prefixed with
 * the keyword "wait" and a colon so earlier libraries are loaded before this library is loaded.
 * For example:
 * lib:asset:123
 * lib:asset:SampleJsLibrary
 * wait:lib:asset:123
 *
 * Web apps and web app js libraries have access to some functions shared by the hosting app via global.IbApp.*.
 */

// One option is to set up a "namespace" for globals which can help things be more discoverable.
if ( !global.EmbeddedApps ) {
    global.EmbeddedApps = {};
}
if ( !global.EmbeddedApps.Libs ) {
    global.EmbeddedApps.Libs = {};
}

// Assign this self-executing function to a global so we can reference it from apps
global.EmbeddedApps.Libs.SampleWebAppLib = (function () {

  // Private variables and functions go inside this function and can be exposed via public getters and setters as needed.

  /**
   * Sample private variable not directly accessible.
   */
  let debugMode = true;

  // Public properties and methods are declared in this return object separated by commas
  return {

    /**
     * Sample public property.
     */
    name: "My Test Web App Library",

    /**
     * Sample public function.
     */
    add: ( x , y ) => { return ( x + y ); },

    /**
     * Sample getter that can give access to a private variable.
     */
    get debug() { return debugMode; },

    /**
     * Sample setter that can give access to a private variable.
     */
    set debug( isDebugMode ) {
        if (typeof isDebugMode === "boolean") {
            debugMode = isDebugMode;
            console.log(${"`"}You set debug mode to "${"${isDebugMode}"}".${"`"});
        } else {
            console.error("debug must be a boolean value.");
        }
    },

  };

})();
// The parenthesis here cause the anonymous function to execute and return the object so public properties and methods are ready to use
`;
    script.Code[0].SourceCode = source;

    return script;

  }


  getWebAppEmpty(): m5core.ScriptViewModel {
    const className: string = `WebApp_${Helper.formatDateTime(new Date(), "yyyy_MM_dd")}`;
    const script = this.codeEditorService.getDefaultScript(className, "app", "client", "WebApp");
    const htmlCode = this.codeEditorService.getDefaultScriptSource("html", "client", m5core.ScriptLanguage.Html);
    htmlCode.Order = 200;
    script.Code.push(htmlCode);
    const cssCode = this.codeEditorService.getDefaultScriptSource("css", "client", m5core.ScriptLanguage.Css);
    cssCode.Order = 300;
    script.Code.push(cssCode);
    const jsonCode = this.codeEditorService.getDefaultScriptSource("data", "client", m5core.ScriptLanguage.Json);
    jsonCode.Order = 400;
    jsonCode.SourceCode = "{}";
    script.Code.push(jsonCode);
    const textCode = this.codeEditorService.getDefaultScriptSource("readme", "client", m5core.ScriptLanguage.Text);
    textCode.ExcludeFromCode = true;
    textCode.Order = 500;
    textCode.SourceCode = this.getWebAppReadMeText();
    script.Code.push(textCode);
    return script;
  }


  getWebAppHelloWorldPlain(): m5core.ScriptViewModel {
    const className: string = `WebApp_${Helper.formatDateTime(new Date(), "yyyy_MM_dd")}`;
    const script = this.codeEditorService.getDefaultScript(className, "app", "client", "WebApp");

    const source: string =
      `/**
 * See readme.txt for information about writing a web app.
 */

function HelloWorld() {

  console.log( "Hello World!" );

  let name = document.getElementById("name");
  if (name) {
      name.innerHTML = "Fred";
  } else {
      console.error( "Unable to find html element with id 'name'.")
  }

}

// Start our app by calling the entry point.
HelloWorld();
`;

    script.Code[0].SourceCode = source;

    const htmlCode = this.codeEditorService.getDefaultScriptSource("html", "client", m5core.ScriptLanguage.Html);
    htmlCode.Order = 200;
    htmlCode.SourceCode =
      `<h1>Hello World!</h1>
<h2 class="name">Hello <span id="name"></span>.</h2>
`;
    script.Code.push(htmlCode);
    const cssCode = this.codeEditorService.getDefaultScriptSource("css", "client", m5core.ScriptLanguage.Css);
    cssCode.Order = 300;
    cssCode.SourceCode =
      `.name {
    color: purple;
}`;
    script.Code.push(cssCode);
    const jsonCode = this.codeEditorService.getDefaultScriptSource("data", "client", m5core.ScriptLanguage.Json);
    jsonCode.Order = 400;
    jsonCode.SourceCode =
      `{
  "staticData1": {
    "foo": "bar",
    "debug": false
  },
  "staticData2": {
    "weather": "sunny",
    "temp": 75
  }
}`;
    script.Code.push(jsonCode);
    const textCode = this.codeEditorService.getDefaultScriptSource("readme", "client", m5core.ScriptLanguage.Text);
    textCode.ExcludeFromCode = true;
    textCode.Order = 500;
    textCode.SourceCode = this.getWebAppReadMeText();
    script.Code.push(textCode);
    const helpCode = this.codeEditorService.getDefaultScriptSource("help", "client", m5core.ScriptLanguage.Html);
    helpCode.ExcludeFromCode = true;
    helpCode.Order = 600;
    helpCode.SourceCode =
      `<h1>Instructions on how to use the web app</h1>
<h3>You might display this in your app when the user clicks a help icon, etc.</h3>
<p>
To see details about "A" hover your mouse over "B".
</p>
<p>
To do "X" in this web app click on "Y".
</p>
`;
    script.Code.push(helpCode);


    return script;

  }


  getWebAppHelloWorldVue3(): m5core.ScriptViewModel {

    // Start with our plain JS hello world app
    const script = this.getWebAppHelloWorldPlain();

    // Add some lib references
    script.ReferencedAssemblies = [];
    script.ReferencedAssemblies.push("https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css");
    script.ReferencedAssemblies.push("https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js");
    script.ReferencedAssemblies.push("https://unpkg.com/vue@3.1.1/dist/vue.global.prod.js");

    // Update JS to use Vue
    const jsSource: string =
      `/**
 * See readme.txt for information about writing a web app.
 */

const vueApp = Vue.createApp({
    data: function() {
      return {
        title: "Hello World!",
        yearFirstHalf: 2000,
        yearSecondHalf: 23,
        name: "Fred"
      };
    },
    computed: {
      computedYear() {
        return this.yearFirstHalf + this.yearSecondHalf;
      },
    },
    methods: {
      getSubtitle() {
        return "this is a test subtitle";
      }
    }
  });
vueApp.mount('#vapp');
`;

    const jsCode = script.Code.find(x => x.Language === m5core.ScriptLanguage[m5core.ScriptLanguage.JavaScript]);
    if (jsCode) {
      jsCode.SourceCode = jsSource;
    }

    // Update HTML to use Vue
    const htmlSource =
      `<div id="vapp">
  <h1>{{title}}</h1>
  <h2 class="name">Hello {{name}}, it's {{computedYear}}.</h2>
</div>
`;

    const htmlCode = script.Code.find(x => x.Name === "html" && x.Language === m5core.ScriptLanguage[m5core.ScriptLanguage.Html]);
    if (htmlCode) {
      htmlCode.SourceCode = htmlSource;
    }

    return script;

  }


  getWebAppReadMeText(): string {
    const readme: string = `This readme can contain notes about your web app.

Here are a few things to keep in mind while developing your app:

1. Code that is shared between apps can go into a web app js library which needs to instantiate itself and provide
access to the web app via the global scope.

2. Web apps can reference libraries to use within the app.

3. Referenced libraries can be public URLs or can be a reference to web app js library asset in the format:
The keyword "lib" followed by colon and keyword "asset" followed by colon and either the asset id or external asset id.
Libraries should have any dependencies listed first and, if there is a dependency, should be prefixed with
the keyword "wait" and a colon so earlier libraries are loaded before this library is loaded.
For example:
lib:asset:123
lib:asset:SampleJsLibrary
wait:lib:asset:123

4. Web apps have access to some functions shared by the hosting app via window.IbApp.*.  For example: IbApp.Helper.equals(), etc.

5. Web apps are responsible for starting themselves.  Once the JavaScript is loaded it should self-execute since there
is no entry point that is executed to start the application.

6. Web apps can access their data, properties, other documents (e.g. end user help), via window.[SanitizedWebAppName].*.  The sanitized
web app name is the name of the app with only alphanumeric and underscore characters.  For example, if the name of this web app is
"My Super Web App 1" the data, properties, etc. could be referenced from window.MySuperWebApp1.*.
`;

    return readme;

  }





}

interface AssetUrls {
  assetId?: number;
  friendlyName?: string;
  fileType?: string;
  viewUrl?: string;
  downloadUrl?: string;
  uploadUrl?: string;
  token?: string;
  asOf?: Date;
}


export class AssetRatingInformation {

  AssetId: number = null;

  HitCount: number = 0;
  LastHitDateTime: Date = null;

  HelpedYes: number = 0;
  HelpedYesPercent: number = 0;
  HelpedNo: number = 0;
  HelpedNoPercent: number = 0;
  HelpedNa: number = 0;
  HelpedNaPercent: number = 0;
  HelpedTotal: number = 0;

  Rating01: number = 0;
  Rating01Percent: number = 0;
  Rating02: number = 0;
  Rating02Percent: number = 0;
  Rating03: number = 0;
  Rating03Percent: number = 0;
  Rating04: number = 0;
  Rating04Percent: number = 0;
  Rating05: number = 0;
  Rating05Percent: number = 0;
  RatingAverage: number = 0;
  RatingTotal: number = 0;

  constructor(asset: m5.AssetListViewModel | m5.AssetEditViewModel = null) {
    if (asset) {
      this.AssetId = asset.AssetId;
      this.HitCount = asset.HitCount;
      this.LastHitDateTime = asset.LastHitDateTime;
      this.HelpedYes = asset.HelpedYes;
      this.HelpedYesPercent = asset.HelpedYesPercent;
      this.HelpedNo = asset.HelpedNo;
      this.HelpedNoPercent = asset.HelpedNoPercent;
      this.HelpedNa = asset.HelpedNa;
      this.HelpedNaPercent = asset.HelpedNaPercent;
      this.HelpedTotal = asset.HelpedTotal;
      this.Rating01 = asset.Rating01;
      this.Rating01Percent = asset.Rating01Percent;
      this.Rating02 = asset.Rating02;
      this.Rating02Percent = asset.Rating02Percent;
      this.Rating03 = asset.Rating03;
      this.Rating03Percent = asset.Rating03Percent;
      this.Rating04 = asset.Rating04;
      this.Rating04Percent = asset.Rating04Percent;
      this.Rating05 = asset.Rating05;
      this.Rating05Percent = asset.Rating05Percent;
      this.RatingAverage = asset.RatingAverage;
      this.RatingTotal = asset.RatingTotal;
    }
  }

}
