import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';

@Component({
  selector: 'ib-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.css']
})
export class BadgeComponent extends BaseComponent implements OnInit {

  @Input() icon: string = "";
  @Input() label: string = "";
  @Input() labelClasses: string = "";
  @Input() labelStyles: string = "";
  /**
   * When true the label will appear before the icon.  The default is false which
   * shows the icon before the label.
   */
  @Input() labelFirst: boolean = false;
  @Input() tooltip: string = "";
  @Input() tooltipPlacement: string = "auto";
  @Input() iconSize: string = ""; // xs, sm, lg, 2x-10x, 15x, 20x, 30x, font-size px, em, %
  @Input() iconColor: string = "";

  @Input() color: string = "info";


  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
