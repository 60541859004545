import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssetService } from 'projects/core-lib/src/lib/services/asset.service';
import { InputInformationValues } from 'projects/common-lib/src/lib/input/input-base-component';
import { InputAssetPickerModalComponent } from '../input-asset-picker-modal/input-asset-picker-modal.component';

@Component({
  selector: 'ib-input-asset',
  templateUrl: './input-asset.component.html',
  styleUrls: ['./input-asset.component.css']
})
export class InputAssetComponent implements OnInit, OnChanges, OnDestroy {

  @Input() name: string = "";
  @Input() label: string = "";
  /**
  Prefix rendered directly before the label.  Can be used for things like * asterisk prefix
  to indicate a required field or other custom prefix indicators.  The prefix is not
  translated and provides the ability for the label that is translated to share translations
  that otherwise would have to be repeated due to the prefix.  For example, "Customer"
  translation can be reused where "*Customer:" label would otherwise need a repeated translation.
  */
  @Input() public labelPrefix: string = "";
  /**
  Suffix rendered directly after the label.  Can be used for things like : colon suffix 
  prompt before input or other custom suffix indicators.  The suffix is not
  translated and provides the ability for the label that is translated to share translations
  that otherwise would have to be repeated due to the suffix.  For example, "Customer"
  translation can be reused where "*Customer:" label would otherwise need a repeated translation.
  */
  @Input() public labelSuffix: string = "";
  @Input() placeholder: string = "";
  @Input() tooltip: string = "";
  @Input() bold: boolean = false; // true = bold label
  @Input() tight: boolean = false; // true = tight form groups
  @Input() vertical: boolean = false; // true = vertical form with label above input control.  default is false.
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() autofocus: boolean = false;
  @Input() standalone: boolean = false; // true will not mark form dirty when control is dirty
  @Input() width: string = "";
  @Input() size: string = ""; // "",large,small
  /**
  filter input attribute allows filtering assets that are displayed in the picker
  to match desired context.
  */
  @Input() filter: string = "";
  @Input() data: { AssetId: number, Title: string } = null;

  @Input() showSearch: boolean = true;
  @Input() showRelated: boolean = false;
  @Input() showPreview: boolean = true;

  @Input() includeRequiredIcon: boolean = true;

  @Input() outerClass: string = "";
  @Input() labelClass: string = "";
  @Input() controlClass: string = "";

  @Input() suffixIcon: string = "";
  @Input() suffixText: string = "";
  @Input() suffixTooltip: string = "";
  @Input() suffixClickEventEnabled: boolean = true;
  @Output() suffixClick: EventEmitter<EventModel> = new EventEmitter();

  @Output() change: EventEmitter<EventModel> = new EventEmitter();
  @Output() status: EventEmitter<EventModel> = new EventEmitter();


  @Input() public errorRequiredMessage: string = "This value is required.";
  @Input() public errorMinimumLengthMessage: string = "This value must be at least {{MinimumLength}} characters long.";
  @Input() public errorMaximumLengthMessage: string = "This value cannot be longer than {{MaximumLength}} characters but is currently {{ActualLength}} characters long.";
  @Input() public errorInvalidFormatMessage: string = "This is not a valid format: {{FormatErrorMessage}}";
  @Input() public errorOtherMessage: string = "This value is not valid: {{OtherErrorMessage}}";
  errorMessages: string[] = [];
  inputInformationValues: InputInformationValues = {};


  /**
  Copy of attribute binding data in case user hits cancel on modal we don't want to have modified the data object submitted.
  */
  public asset: { AssetId: number, Title: string };

  /**
  We use takeUntil pattern (see https://stackoverflow.com/a/41177163) where we
  only need to unsubscribe from this one shared subject in ngOnDestroy as it
  is passed as parameter to takeUntil.
  */
  private ngUnsubscribe = new Subject();

  constructor(
    protected assetService: AssetService,
    protected ngbModalService: NgbModal) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
    if (changes.data && this.data) {
      // Work with a cloned object
      this.asset = Helper.deepCopy(this.data);
    }
    // If we have an id but don't have a title let's figure it out
    if (this.data.AssetId && !this.data.Title) {
      this.assetService.getAssetListObject(this.data.AssetId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((asset) => {
        this.data.Title = asset.Title;
        this.asset.Title = asset.Title;
      });
    }
  }

  ngOnDestroy() {
    /**
    We use takeUntil pattern (see https://stackoverflow.com/a/41177163) where we
    only need to unsubscribe from this one shared subject in ngOnDestroy as it
    is passed as parameter to takeUntil.
    */
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  configure() {
    if (this.label && !this.placeholder) {
      this.placeholder = this.label;
    }
  }

  openAssetPicker($event) {
    if (this.readonly) {
      return;
    }
    //console.error("contact picker button", $event);
    let options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.title = "Select Asset"
    // Open the modal
    const modalRef = this.ngbModalService.open(InputAssetPickerModalComponent, ModalCommonOptions.toNgbModalOptions(options));
    // Set @Input() properties for our component being used as the modal content
    modalRef.componentInstance.options = options;
    modalRef.componentInstance.filter = this.filter;
    modalRef.componentInstance.showSearch = this.showSearch;
    modalRef.componentInstance.showRelated = this.showRelated;
    modalRef.componentInstance.showPreview = this.showPreview;
    modalRef.componentInstance.data = Helper.deepCopy(this.asset);
    // Set actions when modal promise is resolved with either ok or cancel
    modalRef.result.then((value: EventModel) => {
      // User hit ok so value.data is the data object.  If in add mode then add to data store.  If in edit mode then update data store.
      this.asset.AssetId = value.data.AssetId;
      this.asset.Title = value.data.Title;
      this.data.AssetId = value.data.AssetId;
      this.data.Title = value.data.Title;
      let payload: EventModel = new EventModel("change", event, value.data, new EventElementModel("asset-picker", null, this.label, this.label, this.placeholder));
      this.change.emit(payload);
    }, (reason) => {
      // User hit cancel so nothing to save
    });
  }


  onSuffixClick($event) {

    if (!this.suffixClickEventEnabled || !this.suffixClick) {
      return;
    }

    let payload: EventModel = new EventModel("suffix-click", $event, this.data, new EventElementModel("asset-picker", null, this.label, this.label, this.placeholder));
    this.suffixClick.emit(payload);

  }


}
