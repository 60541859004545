import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NavService } from 'projects/common-lib/src/lib/ux/nav/nav.service';

@Component({
  selector: 'ib-nav-footer',
  templateUrl: './nav-footer.component.html',
  styleUrls: ['./nav-footer.component.css']
})
export class NavFooterComponent implements OnInit, OnChanges {

  /**
   * When true the footer is a standalone footer being shown without other site
   * elements like header and menu.  This may impact the presentation of the
   * footer to match standalone component styling, etc.
   */
  @Input() standalone: boolean = false;

  /**
   * Typically this is the same as nav.themeClasses but if this is a standalone
   * component it may be a theme to match standalone component styling.
   */
  themeClasses: string = "";

  constructor(public nav: NavService) {
    this.themeClasses = this.nav.themeClasses;
  }


  ngOnInit() {
  }


  ngOnChanges(changes: SimpleChanges) {
    if (this.standalone) {
      this.themeClasses = "ib-nav-light";
    } else {
      this.themeClasses = this.nav.themeClasses;
    }
  }


}
