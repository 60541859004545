<footer class="{{themeClasses}} ib-nav-bottom"
        [ngClass]="{'mobile': nav.isMobile()}">
  <div class="ib-nav-bottom-content"
       [ngClass]="{'ib-menu-width-narrow': nav.isSideCollapsed, 'ib-menu-width-standard': !nav.isSideCollapsed}">
    <ng-container *ngFor="let footer of nav.footers; last as isLast">
      <span [innerHtml]="footer | safe: 'html'"></span>
      <span *ngIf="!isLast">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
    </ng-container>
  </div>
</footer>
