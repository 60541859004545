import { Component, Input, OnInit } from '@angular/core';
import { UploadCounters } from 'projects/core-lib/src/lib/services/file-upload.service';

@Component({
  selector: 'ib-file-upload-counters',
  templateUrl: './file-upload-counters.component.html',
  styleUrls: ['./file-upload-counters.component.css']
})
export class FileUploadCountersComponent implements OnInit {
  // Counters to use for displaying stats
  @Input() counters: UploadCounters = {
    countPending: 0,
    countStarted: 0,
    countUploading: 0,
    countDone: 0,
    countSuccess: 0,
    countError: 0,
  }

  // Flag if we should show stats about the upload process.  Defaults to true.
  @Input() showStats: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
