import { Component, OnInit, forwardRef, Output, Input, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import * as Enumerable from 'linq';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiOperationType, CacheLevel, Query, IApiResponseWrapperTyped } from 'projects/core-lib/src/lib/api/ApiModels';
import { ApiModuleCore } from 'projects/core-lib/src/lib/api/Api.Module.Core';

/**
 * Taken from https://github.com/Sonaryr/ngx-tags-input
 * modified to not use ngx-bootstrap
 */

const noop = () => { };

const TAGS_INPUT_TEMPLATE = `
    <div class="tags-input" [ngClass]="{'tags-input-readonly': readonly||disabled}">
        <span class="tags-input__tag badge bg-primary bg-tags" *ngFor="let tag of tags">
            {{tag}}
            <span *ngIf="isDeleteable(tag)" role="button" class="tags-input__tag-remove-btn" (click)="removeTag(tag)" (touch)="removeTag(tag)">
                <span aria-hidden="true">&times;</span>
                <span class="sr-only">Close</span>
            </span>
        </span>
        <input
            *ngIf="options === null"
            class="tags-input__input-field"
            type="text"
            placeholder="{{ getPlaceHolder() }}"
            name="tags"
            (blur)="addTag(tagInput)"
            (keyup.enter)="addTag(tagInput)"
            (keydown.backspace)="removeLastTag(tagInput)"
            [ngbTypeahead]="typeaheadSearch"
            [readonly]="readonly ? 'readonly' : null"
            [disabled]="disabled || maximumOfTagsReached()"
            [hidden]="disabled || maximumOfTagsReached()"
            #tagInput />
        <input
            *ngIf="options !== null"
            class="tags-input__input-field"
            type="text"
            placeholder="{{ getPlaceHolder() }}"
            name="tags"
            (keydown.backspace)="removeLastTag(tagInput)"
            [(ngModel)]="selected"
            [ngbTypeahead]="typeaheadSearch"
            [readonly]="readonly ? 'readonly' : null"
            [disabled]="disabled || maximumOfTagsReached()"
            [hidden]="disabled || maximumOfTagsReached()"
            #tagInput />
    </div>
`;

const TAGS_INPUT_STYLE = `
    :host {
        overflow: auto;
        white-space: nowrap;
    }

    .tags-input {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }

    .tags-input-readonly {
        background-color: #e9ecef;
    }

    .tags-input__tag {
        display: inline-block;
        margin-bottom: 2px;
        margin-right: 5px;
        padding-right: 0.3em;
    }

    .tags-input__tag-remove-btn {
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        margin: -3px 0 0 3px;
        padding: 0;
        vertical-align: top;
    }

    .tags-input__input-field {
        border: none;
        flex-grow: 1;
        outline: none;
        padding: 0.375rem 0.75rem;
    }

    .bg-tags {
        font-size: unset;
        font-weight: unset;
    }
`;

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TagsComponent),
  multi: true
};

@Component({
  selector: 'ib-tags',
  template: TAGS_INPUT_TEMPLATE,
  styles: [TAGS_INPUT_STYLE],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class TagsComponent implements OnInit, OnChanges, ControlValueAccessor {
  public selected: string = '';
  public tags: any[] = [];
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input() maxTags: number;
  @Input() removeLastOnBackspace: boolean = false;
  @Input() canDeleteTags: boolean = true;
  @Input() placeholder: string = '';
  @Input() options: any = null;
  @Input() displayField: string = 'displayValue';
  @Input() minLengthBeforeOptions: number = 1;
  @Input() scrollableOptions: boolean = false;
  @Input() scrollableOptionsInView: number = 5;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() startsWith: boolean = false; // default is contains mode
  @Input() optionCount: number = 15;

  @Output() onTagsChanged = new EventEmitter();
  @Output() onMaxTagsReached = new EventEmitter();
  @Output() onNoOptionsMatch = new EventEmitter();

  @Input() public optionsPickListId: string = "";
  @Input() public optionsPickListFilter: string = "";
  @Input() public optionsPickList: m5core.PickListSelectionViewModel[] = [];

  public pickListId: string = "";
  public pickList: m5core.PickListSelectionViewModel[] = [];


  constructor(protected apiService: ApiService) {
  }

  ngOnInit() { }


  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }

  public configure() {

    if (this.optionsPickList && this.optionsPickList.length > 0) {
      this.pickList = this.optionsPickList;
    }

    if (this.optionsPickListId && !Helper.equals(this.pickListId, this.optionsPickListId, true)) {
      this.loadPickList(this.optionsPickListId);
    }

  }




  public getPlaceHolder(): string {
    if (this.tags && this.tags.length > 0) {
      return '';
    }
    return this.placeholder;
  }

  private tagsChanged(type: string, tag: any): void {
    this.onChangeCallback(this.tags);
    this.onTagsChanged.emit({
      change: type,
      tag: tag,
      tags: this.tags
    });
    if (this.maximumOfTagsReached()) {
      this.onMaxTagsReached.emit();
    }
  }

  public removeLastTag(tagInput: HTMLInputElement): void {
    if (this.readonly || this.disabled || !this.removeLastOnBackspace || !this.tags.length) {
      return;
    }

    if (tagInput.value === '') {
      this.removeTag(this.tags[this.tags.length - 1]);
    }
  }

  public addTag(tagInput: HTMLInputElement): void {
    if (this.readonly || this.disabled) {
      return;
    }
    if (tagInput.value.trim() !== '') {
      //let tag = {
      //    [this.displayField]: tagInput.value
      //};
      //this.addPredefinedTag(tag);
      this.addPredefinedTag(tagInput.value.trim());
    }
    tagInput.value = '';
  }

  private addPredefinedTag(tag: Object): void {
    if (this.readonly || this.disabled) {
      return;
    }
    if (!this.maximumOfTagsReached()) {
      this.tags.push(tag);
      this.tagsChanged('add', tag);
    }
  }

  public removeTag(tagToRemove: any): void {
    if (this.readonly || this.disabled) {
      return;
    }
    if (!this.isDeleteable(tagToRemove)) {
      return;
    }
    this.tags = this.tags.filter(tag => tagToRemove !== tag);
    this.tagsChanged('remove', tagToRemove);
  }

  public maximumOfTagsReached(): boolean {
    return typeof this.maxTags !== 'undefined' && this.tags.length >= this.maxTags;
  }

  public isDeleteable(tag: any): boolean {
    if (this.readonly || this.disabled) {
      return false;
    }
    if (typeof tag.deleteable !== "undefined" && !tag.deleteable) {
      return false;
    }
    return this.canDeleteTags;
  }

  typeaheadSearch = (text$: Observable<string>) => {
    if (this.startsWith) {
      return text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(term => term.length < 2 ? []
          : Enumerable.from(this.pickList).where(v => Helper.startsWith(v.Value, term, true)).take(this.optionCount || 10).select(s => s.Value).toArray())
      );
    } else {
      return text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(term => term.length < 2 ? []
          : Enumerable.from(this.pickList).where(v => Helper.contains(v.Value, term, true)).take(this.optionCount || 10).select(s => s.Value).toArray())
      );
    }
  }

  //private typeaheadOnSelect(e: ngbTypeahead):void {
  //    if(typeof e.item === 'string'){
  //        this.addPredefinedTag({
  //            [this.displayField]: e.value
  //        });
  //    }else {
  //        this.addPredefinedTag(e.item);
  //    }
  //    this.selected = '';
  //}

  //private typeaheadOnNoMatch(e:any):void {
  //    if(typeof this.onNoOptionsMatch !== 'undefined'){
  //        this.onNoOptionsMatch.emit(e)
  //    }
  //}

  public loadPickList(pickListId: string, pickListFilter: string = "") {
    let apiProp = ApiModuleCore.InputPickList();
    let apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.List);
    apiCall.silent = true;
    // Tweak caching
    if (Helper.startsWith(pickListId, "___")) {
      // Don't cache type-ahead pick lists like we do others
      apiCall.cacheIgnoreOnRead = true;
    } else if (Helper.startsWith(pickListId, "__")) {
      // Static data model options don't change
      apiCall.cacheLevel = CacheLevel.Static;
    }
    let query = new Query();
    query.Page = 1;
    query.Size = Constants.RowsToReturn.All;
    query.Filter = pickListFilter;
    (<any>query).PickListId = pickListId;
    this.apiService.execute(apiCall, query).subscribe((result: IApiResponseWrapperTyped<m5core.PickListSelectionViewModel[]>) => {
      if (!result.Data.Success) {
        console.error(result.Data);
        this.pickListId = pickListId;
        this.pickList = [];
      } else {
        this.pickListId = pickListId;
        this.pickList = result.Data.Data;
      }
    });
  }

  writeValue(value: any) {
    if (value !== this.tags) {
      this.tags = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

}
