import { Pipe, PipeTransform } from '@angular/core';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import * as Enumerable from 'linq';

@Pipe({
  name: 'distinctPropertyValues'
})
export class DistinctPropertyValuesPipe implements PipeTransform {

  public transform(value: any[], propertyName: string): any[] {

    if (!value || !Helper.isArray(value) || value.length === 0) {
      return [];
    }

    if (!propertyName) {
      return value;
    }

    let distinct: any[] = [];
    try {
      distinct = Enumerable.from(value).distinct(x => x[propertyName]).select(x => x[propertyName]).toArray();
    } catch (err) {
      Log.errorMessage(err);
    }

    return distinct;

  }


  //transform(value: any, args?: any): any {
  //  return null;
  //}

}
