import { OnDestroy, OnInit, SimpleChanges, OnChanges, Directive, AfterViewInit, AfterViewChecked, SimpleChange } from "@angular/core";
import { Subject } from "rxjs";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { Helper } from "./helper";

@Directive()
export abstract class BaseComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, AfterViewChecked {

  /**
  Make constants available in html view.
  */
  public Constants = Constants;

  public get isMobilePortrait(): boolean {
    return Helper.isMobilePortrait();
  }
  public get isMobile(): boolean {
    return Helper.isMobile();
  }
  public get isTablet(): boolean {
    return Helper.isTablet();
  }
  public get isMobileOrTablet(): boolean {
    return Helper.isMobileOrTablet();
  }

  /**
  We use takeUntil pattern (see https://stackoverflow.com/a/41177163) where we
  only need to unsubscribe from this one shared subject in ngOnDestroy as it
  is passed as parameter to takeUntil.  Subclasses with their own ngOnDestroy
  need to call super.ngOnDestroy().
  For example:
  this.appService.systemSettingsTryGetOne("Report", "SupportFreeFormCopyTargets", "true").pipe(takeUntil(this.ngUnsubscribe)).subscribe((setting: m5.SettingEditViewModel) => {
    this.supportFreeFormCopyTargets = Helper.equals(setting.Value, "true", true);
  });
  */
  protected ngUnsubscribe = new Subject();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  protected inputHasChanged(change: SimpleChange) {
    return (change && !change.isFirstChange() && change.currentValue !== change.previousValue);
  }

  ngAfterViewInit() {
  }

  ngAfterViewChecked() {
  }

  ngOnDestroy() {
    /**
    We use takeUntil pattern (see https://stackoverflow.com/a/41177163) where we
    only need to unsubscribe from this one shared subject in ngOnDestroy as it
    is passed as parameter to takeUntil.  Subclasses with their own ngOnDestroy
    need to call super.ngOnDestroy().
    */
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  trackByIndex(index, item) {
    return index; // or item.id
  }



  /**
   * Returns true if there is a browser history.
   */
  browserHasHistory(): boolean {
    // It's tempting to want to return false if our last page was a login
    // page since that's probably not what is desired but we're not allowed
    // to peek into the history to see what page that would be so it is
    // what it is.
    return (window.history.length > 0);
  }

  /**
   * Goes back in browser history.  Returns true if that was done and false if there was no history.
   */
  browserBack(): boolean {
    //console.error("ready to go back");
    // Location is probably better but we'd have to inject in the constructor all the way up the inheritance hierarchy
    // and a lot of components (virtually all) inherit from this class or a descendant of this class.
    if (window.history.length > 0) {
      window.history.back();
      return true;
    }
    return false;
  }


}
