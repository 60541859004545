import { NgbModalOptions, NgbModalConfig, NgbActiveModal, NgbConfig } from "@ng-bootstrap/ng-bootstrap";
import { Helper } from "projects/core-lib/src/lib/helpers/helper";
import { HelpContext } from "projects/core-lib/src/lib/models/help-context";
import { StateIcon } from "../image/icon/icon.component";
import { ButtonItem } from "../ux-models";

export class ModalCommonOptions {

  public titleIcon: string = "";
  public titleIconContextColor: string = ""; //primary, secondary, info, success, warning, danger, light, dark
  public title: string = "";
  public titleContextColor: string = ""; //primary, secondary, info, success, warning, danger, light, dark
  public message: string = "";
  public accessArea: string = "";
  public mode: "view" | "add" | "edit" = "view"; // give some context to the modal.  typically "view" but could be "add" or "edit"
  public pickList: any = {}; // container for pick lists.
  /**
  If assigned function returns true or false to indicate if ok action can proceed.
  Param #1 = ModalCommonOptions object.
  Param #2 = okData object.  This is typically the data associated with the modal.
  Param #3 = errors string array.  Note that errors are shown w/o this function being assigned but are passed as
  parameter in case they need to be adjusted or merged with custom validation triggered errors.
  Param #4 = NgbActiveModal which can be used to close or dismiss the modal if the validate function should do that.
  */
  public validate: (options: ModalCommonOptions, okData: any, errors: string[], modal: NgbActiveModal) => boolean = null;
  public okButtonContextColor: string = "primary"; //primary, secondary, info, success, warning, danger, light, dark
  public okButtonIcon: string = "";
  public okButtonText: string = "Ok";
  public ok: (value: any) => any = null;
  public cancelButtonContextColor: string = "secondary"; //primary, secondary, info, success, warning, danger, light, dark
  public cancelButtonIcon: string = "";
  public cancelButtonText: string = "";
  public cancel: (value: any) => any = null;
  public size: "small" | "large" | "larger" | "largest" = "large"; //sm //lg //xl //largest
  public template: string = "";
  public templateUrl: string = "";
  public showAlerts: boolean = true;
  public callback: any = {}; // Attach callback methods here
  public cargo: any = {}; // Attach any cargo data here

  /**
   * When user hits ok (close) we assume data was edited which may result in
   * data being marked as dirty that wasn't dirty.  If this setting is true
   * and we have a form status we can check and the status says our form is
   * pristine then we act as if the user hit cancel (dismiss) which will
   * ignore edits (since none were made) and our form won't be marked as
   * dirty.  We need to do this as a modal option since some modals don't have
   * any data and if this is always enabled then it acts as if the user always
   * hit the cancel button instead of the ok button on the modal.
   */
  public whenPristineDismissInsteadOfClose: boolean = false;

  public featureToggleSupport: boolean = false;
  public featureToggleOn: boolean = false;
  public featureToggleLabel: string = "";
  public featureToggleTooltip: string = "";
  /**
   * When a toggle event is provided the feature toggle will call this event any time it
   * is toggled.  Typically either this event handler and/or the featureToggleStorageKey
   * will be specified.
   */
  public featureToggleEvent: (isFeatureToggleOn: boolean) => any = null;
  /**
   * Use private for storage key so in setter we can get the
   * current value to use.
   */
  private _featureToggleStorageKey: string = "";
  public get featureToggleStorageKey(): string {
    return this._featureToggleStorageKey;
  }
  /**
   * When a storage key is provided the feature toggle will save the state of the toggle and
   * will load that state when the modal is displayed.  Typically either this storage key
   * and/or the featureToggleEvent will be specified.
   */
  public set featureToggleStorageKey(key: string) {
    this._featureToggleStorageKey = key;
    if (key) {
      // Since we just set our storage key let's see if there is a current value to use
      const value = Helper.localStorageGet(this.featureToggleStorageKey, "");
      if (value) {
        this.featureToggleOn = Helper.equals(value, "true", true);
      }
    }
  }
  public featureToggleStateIcons: StateIcon[] = [{ state: "true", icon: "toggle-on" }, { state: "false", icon: "toggle-off" }];
  public featureToggleClick: ($event: any, options: ModalCommonOptions) => void = ($event: any, options: ModalCommonOptions) => {
    if (!options) {
      options = this;
    }
    options.featureToggleOn = !options.featureToggleOn;
    if (options.featureToggleEvent) {
      options.featureToggleEvent(options.featureToggleOn);
    }
    if (options.featureToggleStorageKey) {
      Helper.localStorageSave(options.featureToggleStorageKey, options.featureToggleOn.toString());
    }
    return;
  };

  public customButtons: ButtonItem[] = [];

  public helpContext: HelpContext;
  public helpTag: string;


  public static defaultDataEntryModalOptions(): ModalCommonOptions {
    const options = new ModalCommonOptions();
    options.size = "largest"; // options are: large, small, largest.  Default is largest.
    options.titleContextColor = "primary"; // options are: primary, secondary, info, success, warning, danger, light, dark
    options.okButtonContextColor = "primary"; // options are: primary, secondary, info, success, warning, danger, light, dark
    options.okButtonText = "Ok";
    options.cancelButtonContextColor = "secondary";
    options.cancelButtonText = "Cancel";
    return options;
  }

  public static toNgbModalOptions(options: ModalCommonOptions): NgbModalOptions {
    const _ngbConfig: NgbConfig = new NgbConfig();
    const modalOptions: NgbModalOptions = new NgbModalConfig(_ngbConfig);

    modalOptions.backdrop = "static";
    if (Helper.equals(options.size, "large", true) || Helper.equals(options.size, "lg", true)) {
      modalOptions.size = "lg";
    } else if (Helper.equals(options.size, "small", true) || Helper.equals(options.size, "sm", true)) {
      modalOptions.size = "sm";
    } else if (Helper.equals(options.size, "larger", true) || Helper.equals(options.size, "xl", true)) {
      (modalOptions as any).size = "larger";
    } else if (Helper.equals(options.size, "largest", true)) {
      (modalOptions as any).size = "largest";
    }
    return modalOptions;
  }

}
