import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { InputTextComponent } from './input/input-text/input-text.component';
import { FormsModule } from '@angular/forms';
import { IconComponent } from './image/icon/icon.component';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/calendar';
import { KeyFilterModule } from 'primeng/keyfilter';
import { OverlayPanelModule } from 'primeng/overlaypanel';
//import { MultiSelectModule } from 'primeng/multiselect';
import { ListboxModule } from 'primeng/listbox';
//import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { ContextMenuModule } from 'primeng/contextmenu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TooltipModule } from 'primeng/tooltip';
//import { DragDropModule } from 'primeng/dragdrop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MenuModule } from 'primeng/menu';
import { SlideMenuModule } from 'primeng/slidemenu';
import { MenuItem } from 'primeng/api';
import { InputStaticComponent } from './input/input-static/input-static.component';
import { ButtonComponent } from './ux/button/button.component';
import { CodeComponent } from './dev/code/code.component';
import { CodeBlockComponent } from './dev/code-block/code-block.component';
import { InputNumberComponent } from './input/input-number/input-number.component';
import { InputTextareaComponent } from './input/input-textarea/input-textarea.component';
import { InputCheckboxComponent } from './input/input-checkbox/input-checkbox.component';
import { InputWrapperComponent } from './input/input-wrapper/input-wrapper.component';
import { InputRadioComponent } from './input/input-radio/input-radio.component';
import { InputSelectComponent } from './input/input-select/input-select.component';
import { InputDateComponent } from './input/input-date/input-date.component';
import { IconPickerComponent } from './input/icon-picker/icon-picker.component';
import { AlertComponent } from './alert/alert.component';
import { ContextColorPickerComponent } from './ux/context-color-picker/context-color-picker.component';
import { CoreLibModule } from 'projects/core-lib/src/lib/core-lib.module';
import { StandardTableComponent } from './table/standard-table/standard-table.component';
import { TableModule } from 'primeng/table';
import { ChartModule } from 'primeng/chart';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { StandardTableCellOutputComponent } from './table/standard-table-cell-output/standard-table-cell-output.component';
import { StandardTableCellInputComponent } from './table/standard-table-cell-input/standard-table-cell-input.component';
import { InputTagsComponent } from './input/input-tags/input-tags.component';
import { TagsComponent } from 'projects/common-lib/src/lib/input/input-tags/tags.component';
import { ActionButtonComponent } from './ux/action-button/action-button.component';
import { FormStatusErrorOutputComponent } from './form/form-status-error-output/form-status-error-output.component';
import { FormSaveCloseButtonsComponent } from './form/form-save-close-buttons/form-save-close-buttons.component';
import { FormHeaderComponent } from './form/form-header/form-header.component';
import { NavHeaderComponent } from './ux/nav/nav-header/nav-header.component';
import { NavFooterComponent } from './ux/nav/nav-footer/nav-footer.component';
import { NavSideComponent } from './ux/nav/nav-side/nav-side.component';
import { NavService } from 'projects/common-lib/src/lib/ux/nav/nav.service';
import { AvatarModule } from 'ngx-avatar';
import { AvatarComponent } from 'projects/common-lib/src/lib/image/avatar/avatar.component';
import { ModalCommonTitleComponent } from './modal/modal-common-title/modal-common-title.component';
import { ModalCommonButtonsComponent } from './modal/modal-common-buttons/modal-common-buttons.component';
import { ModalDynamicFormComponent } from './modal/modal-dynamic-form/modal-dynamic-form.component';
import { ModalSimpleAlertComponent } from './modal/modal-simple-alert/modal-simple-alert.component';
import { FormRenderComponent } from './form-render/form-render/form-render.component';
import { FormGroupRenderComponent } from './form-render/form-group-render/form-group-render.component';
import { FormControlRenderComponent } from './form-render/form-control-render/form-control-render.component';
import { FavIconProvider, FavIconConfigProvider } from 'projects/common-lib/src/lib/image/favicon/fav-icons';
import { FileUploadComponent } from './file/file-upload/file-upload.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { InputPlainComponent } from './input/input-plain/input-plain.component';
import { MenuStandardComponent } from './ux/nav/menu-standard/menu-standard.component';
import { FileManagementComponent } from './file/file-management/file-management.component';
import { InputSelectPlainComponent } from './input/input-select-plain/input-select-plain.component';
import { InputCheckboxesFromPickListComponent } from './input/input-checkboxes-from-pick-list/input-checkboxes-from-pick-list.component';
import { InputSimpleObjectListComponent } from './input/input-simple-object-list/input-simple-object-list.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { GalleriaModule } from 'primeng/galleria';
import { NgxJsonViewModule } from 'ng-json-view';
//import { AngularDokaModule } from 'angular-doka';
//import { AngularDokaModule } from '../../../../lib/angular-doka';
import { NativeTableComponent } from './table/native-table/native-table.component';
import { InputSelectButtonComponent } from './input/input-select-button/input-select-button.component';
import { FilterBuilderComponent } from './filter/filter-builder/filter-builder.component';
import { FilterSelectionModalComponent } from './filter/filter-selection-modal/filter-selection-modal.component';
import { FilterSelectionButtonComponent } from './filter/filter-selection-button/filter-selection-button.component';
import { InputMultiselectComponent } from './input/input-multiselect/input-multiselect.component';
import { InputAssetComponent } from './input/input-asset/input-asset.component';
import { InputAssetPickerModalComponent } from './input/input-asset-picker-modal/input-asset-picker-modal.component';
import { InputContactComponent } from './input/input-contact/input-contact.component';
import { InputContactPickerModalComponent } from './input/input-contact-picker-modal/input-contact-picker-modal.component';
import { InputDropdownComponent } from './input/input-dropdown/input-dropdown.component';
import { DropdownComponent, DropdownItemComponent } from './input/input-dropdown/dropdown';
import { MultiSelectComponent, MultiSelectItemComponent } from './input/input-multiselect/multiselect';
import { InputStringListComponent } from './input/input-string-list/input-string-list.component';
import { InputContactAddressBlockComponent } from './input/input-contact-address-block/input-contact-address-block.component';
import { InputContactFormModalComponent } from './input/input-contact-form-modal/input-contact-form-modal.component';
import { InputCurrencyComponent } from './input/input-currency/input-currency.component';
import { InputKeyValuePairListComponent } from './input/input-key-value-pair-list/input-key-value-pair-list.component';
import { FlexTableComponent } from './table/flex-table/flex-table.component';
import { ObjectViewerComponent } from './input/object-viewer/object-viewer.component';
import { InputDurationComponent } from './input/input-duration/input-duration.component';
import { InputPasswordComponent } from './input/input-password/input-passwordcomponent';
import { InputPercentComponent } from './input/input-percent/input-percent.component';
import { BadgeComponent } from './ux/badge/badge.component';
import { CodeEditorComponent } from './input/code-editor/code-editor.component';
import { TimelineItemComponent } from './ux/timeline/timeline-item.component';
import { TimelineComponent } from './ux/timeline/timeline.component';
import { StarRatingComponent } from './ux/star-rating/star-rating.component';
import { DataEditorExportModalComponent } from './ux/data-editor/data-editor-export-modal/data-editor-export-modal.component';
import { PeekWithHeaderComponent } from './ux/peek-with-header/peek-with-header.component';
import { PeekComponent } from './ux/peek/peek.component';
import { DataEditorHeaderComponent } from './ux/data-editor/data-editor-header/data-editor-header.component';
import { HtmlEditorComponent } from './input/html-editor/html-editor.component';
import { InputAssetLicenseMultiselectComponent } from './input/input-asset-license-multiselect/input-asset-license-multiselect.component';
import { InputAssetLicenseSelectDisplayComponent } from './input/input-asset-license-select-display/input-asset-license-select-display.component';
import { InputAssetLicenseSelectComponent } from './input/input-asset-license-select/input-asset-license-select.component';
import { InputRichTextComponent } from './input/input-rich-text/input-rich-text.component';
import { ListBoxComponent } from './input/list-box/list-box.component';
import { DropdownAppendToBodyDirective } from './ux/drop-down-append-to-body/drop-down-append-to-body.directive';
import { DataNavComponent } from './ux/nav/data-nav/data-nav.component';
import { TileComponent } from './ux/tile/tile.component';
import { OverlaySimpleAlertComponent } from './modal/overlay-simple-alert/overlay-simple-alert.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { TextFieldModule } from '@angular/cdk/text-field';
import { HelpButtonComponent } from './ux/help-button/help-button.component';
import { LoginRecoverComponent } from './ux/security/login-recover/login-recover.component';
import { PasswordResetComponent } from './ux/security/password-reset/password-reset.component';
import { PasswordResetLinkRequestComponent } from './ux/security/password-reset-link-request/password-reset-link-request.component';
import { SecurityPolicyPasswordComplianceComponent } from './ux/security/security-policy-password-compliance/security-policy-password-compliance.component';
import { MultiFactorAuthenticationStepOneComponent } from './ux/security/multi-factor-authentication-step-one/multi-factor-authentication-step-one.component';
import { MultiFactorAuthenticationStepTwoComponent } from './ux/security/multi-factor-authentication-step-two/multi-factor-authentication-step-two.component';
import { DynamicEditorComponent } from './ux/dynamic-editor/dynamic-editor.component';
import { TableConfigurationModalComponent } from './table/table-configuration-modal/table-configuration-modal.component';
import { TableColumnConfigurationModalComponent } from './table/table-column-configuration-modal/table-column-configuration-modal.component';
import { DataNavTreeComponent } from './ux/nav/data-nav-tree/data-nav-tree.component';
import { CdkTreeModule } from '@angular/cdk/tree';
import { DynamicValueFormatterComponent } from './ux/dynamic-value-formatter/dynamic-value-formatter.component';
import { DiffDisplayComponent } from './dev/diff-display/diff-display.component';
import { FileUploadCountersComponent } from './file/file-upload-counters/file-upload-counters.component';
import { AttachmentEditModule } from 'projects/shared-lib/src/lib/attachment/attachment-edit-modal/attachment-edit.module';
import { GridSizeSelectorComponent } from './ux/grid-size-selector/grid-size-selector.component';

//import { NgxCurrencyModule } from 'ngx-currency';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    CoreLibModule,
    OverlayModule,
    TextFieldModule,
    CalendarModule,
    TableModule,
    ChartModule,
    MultiSelectModule,
    TooltipModule,
    ListboxModule,
    //ChipsModule,
    DragDropModule,
    GalleriaModule,
    //NgxCurrencyModule,
    //AngularDokaModule,
    DropdownModule,
    ContextMenuModule,
    PanelMenuModule,
    SlideMenuModule,
    MenuModule,
    KeyFilterModule,
    OverlayPanelModule,
    AvatarModule,
    NgxJsonViewModule,
    DropzoneModule,
    CdkTreeModule,
    AttachmentEditModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    }), // See https://github.com/Zak-C/ngx-loading
  ],
  declarations: [
    InputTextComponent,
    InputTextareaComponent,
    InputNumberComponent,
    InputCurrencyComponent,
    InputDateComponent,
    InputDurationComponent,
    InputSelectComponent,
    InputSelectPlainComponent,
    InputSelectButtonComponent,
    InputCheckboxComponent,
    InputCheckboxesFromPickListComponent,
    InputRadioComponent,
    InputTagsComponent,
    InputPlainComponent,
    InputSimpleObjectListComponent,
    InputStaticComponent,
    InputWrapperComponent,
    DropdownItemComponent,
    DropdownComponent,
    MultiSelectComponent,
    MultiSelectItemComponent,
    InputDropdownComponent,
    InputMultiselectComponent,
    InputStringListComponent,
    InputKeyValuePairListComponent,
    InputAssetComponent,
    InputAssetPickerModalComponent,
    InputContactComponent,
    InputContactPickerModalComponent,
    InputContactFormModalComponent,
    InputContactAddressBlockComponent,
    InputPasswordComponent,
    InputPercentComponent,
    IconPickerComponent,
    ObjectViewerComponent,
    TagsComponent,
    CodeComponent,
    CodeBlockComponent,
    // Modal Components
    ModalCommonTitleComponent,
    ModalCommonButtonsComponent,
    ModalSimpleAlertComponent,
    ModalDynamicFormComponent,
    // Form Render
    FormRenderComponent,
    FormGroupRenderComponent,
    FormControlRenderComponent,
    IconComponent,
    AvatarComponent,
    ButtonComponent,
    ActionButtonComponent,
    AlertComponent,
    ContextColorPickerComponent,
    StandardTableComponent,
    StandardTableCellOutputComponent,
    StandardTableCellInputComponent,
    FormStatusErrorOutputComponent,
    FormHeaderComponent,
    FormSaveCloseButtonsComponent,
    NavHeaderComponent,
    NavFooterComponent,
    NavSideComponent,
    MenuStandardComponent,
    FileUploadComponent,
    FileManagementComponent,
    NativeTableComponent,
    FlexTableComponent,
    FilterBuilderComponent,
    FilterSelectionModalComponent,
    FilterSelectionButtonComponent,
    ListBoxComponent,
    InputAssetLicenseSelectDisplayComponent,
    InputAssetLicenseSelectComponent,
    InputAssetLicenseMultiselectComponent,
    InputRichTextComponent,
    CodeEditorComponent,
    HtmlEditorComponent,
    DropdownAppendToBodyDirective,
    TimelineComponent,
    TimelineItemComponent,
    DataNavComponent,
    AlertComponent,
    StarRatingComponent,
    PeekComponent,
    PeekWithHeaderComponent,
    DataEditorHeaderComponent,
    DataEditorExportModalComponent,
    DynamicEditorComponent,
    TileComponent,
    BadgeComponent,
    OverlaySimpleAlertComponent,
    HelpButtonComponent,
    // Security
    LoginRecoverComponent,
    PasswordResetComponent,
    PasswordResetLinkRequestComponent,
    SecurityPolicyPasswordComplianceComponent,
    MultiFactorAuthenticationStepOneComponent,
    MultiFactorAuthenticationStepTwoComponent,
    TableConfigurationModalComponent,
    TableColumnConfigurationModalComponent,
    DataNavTreeComponent,
    DynamicValueFormatterComponent,
    DiffDisplayComponent,
    FileUploadCountersComponent,
    GridSizeSelectorComponent,
  ],
  exports: [
    ModalCommonTitleComponent,      // used by a lot of our modals not just those in this lib
    ModalCommonButtonsComponent,    // used by a lot of our modals not just those in this lib
    ModalSimpleAlertComponent,
    ModalDynamicFormComponent,
    FormRenderComponent,
    FormGroupRenderComponent,     // used by form designer
    FormControlRenderComponent,   // used by form designer
    NgxLoadingModule,
    InputTextComponent,
    InputTextareaComponent,
    InputNumberComponent,
    InputCurrencyComponent,
    InputDateComponent,
    InputDurationComponent,
    InputSelectComponent,
    InputSelectPlainComponent,
    InputSelectButtonComponent,
    InputCheckboxComponent,
    InputCheckboxesFromPickListComponent,
    InputRadioComponent,
    InputTagsComponent,
    InputPlainComponent,
    InputSimpleObjectListComponent,
    InputStaticComponent,
    InputWrapperComponent,
    MultiSelectComponent,           // we sometimes (rare but sometimes) use this externally
    InputDropdownComponent,
    InputMultiselectComponent,
    InputStringListComponent,
    InputKeyValuePairListComponent,
    InputAssetComponent,
    InputAssetPickerModalComponent,
    InputContactComponent,
    InputContactPickerModalComponent,
    InputContactAddressBlockComponent,
    InputPasswordComponent,
    InputPercentComponent,
    IconPickerComponent,
    ObjectViewerComponent,
    TagsComponent,
    CodeComponent,
    CodeBlockComponent,
    IconComponent,
    AvatarComponent,
    ButtonComponent,
    ActionButtonComponent,
    AlertComponent,
    ContextColorPickerComponent,
    StandardTableComponent,
    NativeTableComponent,
    FlexTableComponent,
    FormStatusErrorOutputComponent, // used by a lot of our forms not just dynamic FormRenderComponent
    FormHeaderComponent,            // used by a lot of our forms not just dynamic FormRenderComponent
    FormSaveCloseButtonsComponent,  // used by a lot of our forms not just dynamic FormRenderComponent
    NavHeaderComponent,
    NavFooterComponent,
    NavSideComponent,
    MenuStandardComponent,
    FileUploadComponent,
    FileManagementComponent,
    FilterSelectionButtonComponent,
    FilterBuilderComponent,
    ListBoxComponent,
    InputAssetLicenseSelectDisplayComponent,
    InputAssetLicenseSelectComponent,
    InputAssetLicenseMultiselectComponent,
    InputRichTextComponent,
    CodeEditorComponent,
    HtmlEditorComponent,
    DropdownAppendToBodyDirective,
    TimelineComponent,
    TimelineItemComponent,
    DataNavComponent,
    TileComponent,
    StarRatingComponent,
    PeekComponent,
    PeekWithHeaderComponent,
    DataEditorHeaderComponent,
    DataEditorExportModalComponent,
    DynamicEditorComponent,
    BadgeComponent,
    // Security
    LoginRecoverComponent,
    PasswordResetComponent,
    PasswordResetLinkRequestComponent,
    SecurityPolicyPasswordComplianceComponent,
    MultiFactorAuthenticationStepOneComponent,
    MultiFactorAuthenticationStepTwoComponent,
    DataNavTreeComponent,
    DynamicValueFormatterComponent,
    DiffDisplayComponent,
    FileUploadCountersComponent,
    GridSizeSelectorComponent
  ],
  // Any components used as modals need to be listed in entry components
  // See https://stackoverflow.com/questions/39756192/what-is-entrycomponents-in-angular-ngmodule
  entryComponents: [
    ModalSimpleAlertComponent,
    ModalDynamicFormComponent,
    InputAssetPickerModalComponent,
    InputContactPickerModalComponent,
    InputContactFormModalComponent,
    DataEditorExportModalComponent,
  ],
  // All services not annotated as injectable in root go here
  providers: [FavIconProvider, FavIconConfigProvider]
})
export class CommonLibModule { }
