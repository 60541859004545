import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { TableHelper } from 'projects/common-lib/src/lib/table/table-helper';
import { Log, Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ButtonItem, Action, EventModelTyped, EventModel } from 'projects/common-lib/src/lib/ux-models';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { Subject } from 'rxjs';
import { ContactService } from 'projects/core-lib/src/lib/services/contact.service';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiOperationType, IApiResponseWrapperTyped, ApiProperties, Query, IApiResponseWrapper } from 'projects/core-lib/src/lib/api/ApiModels';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';

@Component({
  selector: 'ib-data-editor-export-modal',
  templateUrl: './data-editor-export-modal.component.html',
  styleUrls: ['./data-editor-export-modal.component.css']
})
export class DataEditorExportModalComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() options: ModalCommonOptions = new ModalCommonOptions();
  @Input() apiProperties: ApiProperties = null;
  @Input() currentFilter: Query = null;
  @Input() allowExportWithoutFilter: boolean = true;
  @Input() showThatFilterWillBeApplied: boolean = true;


  working: boolean = false;


  constructor(
    protected contactService: ContactService,
    protected appService: AppService,
    protected apiService: ApiService,
    protected sanitizer: DomSanitizer,
    protected ngbModalService: NgbModal,
    public modal: NgbActiveModal) {
    super();
  }

  // ngOnInit() {
  //   super.ngOnInit();
  // }

  // ngOnChanges(changes: SimpleChanges) {
  //   super.ngOnChanges(changes);
  // }


  exportUsingCurrentFilter($event) {
    const apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Export);
    this.working = true;
    this.apiService.execute(apiCall, this.currentFilter).subscribe((result: IApiResponseWrapper) => {
      if (result.Data.Success) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        this.working = false;
      } else {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        this.working = false;
      }
      // close response like hitting ok and will switch us to download mode on data editor
      const payload: EventModel = new EventModel("click", $event, null, null);
      payload.cargo = { ok: true, cancel: false };
      this.modal.close(payload)
    });
  }

  exportWithoutFilter($event) {
    const apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Export);
    const query = new Query();
    this.working = true;
    this.apiService.execute(apiCall, query).subscribe((result: IApiResponseWrapper) => {
      if (result.Data.Success) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        this.working = false;
      } else {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        this.working = false;
      }
      // close response like hitting ok and will switch us to download mode on data editor
      const payload: EventModel = new EventModel("click", $event, null, null);
      payload.cargo = { ok: true, cancel: false };
      this.modal.close(payload)
    });
  }

  download($event) {
    // close response like hitting ok and will switch us to download mode on data editor
    const payload: EventModel = new EventModel("click", $event, null, null);
    payload.cargo = { ok: true, cancel: false };
    this.modal.close(payload)
  }

}
