import { Component, OnInit } from '@angular/core';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiProperties, ApiOperationType, IApiResponseWrapper, IApiResponseWrapperTyped, ApiCall } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { ApiModuleSecurity } from 'projects/core-lib/src/lib/api/Api.Module.Security';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';

@Component({
  selector: 'ib-login-recover',
  templateUrl: './login-recover.component.html',
  styleUrls: ['./login-recover.component.css']
})
export class LoginRecoverComponent extends BaseComponent implements OnInit {

  public data: m5sec.LoginRecoveryRequestViewModel;
  public apiProperties: ApiProperties;
  public apiCall: ApiCall;
  public errorMessage: string = "";
  public responseMessage: string = "";

  constructor(
    protected appService: AppService,
    protected apiService: ApiService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.data = new m5sec.LoginRecoveryRequestViewModel();
    this.apiProperties = ApiModuleSecurity.SecurityLoginRecover();
    this.apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Call);
  }

  recoverLogin($event) {
    this.errorMessage = "";
    this.responseMessage = "";
    if (!this.data.Email) {
      this.errorMessage = "An email address is required for finding your login name.  If there was no email address " +
        "registered with your account or you no longer have access to the email address registered " +
        "with your account please contact your system administrator or technical support for assistance.";
      return;
    }
    if (!Helper.contains(this.data.Email, "@") || !Helper.contains(this.data.Email, ".")) {
      this.errorMessage = "This does not appear to be a valid email address.  An email address is required for finding your login name.  If there was no email address " +
        "registered with your account or you no longer have access to the email address registered " +
        "with your account please contact your system administrator or technical support for assistance.";
      return;
    }
    this.apiService.call(this.apiCall, this.data).subscribe((response: IApiResponseWrapper) => {
      // ResultCode 1101 is not found error and we don't want to reveal if an email address exists in the system or not
      if (response.Data.Success || response.Data.ResultCode === 1101) {
        this.responseMessage = "If the email address you provided is found you will receive an email that contains the " +
          "login name(s) associated with the email address you entered.  If you don't receive this email within an hour, " +
          "please check your junk or spam folder.";
      } else {
        this.errorMessage = "There was a failure while trying to recover your login.  Please contact your system " +
          "administrator or technical support for assistance.";
      }
    });
  }


}
