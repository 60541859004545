import { Injectable, ErrorHandler } from "@angular/core";
import { ApiService } from "../api/api.service";
import { Helper, Log } from "../helpers/helper";
declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";
import { TrackJS, TrackJSInstallOptions } from "trackjs";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  /*
   * Settings passed in from the user object when it is set.  This provides information
   * about user preferences for the type and depth of analytics to include.
   */
  settings: m5sec.UserSettingsApplicationSecurityPreferences = null;

  /*
   * Default settings to use when no user settings are available.
   */
  defaultSettings: m5sec.UserSettingsApplicationSecurityPreferences = null;



  constructor() {

    super();

    this.defaultSettings = new m5sec.UserSettingsApplicationSecurityPreferences();
    this.defaultSettings.ExternalErrorTracking = "Identifier";
    this.defaultSettings.ExternalAnalyticsForUsage = "Identifier";
    this.defaultSettings.ExternalAnalyticsForScreenCapture = "Identifier";
    this.settings = Helper.deepCopy(this.defaultSettings);

    /* Note if we ever need to inject services here we do it by injecting an Injector as noted here:
     * See https://www.concretepage.com/angular/angular-custom-error-handler
     *
      ErrorHandler is created before the providers. So we need Injector for dependency injection in our custom error handler class.
      Injector is imported from @angular/core library. Find the code snippet to use Injector.
      @Injectable()
      export class GlobalErrorHandlerService implements ErrorHandler {
          constructor(private injector: Injector) { }

          handleError(error: any) {
            let router = this.injector.get(Router);
            console.log('URL: ' + router.url);
            ------
          }
      }
      In the above code we are fetching Router using Injector.
    */

  }


  handleError(error) {

    if (!error) {
      return;
    }

    // Special error handling scenarios that log another error message
    if (error.message) {
      if (Helper.contains(error.message, "deleteItemPositionFromGrid", true) ||
        Helper.contains(error.message, "pullItemsToLeft", true) ||
        Helper.contains(error.message, "markItemPositionToGrid ", true) ||
        Helper.contains(error.message, "deleteItemPositionFromGrid", true) ||
        Helper.contains(error.message, "deleteItemPositionFromGrid", true)) {
        // TODO this does not work when builds are pulling packages from npm!
        // angular2gridster.js:1965 TypeError: Cannot read property 'deleteItemPositionFromGrid' of undefined
        // Use node_modules/angular2gridster/__ivy_ngcc__/fesm5/angular2gridster.js file for these changes.  Methods noted above need try...catch added to them
        // so they don't prevent execution of route changes away from dashboard.
        // Old non-ivy reference was node_modules/angular2gridster/fesm5/angular2gridster.js
        console.error("NOTE: If execution is halting then please ask R&D to check global error handler for fixes related to angular2gridster.js!")
      }
    }

    // If no error handlers configured or blocked by localhost check then fire our internal log function and exit
    if (!this.isAnyErrorHandlerConfigured() || this.isLocalHostAndLocalHostBlocked()) {
      Log.errorMessage(error);
      return;
    }

    // Now log to all configured targets

    if (this.shouldLogToConsole()) {
      console.error(error);
    }

    if (this.shouldLogToInternalLogger()) {
      Log.errorMessage(error);
    }

    if (this.shouldLogToTrackJS()) {
      // Add the error message to the telemetry timeline.
      // It can occasionally have useful additional context.
      console.warn(error.message);
      // Assumes we have already loaded and configured TrackJS*
      TrackJS.track(error.originalError || error);
    }

  }


  public installErrorHandler() {

    if (!this.isTrackJSConfigured()) {
      return;
    }

    if (this.isLocalHostAndLocalHostBlocked()) {
      Log.debugMessage("Running on localhost so TrackJS not installed.");
      return;
    }

    // Install TrackJS
    //console.warn("Installing TrackJS", AppConfig.errorReporting.trackJS);
    TrackJS.install(AppConfig.errorReporting.trackJS.settings);
    //TrackJS.track('Testing TrackJS!');

    // Add any metadata we have configured for TrackJS
    if (AppConfig.errorReporting.metaData) {
      for (const key in AppConfig.errorReporting.metaData) {
        try {
          let value: any = AppConfig.errorReporting.metaData[key];
          if (typeof value === "string") {
            if (Helper.contains(value, "{", true)) {
              value = Helper.stringFormat(value.toString(), AppConfig);
            }
          }
          TrackJS.addMetadata(key, value);
        } catch (error) {
          Log.errorMessage(error);
        }
      }
    }

  }



  public shouldLogToTrackJS(): boolean {
    if (!this.isTrackJSConfigured()) {
      return false;
    }
    if (this.isLocalHostAndLocalHostBlocked()) {
      return false;
    }
    if (Helper.equals(this.settings.ExternalErrorTracking, "None", true)) {
      return false;
    }
    return true;
  }

  public shouldLogToConsole(): boolean {
    if (!AppConfig.errorReporting) {
      return true;
    }
    if (!AppConfig.errorReporting.logToConsole) {
      return false;
    }
    return true;
  }

  public shouldLogToInternalLogger(): boolean {
    if (!AppConfig.errorReporting) {
      return true;
    }
    if (!AppConfig.errorReporting.logToInternalLogger) {
      return false;
    }
    return true;
  }

  public isLocalHostAndLocalHostBlocked(): boolean {
    if ((location.host.indexOf("localhost") > -1 || location.host.indexOf("localtest.me") > -1) && !AppConfig.errorReporting.includeLocalhost) {
      return true;
    }
    return false;
  }

  public isTrackJSConfigured(): boolean {
    if (!AppConfig.errorReporting) {
      return false;
    }
    if (!AppConfig.errorReporting.trackJS) {
      return false;
    }
    if (!AppConfig.errorReporting.trackJS.enabled) {
      return false;
    }
    if (!AppConfig.errorReporting.trackJS.settings) {
      return false;
    }
    return true;
  }

  public isAnyErrorHandlerConfigured(): boolean {
    if (!AppConfig.errorReporting) {
      return false;
    }
    if (!AppConfig.errorReporting.trackJS) { // Expand this if support for other handlers added
      return false;
    }
    if (!AppConfig.errorReporting.trackJS.settings) { // Expand this if support for other handlers added
      return false;
    }
    return true;
  }


  public setUser(user: m5sec.AuthenticatedUserViewModel): void {

    if (!user) {
      return;
    }

    // Save user preferences for analytics so we can decide what to include
    this.settings = user?.Settings?.ApplicationSecurityPreferences;
    if (!this.settings) {
      this.settings = Helper.deepCopy(this.defaultSettings);
    }

    const id = `${user.ContactId} [P${user.PartitionId}]`;
    const name = `${user.ContactName} (${user.ContactId}) [P${user.PartitionId}]`;

    if (this.shouldLogToTrackJS()) {
      if (Helper.equals(this.settings.ExternalErrorTracking, "Identifier", true)) {
        TrackJS.configure({ userId: id });
        Log.debugMessage(`TrackJS userId set to "${id}" (configured for identifier)`);
      } else if (Helper.equals(this.settings.ExternalErrorTracking, "Name", true)) {
        // Drop Name support for better PII protection
        // TrackJS.configure({ userId: name });
        // Log.debugMessage(`TrackJS userId set to "${name}" (configured for name)`);
        TrackJS.configure({ userId: id });
        Log.debugMessage(`TrackJS userId set to "${id}" (configured for identifier)`);
      }
    }

  }


  public setVersion(version: string): void {
    if (!version) {
      return;
    }
    if (this.shouldLogToTrackJS()) {
      TrackJS.configure({ version: version });
      Log.debugMessage(`TrackJS version set to "${version}"`);
    }
  }


}
