import * as Enumerable from "linq";
import { format, addMinutes } from 'date-fns';
import { Helper, Log } from "projects/core-lib/src/lib/helpers/helper";

declare var AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";

import * as m from "./ApiModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";

import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { HttpHeaders, HttpErrorResponse, HttpResponse } from "@angular/common/http";

export class ApiHelper {


  public static getApiModelDocDataTypeDescription(col: m.ApiDocDataModelColumn): string {
    let description: string = col.DataType;
    if (col.IsInteger) {
      description = "Integer";
    } else if (col.IsFloat) {
      description = "Float";
    } else if (col.IsDateTime) {
      description = "DateTime";
    } else if (col.IsBoolean) {
      description = "Boolean";
    } else if (col.IsChar || col.IsText || col.IsRowVersion) {
      description = "String";
    } else if (col.IsObject) {
      if (col.SubDataType) {
        description = ApiHelper.getApiModelDocSubDataTypeDescriptionWithRawName(col);
      } else {
        description = "Object";
      }
    } else if (col.IsCollection) {
      if (col.SubDataType) {
        description = "Collection of " + ApiHelper.getApiModelDocSubDataTypeDescriptionWithRawName(col);
      } else {
        description = "Collection";
      }
    }
    return description;
  }

  public static getApiModelDocSubDataTypeDescription(col: m.ApiDocDataModelColumn): string {
    if (!col.SubDataType) {
      return col.DataType;
    }
    if (Helper.equals(col.SubDataType, "object", true)) {
      return "dynamic object";
    } else if (Helper.equals(col.SubDataType, "string", true)) {
      return "string";
    } else if (Helper.contains(col.SubDataType, "int", true)) {
      return "integer";
    } else if (Helper.contains(col.SubDataType, "pairs", true)) {
      return col.SubDataType;
    } else {
      return col.SubDataType + " Object";
    }
  }

  public static getApiModelDocSubDataTypeDescriptionWithRawName(col: m.ApiDocDataModelColumn): string {
    if (!col.SubDataType) {
      return col.DataType;
    }
    let description = ApiHelper.getApiModelDocSubDataTypeDescription(col);
    if (col.RawName) {
      description += ` (${col.RawName})`;
    }
    return description;
  }


  /**
  This function gets model of sample api response.
  @param {any} data The model of the "Data" property in the api response.
  @returns {m5.IApiResponse} An API response object suitable for showing a sample response.
  */
  public static getApiResponseDataModelSample(data: any): m.IApiResponse {
    const model = new m.ApiResponse();
    model.Success = true;
    model.ResultCode = 0;
    model.ResultText = "Success";
    model.Scope = new m.ApiResponseScope();
    model.Data = data;
    model.Links = [];
    model.Errors = [];
    model.TimeStamp = new Date().toISOString();
    model.Trace = [];
    return model;
  }



  public static getApiDocumentationUrl(api: m.ApiProperties, endpoint: m.ApiEndpoint, version: number = null): string {

    let urlPart = m.ApiOperationType[endpoint.type].toLowerCase(); // String representation of the enum
    if (endpoint.id) {
      urlPart += `-${endpoint.id.toLowerCase()}`;
    }

    let url = api.documentation.documentationUrlBase;
    if (endpoint.documentation && endpoint.documentation.documentationUrlBase) {
      url = endpoint.documentation.documentationUrlBase;
    }

    if (version) {
      return `${url}${urlPart}-v${version}`;
    }

    return `${url}${urlPart}`;

  }



  /**
  Helper method for quick update of some endpoint documentation items.
  */
  public static updateApiEndpointDocumentation(api: m.ApiProperties, type: m.ApiOperationType, id: string = "", overviewText: string = ""): void {
    const endpoint = ApiHelper.getApiEndpoint(api, type, id);
    if (endpoint) {
      if (!endpoint.documentation) {
        endpoint.documentation = new m.ApiDocumentation();
      }
      if (overviewText) {
        endpoint.documentation.overviewText = overviewText;
      }
    }
  }



  /**
  This function gets the desired API endpoint object.
  @param {m.ApiProperties} api The API properties.
  @param {m.ApiOperationType} type The type of the desired API endpoint.
  @param {string} id The id of the desired API endpoint.  Optional unless the API defines more than one endpoint of the same type.
  @returns {m.ApiEndpoint} Returns the desired API endpoint object or null if not found.
  */
  public static getApiEndpoint(api: m.ApiProperties, type: m.ApiOperationType, id: string = "", logErrors: boolean = true): m.ApiEndpoint {

    const typeName = m.ApiOperationType[type];

    // Find our api endpoint
    const linq = Enumerable.from(api.endpoints);
    let typeCount = linq.count(x => x.type === type && (!id || x.id === id));
    if (typeCount > 1) {
      if (logErrors) {
        Log.errorMessage(`The ${api.documentation.objectName} api has ${typeCount} endpoints that match type ${typeName} and id ${id}.  Using the first.`);
      }
    }
    let endpoint = linq.firstOrDefault(x => x.type === type && (!id || x.id === id), null);
    if (!endpoint) {
      let errorMessage = `Unable to find ${api.documentation.objectName} api endpoint based on type ${typeName} and id ${id}.`;
      typeCount = linq.count(x => x.type === type);
      if (typeCount === 0) {
        errorMessage += `  There are no api endpoints of type ${typeName}.`;
        endpoint = null;
      } else {
        endpoint = linq.firstOrDefault(x => x.type === type, null);
        if (typeCount === 1) {
          errorMessage += `  There was one api endpoint of type ${typeName} so using that endpoint.`;
        } else {
          errorMessage += `  There were ${typeCount} api endpoints of type ${typeName} so unable to proceed.`;
          endpoint = null;
        }
      }
      if (logErrors) {
        Log.errorMessage(errorMessage);
      }
    }

    return endpoint;

  }



  /**
  This function creates an API call object.
  @param {m.ApiProperties} api The API properties.
  @param {m.ApiOperationType} type The type of the desired API endpoint.
  @param {string} id The id of the desired API endpoint.  Optional unless the API defines more than one endpoint of the same type.
  @returns {m.ApiCall} Returns an ApiCall object that can be used for calling the desired API type and id.
  */
  public static createApiCall(api: m.ApiProperties, type: m.ApiOperationType, id: string = "", oldApiCall: m.ApiCall = null): m.ApiCall {

    // Find our api endpoint
    let endpoint = ApiHelper.getApiEndpoint(api, type, id);
    if (!endpoint) {
      endpoint = new m.ApiEndpoint("", type);
    }

    const model: m.ApiCall = new m.ApiCall();

    // API information
    model.baseUrl = AppConfig.apiUrl;
    model.fragmentUrl = endpoint.path || "";
    model.url = model.baseUrl + model.fragmentUrl;
    model.type = endpoint.type;
    model.typeName = m.ApiOperationType[endpoint.type]; // type as string
    model.endpointId = id;
    model.method = endpoint.method;
    model.methodName = m.ApiMethodType[endpoint.method].toUpperCase(); // method as string
    model.version = api.version || AppConfig.apiVersion;

    // Headers
    model.token = this.getAuthToken();
    if (!model.token) {
      model.apiKey = this.getAuthKey();
    }
    // If we do not have a token or api key attempt to use the anonymousApiKey value from our config
    // this is a locked down api key so it will only work for very basic get site settings type operations.
    if (!model.token && !model.apiKey) {
      //console.error("using anonymous api key");
      model.apiKey = AppConfig.anonymousApiKey;
    }
    model.partnerToken = this.getPartnerExternalAuthenticationToken();
    model.meta = [];
    if (AppConfig.debug) {
      // TMI even for debug mode?  model.meta = ["Options", "Attributes"];
    }
    model.trace = AppConfig.debug;
    model.httpResponseOverride = false;
    model.httpMethodOverride = false;

    // If we had an old object we're replacing keep some properties from that
    if (oldApiCall) {
      if (!model.token && oldApiCall.token) {
        model.token = oldApiCall.token;
      }
      if (!model.token && !model.apiKey && oldApiCall.apiKey) {
        model.apiKey = oldApiCall.apiKey;
      }
      if (oldApiCall.meta && oldApiCall.meta.length > 0) {
        model.meta = oldApiCall.meta;
      }
      if (!model.trace && oldApiCall.trace) {
        model.trace = true;
      }
      if (!model.httpResponseOverride && oldApiCall.httpResponseOverride) {
        model.httpResponseOverride = true;
      }
      if (!model.httpMethodOverride && oldApiCall.httpMethodOverride) {
        model.httpMethodOverride = true;
      }
      if (!model.encryptionKeyTag && oldApiCall.encryptionKeyTag) {
        model.encryptionKeyTag = oldApiCall.encryptionKeyTag;
      }
      if (!model.responseProperties && oldApiCall.responseProperties) {
        model.responseProperties = oldApiCall.responseProperties;
      }
      if (!model.encryptedProperties && oldApiCall.encryptedProperties) {
        model.encryptedProperties = oldApiCall.encryptedProperties;
      }
    }

    //// Local ip address was more trouble than it was worth
    //if (Helper.localIpAddressList && Helper.localIpAddressList.length > 0) {
    //  model.localIpAddress = Helper.localIpAddressList[0];
    //} else {
    //  Helper.buildLocalIpAddressList();
    //  if (Helper.localIpAddressList && Helper.localIpAddressList.length > 0) {
    //    model.localIpAddress = Helper.localIpAddressList[0];
    //  }
    //}
    // Report local device id (creating a new device id if none is found)
    let deviceId = window.localStorage[Constants.LocalStorage.DeviceId];
    if (!deviceId) {
      deviceId = Helper.createBase36Guid();
      window.localStorage[Constants.LocalStorage.DeviceId] = deviceId;
    }
    model.localDeviceId = deviceId;

    // Path variables and model properties are used for url variable replacement.
    model.pathVariables = ApiHelper.getApiPathVariables(api, endpoint);
    model.pathModelProperties = ApiHelper.getApiPathProperties(api, endpoint);

    // Meta data used for caching, logging, etc.
    // If our endpoint has docs and values defined here use those otherwise use our api common docs
    if (endpoint.documentation && endpoint.documentation.objectName) {
      model.objectName = endpoint.documentation.objectName;
    } else if (api.documentation.objectName) {
      model.objectName = api.documentation.objectName;
    }
    if (endpoint.documentation && endpoint.documentation.objectPrimaryKey) {
      model.objectPrimaryKey = endpoint.documentation.objectPrimaryKey;
    } else if (api.documentation.objectPrimaryKey) {
      model.objectPrimaryKey = api.documentation.objectPrimaryKey;
    }
    if (endpoint.documentation && endpoint.documentation.objectDescription) {
      model.objectShortDescription = endpoint.documentation.objectDescription;
    } else if (api.documentation.objectDescription) {
      model.objectShortDescription = api.documentation.objectDescription;
    }
    model.cacheName = api.cacheName;
    model.cacheLevel = api.cacheLevel;
    model.impactedPickListIds = api.impactedPickListIds;

    return model;

  }


  public static refreshApiCall(apiCall: m.ApiCall): m.ApiCall {
    if (!apiCall) {
      Log.errorMessage("ApiHelper.refreshApiCall received null apiCall object");
      return apiCall;
    }
    // Update the token in case it was refreshed since the api call object was created
    apiCall.token = this.getAuthToken();
    if (!apiCall.token && !apiCall.apiKey) {
      apiCall.apiKey = this.getAuthKey();
    }
    // In api docs our api host url can also change if docs include support for multiple hosts
    apiCall.baseUrl = AppConfig.apiUrl;
    apiCall.url = apiCall.baseUrl + apiCall.fragmentUrl;
    return apiCall;
  }


  public static getOperationTypeFromString(type: string): m.ApiOperationType {
    if (Helper.equals(type, "list", true)) {
      return m.ApiOperationType.List;
    } else if (Helper.equals(type, "get", true)) {
      return m.ApiOperationType.Get;
    } else if (Helper.equals(type, "add", true)) {
      return m.ApiOperationType.Add;
    } else if (Helper.equals(type, "edit", true)) {
      return m.ApiOperationType.Edit;
    } else if (Helper.equals(type, "delete", true)) {
      return m.ApiOperationType.Delete;
    } else if (Helper.equals(type, "patch", true)) {
      return m.ApiOperationType.Patch;
    } else if (Helper.equals(type, "merge", true)) {
      return m.ApiOperationType.Merge;
    } else if (Helper.equals(type, "copy", true)) {
      return m.ApiOperationType.Copy;
    } else if (Helper.equals(type, "report", true)) {
      return m.ApiOperationType.Report;
    } else if (Helper.equals(type, "call", true)) {
      return m.ApiOperationType.Call;
    } else {
      return m.ApiOperationType.Get;
    }
  }


  /**
  This function gets auth token to use (if any).
  @returns {string} Returns auth token if one is known.
  */
  public static getAuthToken(): string {
    let token: string = window.localStorage[Constants.LocalStorage.AuthenticationToken + "-" + AppConfig.apiUrl];
    if (!token) {
      token = window.sessionStorage[Constants.SessionStorage.AuthenticationToken + "-" + AppConfig.apiUrl];
    }
    return token;
  }


  /**
  This function removes auth tokens from storage.
  */
  public static removeAuthToken(): void {
    localStorage.removeItem(Constants.LocalStorage.AuthenticationToken + "-" + AppConfig.apiUrl);
    sessionStorage.removeItem(Constants.SessionStorage.AuthenticationToken + "-" + AppConfig.apiUrl);
  }


  /**
  This function gets auth token to use (if any).
  @returns {string} Returns auth token if one is known.
  */
  public static getPartnerExternalAuthenticationToken(): string {
    let token: string = window.localStorage[Constants.LocalStorage.PartnerExternalAuthenticationToken] || "";
    if (!token) {
      token = Helper.getQueryStringParameter("peat");
      if (token) {
        window.localStorage[Constants.LocalStorage.PartnerExternalAuthenticationToken] = token;
      }
    }
    return token;
  }


  /**
  This function gets auth key to use (if any).
  @returns {string} Returns auth key if one is known.
  */
  public static getAuthKey(): string {
    let apiKey = window.localStorage[Constants.LocalStorage.ApiKey + "-" + AppConfig.apiUrl];
    if (!apiKey) {
      apiKey = window.sessionStorage[Constants.SessionStorage.ApiKey + "-" + AppConfig.apiUrl];
    }
    return apiKey;
  }

  /**
  This function takes api properties and api endpoint and returns an array of path variables that are applicable.
  @param {m.ApiProperties} api The API properties.
  @param {m.ApiEndpoint} endpoint The API endpoint.
  @returns {string[]} Returns a string array of path variables that apply to this api and endpoint.
  */
  public static getApiPathVariables(api: m.ApiProperties, endpoint: m.ApiEndpoint): string[] {

    let results: string[] = [];

    // Path variables and model properties are used for url variable replacement.
    // The api itself may have string or string array that we map to our array
    if (api.pathVariablesIsArray) {
      results = Helper.deepCopy(<string[]>api.pathVariables);
    } else if (api.pathVariables) {
      results = [<string>api.pathVariables];
    }

    // Certain endpoints might have their own path variables that we want
    if (endpoint.pathVariables && endpoint.pathVariables.length > 0) {
      results = Helper.deepCopy(endpoint.pathVariables);
    }

    return results;

  }


  /**
  This function takes api properties and api endpoint and returns an array of path properties that are applicable.
  @param {m.ApiProperties} api The API properties.
  @param {m.ApiEndpoint} endpoint The API endpoint.
  @param {boolean} onlyIncludePropertiesMappedInUrl When true only properties that map to variables that appear in the endpoint url are included.
  @returns {string[]} Returns a string array of path properties that apply to this api and endpoint.
  */
  public static getApiPathProperties(api: m.ApiProperties, endpoint: m.ApiEndpoint, onlyIncludePropertiesMappedInUrl: boolean = false): string[] {

    let results: string[] = [];

    // Path variables and model properties are used for url variable replacement.
    // The api itself may have string or string array that we map to our array
    if (api.pathModelPropertiesIsArray) {
      results = Helper.deepCopy(<string[]>api.pathModelProperties);
    } else if (api.pathModelProperties) {
      results = [<string>api.pathModelProperties];
    }

    // Certain endpoints might have their own path variables that we want
    if (endpoint.pathModelProperties && endpoint.pathModelProperties.length > 0) {
      results = Helper.deepCopy(endpoint.pathModelProperties);
    }

    // See if we need to trim down our list since we use this for anon object models in test forms
    // and not every endpoint utilizes every property we don't want to ask for test form input that
    // doesn't make sense for the current context.
    if (onlyIncludePropertiesMappedInUrl) {
      let indexes: number[] = [];
      const variables: string[] = ApiHelper.getApiPathVariables(api, endpoint);
      variables.forEach((value: string, index: number, array: string[]) => {
        if (!Helper.contains(endpoint.path, value, true)) {
          indexes.push(index);
        }
      });
      if (indexes.length > 0) {
        // We need to drop the specified index properties from our array.
        // Sort the indexes descending so we drop from end to beginning since any other order would change the index pointers
        indexes = Helper.arraySortNumbers(indexes, true);
        indexes.forEach((index: number) => {
          results.splice(index, 1);
        });
      }
    }

    return results;

  }


  /**
  This function takes api properties, api endpoint, and optional data model and returns request data model object that is applicable.
  @param {m.ApiProperties} api The API properties.
  @param {m.ApiEndpoint} endpoint The API endpoint.
  @param {m.ApiDocDataModel} dataModel The data model documentation object for the specified endpoint or api.
  @returns {any} Returns an object that represents our request data model for this api endpoint.
  */
  public static getApiRequestDataModelObject(api: m.ApiProperties, endpoint: m.ApiEndpoint, dataModel?: m.ApiDocDataModel): any {

    // Maybe we don't want a model object
    if (endpoint.documentation && endpoint.documentation.requestDataModelIsNull) {
      return null;
    }
    if (api.documentation.requestDataModelIsNull) {
      return null;
    }

    // If our endpoint has a request data model object run with it as our first option
    if (endpoint.documentation && endpoint.documentation.requestDataModelObject) {
      return endpoint.documentation.requestDataModelObject;
    }

    // We have test form properties which will build our request data model
    if (endpoint.documentation && endpoint.documentation.testFormProperties && endpoint.documentation.testFormProperties.length > 0) {
      const dynamic = {};
      endpoint.documentation.testFormProperties.forEach((property: m.ApiDocTestFormProperty, index: number, array: m.ApiDocTestFormProperty[]) => {
        if (property.defaultValue) {
          dynamic[property.name] = property.defaultValue;
        } else if (property.type === m.ApiDocTestFormPropertyType.Bool) {
          dynamic[property.name] = false;
        } else if (property.type === m.ApiDocTestFormPropertyType.Number) {
          dynamic[property.name] = null;
        } else {
          dynamic[property.name] = "";
        }
      });
      return dynamic;
    }

    // We want our array of path model properties to build our request data model
    if (endpoint.documentation && endpoint.documentation.testFormUseAnonymousObjectForPathModelProperties) {
      const dynamic = {};
      const properties = ApiHelper.getApiPathProperties(api, endpoint);
      properties.forEach((property: string, index: number, array: string[]) => {
        dynamic[property] = null;
      });
      return dynamic;
    }

    // List operation types use query object for request data model
    if (endpoint.type === m.ApiOperationType.List) {
      return new m.Query();
    }

    // If we asked to use our data model to build this object then do that
    if (endpoint.documentation && endpoint.documentation.requestDataModelObjectFromDataModelDocumentation && dataModel) {
      const dynamic = {};
      dataModel.Columns.forEach((property: m.ApiDocDataModelColumn, index: number, array: m.ApiDocDataModelColumn[]) => {
        if (property.IsBoolean) {
          dynamic[property.Name] = false;
        } else if (property.IsChar) {
          dynamic[property.Name] = "";
        } else {
          dynamic[property.Name] = null;
        }
      });
      return dynamic;
    }

    // If we're doing get or delete operations we only need our pk which is in our path model properties
    if (endpoint.type === m.ApiOperationType.Get || endpoint.type === m.ApiOperationType.Delete) {
      const dynamic = {};
      const properties = ApiHelper.getApiPathProperties(api, endpoint);
      properties.forEach((property: string, index: number, array: string[]) => {
        dynamic[property] = null;
      });
      return dynamic;
    }

    // Use a request data model object provided by our api itself
    return api.documentation.requestDataModelObject;

  }


  /**
  This function takes api properties, api endpoint, and optional data model and returns response data model object that is applicable.
  @param {m.ApiProperties} api The API properties.
  @param {m.ApiEndpoint} endpoint The API endpoint.
  @param {m.ApiDocDataModel} dataModel The data model documentation object for the specified endpoint or api.
  @returns {any} Returns an object that represents our response data model for this api endpoint.
  */
  public static getApiResponseDataModelObject(api: m.ApiProperties, endpoint: m.ApiEndpoint, dataModel?: m.ApiDocDataModel): any {

    // Maybe we don't want a model object
    if (endpoint.documentation && endpoint.documentation.responseDataModelIsNull) {
      return null;
    }
    if (api.documentation.responseDataModelIsNull) {
      return null;
    }

    // If we asked to use our data model to build this object then do that
    if (endpoint.documentation && endpoint.documentation.responseDataModelObjectFromDataModelDocumentation && dataModel) {
      const dynamic = {};
      dataModel.Columns.forEach((property: m.ApiDocDataModelColumn, index: number, array: m.ApiDocDataModelColumn[]) => {
        if (property.IsBoolean) {
          dynamic[property.Name] = false;
        } else if (property.IsChar) {
          dynamic[property.Name] = "";
        } else {
          dynamic[property.Name] = null;
        }
      });
      return dynamic;
    }

    // If our endpoint has a response data model object run with it
    if (endpoint.documentation && endpoint.documentation.responseDataModelObject) {
      return endpoint.documentation.responseDataModelObject;
    }

    // Use a response data model object provided by our api itself
    return api.documentation.responseDataModelObject;

  }


  /**
  Returns ApiDocTestFormPropertyType enum value based on the type of the property parameter.
  */
  public static getApiDocTestFormPropertyType(property: any): m.ApiDocTestFormPropertyType {
    let type: m.ApiDocTestFormPropertyType = m.ApiDocTestFormPropertyType.Text;
    if (typeof property === "number") {
      type = m.ApiDocTestFormPropertyType.Number;
    } else if (typeof property === "boolean") {
      type = m.ApiDocTestFormPropertyType.Bool;
    }
    return type;
  }


  /**
  Builds an array of ApiDocTestFormProperty objects based on the model parameter.
  */
  public static getApiDocTestFormPropertiesFromModel(model: any): m.ApiDocTestFormProperty[] {
    const properties: m.ApiDocTestFormProperty[] = [];
    for (const property in model) {
      properties.push(new m.ApiDocTestFormProperty(property, ApiHelper.getApiDocTestFormPropertyType(model[property]), model[property]));
    }
    return properties;
  }



  /**
  When user selects different api endpoint we need to update the api model with our new api url.
  @param {m.ApiCall} model The API call model object.
  @param {string} apiUrl The base URL of the new API endpoint.
  */
  public static updateApiCall(
    model: m.ApiCall,
    apiUrl: string) {

    // Set the URLs
    model.baseUrl = apiUrl;
    model.url = model.baseUrl + model.fragmentUrl;

    // Set the token and api-key which are saved based on api url
    let token = window.localStorage[Constants.LocalStorage.AuthenticationToken + "-" + AppConfig.apiUrl];
    if (!token) {
      token = window.sessionStorage[Constants.SessionStorage.AuthenticationToken + "-" + AppConfig.apiUrl];
    }
    model.token = token;
    let apiKey = window.localStorage[Constants.LocalStorage.ApiKey + "-" + AppConfig.apiUrl];
    if (!apiKey) {
      apiKey = window.sessionStorage[Constants.SessionStorage.ApiKey + "-" + AppConfig.apiUrl];
    }
    model.apiKey = apiKey;

  }


  /**
  A static object of HTTP code and default message.  Some API responses do not include
  a message and we can use this to translate the HTTP code to a default message.
  */
  public static httpStatusMessages: any = {
    'CODE_200': 'OK',
    'CODE_201': 'Created',
    'CODE_202': 'Accepted',
    'CODE_203': 'Non-Authoritative Information',
    'CODE_204': 'No Content',
    'CODE_205': 'Reset Content',
    'CODE_206': 'Partial Content',
    'CODE_300': 'Multiple Choices',
    'CODE_301': 'Moved Permanently',
    'CODE_302': 'Found',
    'CODE_303': 'See Other',
    'CODE_304': 'Not Modified',
    'CODE_305': 'Use Proxy',
    'CODE_307': 'Temporary Redirect',
    'CODE_400': 'Bad Request',
    'CODE_401': 'Unauthorized',
    'CODE_402': 'Payment Required',
    'CODE_403': 'Forbidden',
    'CODE_404': 'Not Found',
    'CODE_405': 'Method Not Allowed',
    'CODE_406': 'Not Acceptable',
    'CODE_407': 'Proxy Authentication Required',
    'CODE_408': 'Request Timeout',
    'CODE_409': 'Conflict',
    'CODE_410': 'Gone',
    'CODE_411': 'Length Required',
    'CODE_412': 'Precondition Failed',
    'CODE_413': 'Request Entity Too Large',
    'CODE_414': 'Request-URI Too Long',
    'CODE_415': 'Unsupported Media Type',
    'CODE_416': 'Requested Range Not Satisfiable',
    'CODE_417': 'Expectation Failed',
    'CODE_500': 'Internal Server Error',
    'CODE_501': 'Not Implemented',
    'CODE_502': 'Bad Gateway',
    'CODE_503': 'Service Unavailable',
    'CODE_504': 'Gateway Timeout',
    'CODE_505': 'HTTP Version Not Supported'
  };


  /**
  Gets the default http status message for an http status code.
  @param {number} httpCode The HTTP status code.
  @returns {string} The default HTTP status message.
  */
  public static getHttpStatusMessage(httpCode: number): string {
    var lookupCode = 'CODE_'.concat(httpCode.toString());
    var description = "";
    if (ApiHelper.httpStatusMessages[lookupCode]) {
      description = ApiHelper.httpStatusMessages[lookupCode];
    }
    return description;
  }



  /**
  Builds the absolute API URL by doing property substitution using the specified data and API call object.
  @param {m.ApiCall} apiCall The API call object.
  @param {any} data The data to use as a data source for variable portions of the API URL.
  @returns {string} The absolute URL for the specified API call and data.
  */
  public static buildApiAbsoluteUrl(apiCall: m.ApiCall, data: any, url: string = null): string {

    // This debug line is VERY verbose making it hard to parse data in the console
    //Log.LogDebug("url", "URL", "Ready to determine absolute url for api call " + JSON.stringify(apiCall) + " with data " + JSON.stringify(data));

    // If we were not given a url then use the url from our api call.  In some scenarios the url building
    // may happen in more than one pass so we can plug in variables that are from a url properties only
    // object followed by variables that are from a data object also used for non-GET payloads.
    if (!url) {
      url = apiCall.url;
    }

    // If we don't have data passed in then we're done
    if (!data) {
      Log.debug("url", "URL", "No data provided for url building so using url " + url, Helper.contains(url, "{"));
      return url;
    }

    if (!apiCall.pathVariables) {
      apiCall.pathVariables = [];
    }

    // If our data is an object then get the properties from the object that map to variables in our url
    if (Helper.isObject(data)) {
      // We have an array of path variables to swap out
      apiCall.pathVariables.forEach((variable: string, index: number, variables: string[]) => {
        url = url.replace(variable, Helper.encodeURIPart(data[apiCall.pathModelProperties[index]]));
      });
      // Replace any object keys that are also url place markers.
      const keys = Object.keys(data);
      keys.forEach((key: string, index: number, keys: string[]) => {
        url = url.replace(`{${key}}`, Helper.encodeURIPart(data[key]));
      });
    }
    else if (Helper.isArray(data)) {
      // We have an array of path variables to swap out
      apiCall.pathVariables.forEach((variable: string, index: number, variables: string[]) => {
        url = url.replace(variable, Helper.encodeURIPart(data[index]));
      });
    }
    else {
      if (apiCall.pathVariables.length > 1) {
        Log.errorMessage(`Error: api url builder found api call defines an array of path variables (${Helper.buildCsvString(apiCall.pathVariables)}) but only a single data value (${data}) was provided.`);
      }
      // We have an array of path variables to swap out but only a simple data to swap with so this will probably be a bad url
      // we assume the first path variable holds the value we were given and the rest will be passed undefined value.
      apiCall.pathVariables.forEach((variable: string, index: number, variables: string[]) => {
        if (index === 0) {
          url = url.replace(variable, Helper.encodeURIPart(data));
        } else {
          url = url.replace(variable, "");
        }
      });
    }

    // Now some special use case scenarios
    // TestFreeFormQueryStringParameters that we use in our api docs sometimes
    if (data.TestFreeFormQueryStringParameters) {
      url = ApiHelper.addQueryStringToUrl(url, data.TestFreeFormQueryStringParameters);
    }
    // Common collection paging parameters
    if (Helper.contains(url, '{page}', true) || Helper.contains(url, '{size}', true) || Helper.contains(url, '{sort}', true) ||
      Helper.contains(url, '{filterId}', true) || Helper.contains(url, '{filter}', true) ||
      Helper.contains(url, '{q}', true) || Helper.contains(url, '{expand}', true) || Helper.contains(url, '{id}', true)) {
      url = ApiHelper.replaceUrlListParameters(url, data.Page, data.Size, data.Sort, data.FilterId, data.Filter, data.Q, data.Expand, data.Id);
    }

    //Log.LogDebug("url", "URL", "Absolute Url: " + url + " based on api call " + JSON.stringify(apiCall) + " and data " + JSON.stringify(data));
    //Log.debug("url", "URL", "API url to use: " + url);

    return url;

  }


  /**
  Replaces common list parameters in url.
  @param {string} rawUrl The raw URL of API call.
  @param {number} page The page number.
  @param {number} size The page size.
  @param {string} sort The sort order.
  @param {number} filterId The filter id.
  @param {string} filter The filter.
  @param {string} q The free form filter.
  @param {string} expand Any expand properties.
  @param {number} id Any optional id filter.
  @returns {string} The absolute URL for the specified list/collection.
  */
  public static replaceUrlListParameters(
    rawUrl: string,
    page: number,
    size: number,
    sort: string,
    filterId: number,
    filter: string,
    q: string,
    expand: string,
    id?: number): string {

    let url = rawUrl;

    // Default page is none (i.e. api server default for this scenario)
    if (page) {
      if (page < 1) {
        page = 1;
      }
      url = url.replace('{page}', page.toString());
    } else {
      // Do NOT take out ? query string marker ... ?page={page} we need the
      // ? left in place for now in case we have other query string parameters
      // and we will then clean it up at the end of this method.
      url = url.replace('page={page}', '');
    }

    // Default size is none (i.e. api server default for this scenario)
    if (size) {
      if (size < 1) {
        size = 1;
      }
      url = url.replace('{size}', size.toString());
    } else {
      url = url.replace('&size={size}', '');
    }

    // Default sort is none
    if (sort) {
      url = url.replace('{sort}', encodeURIComponent(sort));
    } else {
      url = url.replace('&sort={sort}', '');
      // For export we don't have paging and sort is our first query string parameter so "&sort" never exists.
      // Do NOT take out ? query string marker ... ?sort={sort} we need the
      // ? left in place for now in case we have other query string parameters
      // and we will then clean it up at the end of this method.
      url = url.replace('sort={sort}', '');
    }
    // Default filter id is none
    if (filterId) {
      url = url.replace('{filterId}', filterId.toString());
    } else {
      url = url.replace('&filterId={filterId}', '');
    }
    // Default filter is none
    if (filter) {
      url = url.replace('{filter}', encodeURIComponent(filter));
    } else {
      url = url.replace('&filter={filter}', '');
    }
    // Default free form query text is none
    if (q) {
      url = url.replace('{q}', encodeURIComponent(q));
    } else {
      url = url.replace('&q={q}', '');
    }
    // Default expand string is none
    if (expand) {
      url = url.replace('{expand}', encodeURIComponent(expand));
    } else {
      url = url.replace('&expand={expand}', '');
    }
    // Default id value is none
    if (id) {
      url = url.replace('{id}', id.toString());
    } else {
      url = url.replace('&id={id}', '');
    }

    // Now see if have ?& in the url which means we removed our first query string parameter page
    // but left ? in case there were other query strings left in the url and if we see ?& then
    // there were and we should convert ?& to ?
    if (Helper.contains(url, "?&")) {
      url = url.replace("?&", "?");
    }

    // Now if we end with ? then we had no query string parameters
    if (Helper.endsWith(url, "?")) {
      url = url.replace("?", "");
    }

    // If we end with & trim that off w/o replace since there may be other & that are valid
    if (Helper.endsWith(url, "&")) {
      url = Helper.left(url, url.length - 1);
    }

    return url;

  }


  /**
  Adds query string to url with auto ?/& handling.
  */
  public static addQueryStringToUrl(url: string, queryString: string): string {

    if (!url || !queryString) {
      return url;
    }

    // Start by removing any ? and & prefix to the query string
    if (queryString.startsWith("?") || queryString.startsWith("&")) {
      queryString = queryString.substr(1);
    }

    if (!queryString) {
      // Guess our whole query string was ? or &
      return url;
    }

    // Now based on the url we were given decide what query string delimiter is appropriate to use: ? or &
    if (Helper.contains(url, "?")) {
      url += "&" + queryString;
    } else {
      url += "?" + queryString;
    }

    return url;

  }

  /**
  Builds a HTTP header object for the specified API call object.
  @param {m.ApiCall} api The API call object.
  @param {any} headerOptions Options to use when building the headers object.  Possible values include a boolean value of NoAuthHeaders which should
  be true if the resulting HTTP headers object should not include authorization and api key headers.
  */
  public static getApiHttpHeaders(api: m.ApiCall, headerOptions?: any): any {
    let headers: any = {};
    if (!headerOptions) {
      headerOptions = { NoAuthHeaders: false, NoEncryptionHeaders: false, NoContentTypeHeaders: false, NoApiVersionHeaders: false, NoLocalDeviceHeaders: false };
    } else {
      headerOptions.NoAuthHeaders = headerOptions.NoAuthHeaders || false;
      headerOptions.NoEncryptionHeaders = headerOptions.NoEncryptionHeaders || false;
      headerOptions.NoContentTypeHeaders = headerOptions.NoContentTypeHeaders || false;
      headerOptions.NoApiVersionHeaders = headerOptions.NoApiVersionHeaders || false;
      headerOptions.NoLocalDeviceHeaders = headerOptions.NoLocalDeviceHeaders || false;
    }
    if (api) {
      if (api.token && !headerOptions.NoAuthHeaders) {
        headers.Authorization = 'Bearer ' + api.token;
      }
      if (api.partnerToken && !headerOptions.NoAuthHeaders) {
        headers["X-Auth-External-Token"] = api.partnerToken;
      }
      if (api.apiKey && !headerOptions.NoAuthHeaders) {
        headers["X-Auth-Key"] = api.apiKey;
      }
      if (api.version && !headerOptions.NoApiVersionHeaders) {
        if (!headerOptions.NoContentTypeHeaders) {
          headers.Accept = "application/vnd.ib.api-v" + api.version + "+json";
          headers["Content-Type"] = "application/vnd.ib.api-v" + api.version + "+json";
        }
        headers["X-Api-Version"] = api.version;
      }
      if (api.language) {
        headers["X-Language"] = api.language;
      }
      if (api.meta && api.meta.length > 0) {
        headers["X-Meta-Data"] = Helper.buildCsvString(api.meta);
      }
      if (api.trace) {
        headers["X-Trace"] = "true";
      }
      if (api.overwriteChanges) {
        headers["X-Overwrite-Changes"] = "true";
      }
      if (api.httpResponseOverride) {
        headers["X-HTTP-Response-Override"] = "true";
      }
      if (api.httpMethodOverride) { // TODO should we care what method we're trying to override???? we now have merge & patch && (api.methodName.equalsCaseInsensitive("put") || api.methodName.equalsCaseInsensitive("delete"))) {
        headers["X-HTTP-Method-Override"] = api.methodName;
      }
      if (api.responseProperties) {
        headers["X-Response-Properties"] = api.responseProperties;
      }
      if (!headerOptions.NoEncryptionHeaders) {
        if (api.encryptionKeyTag && api.encryptionKeyTag !== "") {
          headers["X-Encryption-Key-Tag"] = api.encryptionKeyTag;
        }
        if (api.encryptedProperties && api.encryptedProperties !== "") {
          headers["X-Encrypted-Properties"] = api.encryptedProperties;
        }
      }
      if (!headerOptions.NoLocalDeviceHeaders) {
        //if (api.localIpAddress && api.localIpAddress !== "") {
        //  headers["X-Local-Ip-Address"] = api.localIpAddress;
        //}
        if (api.localDeviceId && api.localDeviceId !== "") {
          headers["X-Local-Device-Id"] = api.localDeviceId;
        }
      }
    }
    return headers;
  }


  /**
  Builds a HTTP objects object for the specified API call object.
  @param {m.ApiCall} api The API call object.
  @param {any} headerOptions Options to use when building the headers object.  Possible values include a boolean value of NoAuthHeaders which should
  be true if the resulting HTTP headers object should not include authorization and api key headers.
  */
  public static getApiHttpOptions(api: m.ApiCall, headerOptions?: any): any {
    const headers = ApiHelper.getApiHttpHeaders(api, headerOptions);
    const options: any = { headers: headers };
    if (api.silent) {
      // If our api call is in silent mode then ignore our loading bar ui candy and our busy button text, disable state, icon, etc. just do the
      // http silently in the background with no ui indication that we're doing some work.  This is used for some background processes, posting
      // logs, unsaved objects, etc.
      options.ignoreLoadingBar = true;
      options.notBusy = true;
    }
    return options;
  }



  public static isApiResponse(response: any): boolean {
    if (Helper.isString(response)) {
      try {
        const apiResponse: m.IApiResponse = JSON.parse(response);
        if (apiResponse.ResultCode || apiResponse.ResultText || apiResponse.TimeStamp) {
          return true;
        } else {
          return false;
        }
      } catch {
        return false;
      }
    } else if (Helper.isObject(response)) {
      try {
        const apiResponse: m.IApiResponse = response as m.IApiResponse;
        if (apiResponse.ResultCode || apiResponse.ResultText || apiResponse.TimeStamp) {
          return true;
        } else {
          return false;
        }
      } catch {
        return false;
      }
    }
  }



  public static formatResponseFromHttpResponse(data: HttpResponse<m.IApiResponse>, requestData: m.IApiRequestInformation): m.ApiResponseWrapper {
    return ApiHelper.formatResponseFromParts(data.body, data.status, data.statusText, ApiHelper.convertHttpHeadersToDictionary(data.headers), requestData);
  }


  public static formatResponseFromHttpError(data: HttpErrorResponse, requestData: m.IApiRequestInformation): m.ApiResponseWrapper {
    return ApiHelper.formatResponseFromParts(data.error, data.status, data.statusText, ApiHelper.convertHttpHeadersToDictionary(data.headers), requestData);
  }


  /**
  Takes results of an API call and formats a common response object to help API results always be in a predictable format.
  @param {m5.IApiResponse} data The API response object.
  @param {number} status The HTTP status code.
  @param {string} statusText Any provided HTTP status text.
  @param {any} headers The HTTP headers for the API response.
  @param {any} requestData The configuration object for the request that resulted in this API response.
  @returns {m5.Response} The response object which contains needed information about the API response.
  */
  public static formatResponseFromParts(
    data: m.IApiResponse,
    status: number,
    statusText: string,
    headers: any,
    requestData: m.IApiRequestInformation): m.ApiResponseWrapper {
    if (!statusText || statusText === "") {
      statusText = ApiHelper.getHttpStatusMessage(status);
    }
    if (status <= 0 && !data) {
      // Some server errors come back without CORS headers and, therefore, don't
      // get pushed back via XMLHttpRequest which is a pain the rear which we need
      // to address server side but until that happens try to give a more meaningful
      // response client side.
      const result = "Unknown Server Error With Missing CORS Headers";
      const message = result + ".  Common errors causing this situation include 404 and 500 errors.  " +
        "Check the API URL to make sure it's a valid URL and that any {parameters} have been replaced with non-null values (i.e. not 404 error).  " +
        "Check the browser console log for XMLHttpRequest error messages and if it shows a 500 error please contact support.  " +
        "If there is a network request for method OPTIONS but no subsequent request to the same API URL there is probably an error on the server " +
        "and your browser has blocked the follow on request so please contact support.";
      status = 400;
      statusText = result;
      data = new m.ApiResponse();
      data.Success = false;
      data.ResultCode = 1;
      data.ResultText = result;
      data.Message = message;
      data.Errors.push({ ResultCode: -1, Type: "Server", Subtype: "CORS", Reference: "", Message: message, Details: "", Warning: false });
    }
    const response = new m.ApiResponseWrapper();
    response.Data = data;
    response.Status = status;
    response.StatusText = statusText;
    response.Headers = headers;
    response.Request = new m.ApiRequestInformation();
    response.Request.Url = requestData.Url;
    response.Request.Method = requestData.Method;
    response.Request.Headers = requestData.Headers;
    response.Request.Data = requestData.Data;
    // See if we got a new token back and if so then refresh what we have stored
    const newToken: string = response.Headers.authorization;
    let currentToken: string = "";
    //console.error("new token", newToken, response.Headers);
    if (newToken) {
      if (window.localStorage[Constants.LocalStorage.AuthenticationToken + "-" + AppConfig.apiUrl]) {
        // If current token is in local storage then we update local storage
        currentToken = window.localStorage[Constants.LocalStorage.AuthenticationToken + "-" + AppConfig.apiUrl];
        if (currentToken !== newToken) {
          window.localStorage[Constants.LocalStorage.AuthenticationToken + "-" + AppConfig.apiUrl] = newToken;
          Log.debug("data", "Data", "Refreshing token in local storage.");
          // Temp error to post to TrackJS to see if refresh is ever happening
          //console.error("auth token refreshed");
        }
      } else {
        // Otherwise we update session storage with our new token
        currentToken = window.sessionStorage[Constants.LocalStorage.AuthenticationToken + "-" + AppConfig.apiUrl];
        if (currentToken !== newToken) {
          window.sessionStorage[Constants.SessionStorage.AuthenticationToken + "-" + AppConfig.apiUrl] = newToken;
          Log.debug("data", "Data", "Refreshing token in session storage.");
          // Temp error to post to TrackJS to see if refresh is ever happening
          //console.error("auth token refreshed");
        }
      }
    }
    if (AppConfig.debug) {
      Log.debug("data", "Data", "Response Object From " + requestData.Url);
      console.log(response);
    }
    return response;
  }



  /**
  Takes {HttpHeaders} from {HttpClient} response and converts to dictionary object.
  @param {HttpHeaders} headers The http headers object.
  @returns A dictionary object of all headers.
  */
  public static convertHttpHeadersToDictionary(headers: HttpHeaders): { [id: string]: string; } {

    const result: { [id: string]: string; } = {};

    if (!headers) {
      return result;
    }

    const keys: string[] = headers.keys();
    keys.forEach((key, index, keys) => {
      const values: string[] = headers.getAll(key);
      if (values.length === 1) {
        result[key] = values[0];
      } else if (values.length > 1) {
        result[key] = values.join(", ");
      }
    });

    return result;
  }


  public static shouldCache(api: m.ApiCall, cacheKey: string, operationType: "read" | "write" | "ignore"): boolean {

    // Here are all the reasons we might not want to do caching
    if (!api) {
      return false;
    }
    if (!cacheKey) {
      return false;
    }
    if (!AppConfig.useCaching) {
      return false;
    }
    if (!api.cacheName) {
      return false;
    }
    if (!api.objectName) {
      return false;
    }
    if (operationType === "read" && api.cacheIgnoreOnRead) {
      return false;
    }
    if (operationType === "write" && api.cacheIgnoreOnWrite) {
      return false;
    }
    try {
      if (api.cacheLevel > AppConfig.cacheLevel) {
        return false;
      }
    } catch { }

    // If we didn't find a reason not to cache then we should cache
    return true;

  }


  /**
  Builds the cache key to use for the specified API call and data.
  @param {m.ApiCall} apiCall The API call object.
  @param {any} data The data object being used in conjunction with this API call.
  @param {number} partitionId If provided the partition id will be part of the cache key for scenarios where a user switches partitions.
  @returns {string} The cache key to use for this call and object.  If null then no caching should be performed.
  */
  public static buildCacheKey(apiCall: m.ApiCall, data: any, partitionId: number = null): string {

    // A few reasons we don't bother creating a cache key:
    // Not using caching
    if (!AppConfig.useCaching) {
      Log.debug("cache", "No Cache", "Caching has been disabled.");
      return null;
    }
    // No cache defined, no data object, no object name, no object primary key
    if (!data || !apiCall.cacheName || !apiCall.objectName || !apiCall.objectPrimaryKey) {
      Log.debug("cache", "No Cache", `${apiCall.objectName} has no cache name, no data provided, no object name, or no primary key defined so no cache key available.`);
      return null;
    }

    if (apiCall.cacheKey) {
      Log.debug("cache", "Cache", `Cache Key: ${apiCall.cacheKey} defined in the ApiCall object.`);
      return apiCall.cacheKey;
    }

    let key = apiCall.objectName + "-";

    // If our data is an object then get the properties from the object that map to our primary key
    if (Helper.isObject(data)) {
      if (typeof apiCall.objectPrimaryKey === "string") {
        // We have a single key property
        key = key + data[<string>apiCall.objectPrimaryKey];
      } else if (Helper.isArray(apiCall.objectPrimaryKey)) {
        // We have an array of keys to retrieve
        (<string[]>apiCall.objectPrimaryKey).forEach((value: string, index: number, values: string[]) => {
          if (index > 0) {
            key = key + "-";
          }
          key = key + data[value];
        });
      }
    }
    else if (Helper.isArray(data)) {
      if (typeof apiCall.objectPrimaryKey === "string") {
        // We have a single key but an array of input so just use the first value
        key = key + data[0];
      } else if (Helper.isArray(apiCall.objectPrimaryKey)) {
        // We have an array of keys to retrieve
        (<string[]>apiCall.objectPrimaryKey).forEach((value: string, index: number, values: string[]) => {
          if (index > 0) {
            key = key + "-";
          }
          // The key array and input data array have the same index
          key = key + data[index];
        });
      }
    }
    else {
      // Our data is a simple value which only works when we have a simple value for our key so this will break if the array check below is true
      if (typeof apiCall.objectPrimaryKey === "string") {
        // We have a key value
        key = key + data;
      } else if (Helper.isArray(apiCall.objectPrimaryKey)) {
        Log.errorMessage("Error: cache key creation found api call defines an array of primary keys but only a single data value was provided.");
        // We have an array of key but only a simple data so this may not map properly
        (<string[]>apiCall.objectPrimaryKey).forEach((value: string, index: number, values: string[]) => {
          if (index > 0) {
            key = key + "-";
          }
          key = key + data;
        });
      }
    }

    // If we were given a partition id then put that at the front of the cache key
    if (partitionId) {
      key = `P${partitionId}-${key}`;
    }

    // Our PK that got used in the cache key is probably at the "top" of the object so only
    // take left 50 characters of the json string so we don't make console log too large.
    Log.debug("cache", "Cache", `Cache Key: ${key} based on api url ${apiCall.fragmentUrl} and data ${Helper.left(JSON.stringify(data), 50, true)}`);

    return key;

  }



  public static multiPartModelIdParse(id: string, type: "month" | "year" | ""): { datePart: string; id: number; tag: string } {

    if (!id) {
      console.warn("No multi-part model id provided so nothing to parse.");
      return { datePart: "", id: 0, tag: "" };
    }

    const parts: string[] = id.split('-');
    if (!parts || parts.length < 2) {
      console.warn(`Incorrect format: multi-part model id "${id}" only has ${parts.length} sections.`);
      return { datePart: "", id: 0, tag: "" };
    }

    const date: string = Helper.baseConvert(parts[0], 36, 10);
    const key: number = parseInt(Helper.baseConvert(parts[1], 36, 10), 10);
    let tag: string = "";
    if (parts.length > 2) {
      tag = parts[2];
    }

    return { datePart: date, id: key, tag: tag };

  }





  public static xhrPostForm<T>(url: string, form: FormData): Promise<T> {

    const promise = new Promise<T>((resolve, reject) => {

      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(<T>JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };

      xhr.open('POST', url, true);
      xhr.send(form);

    });

    return promise;

  }



}

