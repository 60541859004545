import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked, AfterViewInit, Directive } from '@angular/core';
import { TableModule, Table } from 'primeng/table';
import { SelectItem } from 'primeng/api';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuItem } from 'primeng/api';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { TableHelper } from 'projects/common-lib/src/lib/table/table-helper';
//import { Column } from 'primeng/components/common/shared';
import { EventModel, EventElementModel, EventModelTyped } from 'projects/common-lib/src/lib/ux-models';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import * as Enumerable from 'linq';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { TableColumnOptions, PrimeColumn } from 'projects/common-lib/src/lib/table/table-column-options';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ApiProperties, Query, ApiOperationType, ApiCall, IApiResponseWrapper, IApiResponseWrapperTyped } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { SafeStyle, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { FilterSelectionData } from 'projects/common-lib/src/lib/filter/filter-selection-data';
import { QueryService } from 'projects/core-lib/src/lib/services/query.service';
import { StaticPickList } from 'projects/core-lib/src/lib/models/model-helpers';
import { TableBaseClass, TableState } from '../table-base-class';
import { TableService } from 'projects/core-lib/src/lib/services/table.service';
import { UxService } from '../../services/ux.service';
import { SystemService } from 'projects/core-lib/src/lib/services/system.service';

@Component({
  selector: 'ib-standard-table',
  templateUrl: './standard-table.component.html',
  styleUrls: ['./standard-table.component.css'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class StandardTableComponent extends TableBaseClass implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {



  /**
  Incrementing this input will alert the table that selected data needs to be refreshed.
  */
  @Input() refreshSelectedDataCount: number = 0;

  /**
  Incrementing this input will alert the table that any existing filters should be cleared.
  */
  @Input() clearFiltersCount: number = 0;

  /**
  Data editors can have a scope object assigned to them which represents a data ownership scope for
  the component providing values for filtering of lists as well as default values when adding new
  objects.  For example, a list of contacts may have scope defining the contact type and who the
  contacts belong to: { ContactType: "T", ParentContactId: 12345 }.
  A list of case task lists may have scope defining the case they belong to: { CaseId: 12345 }.
  */
  @Input() scope: any = {};
  /**
   Typically our scope is added to the filter but sometimes that's handled via url hierarchy
   and we don't want it added to the filter expression.
   */
  @Input() scopeInFilter: boolean = true;

  @Output() page: EventEmitter<any> = new EventEmitter();
  @Output() sort: EventEmitter<any> = new EventEmitter();
  @Output() filter: EventEmitter<any> = new EventEmitter();
  @Output() headerCheckboxToggle: EventEmitter<any> = new EventEmitter();
  @Output() rowSelect: EventEmitter<any> = new EventEmitter();
  @Output() rowUnselect: EventEmitter<any> = new EventEmitter();
  // base class .... @Output() rowReorder: EventEmitter<any> = new EventEmitter();
  @Output() rowExpand: EventEmitter<any> = new EventEmitter();
  @Output() rowCollapse: EventEmitter<any> = new EventEmitter();
  @Output() colResize: EventEmitter<any> = new EventEmitter();
  @Output() colReorder: EventEmitter<any> = new EventEmitter();
  @Output() editInit: EventEmitter<any> = new EventEmitter();
  @Output() editComplete: EventEmitter<any> = new EventEmitter();
  @Output() editCancel: EventEmitter<any> = new EventEmitter();
  @Output() contextMenuSelect: EventEmitter<any> = new EventEmitter();
  @Output() queryPrepared: EventEmitter<any> = new EventEmitter();
  @Output() lazyLoad: EventEmitter<any> = new EventEmitter();
  /**
   * Each time the paginator is clicked, this is updated with the page#.
   */
  lastQueryPage: number = 1;

  public cols: PrimeColumn[] = [];
  public componentStyle: string = "";
  public tableStyle: string = "";

  /**
   * This defaults to 0 but gets updated by the table as pagination happens
   * and in some cases we may want to note this value and restore it around
   * events (like filtering) that may reset the table to the first row of data.
   */
  public firstRowNumberToShow: number = 0;
  /**
   * Sometime events (like filtering) run async and fire an event when done
   * so we may need to keep the first row number to restore to in this
   * property which can be accessed in the async event fired.
   */
  public firstRowNumberToRestoreTo: number = null;

  public totalRowCount: number = 0;
  public filteredRowCount: number = 0;
  /**
   * Criteria of last applied filter.
   */
  public filterCriteria: any = null;
  public filterSelectionData: FilterSelectionData = null;
  /**
   * Subset of data that matches the last applied filter.
   */
  public filteredData: any[] = [];

  public filterSelectOptions: { [index: string]: SelectItem[] } = {};
  public currentFilterCol: PrimeColumn = null;
  dateRangeOptionsPickList: m5core.PickListSelectionViewModel[] = [];

  @ViewChild('filterText') filterTextElement: ElementRef;
  @ViewChild('dt', { static: true }) table: Table;

  /**
  If we determine we're doing api interaction we'll flip lazy to true and the
  table will fire the lazy event when it's time to get more data.
  */
  public lazy: boolean = false;
  public lazyDataFiltered: boolean = false;
  /**
  Some lazy filtering gets applied as the result of a scope object and if that is
  the case we don't want the row count to show that it was filtered as the scope
  filter is hard coded and cannot be cleared as opposed to a user supplied filter.
  */
  public lazyScopeFilterExpression: string = "";
  /**
  The last table event submitted to lazy load method.  This is kept for scenarios
  where we need to manually fire a lazy load without an event.
  */
  public lazyLastTableEvent: any = null;
  public apiProperties: ApiProperties = null;
  public apiCall: ApiCall = null;

  //get showTableFilter() {
  //  //console.error(this.appService.optInFeatures);
  //  //console.error(this.options);
  //  if (this.appService.optInFeatures.TableFilterButton) {
  //    return true;
  //  } else {
  //    return false;
  //  }
  //}

  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected systemService: SystemService,
    protected queryService: QueryService,
    protected uxService: UxService,
    protected sanitizer: DomSanitizer,
    protected cdr: ChangeDetectorRef,
    protected elementRef: ElementRef) {
    super(apiService, appService, systemService, queryService, uxService, sanitizer, cdr, elementRef);
  }


  ngOnInit() {
    super.ngOnInit();
    this.dateRangeOptionsPickList = StaticPickList.DateRangeOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    // Keep copy of options as assigned to us
    if (changes.options) {
      //console.error("ngOnchanges options change triggered optionsLoad.");
      //console.time('optionsLoad');
      // Keep copy of options as assigned to us
      this.defaultOptions = Helper.deepCopy(this.options);
      // Load any saved table config
      this.optionsLoad();
      //console.timeEnd('optionsLoad');
    }
    // We have data and options and one of them changed
    if (this.data && this.options && (changes.data || changes.options)) {
      //console.error("ngOnchanges options or data change triggered prepare filter options and init selected data.");
      //console.time('initSelectedData');
      this.prepareFilterOptions();
      this.initSelectedData();
      //console.timeEnd('initSelectedData');
    }
    // data changed
    if (this.data && changes.data && !this.lazy) {
      //console.error("ngOnchanges data change triggered count and reload.");
      //console.time('countAndReload');
      this.totalRowCount = this.data.length;
      this.filteredRowCount = this.data.length;
      this.reload();
      //console.timeEnd('countAndReload');
    }
    //if (this.dataTotalRowCount && changes.dataTotalRowCount) {
    //  this.totalRowCount = this.dataTotalRowCount;
    //  this.filteredRowCount = this.dataTotalRowCount;
    //}
    //console.error("std table frozen data", this.frozenData);
    if (this.frozenData && this.frozenData.length > 0) {
      this.options.scrollable = true;
    }
    // reload requested
    if (this.reloadCount && changes.reloadCount && !changes.reloadCount.firstChange) {
      // console.error("ngOnchanges reloadcount change triggered reload.", this.data);
      //console.time('reload');
      this.reload(true);
      //console.timeEnd('reload');
    }
    // Collapse all rows if collapseRowsCount is increased.
    if (this.collapseRowsCount && changes.collapseRowsCount && !changes.collapseRowsCount.firstChange) {
      // console.error("ngOnchanges collapseRowsCount triggered rows collapse.");
      // Clear the registry of expanded rows, forcing them to collapse.
      this.table.expandedRowKeys = {};
    }
    // Scope changed so data needs to be reloaded since scope can impact filter
    if ((this.scope && changes.scope && !changes.scope.firstChange) || (this.scopeInFilter && changes.scopeInFilter && !changes.scopeInFilter.firstChange)) {
      //console.error("ngOnchanges scope change triggered reload.");
      //console.time('reload');
      this.reload(true);
      //console.timeEnd('reload');
    }
    if (this.refreshSelectedDataCount && changes.refreshSelectedDataCount && !changes.refreshSelectedDataCount.firstChange) {
      this.initSelectedData();
    }
    // clear filters requested
    if (this.clearFiltersCount && changes.clearFiltersCount && !changes.clearFiltersCount.firstChange) {
      // Clear global filter
      this.globalFilterText = "";
      // Reset the table which clears its internal filter data used to apply the actual filter
      this.table.filters = {};
      //this.table.clear();
      // Clear the filter data we have attached to column options which impacts the UI showing if filter is in place or not
      this.cols.forEach(col => {
        //(<TableColumnOptions>(<any>col).options).filterDateRange = "CUSTOM";
        //(<TableColumnOptions>(<any>col).options).filterDateValue1 = "";
        //(<TableColumnOptions>(<any>col).options).filterDateValue2 = "";
        (<TableColumnOptions>(<any>col).options).filterValue = "";
        (<TableColumnOptions>(<any>col).options).filterSelections = [];
      });
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // HACK: If we don't have any contents in the caption then let's hide it as we get left with ugly artifact.
    const showTableCaption: boolean = (this.options.title || this.options.globalSearch || (this.options.staticFilterPickList && this.options.staticFilterPickList.length > 0) || this.options.actionButtonLeft1 || this.options.actionButtonLeft2 || this.options.actionButtonRight1 || this.options.actionButtonRight2) as boolean;
    try {
      const div = this.elementRef.nativeElement.querySelector(".p-datatable-header");
      if (showTableCaption && div) {
        div.style.display = null;
      } else if (div) {
        div.style.display = "none";
      } else {
        Log.errorMessage("Unable to find table caption using .p-datatable-header selector.");
      }
    } catch (err) {
      Log.errorMessage(err);
    }
  }

  ngAfterViewChecked() {
    // Address Error in StandardTableComponent.html: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'loading: false'. Current value: 'loading: true'.
    this.cdr.detectChanges();
  }

  public configure() {

    // Setup common config
    super.configure();

    // Convert from theme to classes
    if (Helper.equals(this.options.theme, "plain", true)) {
      this.componentStyle = "p-datatable-component-plain";
      this.tableStyle = "p-datatable-plain";
    } else if (Helper.equals(this.options.theme, "striped", true)) {
      this.componentStyle = "p-datatable-striped"; //"p-datatable-component-plain";
      this.tableStyle = "p-datatable-striped";
    }

    // Convert from our options to prime
    this.cols = TableHelper.toPrimeColumns(this.options.columns);

    // If we were given a sort then set that to prime
    if (this.options.sort) {
      TableHelper.toPrimeSort(this.options.sort, this.options, this.table);
      //setTimeout(() => {
      //  TableHelper.toPrimeSort(this.options.sort, this.options, this.table);
      //  console.error("setting sort", this.options.sort, this.table.multiSortMeta, this.table.sortOrder, this.table.sortField);
      //}, 5000);
    }

    // Setup api if calling api
    if (!this.apiProperties && this.options.apiProperties) {
      this.apiProperties = this.options.apiProperties
      if (this.options.loadDataFromServer) {
        this.apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.List);
      }
      this.lazy = this.options.loadDataFromServer;
    }
    // Still no api properties?
    if (!this.apiProperties && this.options.apiName) {
      this.apiProperties = Api.GetApi(this.options.apiName);
      if (this.options.loadDataFromServer) {
        this.apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.List);
      }
      this.lazy = this.options.loadDataFromServer;
    }

  }


  initSelectedData() {

    if (!this.options?.selectRows) {
      return;
    }

    this.selectedData = [];
    this.selectionMode = "multiple"; // "multipleWithCheckboxes"; // "multiple";

    // If we have data and there is a property name in that data that identifies a
    // row as selected then we want those rows in our selected data array.
    if (this.data && this.options.dataSelectedBooleanPropertyName) {
      this.data.forEach(row => {
        if (row[this.options.dataSelectedBooleanPropertyName]) {
          this.selectedData.push(row);
        }
      });
      if (this.selectedData.length > 0) {
        // Trigger refresh in component by creating a new array
        this.selectedData = [...this.selectedData];
      }
    }

  }


  public prepareFilterOptions() {
    this.filterSelectOptions = {};
    this.options.columns.forEach(col => {
      this.determineMultiSelectOptions(col);
    });
  }


  public determineMultiSelectOptions(col: TableColumnOptions) {

    if (!Helper.equals(col.filterType, "select", true) && !Helper.equals(col.filterType, "multiselect", true)) {
      return;
    }

    const options: SelectItem[] = [];
    let loaded: boolean = false;

    if (col.filterPickListId) {
      // Pick list specified so use that
      this.apiService.loadPickList(col.filterPickListId).subscribe((result: IApiResponseWrapperTyped<m5core.PickListSelectionViewModel[]>) => {
        if (result.Data.Success) {
          result.Data.Data.forEach(value => {
            options.push({ label: value.DisplayText, value: value.Value });
          });
          this.filterSelectOptions[col.propertyName] = options;
          loaded = true;
        } else {
          Log.errorMessage(result);
        }
      });
    }

    // No pick list id specified see if we have pick list values
    if (!loaded && col.filterPickListValues && col.filterPickListValues.length > 0) {
      col.filterPickListValues.forEach(value => {
        options.push({ label: value.DisplayText, value: value.Value });
      });
      this.filterSelectOptions[col.propertyName] = options;
      loaded = true;
    }

    // No pick list specified or unable to use those values for some reason
    if (!loaded) {
      // No pick list specified so use values from our data
      Enumerable.from(this.data).distinct(x => x[col.propertyName]).orderBy(x => x[col.propertyName] ? x[col.propertyName].toString().toUpperCase() : "").forEach(x => {
        options.push({ label: x[col.propertyName], value: x[col.propertyName] });
      });
      this.filterSelectOptions[col.propertyName] = options;
      //console.error(this.filterSelectOptions);
    }

  }



  public firePage(event) {
    const payload: EventModel = new EventModel("page", event, Helper.tryGetValue(event, x => x.filteredValue, null), new EventElementModel("table", this.options.tableId));
    this.page.emit(payload);
    // Save options
    //console.error("page change event", this.options.rowsPerPage, event);
    this.options.page = TableHelper.fromPrimePage(event);
    this.optionsSave();
  }

  public fireSort(event) {
    const payload: EventModel = new EventModel("sort", event, Helper.tryGetValue(event, x => x.filteredValue, null), new EventElementModel("table", this.options.tableId));
    this.sort.emit(payload);
    // Save options
    this.options.sort = TableHelper.fromPrimeSort(event);
    this.optionsSave();
  }

  public fireFilter(event) {
    // Update our filtered row count if not lazy loading
    if (!this.lazy) {
      this.filteredRowCount = Helper.tryGetValue(event, x => x.filteredValue.length, this.totalRowCount);
    }
    this.filterCriteria = event.filters;
    this.filteredData = event.filteredValue;
    // Pass on the event
    const cargo: any = { filters: this.filterCriteria };
    const payload: EventModel = new EventModel("filter", event, this.filteredData, new EventElementModel("table", this.options.tableId), cargo);
    this.filter.emit(payload);
    const changePayload: EventModel = new EventModel("filter", event, null, null, cargo);
    this.filterChange.emit(changePayload);
    // Save options
    this.optionsSave();
  }

  public fireHeaderCheckboxToggle(event) {

    const cargo: any = { checked: event.checked };
    if (Helper.isArray(this.selectedData)) {
      cargo.selectedRows = this.selectedData;
    } else {
      cargo.selectedRows = [this.selectedData];
    }
    cargo.visibleRows = this.filteredData;

    const payload: EventModel = new EventModel("headerCheckboxToggle", event, event.checked, new EventElementModel("table", this.options.tableId), cargo);
    this.headerCheckboxToggle.emit(payload);
    //console.error("toggle", event, payload);

    // If our table options has row (un)selected action fire it now
    if (event.checked && this.options.allRowsSelectedAction) {
      this.options.allRowsSelectedAction(this.filteredData);
    } else if (!event.checked && this.options.allRowsUnselectedAction) {
      this.options.allRowsUnselectedAction(this.filteredData);
    }

  }

  public fireTableRowClick(event, rowIndex: number, row: any) {
    Helper.fireClickVibration();
    //// See if we need to manually select data
    //if (rowIndex > -1 && this.selectionMode === "multipleWithCheckboxes") {
    //  if (!this.selectedData) {
    //    this.selectedData = this.data[rowIndex];
    //  } else if (!Helper.isArray(this.selectedData)) {
    //    if (this.selectedData === this.data[rowIndex]) {
    //      this.selectedData = [];
    //    } else {
    //      this.selectedData = [this.selectedData, this.data[rowIndex]];
    //    }
    //  } else {
    //    let match = (<any[]>this.selectedData).indexOf(this.data[rowIndex]);
    //    if (match > -1) {
    //      (<any[]>this.selectedData).splice(match, 1);
    //    } else {
    //      this.selectedData.push(this.data[rowIndex]);
    //    }
    //  }
    //}
    // See if we have a context menu index to set
    const isRightButton = Helper.htmlEventIsRightMouseButton(event);
    //console.error(event);
    //console.error(`Right Button = ${isRightButton}; Row = ${rowIndex}`);
    if (isRightButton) {
      this.contextMenuRowIndex = rowIndex;
    } else {
      this.contextMenuRowIndex = -1;
    }
    // See if user wants row click to act as select.  Note that prime ng table is unhappy with
    // checkbox and row select both being in action at the same time so we removed the prime ng
    // table row click support and reverted to native js click event which we now simulate as
    // a row click when desired.
    if (!isRightButton && this.options.selectRowViaRowClick) {
      //console.error("row click", event, rowIndex);
      if (rowIndex === -1) {
        Log.errorMessage("Unable to turn row click into row (un)select without row index.");
        return;
      }
      if (!row) {
        row = this.data[rowIndex];
      }
      const isLink: boolean = this.isLink(event);
      if (isLink) {
        // Trying to click a link so don't do other events here
        //console.error("link click", event);
        return;
      }
      const isMenu: boolean = this.isRowMenuCell(event);
      const isCheckbox: boolean = this.isRowCheckboxCell(event);
      const isExpand: boolean = this.isRowExpandCell(event);
      const isAlreadySelected: boolean = this.isRowSelected(row);
      // Click on the menu or checkbox should not trigger the below action
      if (!isMenu && !isCheckbox && !isExpand) {
        // row event which we will fire expects index, data and type of "checkbox" or "row" so create a new event object
        const rowEvent = { index: rowIndex, data: row, type: "row", originalEvent: event };
        // Determine if clicked row needs to be added or removed from selected data
        //console.error(this.options.selectRowViaRowClick, this.options.dataSelectedBooleanPropertyName)
        if (isAlreadySelected) {
          if (this.options.selectRowViaRowClick && this.options.dataSelectedBooleanPropertyName && !this.options.rowUnselectedAction) {
            // If we have rowUnselectedAction assume that will take care of this with any other logic it needs to perform
            row[this.options.dataSelectedBooleanPropertyName] = false;
            this.data[rowIndex][this.options.dataSelectedBooleanPropertyName] = false;
          }
          if (Helper.isArray(this.selectedData)) {
            if (this.options.primaryKey) {
              this.selectedData = this.selectedData.filter(x => x[this.options.primaryKey] !== row[this.options.primaryKey]);
            } else {
              this.selectedData = this.selectedData.filter(x => JSON.stringify(x) !== JSON.stringify(row));
            }
          } else {
            this.selectedData = null;
          }
          this.fireRowUnselect(rowEvent);
        } else {
          if (this.options.selectRowViaRowClick && this.options.dataSelectedBooleanPropertyName && !this.options.rowSelectedAction) {
            // If we have rowSelectedAction assume that will take care of this with any other logic it needs to perform
            row[this.options.dataSelectedBooleanPropertyName] = true;
            this.data[rowIndex][this.options.dataSelectedBooleanPropertyName] = true;
          }
          if (Helper.isArray(this.selectedData)) {
            this.selectedData.push(row);
          } else {
            this.selectedData = row;
          }
          this.fireRowSelect(rowEvent);
        }
        //console.error( "row selected" , row[this.options.dataSelectedBooleanPropertyName] );
      }
    }
  }

  public fireRowSelect(event) {
    const isLink: boolean = this.isLink(event);
    if (isLink) {
      // Trying to click a link so don't do other events here
      //console.error("link click", event);
      return;
    }
    // We have one column in our table which might be our row menu.  In cases where that cell was the target of the row select mouse event we do not
    // want to emit an event to listeners which will often react by opening that row when what we really want internally is for the menu to be displayed
    // (see showMenu() for code where that happens) and then let the user pick a menu action to be fired instead of the default row select action.
    // The user may have clicked anywhere in the cell including an icon so we need to check the path for any match to #rowMenuCell which would be our
    // indication that we want the row menu and not row select action to fire.
    const isMenu: boolean = this.isRowMenuCell(event);
    if (!isMenu) {
      //console.error("row select", event, "type", event.type);
      //console.error(this.selectionMode, event.data, this.selectedData);
      //console.error(`row index = ${rowIndex}`);
      const data = Helper.tryGetValue(event, x => x.data, null);
      const cargo: any = { index: Helper.tryGetValue(event, x => x.index, -1), type: event.type };
      if (Helper.isArray(this.selectedData)) {
        cargo.selectedRows = this.selectedData;
        //this.table.selection = [...this.selectedData];
      } else {
        cargo.selectedRows = [this.selectedData];
        //this.table.selection = { ...this.selectedData };
      }
      // If our table options has row selected action fire it now
      if (this.options.rowSelectedAction) {
        this.options.rowSelectedAction(data, cargo.selectedRows, this.data);
      }
      const payload: EventModel = new EventModel("rowSelect", event, data, new EventElementModel("table", this.options.tableId), cargo);
      this.rowSelect.emit(payload);
    }
  }

  public fireRowUnselect(event) {
    // If we had the row selected and then the user clicked on the menu icon that could result
    // in the row being unselected when we need it selected so we know what row the menu is for.
    // See if the cell clicked on is for the row menu icon so we know if that is the case.
    const isMenu: boolean = this.isRowMenuCell(event);
    if (isMenu) {
      //console.error("unselected", event);
      if (event.data) {
        if (this.selectionMode === "single") {
          this.selectedData = event.data;
        } else if (this.selectionMode === "multiple") {
          if (!this.selectedData) {
            this.selectedData = [];
          }
          if (Helper.isArray(event.data)) {
            this.selectedData.push(...event.data);
          } else {
            this.selectedData.push(event.data);
          }
        }
      }
      return;
    }
    const data = Helper.tryGetValue(event, x => x.data, null);
    const cargo: any = { index: Helper.tryGetValue(event, x => x.index, -1), type: event.type };
    if (Helper.isArray(this.selectedData)) {
      cargo.selectedRows = this.selectedData;
    } else {
      cargo.selectedRows = [this.selectedData];
    }
    // If our table options has row unselected action fire it now
    if (this.options.rowUnselectedAction) {
      this.options.rowUnselectedAction(data, cargo.selectedRows, this.data);
    }
    const payload: EventModel = new EventModel("rowUnselect", event, data, new EventElementModel("table", this.options.tableId), cargo);
    this.rowUnselect.emit(payload);
  }


  onFilterChange($event: EventModelTyped<FilterSelectionData>) {
    super.onFilterChange($event);
    this.filterSelectionData = $event.data;
    this.optionsSave(false, true);
    this.fireLazyLoad(null, true);
  }



  public fireRowReorder($event) {
    //console.error("row reorder", $event);

    // Move element
    const updates: { allItems: any[], changedItems: any[] } = Helper.arrayUpdateDisplayOrder(this.data, $event.dropIndex, this.options.orderPropertyName);
    if (updates?.allItems) {
      this.data = updates.allItems;
    }

    // Submit api updates for any changed items
    const results = this.saveOrderChangesToApi(updates?.changedItems);

    // Emit event so owner knows things changed
    const cargo = {
      previousIndex: $event.previousIndex,
      currentIndex: $event.currentIndex,
      changedItems: updates?.changedItems,
      changedItemsUpdateSuccessCount: results?.filter(x => x.Data.Success)?.length,
      changedItemsUpdateFailedCount: results?.filter(x => !x.Data.Success)?.length
    };
    const payload: EventModel = new EventModel("rowReorder", $event, Helper.tryGetValue($event, x => x.data, null), new EventElementModel("table", this.options.tableId), cargo);
    this.rowReorder.emit(payload);

  }

  public fireRowExpand(event) {
    if (this.options.expandedRowEventHandler) {
      this.options.expandedRowEventHandler(event?.data, this.selectedData, this.data, {});
    }
    const payload: EventModel = new EventModel("rowExpand", event, Helper.tryGetValue(event, x => x.data, null), new EventElementModel("table", this.options.tableId));
    this.rowExpand.emit(payload);
  }

  public fireRowCollapse(event) {
    const payload: EventModel = new EventModel("rowCollapse", event, Helper.tryGetValue(event, x => x.data, null), new EventElementModel("table", this.options.tableId));
    this.rowCollapse.emit(payload);
  }

  public fireColResize(event) {
    const payload: EventModel = new EventModel("colResize", event, Helper.tryGetValue(event, x => x.data, null), new EventElementModel("table", this.options.tableId));
    this.colResize.emit(payload);
    // Save options
    this.optionsSave();
  }

  public fireColReorder(event) {
    const payload: EventModel = new EventModel("colReorder", event, Helper.tryGetValue(event, x => x.data, null), new EventElementModel("table", this.options.tableId));
    this.colReorder.emit(payload);
    // Save options
    this.optionsSave();
  }

  public fireEditInit(event) {
    const payload: EventModel = new EventModel("editInit", event, Helper.tryGetValue(event, x => x.data, null), new EventElementModel("table", this.options.tableId));
    this.editInit.emit(payload);
  }

  public fireEditComplete(event) {
    const payload: EventModel = new EventModel("editComplete", event, Helper.tryGetValue(event, x => x.data, null), new EventElementModel("table", this.options.tableId));
    this.editComplete.emit(payload);
  }

  public fireEditCancel(event) {
    const payload: EventModel = new EventModel("editCancel", event, Helper.tryGetValue(event, x => x.data, null), new EventElementModel("table", this.options.tableId));
    this.editCancel.emit(payload);
  }

  public fireContextMenuSelect(event) {
    const cargo: any = {
      index: this.contextMenuRowIndex
    };
    const rowData = Helper.tryGetValue(event, x => x.data, null);
    this.prepareContextMenu(rowData);
    const payload: EventModel = new EventModel("contextMenuSelect", event, rowData, new EventElementModel("table", this.options.tableId), cargo);
    this.contextMenuSelect.emit(payload);
    // Don't clear on this event... we will clear on the context menu action function execution
    // this.contextMenuRowIndex = -1;
  }

  public fireLazyLoad(event, reloadRequested: boolean = false) {

    // If the refresh button was clicked, this will be set to true.
    let canDumpCache: boolean = false;

    if (event) {
      this.lazyLastTableEvent = event;
    } else {
      event = this.lazyLastTableEvent;
    }

    // Load data from the api
    //console.error(event);
    const query: Query = new Query();

    // Check to see if its a paginator event or if the refresh button was clicked.
    if (Helper.objectHasProperties(event, "first", "rows")) {

      // Event was from paginator, so set the page using the event.
      query.Page = TableHelper.fromPrimePage(event) || this.options.page;
      this.lastQueryPage = query.Page;
    } else if (this.lastQueryPage) {

      // Note: we can't reference this.options.page here because fireLazyLoad gets called before
      // firePage and firePage is what updates options.page, so options.page will not be the current page.
      query.Page = this.lastQueryPage;
      canDumpCache = true;
    }

    // Convert sort from table component to our api format
    query.Sort = TableHelper.fromPrimeSort(event) || this.options.sort;
    query.Size = event?.rows || this.options.rowsPerPage || 10;

    if (this.filterSelectionData) {
      if (this.filterSelectionData.FilterId) {
        query.FilterId = this.filterSelectionData.FilterId;
      } else if (this.queryService.hasFilterConditions(this.filterSelectionData.FilterConditions)) {
        // We're going to add some API format filter expressions below so convert this from SQL to API so we don't have a mismatch
        query.Filter = this.queryService.translateFilterFromSqlFormatToApiFormat(this.queryService.buildFilterExpressionFromConditionModel(this.filterSelectionData.FilterConditions));
      }
    }

    if (this.options.expand) {
      query.Expand = this.options.expand;
    } else if (this.options.staticFilterValue && this.options.staticFilterType === "ValueInExpand") {
      query.Expand = this.options.staticFilterValue;
    }

    // Our filter starts with any defined scope.  If our api endpoint is a child endpoint it may also
    // have scope properties in the url so push these to our query object as well in case needed.
    if (this.scope) {
      for (const property in this.scope) {
        if (this.scopeInFilter) {
          // Update filter
          if (query.Filter) {
            query.Filter += " && ";
          }
          if (typeof this.scope[property] === "string") {
            query.Filter += `${property} == "${this.scope[property]}"`
          } else {
            query.Filter += `${property} == ${this.scope[property]}`
          }
        }
        // Update query object to accommodate possible reference when url is built
        query[property] = this.scope[property];
      }
      // Now append any filter specified in our options
      if (this.options.filter) {
        if (query.Filter) {
          query.Filter = `( ${query.Filter} ) && ( ${this.options.filter} )`;
        } else {
          query.Filter = this.options.filter;
        }
      }
      // Save scope portion of filter so we can determine after data load if we have user filter applied
      this.lazyScopeFilterExpression = query.Filter;
    } else if (this.options.filter) {
      query.Filter = this.options.filter;
      // Save scope portion of filter so we can determine after data load if we have user filter applied
      this.lazyScopeFilterExpression = this.options.filter;
    }

    // Now add table defined filters
    //console.error("event.filters", event.filters);
    if (event?.filters) {
      Object.keys(event.filters).forEach(key => {
        const oneFilter = event.filters[key];
        if (Helper.equals(key, "global", true)) {
          query.Q = oneFilter.value;
        } else {
          if (query.Filter) {
            query.Filter += " && ";
          }
          if (Helper.equals(oneFilter.matchMode, "contains", true)) {
            query.Filter += `${key}.Contains("${oneFilter.value}")`;
          } else if (Helper.equals(oneFilter.matchMode, "startsWith", true)) {
            query.Filter += `${key}.StartsWith("${oneFilter.value}")`;
          } else if (Helper.equals(oneFilter.matchMode, "endsWith", true)) {
            query.Filter += `${key}.EndsWith("${oneFilter.value}")`;
          } else if (Helper.equals(oneFilter.matchMode, "in", true)) {
            let segment: string = "";
            if (oneFilter.value && oneFilter.value.length > 0) {
              oneFilter.value.forEach(value => {
                if (segment) {
                  segment += " || ";
                }
                segment += `${key} == "${value}"`;
              });
            }
            if (segment) {
              query.Filter += `( ${segment} )`;
            }
          } else if (Helper.equals(oneFilter.matchMode, "equals", true)) {
            query.Filter += `${key} == "${oneFilter.value}"`;
          } else if (Helper.equals(oneFilter.matchMode, "notEquals", true)) {
            query.Filter += `${key} != "${oneFilter.value}"`;
          } else if (Helper.equals(oneFilter.matchMode, "lt", true)) {
            query.Filter += `${key} < "${oneFilter.value}"`;
          } else if (Helper.equals(oneFilter.matchMode, "lte", true)) {
            query.Filter += `${key} <= "${oneFilter.value}"`;
          } else if (Helper.equals(oneFilter.matchMode, "gt", true)) {
            query.Filter += `${key} > "${oneFilter.value}"`;
          } else if (Helper.equals(oneFilter.matchMode, "gte", true)) {
            query.Filter += `${key} >= "${oneFilter.value}"`;
          }
        }
      });
      /*
      filters:
        CustomerName:
        matchMode: "startsWith"
        value: "yao"
      */
      //query.Q = Helper.tryGetValue(event, x => x.filters.global.value, "", "");
    }
    //console.error(query);

    const queryPayload: EventModel = new EventModel("queryPrepared", event, query, new EventElementModel("table", this.options.tableId));
    this.queryPrepared.emit(queryPayload);

    // debug
    //console.error(query);

    this.loading = true;
    this.apiCall.silent = this.options.apiCallSilent;
    this.apiCall.cacheIgnoreOnRead = (reloadRequested || this.options.apiCallIgnoreCache);

    //let keys = this.appService.cache.cacheGetKeys();
    //console.log('keys before: ', keys);

    // While the current page is refreshed, other pages might still be cached and therefore stale so remove
    // any pages that exist in the cache for this table.
    if (canDumpCache) {
      this.appService.cache.cacheRemoveAllByCacheName(this.apiCall.cacheName);
      //keys = this.appService.cache.cacheGetKeys();
      //console.log('keys after: ', keys);
    }

    this.apiService.execute(this.apiCall, query).subscribe((result: IApiResponseWrapper) => {
      if (result.Data.Success) {
        this.data = result.Data.Data;
        this.totalRowCount = result.Data.Scope.TotalResults;
        this.filteredRowCount = result.Data.Scope.TotalResults;
        this.loading = false;
        if (result.Data.Scope.FilterId || result.Data.Scope.Q) {
          this.lazyDataFiltered = true;
        } else if (result.Data.Scope.Filter && !Helper.equals(result.Data.Scope.Filter, this.lazyScopeFilterExpression, true)) {
          this.lazyDataFiltered = true;
        } else {
          this.lazyDataFiltered = false;
        }
        if (this.options.lazyLoadAction) {
          this.options.lazyLoadAction(this.data, { apiResponse: result });
        }
      } else {
        this.appService.alertManager.addAlertFromApiResponse(result, this.apiCall);
        this.loading = false;
      }
    });

    // Now emit our own event
    const payload: EventModel = new EventModel("lazyLoad", event, Helper.tryGetValue(event, x => x.data, null), new EventElementModel("table", this.options.tableId));
    this.lazyLoad.emit(payload);

  }



  public filterMatchModeSet(mode: string): void {
    // Set the filter mode
    this.currentFilterCol.filterMatchMode = mode;
    // Reapply the filter when the mode changes
    this.table.filter((<any>this.currentFilterCol).options.filterValue, this.currentFilterCol.field, this.currentFilterCol.filterMatchMode);
  }

  public filterMatchModeLabel(): string {
    // filterMatchMode: string = "contains"; // Available match modes are "startsWith", "contains", "endsWith", "equals", "notEquals", "in", "lt", "lte", "gt" and "gte".
    if (Helper.equals(this.currentFilterCol.filterMatchMode, "contains", true)) {
      return "Contains";
    } else if (Helper.equals(this.currentFilterCol.filterMatchMode, "startsWith", true)) {
      return "Starts With";
    } else if (Helper.equals(this.currentFilterCol.filterMatchMode, "endsWith", true)) {
      return "Ends With";
    } else if (Helper.equals(this.currentFilterCol.filterMatchMode, "equals", true)) {
      return "=";
    } else if (Helper.equals(this.currentFilterCol.filterMatchMode, "notEquals", true)) {
      return "!=";
    } else if (Helper.equals(this.currentFilterCol.filterMatchMode, "lt", true)) {
      return "<";
    } else if (Helper.equals(this.currentFilterCol.filterMatchMode, "lte", true)) {
      return "<=";
    } else if (Helper.equals(this.currentFilterCol.filterMatchMode, "gt", true)) {
      return ">";
    } else if (Helper.equals(this.currentFilterCol.filterMatchMode, "gte", true)) {
      return ">=";
    }
    // Default
    return "Contains";
  }


  public filterColumnClear() {
    (<any>this.currentFilterCol).options.filterDateRange = "CUSTOM";
    (<any>this.currentFilterCol).options.filterDateValue1 = "";
    (<any>this.currentFilterCol).options.filterDateValue2 = "";
    (<any>this.currentFilterCol).options.filterValue = "";
    (<any>this.currentFilterCol).options.filterSelections = [];
    if (Helper.equals(this.currentFilterCol.filterType, "multiselect", true)) {
      this.table.filter((<any>this.currentFilterCol).options.filterSelections, this.currentFilterCol.field, "in");
    } else if (Helper.equals(this.currentFilterCol.filterType, "select", true)) {
      this.table.filter((<any>this.currentFilterCol).options.filterValue, this.currentFilterCol.field, "equals");
    } else {
      this.table.filter((<any>this.currentFilterCol).options.filterValue, this.currentFilterCol.field, this.currentFilterCol.filterMatchMode);
    }
  }


  public filterColumn(event: any, col: PrimeColumn, overlaypanel: OverlayPanel): void {
    this.currentFilterCol = col;
    //console.error("Filter Column");
    //console.error(col);
    overlaypanel.toggle(event);
    if (Helper.equals(this.currentFilterCol.filterType, "text", true) && this.filterTextElement) {
      setTimeout(() => {
        this.filterTextElement.nativeElement.focus();
        //console.error('set focus');
      }, 250);
    }
  }


  public filterColumnOnInput($event): void {
    // dt.filter($event.target.value, currentFilterCol.field, currentFilterCol.filterMatchMode)
    // For lazy loaded tables we filter on change event.  For non-lazy loaded tables we filter on input event.
    if (!this.lazy) {
      this.table.filter($event.target.value, this.currentFilterCol.field, this.currentFilterCol.filterMatchMode);
    }
  }

  public filterColumnOnChange($event): void {
    // dt.filter($event.target.value, currentFilterCol.field, currentFilterCol.filterMatchMode)
    // For lazy loaded tables we filter on change event.  For non-lazy loaded tables we filter on input event.
    if (this.lazy) {
      this.table.filter($event.target.value, this.currentFilterCol.field, this.currentFilterCol.filterMatchMode);
    }
  }


  public filterGlobalOnInput($event): void {
    // Save our filter text in case we need to reference it programatically outside of the context of an event handler
    this.globalFilterText = $event.data;
    // For lazy loaded tables we filter on change event.  For non-lazy loaded tables we filter on input event.
    if (!this.lazy) {
      this.table.filterGlobal(this.globalFilterText, "contains");
    }
  }

  public filterGlobalOnChange($event): void {
    // Save our filter text in case we need to reference it programatically outside of the context of an event handler
    this.globalFilterText = $event.data;
    // For lazy loaded tables we filter on change event.  For non-lazy loaded tables we filter on input event.
    if (this.lazy) {
      this.table.filterGlobal(this.globalFilterText, "contains");
    }
  }


  public reload(reloadRequested: boolean = false): void {
    // Currently, reloading the rows always has the effect of removing the data for any
    // child rows, leaving just an empty and inaccurate child table. Collapse the rows to
    // avoid confusion and error.
    this.table.expandedRowKeys = {};

    if (this.lazy) {
      const event = this.table.createLazyLoadMetadata();
      // A change in rows per page doesn't seem to be reflected in the meta data so set here
      event.rows = this.options.rowsPerPage;
      this.fireLazyLoad(event, reloadRequested);
      //console.error("lazy load", event, "reload", reloadRequested);
    } else {
      // Reset data so we redraw the grid
      //console.error('ready to redraw grid reload request', this.reloadCount, 'data element count', this.data.length);
      // this seems to break binding between editor array being passed in to table array and table does seem to be refreshing without this
      //this.data = [...this.data];
      this.totalRowCount = this.data.length;
      // If reload requested reset the filter?  We don't seem to be getting the grid to redraw here
      if (reloadRequested) {
        this.filteredData = [...this.data];
        this.table.value = [...this.data];

        // sortMultiple will make sure the data in the table is sorted per any column asc/desc filters
        // (added data gets pushed to the end of the array and an edited field could be one that has a column
        //  filter on it)
        if (this.table.sortMode === "multiple") {
          this.table.sortMultiple();
        }

        //this.table.clear();
        //this.table.clearState();
      }
      // The best way to get the filtered row count is to fire off the global filter function regardless if we
      // have a global filter in place or not it will trigger the event that handles the filtered row count.
      if (this.firstRowNumberToShow && !this.firstRowNumberToRestoreTo) {
        // 0 is the default to no need to set restore to point when we're already at the default
        this.firstRowNumberToRestoreTo = this.firstRowNumberToShow;
      }
      this.table.filterGlobal(this.globalFilterText, "contains");
      if (this.firstRowNumberToRestoreTo) {
        // Before a filtering event was initiated we noted a row number to restore to.  After filtering we
        // often end up at row 0 (i.e. page 1) but maybe we were on page 3 when we fired the filtering event
        // and we want to get back to page 3.  We use firstRowNumberToRestoreTo as a pointer for that purpose.
        this.firstRowNumberToShow = this.firstRowNumberToRestoreTo;
        // HACK To add to the awkwardness of this whole thing our filter event gets fired before the table is reset
        // to row 0 (i.e. page 1) so we can't just do that in that event handler.  Also the page change event
        // is not raised when this happens so we can't tap in there to do this.  We have to set a timeout that
        // is long enough to probably be after the table is reset to row 0 but not too long or UI acts weird
        // sitting on page 1 for a while before moving to the page we were previously on.
        setTimeout(() => {
          this.firstRowNumberToShow = this.firstRowNumberToRestoreTo;
          this.firstRowNumberToRestoreTo = null;
        }, 500);
      }

      //console.error("NOT lazy load", this.filteredData, "reload", reloadRequested);
    }
  }


  public setRowsPerPage(event: any): void {
    // Get the rows per page
    this.options.rowsPerPage = parseInt(event.target.value, 10);
    // Reload the table
    this.reload();
    // Save options
    this.optionsSave();
  }

  public applyUrlQueries() {
    if (this.routeQueries.filterId || this.routeQueries.filter) {
      this.filterSelectionData = { FilterId: this.routeQueries?.filterId, FilterConditions: this.routeQueries?.filter };
      this.options.sort = this.routeQueries?.sort;
      if (this.options.sort) {
        this.options.rememberSort = false;
      }
      TableHelper.toPrimeSort(this.options.sort, this.options, this.table);
      this.optionsSave(false, true);
      this.fireLazyLoad({ filters: this.routeQueries?.filter }, true);
    }
  }


  // public onExpandedRowSelected($event) {
  //   //console.error("expanded row click", $event);
  //   if (this.options.expandedRowChildTableOptions?.rowSelectedAction) {
  //     this.options.expandedRowChildTableOptions.rowSelectedAction($event.data, this.selectedData, this.data);
  //   }
  // }


  public optionsSave(saveOptions: boolean = true, saveState: boolean = true): void {
    if (!this.options.tableId) {
      // No tableId then nothing to save
      return;
    }
    // TODO we can save a subset of this... don't need all columns just exposed with order and width
    if (saveOptions) {
      Helper.localStorageSaveObject(this.options.tableId, this.options);
    }
    if (saveState) {
      Helper.localStorageSaveObject(`${this.options.tableId}-State`, {
        filterCriteria: this.filterCriteria,
        filterSelectionData: this.filterSelectionData,
        globalFilterText: this.globalFilterText
      });
    }
    // // Experimental: Save options and state to user preferences on the server so they're portable and not tied to the browser.
    // // TODO: Test to see if this is too chatty.  Maybe we need to first check if the information has changed at
    // // all from what we have in our browser storage to determine if we should persist to the back end or not?
    // //this.appService.preferenceObjectSet(`${Constants.ContactPreference.TableConfig}-${this.options.tableId}`, this.options);
    // //this.options is TMI with ApiProperties, etc.
    // // Add rows per page, page number, columns, to state object to capture customization information
    // this.appService.preferenceObjectSet(`${Constants.ContactPreference.TableConfig}-${this.options.tableId}-State`, {
    //   filterCriteria: this.filterCriteria,
    //   filterSelectionData: this.filterSelectionData,
    //   globalFilterText: this.globalFilterText
    // });
    //console.error("table options saved");
  }


  public optionsLoad(): void {
    if (!this.options.tableId) {
      // No tableId then nothing to load
      return;
    }
    const settings = Helper.localStorageGetObject<TableOptions>(this.options.tableId, this.options);

    if (this.options.rememberPaging && settings.rowsPerPage) {
      this.options.rowsPerPage = settings.rowsPerPage;
      this.options.page = settings.page;
      TableHelper.toPrimePage(this.options.page, this.options, this.table);
    }

    if (this.options.rememberLayout) {
      // TODO
    }
    if (this.options.rememberSort) {
      this.options.sort = settings.sort || this.options.sort;
      TableHelper.toPrimeSort(this.options.sort, this.options, this.table);
    }
    if (this.options.rememberFilter) {
      const state = Helper.localStorageGetObject<TableState>(`${this.options.tableId}-State`, null);
      if (state) {
        let applyFilter = false;
        if (state.filterCriteria) {
          this.filterCriteria = state.filterCriteria;
          applyFilter = true;
        }
        if (state.filterSelectionData) {
          this.filterSelectionData = state.filterSelectionData;
          applyFilter = true;
        }
        if (state.globalFilterText) {
          this.globalFilterText = state.globalFilterText;
          applyFilter = true;
        }
        if (applyFilter) {
          if (this.globalFilterText) {
            this.table.filterGlobal(this.globalFilterText, "contains");
          }
          if (this.filterSelectionData) {
            // NOTE: in this method, if there are any changes to options, they need to happen before
            // this call to fireLazyLoad, since options properties get put in the api call as query
            // parameters
            this.fireLazyLoad(null, true);
          }
        }
      }
    }
  }


}
