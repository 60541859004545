import { ApplicationRef, ComponentFactoryResolver, ComponentRef, ElementRef, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from './base.service';
import { ApiService } from '../api/api.service';
import { AppService } from './app.service';
import { AppCacheService } from './app-cache.service';
import { Router, ROUTER_CONFIGURATION } from '@angular/router';
import { StaticPickList } from '../models/model-helpers';
import { AsyncSubject, BehaviorSubject, Observable } from 'rxjs';
import { ApiCall, ApiOperationType, ApiProperties, CacheLevel, IApiResponseWrapperTyped, Query } from '../api/ApiModels';
import { ApiModuleWeb } from '../api/Api.Module.Web';
import { ApiHelper } from '../api/ApiHelper';
import { takeUntil } from 'rxjs/operators';
import { DynamicComponentService } from './dynamic-component.service';
import { HandlebarsHelpers } from '../helpers/handlebars-helpers';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormService extends BaseService {

  private systemFormsSubject = new BehaviorSubject<m5web.FormEditViewModel[]>([]);
  getSystemForms() { return this.systemFormsSubject.asObservable(); }
  private systemForms: m5web.FormEditViewModel[] = [];
  private systemFormsLoaded: boolean = false;
  private systemFormsApiProperties: ApiProperties = null;
  private systemFormsApiCall: ApiCall = null;
  private systemFormsQuery: Query = null;


  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected cache: AppCacheService,
    protected sanitizer: DomSanitizer,
    protected router: Router,
    public dynamic: DynamicComponentService) {

    super();

    try {
      this.refreshSystemForms();
    } catch (err) {
      Log.errorMessage("Exception refreshing system forms from service constructor");
      Log.errorMessage(err);
    }


  }



  /**
   * Loads system form models.  The models are used internally but also provided
   * for subscribers to pick up on these updates.  This method can be called by service users
   * when they know new system forms have been added and the models need to be updated.
   */
  refreshSystemForms(): Observable<m5web.FormEditViewModel[]> {

    const subject = new AsyncSubject<m5web.FormEditViewModel[]>();

    const cacheName = "SystemForms";
    const cacheKey = "SystemFormsObjectArray";

    // Get a quick response from our cache before we call the API.
    // We still call the API to refresh what we had in our cache.
    const promise: Promise<m5web.FormEditViewModel[]> = this.cache.storedCacheGetValue<m5web.FormEditViewModel[]>(cacheName, cacheKey);
    promise.then((answer) => {
      if (answer && answer.length > 0) {
        this.systemForms = answer;
        this.systemFormsSubject.next(this.systemForms);
        subject.next(this.systemForms);
      }
    }, (cancelled) => {
      // No action
    });

    // Config the query
    if (!this.systemFormsApiProperties || !this.systemFormsApiCall || !this.systemFormsQuery) {
      this.systemFormsApiProperties = ApiModuleWeb.Form();
      this.systemFormsApiCall = ApiHelper.createApiCall(this.systemFormsApiProperties, ApiOperationType.List);
      this.systemFormsApiCall.silent = true;
      this.systemFormsApiCall.cacheIgnoreOnRead = true;
      this.systemFormsQuery = new Query("Description", Constants.RowsToReturn.All);
      this.systemFormsQuery.Expand = "full";
      // We only need forms that have a system form id
      this.systemFormsQuery.Filter = `Enabled == 1 && SystemFormId != ""`;
    }

    // Execute
    this.apiService.execute(this.systemFormsApiCall, this.systemFormsQuery).subscribe((result: IApiResponseWrapperTyped<m5web.FormEditViewModel[]>) => {
      if (result.Data.Success) {
        this.systemFormsLoaded = true;
        this.systemForms = result.Data.Data;
        //console.error("system forms loaded as", this.systemForms);
        this.systemFormsSubject.next(this.systemForms);
        subject.next(this.systemForms);
        subject.complete();
        // Now stick in cache storage with static lifetime.  It may change but this service wants to provide quick response
        // even if it might be stale and then after the api call it will refresh the cache storage.
        this.cache.storedCachePutValue(cacheName, cacheKey, this.systemForms, CacheLevel.Static);
        //console.error("system forms cached as", this.systemForms);
      } else {
        this.systemFormsLoaded = true; // Technically not loaded but we're not going to be retrying
        this.systemFormsSubject.next([]);
        subject.next([]);
        subject.complete();
        Log.errorMessage(result);
      }
    });

    return subject.asObservable();

  }


  findSystemForm(id: string): Observable<m5web.FormEditViewModel> {

    const subject = new AsyncSubject<m5web.FormEditViewModel>();

    // If we haven't loaded system forms we need that to finish first
    if (!this.systemFormsLoaded) {
      Log.debugMessage(`Requesting system form with id '${id}' but system forms not loaded yet.  Loading now.`);
      this.refreshSystemForms().pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(configs => {
          // Should be loaded now but enforce that so we don't get in a stack overflow recursive loop below
          if (!this.systemFormsLoaded) {
            this.systemFormsLoaded = true;
          }
          if (!this.systemForms && configs) {
            this.systemForms = configs;
          }
          // Now loaded we can make a recursive call to get our value
          this.findSystemForm(id).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(config => {
              subject.next(config);
              subject.complete();
            });
        });
      return subject.asObservable();
    }

    // If we have already loaded search configs so find the one we want and we're done.
    const matches: m5web.FormEditViewModel[] = this.systemForms.filter(x => Helper.equals(x.SystemFormId, id, true));
    if (matches && matches.length > 1) {
      Log.warningMessage(`Found ${matches.length} enabled system forms with system form id '${id}'.  There should only be one so we're using the first one in the list.`);
    }
    if (matches && matches.length > 0) {
      subject.next(matches[0]);
      subject.complete();
    } else {
      // This isn't an error... in fact it is the most likely outcome without form customization.
      Log.debugMessage(`Unable to find system form with id '${id}'.  Perhaps one hasn't been created?`);
      subject.next(null);
      subject.complete();
    }

    return subject.asObservable();

  }



  defaultFormGroup(): m5web.FormControlGroupEditViewModel {
    const group = new m5web.FormControlGroupEditViewModel();
    group.FormControlGroupId = Helper.randomInteger(true); // negative integer is temp surrogate key until saved
    group.Enabled = true;
    group.GroupScope = "B";
    group.GroupType = "A";
    group.VisibleInDesktopMode = true;
    group.VisibleInTabletMode = true;
    group.VisibleInMobileMode = true;
    group.LazyLoad = true;
    group.PresentationStyle = "B";
    group.ColumnWidth = "12";
    group.InheritLayout = true;
    group.LabelLayout = "D";
    group.Groups = [];
    group.Controls = [];
    group.SecurityScope = new m.SecurityScope();
    group.Properties = {};
    return group;
  }

  defaultFormControl(): m5web.FormControlEditViewModel {
    const control = new m5web.FormControlEditViewModel();
    control.FormControlId = Helper.randomInteger(true); // negative integer is temp surrogate key until saved
    control.Enabled = true;
    control.PropertyDataType = "String";
    control.ControlType = "InputText";
    control.ControlScope = "B";
    control.InheritLayout = true;
    control.Alignment = "L";
    control.Width = "W";
    control.LabelLayout = "D";
    control.Sortable = true;
    control.Resizable = true;
    control.Wrap = true;
    control.AllowLineBreaks = true;
    control.AllowMarkUp = true;
    control.IncludeInGlobalFilter = true;
    control.FilterType = "Text";
    control.FilterMatchMode = "Contains";
    control.SecurityScope = new m.SecurityScope();
    control.Properties = {};
    return control;
  }


  formCustomizationSystemFormIdPickList(): m5core.PickListSelectionViewModel[] {
    return StaticPickList.stringArrayToPickList(["Customer", "Directory"]);
  }


  formCustomizationComponentPickList(systemFormId: "Customer" | "Directory"): m5core.PickListSelectionViewModel[] {
    if (systemFormId !== "Customer" && systemFormId !== "Directory") {
      return [];
    }
    let content: string[] = [];
    content.push("ContactFormPartialAddress");
    content.push("ContactFormPartialBusiness");
    content.push("ContactFormPartialImage");
    content.push("ContactFormPartialInfo");
    content.push("ContactFormPartialPersonal");
    if (Helper.equals(systemFormId, "Directory", true)) {
      content.push("ContactFormPartialSecurity");
    }
    content.push("FileManagement");
    content.push("SalesOpportunityEditor");
    content.push("Note");
    if (Helper.equals(systemFormId, "Customer", true)) {
      content.push("BillingAccountEditor");
      content.push("BillingTransactionEditor");
      content.push("PaymentTransactionEditor");
      content.push("CachePackageSignupEditor");
    }
    content.push("ContactEditor");
    content = Helper.arraySortStrings(content);
    return StaticPickList.stringArrayToPickList(content);
  }


  defaultFormCustomization(systemFormId: "Customer" | "Directory"): m5web.FormEditViewModel {

    const form = new m5web.FormEditViewModel();
    form.FormId = Helper.randomInteger(true);
    form.ExternalFormId = systemFormId;
    form.SystemFormId = systemFormId;
    form.Description = `${systemFormId} Form Customization`;
    form.Enabled = true;
    form.TableNameReference = systemFormId;
    form.ApiNameReference = systemFormId;
    form.Properties = this.defaultFormProperties(systemFormId);

    const tabSet = this.defaultFormGroup();
    tabSet.FormId = form.FormId;
    tabSet.Description = "Tabs";
    tabSet.GroupOrder = 100;
    tabSet.PresentationStyle = "S"; // S = tabSet
    tabSet.ColumnWidth = "12";
    tabSet.Groups.push(this.defaultFormGroupNavItemForComponent(tabSet.FormControlGroupId, "Info", 100, systemFormId, "ContactFormPartialInfo"));
    tabSet.Groups.push(this.defaultFormGroupNavItemForComponent(tabSet.FormControlGroupId, "Address", 200, systemFormId, "ContactFormPartialAddress"));
    tabSet.Groups.push(this.defaultFormGroupNavItemForComponent(tabSet.FormControlGroupId, "Business", 300, systemFormId, "ContactFormPartialBusiness"));
    tabSet.Groups.push(this.defaultFormGroupNavItemForComponent(tabSet.FormControlGroupId, "Personal", 400, systemFormId, "ContactFormPartialPersonal"));
    tabSet.Groups.push(this.defaultFormGroupNavItemForComponent(tabSet.FormControlGroupId, "Notes", 500, systemFormId, "Note", Constants.Modules.CRM, Constants.AccessArea.Note));
    tabSet.Groups.push(this.defaultFormGroupNavItemForComponent(tabSet.FormControlGroupId, "Files", 600, systemFormId, "FileManagement", Constants.Modules.CRM));
    tabSet.Groups.push(this.defaultFormGroupNavItemForComponent(tabSet.FormControlGroupId, "Sales Opportunities", 700, systemFormId, "SalesOpportunityEditor", Constants.Modules.CRM, Constants.AccessArea.SalesOpportunity));
    tabSet.Groups.push(this.defaultFormGroupNavItemForComponent(tabSet.FormControlGroupId, "Image", 800, systemFormId, "ContactFormPartialImage"));
    if (systemFormId === "Directory") {
      tabSet.Groups.push(this.defaultFormGroupNavItemForComponent(tabSet.FormControlGroupId, "Security", 900, systemFormId, "ContactFormPartialSecurity"));
    }
    tabSet.Groups.push(this.defaultFormGroupNavItemForComponent(tabSet.FormControlGroupId, "Contacts", 1000, systemFormId, "ContactEditor"));
    if (systemFormId === "Customer") {
      tabSet.Groups.push(this.defaultFormGroupNavItemForComponent(tabSet.FormControlGroupId, "Accounts", 1100, systemFormId, "BillingAccountEditor", Constants.Modules.Billing, Constants.AccessArea.BillingAccount));
      tabSet.Groups.push(this.defaultFormGroupNavItemForComponent(tabSet.FormControlGroupId, "Transactions", 1200, systemFormId, "BillingTransactionEditor", Constants.Modules.Billing, Constants.AccessArea.BillingTransaction));
    }
    tabSet.Groups.push(this.defaultFormGroupNavItemForComments(tabSet.FormControlGroupId, "Comments", 1300, systemFormId, "Comments"));

    form.Groups.push(tabSet);
    return form;

  }


  /**
   * This method gets a nav item (e.g. tab or menu item) form group with child group
   * of a row which has it's own child group of a single column which hosts a single
   * control of the specified component.
   * @param parentFormControlGroupId
   * @param navItemName
   * @param order
   * @param objectName
   * @param component
   * @param requiredModule
   * @returns
   */
  defaultFormGroupNavItemForComponent(parentFormControlGroupId: number,
    navItemName: string,
    order: number,
    objectName: "Customer" | "Directory",
    component: string,
    requiredModule: string = "",
    permissionArea: string = ""): m5web.FormControlGroupEditViewModel {

    const nav = this.defaultFormGroupNavItem(parentFormControlGroupId, navItemName, order, 1, requiredModule, permissionArea);
    // nav is a group with 1 child group (row) which has 1 child group (column)
    const col = nav.Groups[0].Groups[0];

    const control = this.defaultFormControl();
    control.FormControlGroupId = col.FormControlGroupId;
    control.Description = component;
    control.ObjectName = objectName;
    control.ControlType = "Component";
    control.Contents = component;
    control.ControlOrder = order;
    control.Properties = this.defaultComponentProperties(component, objectName);

    // Push our component into the column list of controls
    col.Controls.push(control);

    return nav;

  }


  /**
   * This method gets a nav item (e.g. tab or menu item) form group with child group
   * of a row which has it's own child group of a single column which hosts a single
   * control of full-width text area type suitable for things like comments.
   * @param parentFormControlGroupId
   * @param navItemName
   * @param order
   * @param objectName
   * @param propertyName
   * @param requiredModule
   * @returns
   */
  defaultFormGroupNavItemForComments(parentFormControlGroupId: number,
    navItemName: string,
    order: number,
    objectName: string,
    propertyName: string,
    requiredModule: string = "",
    permissionArea: string = ""): m5web.FormControlGroupEditViewModel {

    const nav = this.defaultFormGroupNavItem(parentFormControlGroupId, navItemName, order, 1, requiredModule, permissionArea);
    // nav is a group with 1 child group (row) which has 1 child group (column)
    const col = nav.Groups[0].Groups[0];

    const control = this.defaultFormControl();
    control.FormControlGroupId = col.FormControlGroupId;
    control.Description = propertyName;
    control.ObjectName = objectName;
    control.PropertyName = propertyName;
    control.PropertyDataType = "String";
    control.ControlType = "InputTextArea";
    control.Width = "F";
    control.Height = 15;
    control.ControlOrder = order;

    // Push our component into the column list of controls
    col.Controls.push(control);

    return nav;

  }


  /**
   * This method gets a nav item (e.g. tab or menu item) form group with child group
   * of a row which has it's own child group(s) of the specified number of columns
   * 1-3.
   * @param parentFormControlGroupId
   * @param navItemName
   * @param order
   * @param numberOfColumns
   * @param requiredModule
   * @returns
   */
  defaultFormGroupNavItem(parentFormControlGroupId: number,
    navItemName: string,
    order: number,
    numberOfColumns: number,
    requiredModule: string = "",
    permissionArea: string = ""): m5web.FormControlGroupEditViewModel {

    const nav = this.defaultFormGroup();
    nav.ParentFormControlGroupId = parentFormControlGroupId;
    nav.Description = `${navItemName} Nav Item`;
    nav.GroupOrder = order;
    nav.Label = navItemName;
    nav.PresentationStyle = "T"; // T = tab or other nav item
    if (requiredModule) {
      nav.SecurityScope.ModulesAll.push(requiredModule);
    }
    if (permissionArea) {
      const perm = new m.SecurityScopePermission();
      perm.Id = Helper.createBase36Guid();
      perm.SecurityAreaType = "TB";
      perm.SecurityArea = permissionArea;
      perm.SecurityRights = [Constants.Permission.Read];
      nav.SecurityScope.PermissionsAll.push(perm);
    }

    const row = this.defaultFormGroup();
    row.ParentFormControlGroupId = nav.FormControlGroupId;
    row.Description = `${navItemName} Row`;
    row.GroupOrder = order;
    row.PresentationStyle = "R"; // R = row

    const col1 = this.defaultFormGroup();
    col1.ParentFormControlGroupId = row.FormControlGroupId;
    col1.Description = `${navItemName} Column 1`;
    col1.GroupOrder = order;
    col1.PresentationStyle = "C"; // C = column
    if (numberOfColumns >= 3) {
      col1.ColumnWidth = "4";
    } else if (numberOfColumns === 2) {
      col1.ColumnWidth = "6";
    }

    row.Groups.push(col1);

    if (numberOfColumns >= 3) {
      const col2 = Helper.deepCopy(col1);
      col2.Description = `${navItemName} Column 2`;
      row.Groups.push(col2);
      const col3 = Helper.deepCopy(col1);
      col3.Description = `${navItemName} Column 3`;
      row.Groups.push(col3);
    } else if (numberOfColumns === 2) {
      const col2 = Helper.deepCopy(col1);
      col2.Description = `${navItemName} Column 2`;
      row.Groups.push(col2);
    }

    nav.Groups.push(row);

    return nav;

  }


  /**
   * This method gets the default form control properties for the specified component and system form id.
   * @param component
   * @param systemFormId
   * @returns
   */
  protected defaultComponentProperties(component: string, systemFormId: "Customer" | "Directory"): string[] {
    let properties: any = {};
    if (Helper.startsWith(component, "ContactFormPartial", true)) {
      if (systemFormId === "Directory") {
        properties = {
          "data": "{{data}}",
          "contactType": "D",
          "mode": "{{mode}}"
        };
      } else {
        properties = {
          "data": "{{data}}",
          "contactType": "C",
          "mode": "{{mode}}"
        };
      }
    } else if (Helper.equals(component, "FileManagement", true)) {
      properties = {
        "ownerType": "Contact",
        "ownerId": `{{${systemFormId}Id|integer}}`,
        "mode": "{{mode}}",
        "host": "component",
        "allowUpload": true
      };
    } else if (Helper.equals(component, "SalesOpportunityEditor", true)) {
      properties = {
        "contactId": `{{${systemFormId}Id|integer}}`,
        "contactName": `{{${systemFormId}Name}}`,
        "mode": "list",
        "host": "component",
        "ignoreRouteData": true
      };
    } else if (Helper.equals(component, "Note", true)) {
      properties = {
        "ownerResourceType": "Contact",
        "ownerResourceId": `{{${systemFormId}Id|integer}}`,
        "mode": "{{mode}}",
        "host": "component",
        "savePendingNoteCount": "{{saveCount}}",
        "sortOrder": "desc"
      };
    } else if (Helper.equals(component, "BillingAccountEditor", true)) {
      properties = {
        "mode": "list",
        "host": "component",
        "inputMethod": "modal",
        "ignoreRouteData": true,
        "showHeader": false,
        "contactId": `{{${systemFormId}Id|integer}}`,
        "contactName": `{{${systemFormId}Name}}`
      };
    } else if (Helper.equals(component, "BillingTransactionEditor", true)) {
      properties = {
        "mode": "list",
        "host": "component",
        "inputMethod": "modal",
        "ignoreRouteData": true,
        "showHeader": false,
        "contactId": `{{${systemFormId}Id|integer}}`,
        "includeDetails": true
      };
      // customMode = "{{transactionsCurrentYearOnly?'ThisYearOnly':''}}" >
    } else if (Helper.equals(component, "ContactEditor", true)) {
      properties = {
        "mode": "list",
        "host": "component",
        "inputMethod": "modal",
        "ignoreRouteData": true,
        "showHeader": false,
        "contactType": "T",
        "scope": {
          "ContactType": "T",
          "ParentContactId": `{{${systemFormId}Id|integer}}`,
        }
      };
    }
    return properties;
  }



  /**
   * This method gets the default form properties for the specified system form id.
   * @param systemFormId
   * @returns
   */
  defaultFormProperties(systemFormId: "Customer" | "Directory"): string[] {
    let properties: any = {};
    if (Helper.equals(systemFormId, "Directory", true)) {
      properties = {
        partial: {
          hide: {
            info: false,
            address: false,
            business: false,
            personal: false,
            notes: false,
            files: false,
            salesOpportunities: false,
            image: false,
            security: false,
            contacts: false,
            comments: false
          }
        }
      };
    } else if (Helper.equals(systemFormId, "Customer", true)) {
      properties = {
        partial: {
          hide: {
            info: false,
            address: false,
            business: false,
            personal: false,
            notes: false,
            files: false,
            salesOpportunities: false,
            image: false,
            security: false,
            contacts: false,
            accounts: false,
            transactions: false,
            comments: false
          }
        }
      };
    }
    return properties;
  }


  /**
   * This helper method builds an input object for dynamic components based on the component properties.
   * @param componentProperties
   * @param componentData
   * @param mode
   * @param saveCount
   * @returns
   */
  buildDynamicComponentInputs(componentProperties: any, componentData: any, mode: string, saveCount: number): any {

    const inputs: any = {};

    if (!componentProperties || Helper.isEmpty(componentProperties)) {
      // We default to include a data input when there where no inputs declared
      inputs.data = componentData;
      return inputs;
    }

    let inputCount: number = 0;
    for (const property in componentProperties) {
      let value: any = componentProperties[property];
      // Handle placeholders in the value being assigned
      if (Helper.isString(value)) {
        if (Helper.equals(value, "{{mode}}", true)) {
          // Special handling for {{mode}} to mean our current form mode
          value = mode;
          //console.error("{{mode}}", value);
        } else if (Helper.equals(value, "{{saveCount}}", true)) {
          // Special handling for {{saveCount}} to mean our current form saveCount
          value = saveCount;
          //console.error("{{saveCount}}", value);
        } else if (Helper.equals(value, "{{data}}", true)) {
          // Special handling for {{data}} to mean our current form data
          value = componentData;
          //console.error("{{data}}", value);
        } else if (Helper.contains(value, "{{")) {
          const isStandaloneMacro = (Helper.startsWith(value, "{{") && Helper.endsWith(value, "}}"));
          // Other {{placeholders}} hopefully is dynamic value based on the data submitted
          // like an id found in the data used by the component being displayed to know
          // what data to query, etc.
          // Look for special type conversion instructions like |integer or |number
          const isInteger: boolean = (Helper.contains(value, "|integer", true) || Helper.contains(value, "| integer", true));
          const isFloat: boolean = (Helper.contains(value, "|number", true) || Helper.contains(value, "| number", true) || Helper.contains(value, "|float", true) || Helper.contains(value, "| float", true));
          value = value.replace("|integer", "").replace("| integer", "").replace("|number", "").replace("| number", "").replace("|float", "").replace("| float", "");
          //console.error(value);
          value = Helper.stringFormat(value, componentData);
          //console.error(value);
          // If this is a number and it's a standalone macro (as opposed to embedded in another value like a filter)
          // then replace the value with the numeric equivalent of the value.
          if (isInteger && isStandaloneMacro) {
            value = parseInt(value, 10);
          } else if (isFloat && isStandaloneMacro) {
            value = parseFloat(value);
          }
        }
      } else if (Helper.isObject(value)) {
        // Do a recursive call for child objects
        value = this.buildDynamicComponentInputs(value, componentData, mode, saveCount);
      }
      inputs[property] = value;
      inputCount++;
    }

    if (inputCount === 0) {
      // We default to include a data input when there where no inputs declared
      inputs.data = componentData;
    }

    return inputs;

  }




  deleteFormGroupAndChildrenUsingFormDeletedDataObjects(form: m5web.FormEditViewModel, group: m5web.FormControlGroupEditViewModel) {
    if (!form || !group) {
      return;
    }
    Helper.prepareMetaData(form);
    // Mark this group as deleted if previously saved
    if (group.FormControlGroupId) {
      form.MetaData.DeletedDataObjects.push({ ObjectType: "FormControlGroup", ObjectId: group.FormControlGroupId });
    }
    // Mark any saved controls as deleted
    group.Controls.forEach(control => {
      if (control.FormControlId) {
        form.MetaData.DeletedDataObjects.push({ ObjectType: "FormControl", ObjectId: control.FormControlId });
      }
    });
    // Make recursive call for any child groups
    group.Groups.forEach(child => {
      this.deleteFormGroupAndChildrenUsingFormDeletedDataObjects(form, child);
    });
  }




  /**
   * This methods builds a pick list array of form groups for a form that are valid
   * for hosting controls.  Groups that are only valid for hosting child groups
   * are ignored.  This pick list is then suitable for picking a group to host a
   * control.
   * @param formModel
   * @returns An array of pick list items.
   */
  buildFormGroupPickListForControls(formModel: m5web.FormEditViewModel): m5core.PickListSelectionViewModel[] {
    const pickList: m5core.PickListSelectionViewModel[] = [];
    //console.error("ready to build group pick list", this.formModel?.Groups);
    if (!formModel) {
      // Can't do this without form
      return pickList;
    }
    // Function that we can call recursively for creating pick list item for groups that can host controls
    const findSuitableFormGroupsForHostingControls = (group: m5web.FormControlGroupEditViewModel) => {
      if (group.PresentationStyle === "B" || group.PresentationStyle === "C") {
        // Block and Columns can accept controls.  Other group types only support child groups
        let label = group.Description;
        if (!label) {
          label = this.groupTypeDescription(group);
        }
        if (pickList.some(x => Helper.equals(x.DisplayText, label, true))) {
          label += ` (${this.groupTypeDescription(group)})`;
        }
        pickList.push({ Value: group.FormControlGroupId.toString(), DisplayText: label } as unknown as m5core.PickListSelectionViewModel);
      }
      // Recursive call for child groups
      if (group.Groups && group.Groups.length > 0) {
        group.Groups.forEach(child => {
          findSuitableFormGroupsForHostingControls(child);
        });
      }
    };
    // Build pick list for groups that can host controls
    if (formModel.Groups && formModel.Groups.length > 0) {
      formModel.Groups.forEach(group => {
        findSuitableFormGroupsForHostingControls(group);
      });
    }
    return pickList;
  }


  /**
   * This method builds a pick list array of groups that are valid for hosting the
   * specified group.  Not all groups can host any child group.  For example,
   * column group can only be hosted by a row group and nav item groups can only be
   * hosted by a tab set or menu group.
   * @param formModel
   * @param formGroupModel
   * @returns An array of pick list items.
   */
  buildFormGroupPickListForGroups(formModel: m5web.FormEditViewModel, formGroupModel: m5web.FormControlGroupEditViewModel): m5core.PickListSelectionViewModel[] {
    const pickList: m5core.PickListSelectionViewModel[] = [];
    //console.error("ready to build group pick list", this.formModel?.Groups);
    if (!formModel || !formGroupModel) {
      // Can't do this without form or group
      return pickList;
    }
    // Function that adds a pick list for the specified group
    const addPickListItemForGroup = (group: m5web.FormControlGroupEditViewModel) => {
      let label = group.Description;
      if (!label) {
        label = this.groupTypeDescription(group);
      }
      if (pickList.some(x => Helper.equals(x.DisplayText, label, true))) {
        // Our label is already used in this pick list so add group type description to be part of the label
        label += ` (${this.groupTypeDescription(group)})`;
      }
      pickList.push({ Value: group.FormControlGroupId.toString(), DisplayText: label } as unknown as m5core.PickListSelectionViewModel);
    };
    // Function that we can call recursively for creating pick list item for groups that can host other groups
    const findSuitableFormGroupsForHostingControls = (group: m5web.FormControlGroupEditViewModel) => {
      if (formGroupModel.PresentationStyle === "T" && (group.PresentationStyle === "S" || group.PresentationStyle === "M")) {
        // Nav items (Tabs or Menu Items) can be hosted by tab sets or menu groups
        addPickListItemForGroup(group);
      } else if (formGroupModel.PresentationStyle === "C" && group.PresentationStyle === "R") {
        // Columns can be hosted by rows
        addPickListItemForGroup(group);
      } else if (formGroupModel.PresentationStyle === "R" && (group.PresentationStyle === "T" || group.PresentationStyle === "B")) {
        // Rows can be hosted by nav items (tabs or menu items) or blocks
        addPickListItemForGroup(group);
      } else if (formGroupModel.PresentationStyle === "A" && (group.PresentationStyle === "T" || group.PresentationStyle === "C" || group.PresentationStyle === "B")) {
        // Accordions can be hosted by nav items (tabs or menu items), columns, or blocks
        addPickListItemForGroup(group);
      } else if ((formGroupModel.PresentationStyle === "S" || formGroupModel.PresentationStyle === "M") && (group.PresentationStyle === "T" || group.PresentationStyle === "B")) {
        // Tab Sets or Menu Groups can be hosted by nav item or block
        addPickListItemForGroup(group);
      }
      // Recursive call for child groups
      if (group.Groups && group.Groups.length > 0) {
        group.Groups.forEach(child => {
          findSuitableFormGroupsForHostingControls(child);
        });
      }
    };
    // Build pick list for groups that can host other groups
    if (formModel.Groups && formModel.Groups.length > 0) {
      formModel.Groups.forEach(group => {
        findSuitableFormGroupsForHostingControls(group);
      });
    }
    return pickList;
  }


  /**
   * This method creates a unique system description of a form group by
   * concatenating the group presentation style description with the
   * group id.  This is generally used when there is no group description
   * provided for a group or when the description is not unique.
   * @param group
   * @returns A string that is a unique system description for the group.
   */
  groupTypeDescription(group: m5web.FormControlGroupEditViewModel): string {
    let description = "";
    if (group.PresentationStyle === "B") {
      description = `Block Group ${group.FormControlGroupId}`
    } else if (group.PresentationStyle === "R") {
      description = `Row Group ${group.FormControlGroupId}`;
    } else if (group.PresentationStyle === "C") {
      description = `Column Group ${group.FormControlGroupId}`;
    } else if (group.PresentationStyle === "F") {
      description = `Field Set ${group.FormControlGroupId}`;
    } else if (group.PresentationStyle === "S") {
      description = `Tab Set ${group.FormControlGroupId}`
    } else if (group.PresentationStyle === "T") {
      description = `Nav Item ${group.FormControlGroupId}`
    } else if (group.PresentationStyle === "M") {
      description = `Menu Group ${group.FormControlGroupId}`;
    } else if (group.PresentationStyle === "A") {
      description = `Accordion ${group.FormControlGroupId}`;
    } else {
      description = `Group ${group.FormControlGroupId}`;
    }
    return description;
  }



  evaluateGroupVisibility(groups: m5web.FormControlGroupEditViewModel[],
    data: any,
    currentGroupType: "G" | "S" | "C",
    currentGroupScope: "A" | "E",
    designMode: boolean): void {

    if (!groups || groups.length === 0) {
      return;
    }

    // every is like foreach where return false = break and return true = continue (required or will break)
    groups.every(group => {

      // We set a Visible property to true or false in group.MetaData.Properties.Visible.  This is better
      // than calling a function to check visibility in the html markup because that function will execute
      // on every check cycle instead of this method where we only need to reevaluate when one of our
      // input values change.
      Helper.prepareMetaData(group);
      // Default is visible until we find it's not visible
      group.MetaData.Properties.Visible = true;
      group.MetaData.Properties.VisibilityReason = ``;

      // Process any child groups and controls first so we can later bow continue on the
      // next item in the collection and children have been processed already.
      if (group.Groups && group.Groups.length > 0) {
        this.evaluateGroupVisibility(group.Groups, data, currentGroupType, currentGroupScope, designMode);
      }
      if (group.Controls && group.Controls.length > 0) {
        this.evaluateControlVisibility(group.Controls, data, designMode);
      }

      if (designMode) {
        // When in design mode we want to see all groups so we don't need to check all the below logic
        return true; // continue
      }

      // Make sure our group type is the expected group type or "All"
      if (!Helper.equals(group.GroupType, currentGroupType, true) && !Helper.equals(group.GroupType, "A", true)) {
        group.MetaData.Properties.Visible = false;
        group.MetaData.Properties.VisibilityReason = `Group type "${group.GroupType}" doesn't match the current group type of ${currentGroupType} and is not "A" (all).`;
        return true; // continue
      }

      // Make sure our group scope is the expected group scope or "Both"
      if (!Helper.equals(group.GroupScope, currentGroupScope, true) && !Helper.equals(group.GroupScope, "B", true)) {
        group.MetaData.Properties.Visible = false;
        group.MetaData.Properties.VisibilityReason = `Group type "${group.GroupScope}" doesn't match the current group scope of ${currentGroupScope} and is not "B" (both).`;
        return true; // continue
      }

      // If we have a display when macro expression let's evaluate it to figure out if we're going to display the control or not
      if (group.DisplayWhenExpression && Helper.contains(group.DisplayWhenExpression, "{{") && data) {
        const result = HandlebarsHelpers.handlebarsTemplateResolve(group.DisplayWhenExpression, data, `FormControlGroupId-${group.FormControlGroupId}-DisplayWhenExpression`);
        if (result && !Helper.equals(result, "false", true)) {
          return true; // continue
        } else {
          group.MetaData.Properties.Visible = false;
          group.MetaData.Properties.VisibilityReason = `Group display when expression evaluated to false.`;
          return true; // continue
        }
      }

      // If we have a security scope define then check it
      if (group.SecurityScope) {
        const result = this.appService.security.checkSecurityScope(group.SecurityScope, `Form Group ${group.FormControlGroupId}`,
          this.appService.user, this.appService.appInfoOrDefault);
        if (!result.passed) {
          group.MetaData.Properties.Visible = false;
          group.MetaData.Properties.VisibilityReason = result.message;
          return true; // continue
        }
      }

      // Next group in the list
      return true; // continue

    });

  }


  evaluateControlVisibility(controls: m5web.FormControlEditViewModel[],
    data: any,
    designMode: boolean): void {

    if (!controls || controls.length === 0) {
      return;
    }

    // every is like foreach where return false = break and return true = continue (required or will break)
    controls.every(control => {

      // We set a Visible property to true or false in control.MetaData.Properties.Visible.  This is better
      // than calling a function to check visibility in the html markup because that function will execute
      // on every check cycle instead of this method where we only need to reevaluate when one of our
      // input values change.
      Helper.prepareMetaData(control);
      // Default is visible until we find it's not visible
      control.MetaData.Properties.Visible = true;
      control.MetaData.Properties.VisibilityReason = ``;

      if (designMode) {
        // When in design mode we want to see all groups so we don't need to check all the below logic
        return true; // continue
      }

      // Input controls that are not static must have an object name and property name before we can consider showing them
      if (Helper.startsWith(control.ControlType, "Input", true) && !Helper.equals(control.ControlType, "InputStatic", true)) {
        if (!control.ObjectName) {
          control.MetaData.Properties.Visible = false;
          control.MetaData.Properties.VisibilityReason = `Input control is missing an object name which is required.`;
          return true; // continue
        } else if (!control.PropertyName) {
          control.MetaData.Properties.Visible = false;
          control.MetaData.Properties.VisibilityReason = `Input control is missing a property name which is required.`;
          return true; // continue
        }
      }

      // If we have a display when macro expression let's evaluate it to figure out if we're going to display the control or not
      if (control.DisplayWhenExpression && Helper.contains(control.DisplayWhenExpression, "{{")) {
        const result = HandlebarsHelpers.handlebarsTemplateResolve(control.DisplayWhenExpression, data, `FormControlId-${control.FormControlId}-DisplayWhenExpression`);
        if (result && !Helper.equals(result, "false", true)) {
          return true; // continue
        } else {
          control.MetaData.Properties.Visible = false;
          control.MetaData.Properties.VisibilityReason = `Control display when expression evaluated to false.`;
          return true; // continue
        }
      }

      // If we have a security scope define then check it
      if (control.SecurityScope) {
        const result = this.appService.security.checkSecurityScope(control.SecurityScope, `Form Control ${control.FormControlId}`,
          this.appService.user, this.appService.appInfoOrDefault);
        if (!result.passed) {
          control.MetaData.Properties.Visible = false;
          control.MetaData.Properties.VisibilityReason = result.message;
          return true; // continue
        }
      }

      // Next control in the list
      return true; // continue

    });

  }



}
