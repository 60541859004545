<ib-modal-common-title [options]="options"
                       [modal]="modal"
                       [cancelData]="null"
                       (cancel)="options.cancel">
</ib-modal-common-title>
<div class="modal-body">
  <ul ngbNav #nav='ngbNav' class="{{isMobile ? 'nav-pills' : 'nav-tabs'}}" [destroyOnHide]="false" (navChange)="onNavChange($event)">
    <li title="Search" ngbNavItem="Search" *ngIf="showSearch">
      <a ngbNavLink>Search</a>
      <ng-template ngbNavContent>
        <ib-standard-table [options]="tableOptions"
                           [reloadCount]="tableReloadCount"
                           [headerData]="selectedAssets"
                           (rowSelect)="onRowSelected($event)">
        </ib-standard-table>
      </ng-template>
    </li>
    <!--<li title="Related" id="Related" *ngIf="showRelated">
      <ng-template ngbNavContent>
        TODO
      </ng-template>
    </li>-->
    <li title="Preview" ngbNavItem="Preview" *ngIf="showPreview && selectedAsset">
      <a ngbNavLink>Preview</a>
      <ng-template ngbNavContent>
        <ng-container [ngSwitch]="previewRenderMethod()">
          <span *ngSwitchCase="'html'" [innerHTML]="selectedAsset.AssetText | safe : 'html'"></span>
          <span *ngSwitchCase="'text'">{{selectedAsset.AssetText | newLines }}</span>
          <img *ngSwitchCase="'image'" width="100%" [src]="buildFileViewUrl( selectedAsset.AssetId , selectedAsset.FriendlyName , selectedAsset.FileType )" />
          <iframe *ngSwitchCase="'iframe'" width="100%" height="{{frameHeight}}px" [src]="buildFileViewUrl( selectedAsset.AssetId , selectedAsset.FriendlyName , selectedAsset.FileType )"></iframe>
          <i *ngSwitchCase="'icon'" class="far fa-{{assetIcon(selectedAsset.FileType, selectedAsset.AssetType)}} fa-5x fa-fw"></i>
          <i *ngSwitchDefault class="far fa-{{assetIcon(selectedAsset.FileType, selectedAsset.AssetType)}} fa-5x fa-fw"></i>
        </ng-container>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<ib-modal-common-buttons [options]="options"
                         [modal]="modal"
                         [okData]="data"
                         [cancelData]="null"
                         (ok)="options.ok"
                         (cancel)="options.cancel">
</ib-modal-common-buttons>
