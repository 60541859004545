<div class="{{inputWrapperClass}} {{wrapperClasses}} {{outerClass}}">
  <div class="{{inputGroupClass}}">
    <span *ngIf="prefixText || prefixIcon"
          class="input-group-text"
          ngbTooltip="{{prefixTooltip | translate}}"
          [ngClass]="{'clickable': isPrefixClickable()}"
          (click)="firePrefixClick($event)">
      {{prefixText | translate}}
      <ib-icon icon="{{prefixIcon}}"></ib-icon>
    </span>
    <select class="form-control {{inputSize}} {{controlClass}} form-select"
            id="{{inputControlId}}"
            name="{{name}}"
            ngbTooltip="{{tooltip | translate}}"
            [disabled]="( disabled || readonly ) ? 'disabled' : null"
            [required]="required ? 'required' : null"
            (focus)="fireFocus($event, inputControl)"
            (blur)="fireBlur($event, inputControl)"
            (keyup)="fireKeyUp($event, inputControl)"
            (change)="fireChange($event, inputControl)"
            [(ngModel)]="value"
            [ngModelOptions]="{standalone: standalone}"
            #inputControl="ngModel">
      <ng-container *ngIf="optionsValueIsInteger">
        <option *ngIf="optionsIncludeNone" [ngValue]="0">{{optionsNoneLabel | translate}}</option>
        <ng-container *ngIf="!pickListHasGroups">
          <option *ngFor="let option of pickList" [ngValue]="option.Value | integer">
            {{option.DisplayText}}
          </option>
        </ng-container>
        <ng-container *ngIf="pickListHasGroups">
          <optgroup *ngFor="let group of pickList | distinctPropertyValues : 'GroupText'" label="{{group}}">
            <option *ngFor="let option of pickList | filterPropertyValue : 'GroupText' : group" [ngValue]="option.Value | integer">
              {{option.DisplayText}}
            </option>
          </optgroup>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!optionsValueIsInteger">
        <option *ngIf="optionsIncludeNone" [ngValue]="''">{{optionsNoneLabel | translate}}</option>
        <ng-container *ngIf="!pickListHasGroups">
          <option *ngFor="let option of pickList" [ngValue]="option.Value">
            {{option.DisplayText}}
          </option>
        </ng-container>
        <ng-container *ngIf="pickListHasGroups">
          <optgroup *ngFor="let group of pickList | distinctPropertyValues : 'GroupText'" label="{{group}}">
            <option *ngFor="let option of pickList | filterPropertyValue : 'GroupText' : group" [ngValue]="option.Value">
              {{option.DisplayText}}
            </option>
          </optgroup>
        </ng-container>
      </ng-container>
    </select>
    <span *ngIf="suffixShow && ( suffixText || suffixIcon )"
          class="input-group-text"
          ngbTooltip="{{suffixTooltip | translate}}"
          [ngClass]="{'clickable': isSuffixClickable()}"
          (click)="fireSuffixClick($event)">
      {{suffixText | translate}}
      <ib-icon icon="{{suffixIcon}}"></ib-icon>
    </span>
    <span *ngIf="suffixShow2 && ( suffixText2 || suffixIcon2 )"
          class="input-group-text  {{suffixClass2}}"
          ngbTooltip="{{( suffixTooltip2 | translate ) || suffixTooltip2}}"
          [ngClass]="{'clickable': isSuffixClickable2()}"
          (click)="fireSuffixClick2($event)">
      {{( suffixText2 | translate ) || suffixText2}}
      <ib-icon icon="{{suffixIcon2}}"></ib-icon>
    </span>
  </div>
  <div *ngIf="inputControl.invalid && (inputControl.dirty || inputControl.touched)">
    <small *ngFor="let errorMessage of errorMessages" class="form-text input-invalid input-error-message">
      {{errorMessage | translate:inputInformationValues}}
    </small>
  </div>
</div>
