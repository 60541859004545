import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';

@Component({
  selector: 'ib-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    protected appService: AppService,
    protected router: Router) { }

  ngOnInit() {
    // Redirect to whatever is configured as our home page
    this.appService.redirectToHome();
  }

}
