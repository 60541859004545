import { Component, OnInit } from '@angular/core';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiProperties, ApiOperationType, IApiResponseWrapper, IApiResponseWrapperTyped, ApiCall } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { ApiModuleSecurity } from 'projects/core-lib/src/lib/api/Api.Module.Security';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';

@Component({
  selector: 'ib-password-reset-link-request',
  templateUrl: './password-reset-link-request.component.html',
  styleUrls: ['./password-reset-link-request.component.css']
})
export class PasswordResetLinkRequestComponent extends BaseComponent implements OnInit {

  public data: m5sec.PasswordResetLinkRequestViewModel;
  public apiProperties: ApiProperties;
  public apiCall: ApiCall;
  public errorMessage: string = "";
  public responseMessage: string = "";

  constructor(
    protected appService: AppService,
    protected apiService: ApiService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.data = new m5sec.PasswordResetLinkRequestViewModel();
    this.data.ValidMinutes = 12000;
    this.data.ValidCount = 1000;
    this.apiProperties = ApiModuleSecurity.SecurityPasswordReset();
    this.apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Call);
  }

  sendResetPasswordLink($event) {
    this.responseMessage = "";
    this.errorMessage = "";
    if (!this.data.Login) {
      this.errorMessage = "A login name is required for resetting your password.";
      return;
    }
    this.data.BaseUrl = `${window.location.protocol}//${window.location.host}/link/`;
    this.apiService.call(this.apiCall, this.data).subscribe((response: IApiResponseWrapper) => {
      // ResultCode 1101 is not found error and we don't want to reveal if a login exists in the system or not
      if (response.Data.Success || response.Data.ResultCode === 1101) {
        this.responseMessage = "If the login name you provided is found you will receive an email that contains a link " +
          "for resetting your password.  If you don't receive this email within an hour, please check your junk or spam folder.";
      } else {
        this.errorMessage = "There was a failure while trying to send you a password reset link.  Please contact your system " +
          "administrator or technical support for assistance.";
      }
    });
  }


}
