declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";
import { ErrorHandler, EventEmitter, Inject, Injectable, InjectionToken, OnDestroy, Optional } from '@angular/core';
import { fromEvent, Observable, Subscription, timer } from 'rxjs';
import { debounceTime, delay, retryWhen, startWith, switchMap, tap, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Location } from "@angular/common";
import { BaseService } from './base.service';
import { Helper, Log } from '../helpers/helper';
import { GlobalErrorHandler } from './global-error-handler';
import { AppAnalyticsService } from './app-analytics.service';
import { AppCacheService } from './app-cache.service';

// Inspired by https://github.com/ultrasonicsoft/ng-connection-service but uses /assets/app.json to know things like current app version
@Injectable({
  providedIn: 'root'
})
export class AppStatusService extends BaseService implements OnDestroy {

  public enableHeartbeat: boolean = true;
  public heartbeatUrl: string = "/assets/app.json";
  public heartbeatUrlCacheBusting: boolean = true;
  public heartbeatInterval: number = 90_000; // 90 sec is the default but can be changed via config.js
  public heartbeatRetryInterval: number = 10_000; // 10 sec
  public reportInternetStatus: boolean = true;

  protected stateChangeEventEmitter = new EventEmitter<AppState>();

  public currentState: AppState = {
    hasInternetAccess: true,
    hasNetworkConnection: window.navigator.onLine,
    versionRunning: "",
    versionOnServer: "",
    needsReload: false,
    info: null
  };
  protected offlineSubscription: Subscription;
  protected onlineSubscription: Subscription;
  protected httpSubscription: Subscription;


  constructor(
    protected error: ErrorHandler,
    protected analytics: AppAnalyticsService,
    protected cache: AppCacheService,
    protected http: HttpClient,
    protected location: Location) {

    super();

    // Handle possible base href
    if (!Helper.startsWith(this.heartbeatUrl, "http", true)) {
      // prepareExternalUrl will insert base href (if any)
      this.heartbeatUrl = this.location.prepareExternalUrl(this.heartbeatUrl);
    }

    // See if we have a config to control the number of seconds in our heartbeat interval.
    // 0 means heartbeat check is disabled.
    if (Helper.isUndefinedOrNull(AppConfig?.heartbeatSeconds)) {
      // No interval defined so keep the default interval
    } else if (AppConfig.heartbeatSeconds === 0) {
      // We use heartbeat to get app version information so we don't really disable when the interval is set to 0
      // instead we do a longer heartbeat interval and flag that we are not reporting internet status based on
      // the results of a heartbeat check.
      this.reportInternetStatus = false;
      this.heartbeatInterval = 180_000; // Long heartbeat
      this.heartbeatRetryInterval = 90_000; // Long retry
      Log.warningMessage("Config has heartbeat seconds set to 0 so heartbeat result will not be used to report internet status.");
    } else if (AppConfig.heartbeatSeconds < 30) {
      // We don't want to do this more frequently than every 30 seconds
      this.heartbeatInterval = 30_000; // 30 seconds
      Log.warningMessage(`Config has heartbeat seconds set to ${AppConfig.heartbeatSeconds} but we will be using our minimum interval of 30 seconds.`);
    } else {
      this.heartbeatInterval = (AppConfig.heartbeatSeconds * 1000); // Convert seconds to milliseconds
    }

    this.checkNetworkState();
    this.checkInternetState();

  }


  protected checkInternetState() {

    if (this.httpSubscription) {
      this.httpSubscription.unsubscribe();
    }

    if (this.enableHeartbeat) {
      this.httpSubscription = timer(0, this.heartbeatInterval)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          switchMap(() => this.http["get"](this.heartbeatUrl + `?nocache=${(new Date()).getTime()}`, { responseType: 'text' })),
          retryWhen(errors =>
            errors.pipe(
              // log error message
              tap(val => {
                //console.error('App Status Heartbeat Http Error:', val);
                if (this.reportInternetStatus) {
                  this.currentState.hasInternetAccess = false;
                } else {
                  // If we're not reporting the internet status then we always say we have access
                  this.currentState.hasInternetAccess = true;
                }
                this.emitEvent();
              }),
              // restart after X seconds
              delay(this.heartbeatRetryInterval)
            )
          )
        )
        .subscribe(result => {
          // Successful result means we have internet access
          this.currentState.hasInternetAccess = true;
          // Save our app.json payload
          this.currentState.info = JSON.parse(result);
          if (this.currentState.info) {
            // If we don't have a version that we're running then this is it since this is first load
            if (!this.currentState.versionRunning) {
              this.currentState.versionRunning = this.currentState.info.version;
              (this.error as GlobalErrorHandler).setVersion(this.currentState.versionRunning);
              this.analytics.setVersion(this.currentState.versionRunning);
              this.cache.versionCheck(this.currentState.versionRunning);
            }
            // Note the version currently on the server
            this.currentState.versionOnServer = this.currentState.info.version;
            // Now if the version we're currently running doesn't match what is on the server flag that we need to reload the app
            this.currentState.needsReload = (this.currentState.versionRunning !== this.currentState.versionOnServer);
            //console.error("heartbeat result", this.currentState);
            this.emitEvent();
          } else {
            // TMI console.error("current state info is undefined when parsed from ", result);
          }
        });
    } else {
      if (this.reportInternetStatus) {
        this.currentState.hasInternetAccess = false;
      } else {
        // If we're not reporting the internet status then we always say we have access
        this.currentState.hasInternetAccess = true;
      }
      this.emitEvent();
    }
  }


  protected checkNetworkState() {
    this.onlineSubscription = fromEvent(window, 'online').pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentState.hasNetworkConnection = true;
      this.checkInternetState();
      this.emitEvent();
    });
    this.offlineSubscription = fromEvent(window, 'offline').pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentState.hasNetworkConnection = false;
      this.checkInternetState();
      this.emitEvent();
    });
  }

  protected emitEvent() {
    this.stateChangeEventEmitter.emit(this.currentState);
  }


  /**
   * Monitor Network & Internet connection status by subscribing to this observer. If you set "reportCurrentState" to "false" then
   * function will not report current status of the connections when initially subscribed.
   * @param reportCurrentState Report current state when initial subscription. Default is "true"
   */
  monitor(reportCurrentState = true): Observable<AppState> {
    return reportCurrentState ?
      this.stateChangeEventEmitter.pipe(
        debounceTime(300),
        startWith(this.currentState),
      )
      :
      this.stateChangeEventEmitter.pipe(
        debounceTime(300)
      );
  }


}


/**
 * Instance of this interface is used to report current app status.
 */
export interface AppState {

  /**
   * "True" if browser has network connection. Determined by Window objects "online" / "offline" events.
   */
  hasNetworkConnection: boolean;

  /**
   * "True" if browser has Internet access. Determined by heartbeat system which periodically makes request to heartbeat Url.
   */
  hasInternetAccess: boolean;

  /**
   * Version of app currently running in browser.
   */
  versionRunning: string;

  /*
   * Version of app currently found on server.
   */
  versionOnServer: string;

  /*
   * "True" if browser needs to reload page.  This typically happens when versionRunning != versionOnServer meaning a new
   * version has been deployed but is not what is currently running in the browser.  New versions have all new js files
   * and lazy loading of modules will fail with errors similar to "Uncaught (in promise): Error: Loading chunk 3 failed"
   * etc. since those js files are all different with a new version.  The solution is to reload the page and this flag
   * indicates if that is needed.
   */
  needsReload: boolean;

  /*
   * The most recently received app.json payload from the server.
   */
  info: AppInfo;

}


/**
 * The app.json format.
 */
export interface AppInfo {
  name: string;
  version: string;
  platform: string;
  debug: boolean;
}
