<div class="clearfix"></div>
<div class="modal-footer">
  <ng-container *ngIf="options.customButtons && options.customButtons.length > 0">
    <ib-button *ngFor="let button of options.customButtons; trackBy: trackByIndex"
               label="{{button.label}}"
               icon="{{button.icon}}"
               color="{{button.contextColor}}"
               (click)="fireCustom($event, button)">
    </ib-button>
  </ng-container>
  <ng-container *ngIf="!options.customButtons || options.customButtons.length === 0">
    <ib-button *ngIf="options.okButtonText || options.okButtonIcon"
               name="OkButton"
               label="{{options.okButtonText}}"
               icon="{{options.okButtonIcon}}"
               color="{{options.okButtonContextColor}}"
               (click)="fireOk($event)">
    </ib-button>
    <ib-button *ngIf="options.cancelButtonText || options.cancelButtonIcon"
               name="CancelButton"
               label="{{options.cancelButtonText}}"
               icon="{{options.cancelButtonIcon}}"
               color="{{options.cancelButtonContextColor}}"
               (click)="fireCancel($event)">
    </ib-button>
  </ng-container>
</div>
