import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiProperties, ApiOperationType, IApiResponseWrapper, IApiResponseWrapperTyped, ApiCall, Query } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { ApiModuleSecurity } from 'projects/core-lib/src/lib/api/Api.Module.Security';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { SecurityPolicyItemStatus, ContactService } from 'projects/core-lib/src/lib/services/contact.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ib-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent extends BaseComponent implements OnInit {

  @Input() contact: m5.ContactEditViewModel = null;
  @Input() includeReturnToLoginLink: boolean = true;
  @Input() reason: "reset" | "change" | "expired" = "reset";

  infoMessage: string = "";

  public data: m5sec.PasswordChangeRequest;
  public apiProperties: ApiProperties;
  public apiCall: ApiCall;
  public errorMessage: string = "";
  public responseMessage: string = "";
  public passwordChanged: boolean = false;

  public policy: m5sec.SecurityPolicyViewModel = null;

  constructor(
    protected appService: AppService,
    protected apiService: ApiService,
    protected contactService: ContactService,
    protected route: ActivatedRoute) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();

    let routeBasedReason = this.route.snapshot.data["reason"];
    if (routeBasedReason) {
      if (routeBasedReason === "reset" || routeBasedReason === "change" || routeBasedReason === "expired") {
        this.reason = routeBasedReason;
        this.setInfoMessage();
      } else {
        Log.errorMessage(`Route data had reason set to '${routeBasedReason}' but valid values are reset, change, or expired.`);
      }
    }

    this.data = new m5sec.PasswordChangeRequest();
    this.data.ForcedReset = (this.reason === "reset");

    this.apiProperties = ApiModuleSecurity.SecurityPasswordChange();
    this.apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Call);

    // Use system contact type for this security policy since we don't know the user type in this scenario
    this.contactService.getSecurityPolicy(Constants.ContactType.System).pipe(takeUntil(this.ngUnsubscribe)).subscribe((securityPolicy: m5sec.SecurityPolicyViewModel) => {
      this.policy = securityPolicy;
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.setInfoMessage();
  }

  setInfoMessage() {
    if (this.reason === "expired") {
      this.infoMessage = "Your password has expired and you must change it now.";
    } else {
      this.infoMessage = "";
    }
  }


  resetPassword($event) {
    this.errorMessage = "";
    this.responseMessage = "";
    if (!this.data.Login) {
      this.errorMessage = "A login name is required for resetting your password.";
      return;
    }
    if (!this.data.NewPassword) {
      this.errorMessage = "The new password is required.";
      return;
    }
    if (!this.data.NewPasswordConfirmed) {
      this.errorMessage = "The confirmation of the new password is required.";
      return;
    }
    if (!Helper.equals(this.data.NewPassword, this.data.NewPasswordConfirmed)) {
      this.errorMessage = "The new password and confirmation of the new password do not match.";
      return;
    }
    if (this.reason !== "reset" && Helper.equals(this.data.CurrentPassword, this.data.NewPassword)) {
      this.errorMessage = "The new password cannot be the same as the current password.";
      return;
    }
    let status: SecurityPolicyItemStatus[] = [];
    if (this.contact) {
      status = this.contactService.getSecurityPolicyStatus(this.policy, true, this.data.Login,
        this.data.NewPassword, this.data.NewPasswordConfirmed, this.contact.ContactName, this.contact.FirstName, this.contact.LastName,
        [this.contact.BirthDate, this.contact.AnniversaryDate]);
    } else {
      status = this.contactService.getSecurityPolicyStatus(this.policy, true, this.data.Login,
        this.data.NewPassword, this.data.NewPasswordConfirmed, "", "", "", []);
    }
    const errors: SecurityPolicyItemStatus[] = status.filter(x => !x.Valid);
    if (errors && errors.length > 0) {
      this.errorMessage = "";
      errors.forEach(error => {
        this.errorMessage += `<li>${Helper.stringFormat(error.Message, error.Variables)}</li>\n`;
      });
      this.errorMessage = `<strong>Password Error:</strong>\n<ul>\n${this.errorMessage}</ul>\n`;
      return;
    }
    // We set this when we set up data but in case it changed via input property or route data let's update it
    this.data.ForcedReset = (this.reason === "reset");

    // We don't want it to redirect to login if there is an auth error, because that makes the user
    // think their password was changed successfully.
    this.apiCall.redirectToLoginOnAuthenticationErrors = false;

    this.apiService.call(this.apiCall, this.data).subscribe((response: IApiResponseWrapper) => {
      if (response.Data.Success) {
        this.responseMessage = "Your password has been changed.";
        this.passwordChanged = true;
      } else {
        this.errorMessage = "Password change was unsuccessful. Please contact support for help.";
      }
    });
  }


}
