import { ValidationErrors } from "@angular/forms";

export class InputStatusChangeModel {

  public id: string;
  public name: string;
  public label: string;

  public valid: boolean;
  public invalid: boolean;

  public pristine: boolean;
  public dirty: boolean;

  public touched: boolean;
  public untouched: boolean;

  public status: string;
  public errors: ValidationErrors;

}
