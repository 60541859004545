<ib-modal-common-title [options]="options"
                       [modal]="modal"
                       [cancelData]="false"
                       (cancel)="options.cancel">
</ib-modal-common-title>
<div class="modal-body">
  <span [innerHtml]="options.message | safe: 'html'"></span>
</div>
<ib-modal-common-buttons [options]="options"
                         [modal]="modal"
                         [okData]="true"
                         [cancelData]="false"
                         (ok)="options.ok"
                         (cancel)="options.cancel">
</ib-modal-common-buttons>
