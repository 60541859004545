<div class="row"
     style="padding-top:20px;">
  <div class="{{Constants.Layout.percent25Offset}} {{Constants.Layout.split2column}}">
    <div class="card">
      <h5 class="card-header">{{title}}</h5>
      <div class="card-body">
        <p>
          To continue with your login request we need to verify your identity.
          On the next screen you must enter an authentication code which can
          be sent to one of the contact options on your account.
        </p>
        <p>
          <strong>Select how you would like to receive this code:</strong>
        </p>
        <ib-input-radio name="MFA Option"
                        label=""
                        [tight]="true"
                        [(ngModel)]="selectedOption"
                        [options]="options"></ib-input-radio>
        <ib-input-wrapper label="">
          <ib-button color="success"
                     label="Continue"
                     [disabled]="!selectedOption"
                     (click)="next($event)"></ib-button>
        </ib-input-wrapper>
        <!-- moving registration to inside app post MFA -->
        <!-- <ib-input-wrapper label="" *ngIf="user.MultiFactorAuthenticationSupportsAppTotp">
          <div (click)="registerApp()" class="text-primary clickable underline-on-hover"><i class="fas fa-mobile-alt"></i>&nbsp;Register an authenticator application</div>
        </ib-input-wrapper> -->
        <div *ngIf="errorMessage"
             class="alert alert-danger"
             role="alert">
          {{errorMessage}}
        </div>
      </div>
    </div>
  </div>
</div>
