import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { SafePipe } from 'projects/core-lib/src/lib/pipes/safe.pipe';
import { AlertItem } from './alert-manager';
import { ModalCommonOptions } from '../modal/modal-common-options';

@Component({
  selector: 'ib-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  public get alerts(): AlertItem[] {
    return this.appService.alertManager.alerts;
  }

  constructor(protected appService: AppService) { }

  ngOnInit() {
  }

  close(id: string) {
    this.appService.alertManager.close(id);
  }

}
