declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";
import { Component, OnInit } from '@angular/core';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiProperties, ApiOperationType, IApiResponseWrapper, IApiResponseWrapperTyped, ApiCall } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { ApiModuleSecurity } from 'projects/core-lib/src/lib/api/Api.Module.Security';
import { BaseComponent } from '../../../../../core-lib/src/lib/helpers/base-component';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '../../../../../core-lib/src/lib/helpers/helper';
import { takeUntil } from "rxjs/operators";
import { Observable, Subject, Subscription, Observer, of, BehaviorSubject, AsyncSubject, timer, ReplaySubject } from 'rxjs';

@Component({
  selector: 'ib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent implements OnInit {

  public data: m5sec.LoginRequest;
  public apiProperties: ApiProperties;
  public apiCall: ApiCall;
  public errorMessage: string = "";
  public returnUrl: string = "";

  /**
   * Normally there is a sign up link found on the login page.  If that should be disabled set this
   * value to true which will hide the sign up option.
   */
  get showSignUpOption(): boolean {
    if (AppConfig?.loginOptions?.disableSignUpOption) {
      return false;
    }
    // Default is that we do want to show the sign up option
    return true;
  }

  /**
   * Html that should be injected above the sign in option.
   */
  get htmlSignInAbove(): string {
    if (this.isMobile) {
      return AppConfig?.loginOptions?.htmlSignInAboveMobile;
    }
    return AppConfig?.loginOptions?.htmlSignInAbove;
  }

  /**
   * Html that should be injected below the sign in option.
   */
  get htmlSignInBelow(): string {
    if (this.isMobile) {
      return AppConfig?.loginOptions?.htmlSignInBelowMobile;
    }
    return AppConfig?.loginOptions?.htmlSignInBelow;
  }

  /**
   * Html that should be injected at the top of the sign in page.
   */
  get htmlSignInPageTop(): string {
    if (this.isMobile) {
      return AppConfig?.loginOptions?.htmlSignInPageTopMobile;
    }
    return AppConfig?.loginOptions?.htmlSignInPageTop;
  }

  /**
   * Html that should be injected at the bottom of the sign in page.
   */
  get htmlSignInPageBottom(): string {
    if (this.isMobile) {
      return AppConfig?.loginOptions?.htmlSignInPageBottomMobile;
    }
    return AppConfig?.loginOptions?.htmlSignInPageBottom;
  }

  /**
   * Html that should be injected in the page for the powered by image and link.
   */
  get htmlPoweredBy(): string {
    if (this.isMobile) {
      return AppConfig?.loginOptions?.htmlPoweredByMobile;
    }
    return AppConfig?.loginOptions?.htmlPoweredBy;
  }


  constructor(
    protected appService: AppService,
    protected apiService: ApiService,
    protected route: ActivatedRoute,
    protected router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.data = new m5sec.LoginRequest();
    this.apiProperties = ApiModuleSecurity.SecurityLogin();
    this.apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Call);
    // Clear any alerts that exist as they're probably auth failure or login required alerts which aren't needed on the login screen
    // TODO do selective clearing
    this.appService.alertManager.clear();
    // See if we have a return url to redirect to after successful login
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"];
    if (Helper.contains(this.returnUrl, "/login", true)) {
      this.returnUrl = "";
    }
    this.data.RememberMe = this.route.snapshot.queryParams["rememberMe"] || false;
    // Our login component clears alert messages so use session storage for a sticky login error
    const error: string = Helper.sessionStorageGet(Constants.LocalStorage.LoginErrorMessage, "");
    if (error) {
      Helper.sessionStorageDelete(Constants.LocalStorage.LoginErrorMessage);
      this.errorMessage = error;
    }
    // If we're here because of an explicit logout action then we want to dump any in memory cache
    // by reloading the page but we need to do so without keeping the ?action=logout query string
    // or we'll get stuck in an endless reload page loop
    const action = this.route.snapshot.queryParams["action"];
    if (action && Helper.equals(action, "logout", true)) {
      const urlTree = this.router.createUrlTree(["/", "login"]);
      window.location.href = urlTree.toString();
    }
  }

  login($event) {
    this.errorMessage = "";
    this.apiService.call(this.apiCall, this.data).subscribe((response: IApiResponseWrapperTyped<m5sec.AuthenticatedUserViewModel>) => {
      if (response.Data.Success) {
        if (response.Data.Data.Token) {

          // Need to subscribe so we know when userSet is done with wizard routing, or if it didn't happen
          // so we can allow/disallow routing here as desired.
          this.appService.userSet(response.Data.Data, this.data.RememberMe).pipe(takeUntil(this.ngUnsubscribe)).subscribe(ready => {
            //console.log('1234 ready?: ', ready);

            if (ready) {
              // At this point, any routing in userSet is completed, so it's safe to check location.href to see if we
              // are on a wizard. If so, we shouldn't let it do the other routing.
              if (!Helper.contains(location.href, "quick-start")) {
                if (this.returnUrl) {
                  // Go to the page that we were on when we got redirected to login.
                  // It's tempting in this situation to want to manipulate the history
                  // so the user hitting a form back button goes to table view instead
                  // of this login page but history cannot be manipulated leaving the
                  // best option to be doing window.location.replace() as a hack but
                  // that doesn't help with multi-step logins (e.g. MFA) so we'll just
                  // live with the fact that "back" button does browser back even if
                  // that's not preferred.
                  this.router.navigateByUrl(this.returnUrl);
                } else {
                  // Go to home page
                  this.appService.redirectToHome();
                }
              }
            }
          });
        } else {
          // Erase the token when login fails
          this.appService.userClear();
          this.errorMessage = "Login failed.";
        }
      } else if (response.Data.ResultCode === m.StandardResultCode.PasswordExpired) {
        this.router.navigate(["/", "login", "password-expired"]);
      } else if (response.Data.ResultCode === m.StandardResultCode.AccessRequiresMultiFactorAuthentication ||
        response.Data.ResultCode === m.StandardResultCode.AccessRequiresMultiFactorAuthenticationForNewPublicIpAddress ||
        response.Data.ResultCode === m.StandardResultCode.AccessRequiresMultiFactorAuthenticationForNewDeviceId) {
        // Save a trimmed down user auth object with our result code to a session object for MFA components to utilize
        const pending: any = response.Data.Data;
        pending.LoginResultCode = response.Data.ResultCode;
        delete pending.Settings;
        delete pending.Groups;
        delete pending.Roles;
        delete pending.Permissions;
        Helper.sessionStorageSaveObject(Constants.LocalStorage.UserObjectPending, pending);
        const params: any = {};
        if (this.returnUrl && !Helper.contains(this.returnUrl, "/login", true)) {
          params.returnUrl = this.returnUrl;
        }
        if (this.data.RememberMe) {
          params.rememberMe = this.data.RememberMe;
        }
        this.router.navigate(["/", "login", "mfa-step-1"], { queryParams: params });
      } else {
        // Erase the token when login fails
        this.appService.userClear();
        this.errorMessage = "Login failed.";
      }
    });
  }


}
