<div *ngIf="errors && errors.length > 0" class="clearfix">
  <div class="{{classes}}">
    <ul class="input-invalid">
      <li *ngFor="let error of errors">
        <small class="form-text input-invalid form-error-message">
          {{error}}
        </small>
      </li>
    </ul>
  </div>
</div>
