import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { TableHelper } from 'projects/common-lib/src/lib/table/table-helper';
import { Log, Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ButtonItem, Action } from 'projects/common-lib/src/lib/ux-models';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AssetService } from 'projects/core-lib/src/lib/services/asset.service';
import { BaseComponent } from '../../../../../core-lib/src/lib/helpers/base-component';

@Component({
  selector: 'ib-input-asset-picker-modal',
  templateUrl: './input-asset-picker-modal.component.html',
  styleUrls: ['./input-asset-picker-modal.component.css']
})
export class InputAssetPickerModalComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() options: ModalCommonOptions = new ModalCommonOptions();
  @Input() data: { AssetId: number, Title: string };
  @Input() filter: string = "";
  @Input() allowClearSelection: boolean = true;
  @Input() showSearch: boolean = true;
  @Input() showRelated: boolean = false;
  @Input() showPreview: boolean = true;
  @Input() allowAddAsset: boolean = false;

  public selectedAsset: m5.AssetEditViewModel = null;
  public selectedAssets: m5.AssetListViewModel[] = [];
  public tableOptions: TableOptions = null;
  public tableReloadCount: number = 0;

  public frameHeight: number = Helper.getMaxComponentHeight();


  constructor(
    protected assetService: AssetService,
    protected apiService: ApiService,
    public modal: NgbActiveModal) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validateParameters();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.validateParameters();
  }

  // ngOnDestroy() {
  //   super.ngOnDestroy();
  // }

  /**
   * Validate input parameters to make sure they are supported values.
   */
  protected validateParameters() {

    if (!this.options) {
      this.options = new ModalCommonOptions();
    }

    // We need text or icon on at least one of our buttons
    if (!this.options.okButtonText && !this.options.okButtonIcon && !this.options.cancelButtonText && !this.options.cancelButtonIcon) {
      this.options.okButtonText = "Ok";
    }

    // If we have an asset id then we have an asset currently selected we want frozen at the top of the table
    if (this.data && this.data.AssetId) {
      this.assetService.getAssetFullObject(this.data.AssetId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((asset) => {
        (<any>asset).Selected = true;
        this.selectedAsset = asset;
        this.selectedAssets = [this.assetService.fromEditToListObject(asset)];
        //console.error("frozen", this.frozenData);
      });
    }

    this.tableOptions = this.getTableOptions();

    //console.error(this.form);
    //console.error(this.data);

  }

  onNavChange($event) {
    if (Helper.equals($event.nextId, "Search", true)) {

    } else if (Helper.equals($event.nextId, "Related", true)) {

    } else if (Helper.equals($event.nextId, "Preview", true)) {

    }
  }

  onRowSelected($event) {
    if ($event.data) {
      this.data.AssetId = $event.data.AssetId;
      this.data.Title = Helper.getFirstDefinedStringFromProperties($event.data, "Title", "ExternalAssetId", "FriendlyName");
      //Log.errorMessage("Asset Selected", this.data);
    } else {
      Log.errorMessage("No data included with event", $event);
    }
  }

  getTableOptions(): TableOptions {

    // TODO accept api name and generic model type to see what properties we can turn into columns.
    const options = new TableOptions();
    options.tableId = null; // Don't save layout changes
    options.rowsPerPage = 10;
    options.apiName = "Asset";
    options.loadDataFromServer = true;
    options.theme = "striped";
    options.sort = "Title";
    if (this.filter) {
      options.filter = this.filter;
    }
    options.rowsPerPageOptions = [5, 10, 15];
    options.rowSelectedAction = (row: any, selectedRows: any[], allRows: any[], cargo: any) => {
      // Set selected flag set off any of the rows visible and set on for the selected row
      if (allRows) {
        allRows.forEach((one) => {
          one.Selected = false;
        });
      }
      if (row) {
        row.Selected = true;
        this.selectedAsset = (row as m5.AssetEditViewModel);
        // The row we were actually given was m5.AssetListViewModel so if the user clicks preview it
        // will not show asset text until we retrieve the full object from the api so let's do that
        // now in case the user wants to preview the asset.
        this.assetService.getAssetFullObject(row.AssetId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((asset) => {
          (<any>asset).Selected = true;
          this.selectedAsset = asset;
        });
        this.selectedAssets = [row];
      }
    };
    options.lazyLoadAction = (rows: any[], cargo: any) => {
      if (rows) {
        rows.forEach((one) => {
          one.Selected = (one.AssetId === this.data.AssetId);
        });
      }
    };

    options.columns.push(new TableColumnOptions("Selected", "", "boolean"));
    TableHelper.setColumnHeader(options.columns.slice(-1)[0], "", "check-square", "none", false);
    options.columns.push(new TableColumnOptions("ExternalAssetId", "Id"));
    options.columns.push(new TableColumnOptions("Title"));
    options.columns.push(new TableColumnOptions("FriendlyName"));
    options.columns.push(new TableColumnOptions("FileType", "File Type"));
    options.columns.push(new TableColumnOptions("AssetType", "Asset Type", "picklist", Constants.PickList.__Asset_AssetType));

    if (this.allowClearSelection) {
      // event.data => { data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }
      options.actionButtonRight1 = new ButtonItem(`Clear Selection`, "ban", "warning", (event) => {
        //console.error("clear selection", event);
        this.data.AssetId = null;
        this.data.Title = "";
        this.selectedAsset = null;
        this.selectedAssets = [];
        if (event.data.selectedData) {
          event.data.selectedData.Selected = false;
          event.data.selectedData = null;
        }
        if (event.data.headerData && event.data.headerData.length > 0) {
          event.data.headerData[0].Selected = false;
          event.data.headerData = [];
        }
      });
      options.actionButtonRight1.size = "sm";
    }

    // Not going to be able to use existing modals for this as they are in library that lives upstream from us...
    //if (this.allowAddAsset) {
    //  options.actionButtonRight2 = new ButtonItem(`Add Asset`, "plus", "primary", (event) => {
    //    console.error("add asset", event);
    //  });
    //  options.actionButtonRight2.size = "sm";
    //}

    return options;

  }


  previewRenderMethod(): "html" | "text" | "image" | "iframe" | "icon" | "" {
    if (!this.selectedAsset) {
      return "";
    }
    return this.assetService.previewRenderMethod(this.selectedAsset.FileType, this.selectedAsset.AssetType);
  }


  assetIcon(extension: string, assetType: string) {
    return this.assetService.assetIcon(extension, assetType);
  }

  buildFileViewUrl(assetId: number, friendlyName: string, fileType: string) {
    return this.assetService.buildFileViewUrl(assetId, friendlyName, fileType, true, true, true);
  }


}
