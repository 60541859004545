import { Component, EventEmitter, Output, ElementRef, Input, forwardRef, OnInit, OnDestroy, NgZone, AfterViewInit, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef, ApplicationRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

import "brace";
//import "brace/theme/monokai";
//import "brace/mode/html";

//import * as ace from 'brace';
import { Editor } from 'brace';

import "brace/mode/html";
import "brace/mode/css";
import "brace/mode/markdown";
import "brace/mode/xml";
import "brace/mode/json";
import "brace/mode/typescript";
import "brace/mode/javascript";
import "brace/mode/csharp";
import "brace/mode/powershell";
import "brace/mode/sql";
import "brace/mode/sqlserver";
import "brace/mode/plain_text";

import "brace/snippets/html";
import "brace/snippets/css";
import "brace/snippets/markdown";
import "brace/snippets/xml";
import "brace/snippets/json";
import "brace/snippets/typescript";
import "brace/snippets/javascript";
import "brace/snippets/csharp";
import "brace/snippets/powershell";
import "brace/snippets/sql";
import "brace/snippets/sqlserver";
import "brace/snippets/plain_text";

import "brace/worker/html";
import "brace/worker/css";
import "brace/worker/javascript";
import "brace/worker/json";
import "brace/worker/xml";

import "brace/ext/language_tools";
import "brace/ext/searchbox";
//import "brace/ext/statusbar"
//import { StatusBar } from "brace/ext/statusbar";

import "brace/theme/chrome";
import "brace/theme/sqlserver";
import { EventModel, EventElementModel, ButtonItem } from 'projects/common-lib/src/lib/ux-models';
import { Helper, Log } from "projects/core-lib/src/lib/helpers/helper";
import { CodeEditorService } from "projects/core-lib/src/lib/services/code-editor.service";


declare var ace: any;

@Component({
  selector: 'ib-code-editor',
  templateUrl: './code-editor.component.html',
  styles: [':host { display:block;width:100%; }'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CodeEditorComponent),
    multi: true
  }]
})
export class CodeEditorComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() public height: string = "300px";

  @Output() textChanged = new EventEmitter();
  @Output() textChange = new EventEmitter();
  @Output() change: EventEmitter<EventModel> = new EventEmitter();

  @Input() style: any = {};

  @Input() actions: ButtonItem = null;

  //@ViewChild('hiddentextarea') hiddenTextElement: ElementRef;


  public wordwrap: boolean = false;
  public highlightCurrentLine: boolean = true;
  public showHiddenCharacters: boolean = false;
  public showLineNumbers: boolean = true;
  public errors: string[] = [];

  protected _options: any = {
    showPrintMargin: false,
    showInvisibles: this.showHiddenCharacters,
    highlightGutterLine: true,
    highlightActiveLine: this.highlightCurrentLine,
    fadeFoldWidgets: true,
    showLineNumbers: this.showLineNumbers,
    showGutter: true,
    fontSize: 14,
    wrap: true, // start this true and then set to our desired setting in configure() to prevent odd horizontal scroll issue
    mode: `ace/mode/${this.mode || "plain_text"}`,
    enableBasicAutocompletion: true,
    enableSnippets: false, // true? how do these get injected?
    enableLiveAutocompletion: false
  };

  _readonly: boolean = false;
  _disabled: boolean = false;
  _theme: string = "chrome";
  _mode: any = "html";
  _autoUpdateContent: boolean = true;
  _editor: Editor;
  // See updateText(event) method for how _durationBeforeCallback is used but when this is 0 we seem to have
  // issues where enter key and paste action that includes a delete of text then positions cursor at end which
  // is a very clunky user experience with the code editor.
  _durationBeforeCallback: number = 10;
  _text: string = "";
  oldText: any;
  timeoutSaving: any;
  public editorControlId = `source-code-editor-${Helper.createBase36Guid()}`;

  constructor(private elementRef: ElementRef, private zone: NgZone, private changeDetection: ChangeDetectorRef, protected codeEditorService: CodeEditorService) {
    //let el = elementRef.nativeElement;
    //this.zone.runOutsideAngular(() => {
    //  this._editor = ace['edit'](this.editorControlId);
    //});
    //this._editor.$blockScrolling = Infinity;
  }

  ngOnInit() {
    //this.init();
    //this.initEvents();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this._editor) {

    }
  }

  ngOnDestroy() {
    if (this._editor) {
      this._editor.destroy();
    }
  }

  ngAfterViewInit() {

    this.zone.runOutsideAngular(() => {
      this._editor = ace['edit'](this.editorControlId);
    });

    this.init();
    this.initEvents();

  }

  init() {
    this.setOptions(this._options || {});
    this.setTheme(this._theme);
    this.setMode(this._mode);
    // On init oldText == submitted text
    this.oldText = this._text;
    this.setText(this._text, true);
    if (this._editor) {
      this._editor.setReadOnly(this._readonly || this._disabled);
      this._editor.$blockScrolling = Infinity;
      this._editor.setOption("wrap", this.wordwrap);
    }
  }

  initEvents() {
    this._editor.on('keyup', ($event) => this.updateText($event));
    this._editor.on('change', ($event) => this.updateText($event));
    this._editor.on('paste', ($event) => this.updateText($event));
  }

  setDirty() {
    //(<HTMLInputElement>document.getElementById('amount')) .value);
    //this.hiddenTextElement.nativeElement.mark
  }

  updateText(event) {
    const newVal = this._editor.getValue();
    if (newVal === this.oldText) {
      return;
    }
    //console.error("old");
    //console.error(this.oldText);
    //console.error("new");
    //console.error(newVal);
    if (!this._durationBeforeCallback) {
      this._text = newVal;
      this.zone.run(() => {
        this.textChange.emit(newVal);
        this.textChanged.emit(newVal);
        const payload: EventModel = new EventModel("change", event, newVal, new EventElementModel("code", this.editorControlId, this.mode));
        this.change.emit(payload);
      });
      this._onChange(newVal);
    } else {
      if (this.timeoutSaving) {
        clearTimeout(this.timeoutSaving);
      }
      this.timeoutSaving = setTimeout(() => {
        this._text = newVal;
        this.zone.run(() => {
          this.textChange.emit(newVal);
          this.textChanged.emit(newVal);
          const payload: EventModel = new EventModel("change", event, newVal, new EventElementModel("code", this.editorControlId, this.mode));
          this.change.emit(payload);
        });
        this._onChange(newVal);
        this.timeoutSaving = null;
      }, this._durationBeforeCallback);
    }
    this.oldText = newVal;
  }

  @Input() set options(options: any) {
    this.setOptions(options);
  }

  setOptions(options: any) {
    this._options = options;
    if (this._editor) {
      this._editor.setOptions(options || {});
    }
  }

  @Input() set disabled(disabled: any) {
    this._disabled = disabled;
    if (this._editor) {
      this._editor.setReadOnly(this._readonly || this._disabled);
    }
  }

  @Input() set readonly(readOnly: any) {
    this._readonly = readOnly;
    if (this._editor) {
      this._editor.setReadOnly(this._readonly || this._disabled);
    }
  }


  @Input() set theme(theme: any) {
    this.setTheme(theme);
  }

  setTheme(theme: any) {
    this._theme = theme;
    if (this._editor) {
      this._editor.setTheme(`ace/theme/${theme}`);
    }
  }

  @Input() set fileType(type: string) {
    //console.error("mode", this.getModeFromFileExtension(type));
    this.setMode(this.codeEditorService.getEditorModeFromFileExtension(type));
  }

  @Input() set mode(mode: any) {
    this.setMode(mode);
  }

  setMode(mode: any) {
    this._mode = mode;
    if (this._editor) {
      if (typeof this._mode === 'object') {
        this._editor.getSession().setMode(this._mode);
      } else {
        this._editor.getSession().setMode(`ace/mode/${this._mode}`);
        // Auto set theme based on mode
        if (Helper.startsWith(this._mode, "sql", true)) {
          this.setTheme("sqlserver");
        } else {
          this.setTheme("chrome");
        }
      }
    }
  }

  get value() {
    return this.text;
  }

  @Input()
  set value(value: string) {
    // On value set we don't need change event as someone else changed us
    this.oldText = value;
    this.setText(value);
    // typing triggers change not set value
    //this._onChange(value);
  }

  writeValue(value: any) {
    // On value set we don't need change event as someone else changed us
    this.oldText = value;
    this.setText(value);
  }

  private _onChange = (_: any) => {
  };

  registerOnChange(fn: any) {
    this._onChange = fn;
  }

  private _onTouched = () => {
  };

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  get text() {
    return this._text;
  }

  @Input()
  set text(text: string) {
    // On value set we don't need change event as someone else changed us
    this.oldText = text;
    this.setText(text);
  }

  setText(text: any, force: boolean = false) {
    if (text === null || text === undefined) {
      text = "";
    }
    if (force || (this._text !== text && this._autoUpdateContent === true)) {
      this._text = text;
      if (this._editor) {
        try {
          this._editor.setValue(text);
        } catch (err) {
          Log.errorMessage(err);
        }
        // typing triggers change not setting value
        //this._onChange(text);
        this._editor.clearSelection();
        // If editor is hidden like on another tab we have to call this to get it to render the contents
        this._editor.renderer.updateFull(true);
      }
    }
  }

  @Input() set autoUpdateContent(status: any) {
    this.setAutoUpdateContent(status);
  }

  setAutoUpdateContent(status: any) {
    this._autoUpdateContent = status;
  }

  @Input() set durationBeforeCallback(num: number) {
    this.setDurationBeforeCallback(num);
  }

  setDurationBeforeCallback(num: number) {
    this._durationBeforeCallback = num;
  }

  getEditor() {
    return this._editor;
  }





  public toggleWordwrap = () => {
    this.wordwrap = !this.wordwrap;
    if (this._editor) {
      this._editor.setOption("wrap", this.wordwrap);
    }
  }

  public toggleHighlightCurrentLine = () => {
    this.highlightCurrentLine = !this.highlightCurrentLine;
    if (this._editor) {
      this._editor.setHighlightActiveLine(this.highlightCurrentLine);
    }
  }

  public toggleShowHiddenCharacters = () => {
    this.showHiddenCharacters = !this.showHiddenCharacters;
    if (this._editor) {
      this._editor.setOption("showInvisibles", this.showHiddenCharacters);
    }
  }

  public toggleShowLineNumbers = () => {
    this.showLineNumbers = !this.showLineNumbers;
    if (this._editor) {
      this._editor.setOption("showLineNumbers", this.showLineNumbers);
    }
  }


}
