import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputNumberComponent),
  multi: true
};

@Component({
  selector: 'ib-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputNumberComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  @Input() public min: number;
  @Input() public max: number;
  @Input() public step: number;

  @Input() public decimalPlaces: number;

  @Input() public type: string = "number"; // number, integer, float
  public keyFilterType: string = "num";

  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
    if (!this.width) {
      this.width = "medium";
    }
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }

  public configure() {

    // Call the base class configure method to handle a lot of this
    super.configure();

    if (this.type) {
      if (Helper.equals(this.type, "number", true)) {
        this.keyFilterType = "num";
      } else if (Helper.equals(this.type, "integer", true)) {
        this.keyFilterType = "int";
      } else if (Helper.equals(this.type, "float", true)) {
        this.keyFilterType = "num";
      } else {
        Log.errorMessage(`Unexpected number input type setting ${this.type}.  Expected values include: "number", "integer", "float".  If no other appropriate input control exists please use "number".`);
        this.keyFilterType = "num";
      }
    } else {
      this.keyFilterType = "num";
    }

  }


}
