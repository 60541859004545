import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, Output, EventEmitter, Directive, ElementRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgModel, FormControl } from '@angular/forms';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { Helper, Log } from "projects/core-lib/src/lib/helpers/helper";
import { EventModel, EventElementModel, ButtonItem } from 'projects/common-lib/src/lib/ux-models';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiOperationType, Query, CacheLevel, IApiResponseWrapper, IApiResponseWrapperTyped } from 'projects/core-lib/src/lib/api/ApiModels';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { SelectItem } from 'primeng/api';
import { StaticPickList } from 'projects/core-lib/src/lib/models/model-helpers';
import { InputStatusChangeModel } from 'projects/common-lib/src/lib/input/input-status-change-model';
import { InputStatusChangeHelper } from 'projects/common-lib/src/lib/input/input-status-change-helper';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UxService } from '../services/ux.service';

const noop = () => { };


//export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
//  provide: NG_VALUE_ACCESSOR,
//  useExisting: forwardRef(() => InputBaseComponent),
//  multi: true
//};


@Directive()
export abstract class InputBaseComponent implements ControlValueAccessor, OnChanges {

  /**
  The name for the input control.  This is required.
  */
  @Input() name: string = `UnnamedInput_${Helper.createBase36Guid()}`;

  @Input() label: string = "";
  /**
  Prefix rendered directly before the label.  Can be used for things like * asterisk prefix
  to indicate a required field or other custom prefix indicators.  The prefix is not
  translated and provides the ability for the label that is translated to share translations
  that otherwise would have to be repeated due to the prefix.  For example, "Customer"
  translation can be reused where "*Customer:" label would otherwise need a repeated translation.
  */
  @Input() labelPrefix: string = "";
  /**
  Suffix rendered directly after the label.  Can be used for things like : colon suffix
  prompt before input or other custom suffix indicators.  The suffix is not
  translated and provides the ability for the label that is translated to share translations
  that otherwise would have to be repeated due to the suffix.  For example, "Customer"
  translation can be reused where "*Customer:" label would otherwise need a repeated translation.
  */
  @Input() labelSuffix: string = "";

  /**
   * In some scenarios we want a button in the place of the typical label.
   */
  @Input() labelButton: ButtonItem = null;
  @Input() labelButtonData: any = null;
  @Input() labelButtonCargo: any = null;

  @Input() placeholder: string = "";
  @Input() tooltip: string = "";
  @Input() pattern: string = ""; // html input regex pattern
  @Input() format: string = ""; // "",pull-left (don't have columns allocated to label)
  @Input() width: string = ""; // "",full,medium,short,tiny
  @Input() size: string = ""; // "",large,small
  @Input() bold: boolean = false; // true = bold label
  @Input() tight: boolean = false; // true = tight form groups
  @Input() tightest: boolean = false; // true = tightest form groups
  @Input() plainInput: boolean = false; // If true will alter what wrapper classes get applied expecting there to be no label
  @Input() vertical: boolean = false; // true = vertical form with label above input control.  default is false.
  @Input() maxlength: number = Number.MAX_SAFE_INTEGER;
  @Input() minlength: number;
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() autofocus: boolean = false;
  @Input() typeahead: boolean = true; // true = use typeahead feature using provided options (if any)
  /**
   * Note that standalone scenarios will need to update this in change or keyup event like this:
   * (keyUp)="meta.Input1 = $event.data"
   */
  @Input() standalone: boolean = false; // true will not mark form dirty when control is dirty
  /**
  A template used for character count information.  This can include placeholders
  {{ActualLength}} for the current count, {{MaximumLength}} for the maximum number of characters, and
  {{RemainingLength}} for the number of characters remaining.
  */
  @Input() characterCountTemplate: string = "";

  @Input() includeRequiredIcon: boolean = true;

  @Input() outerClass: string = "";
  @Input() labelClass: string = "";
  @Input() controlClass: string = "";

  /**
  Options pick list id or pick list is used for select lists, text typeahead, tags typeahead, etc.
  */
  @Input() optionsPickListId: string = "";
  @Input() optionsPickListFilter: string = "";
  @Input() optionsPickListAddAllowed: boolean = true;
  @Input() optionsPickListAddTooltip: string = "Add Option";
  @Output() optionsPickListAddClick: EventEmitter<EventModel> = new EventEmitter();
  /**
  Options pick list reload count is a way for us to force a reload of pick list when
  we add pick list values on the fly and want an input component to get that value
  in the list.
  */
  @Input() optionsPickListReloadCount: number = 0;
  @Input() optionsPickList: m5core.PickListSelectionViewModel[] = [];
  /**
  If value presented in options is an integer instead of a string this input attribute should
  be set to true so we can accommodate for that in the select list.
  */
  @Input() optionsValueIsInteger: boolean = false;
  @Input() optionsIncludeNone: boolean = true;
  @Input() optionsNoneLabel: string = "<None>";

  /**
   * Determines the minimum input length before showing typeahead results
   */
  @Input() typeAheadMinLength: number = 2;
  /**
   * Determines the maximum number of results shown in typeahead dropdown
   */
  @Input() typeAheadMaxResults: number = 10;
  @Input() typeAheadShowAllWhenEmpty: boolean = false;


  @Input() prefixShow: boolean = true;
  @Input() prefixClass: string = "";
  @Input() prefixIcon: string = "";
  @Input() prefixText: string = "";
  @Input() prefixTooltip: string = "";
  @Input() prefixClickEventEnabled: boolean = true;
  @Output() prefixClick: EventEmitter<EventModel> = new EventEmitter();

  @Input() suffixShow: boolean = true;
  @Input() suffixClass: string = "";
  @Input() suffixIcon: string = "";
  @Input() suffixText: string = "";
  @Input() suffixTooltip: string = "";
  @Input() suffixClickEventEnabled: boolean = true;
  @Output() suffixClick: EventEmitter<EventModel> = new EventEmitter();

  @Input() suffixShow2: boolean = true;
  @Input() suffixClass2: string = "";
  @Input() suffixIcon2: string = "";
  @Input() suffixText2: string = "";
  @Input() suffixTooltip2: string = "";
  @Input() suffixClickEventEnabled2: boolean = true;
  @Output() suffixClick2: EventEmitter<EventModel> = new EventEmitter();

  @Input() errorRequiredMessage: string = "This value is required.";
  @Input() errorMinimumLengthMessage: string = "This value must be at least {{MinimumLength}} characters long.";
  @Input() errorMaximumLengthMessage: string = "This value cannot be longer than {{MaximumLength}} characters but is currently {{ActualLength}} characters long.";
  @Input() errorInvalidFormatMessage: string = "This is not a valid format: {{FormatErrorMessage}}";
  @Input() errorOtherMessage: string = "This value is not valid: {{OtherErrorMessage}}";
  errorMessages: string[] = [];
  inputInformationValues: InputInformationValues = {};

  @Output() focus: EventEmitter<EventModel> = new EventEmitter();
  @Output() blur: EventEmitter<EventModel> = new EventEmitter();
  @Output() keyUp: EventEmitter<EventModel> = new EventEmitter();
  @Output() keyUpIsEnter: EventEmitter<EventModel> = new EventEmitter();
  @Output() keyUpIsWordBreak: EventEmitter<EventModel> = new EventEmitter();
  @Output() change: EventEmitter<EventModel> = new EventEmitter();
  @Output() status: EventEmitter<EventModel> = new EventEmitter();

  /**
  Make constants available in html view.
  */
  public Constants = Constants;

  public pickListId: string = "";
  public pickList: m5core.PickListSelectionViewModel[] = [];
  public pickListHasGroups: boolean = false;
  public pickListAddButtonSlot: number = 0;
  public selectItems: SelectItem[] = [];
  public mapPickListToOptions: boolean = false;

  public inputLabelClass: string = "";
  public inputWrapperClass: string = "";
  /**
   * Contains the input-group-specifc classes that are also contained in inputWrapperClass
   * so that they can be manually applied to children. This is necessary when there
   * are multiple input-groups inside of single row, which is uncommon.
   * See input-date for an example.
   */
  public inputGroupClass: string = "";


  public inputCharacterCount: number = 0;

  /**
   * This holds column offset or other classes for error messages.
   */
  public inputErrorWrapperClass: string = "";

  /**
  When label is multiple controls this is the div wrapper class(es) to use.
  */
  public inputLabelWrapperClass: string = "";

  /**
  When label is multiple controls this is the label class(es) to use.
  */
  public inputLabelOnlyClass: string = "";


  public inputControlId: string = "";
  //public inputControlName: string = "";
  public inputType: string = "text";
  public inputSize: string = "";
  //public inputGroupWrapperClass: string = "input-group";
  //public inputGroupSize: string = "";
  public inputStyleClass: string = "";
  public inputFormGroupClass: string = "";

  /*
  In some instances we use primeng components which have different
  classes for some of the markup.  If that's true then the subclass
  that extends this base class should indicate this up front.
  */
  public inputLibrary: "bootstrap" | "primeng" = "bootstrap";

  public lastStatus: InputStatusChangeModel = null;

  //public isReady: boolean = false;

  public get isMobilePortrait(): boolean {
    return Helper.isMobilePortrait();
  }
  public get isMobile(): boolean {
    return Helper.isMobile();
  }
  public get isTablet(): boolean {
    return Helper.isTablet();
  }
  public get isMobileOrTablet(): boolean {
    return Helper.isMobileOrTablet();
  }

  constructor(
    protected apiService: ApiService,
    protected uxService: UxService) {
    //this.inputControlId = "input_" + Helper.createBase36Guid();
    // Set the input control id appended with unique value in case labels get repeated in the same form
    this.inputControlId = `input_${Helper.replaceAll(Helper.replaceAll((this.label || this.name || ""), " ", ""), "'", "")}_${Helper.createBase36Guid()}`;
    this.inputWrapperClass = Constants.Layout.split2input;
    this.inputLabelClass = Constants.Layout.split2label + " control-label";
  }

  ngOnChanges(changes: SimpleChanges) {

    this.configure();

    if (changes.optionsPickListReloadCount &&
      changes.optionsPickListReloadCount.currentValue &&
      this.optionsPickListId) {
      // Note ignoreCache = true since explicitly asked to reload
      this.loadPickList(this.optionsPickListId, this.optionsPickListFilter, true);
    }

    if ((changes.optionsPickListId || changes.optionsPickListFilter) && this.optionsPickListId) {
      this.loadPickList(this.optionsPickListId, this.optionsPickListFilter, true);
    }

  }

  public configure() {

    //if (this.standalone) {
    //  console.error(`${this.name} is standalone`);
    //}

    // Form labels will be above form controls for smaller screen widths
    // Note: this style is does not change on the fly as the screen width changes
    if (!this.vertical && this.isMobilePortrait) {
      this.vertical = true;
    }

    // Default classes
    if (this.vertical) {
      this.inputFormGroupClass = "form-group";
      this.inputWrapperClass = "";
      this.inputLabelClass = "col-form-label";
    } else {
      this.inputFormGroupClass = "form-group row";
      if (this.plainInput || Helper.equals(this.format, "pull-left", true)) {

        // After BS5 update we need to set col-auto or the width of the div that holds
        // the input will default to 100% and will not be inline.
        this.inputWrapperClass = "col-auto";
        this.inputErrorWrapperClass = "";
      } else {
        this.inputWrapperClass = Constants.Layout.split2input;
        this.inputErrorWrapperClass = Constants.Layout.split2inputOffset + " ps-3"; // ps-3 is left padding to match our columns gutters
      }
      if (Helper.equals(this.format, "pull-left", true)) {

        // After BS5 update we need to set col-auto or the width of the div that holds
        // the label will default to 100% and will not be inline.
        if (this.labelButton) {
          this.inputLabelClass = "col-form-label-tight col-auto";
        } else {
          this.inputLabelClass = "col-form-label col-auto";
        }
      } else {
        if (this.labelButton) {
          this.inputLabelClass = Constants.Layout.split2label + " col-form-label-tight";
        } else {
          this.inputLabelClass = Constants.Layout.split2label + " col-form-label";
        }
        this.inputLabelWrapperClass = Constants.Layout.split2label;
      }
      this.inputLabelOnlyClass = "col-form-label";
      // If we're not mobile and, therefore, not stacked labels we want to pull the label right
      if (!Helper.isMobile()) {
        this.inputLabelClass += " label-right";
        this.inputLabelOnlyClass += " label-right";
      }
    }

    if (this.bold) {
      this.inputLabelClass += " label-strong";
      this.inputLabelOnlyClass += " label-strong";
    }

    if (this.labelClass) {
      this.inputLabelClass += " " + this.labelClass;
      this.inputLabelOnlyClass += " " + this.labelClass;
    }

    // Tight form
    if (this.tight) {
      this.inputFormGroupClass += " form-group-tight";
    } else if (this.tightest) {
      this.inputFormGroupClass += " form-group-tightest";
    }


    // Set the class for our input wrapper if not in vertical mode or if we have explicit width
    if (this.vertical) {
      this.inputErrorWrapperClass = ""; // Vertical labels means no offset for error messages
      if (Helper.startsWith(this.width, "m", true)) { // medium
        this.inputWrapperClass = `${Constants.Layout.split2inputMedium} ps-0`;
      } else if (Helper.startsWith(this.width, "s", true) || Helper.startsWith(this.width, "n", true)) { // short, narrow
        this.inputWrapperClass = `${Constants.Layout.split2inputShort} ps-0`;
      } else if (Helper.startsWith(this.width, "t", true)) { // tiny
        this.inputWrapperClass = `${Constants.Layout.split2inputTiny} ps-0`;
      }
    } else if (!this.vertical) { // && !this.plainInput) {
      if (this.plainInput) {
        this.inputWrapperClass = Constants.Layout.fullWidth;
        //this.inputWrapperClass = ""; // As of 2020-08-01 JPM thinks plain input should have no width, gutters, etc. that comes from Constants.Layout.fullWidth;
      } else if (!this.width) {
        this.inputWrapperClass = Constants.Layout.split2input;
      } else if (Helper.startsWith(this.width, "m", true)) { // medium
        this.inputWrapperClass = Constants.Layout.split2inputMedium;
      } else if (Helper.startsWith(this.width, "s", true) || Helper.startsWith(this.width, "n", true)) { // short, narrow
        this.inputWrapperClass = Constants.Layout.split2inputShort;
      } else if (Helper.startsWith(this.width, "t", true)) { // tiny
        this.inputWrapperClass = Constants.Layout.split2inputTiny;
      } else {
        this.inputWrapperClass = Constants.Layout.split2input;
      }
    }

    // If we have add pick list value enabled sort out what suffix button will trigger that
    // We need add allowed and permissions and either a click event for custom add or a
    // pick list id that doesn't start with underscore for default add handler
    if (this.optionsPickListAddAllowed && this.uxService.appService.hasPermission("PickListValue", "A") &&
      ((this.optionsPickListId && !Helper.startsWith(this.optionsPickListId, "_")) || this.optionsPickListAddClick.observers.length > 0)) {
      if (!this.suffixIcon || Helper.equals(this.suffixIcon, "plus", true)) {
        this.pickListAddButtonSlot = 1;
        this.suffixIcon = "plus";
        this.suffixTooltip = Helper.getFirstDefinedString(this.optionsPickListAddTooltip, "Add Option");
      } else if (!this.suffixIcon2 || Helper.equals(this.suffixIcon2, "plus", true)) {
        this.pickListAddButtonSlot = 2;
        this.suffixIcon2 = "plus";
        this.suffixTooltip2 = Helper.getFirstDefinedString(this.optionsPickListAddTooltip, "Add Option");
      }
    } else {
      this.pickListAddButtonSlot = 0;
    }

    // If we have prefix and/or suffix we have additional wrapper class to insert
    if (this.prefixText || this.prefixIcon || this.suffixText || this.suffixIcon || this.suffixText2 || this.suffixIcon2) {
      if (this.inputLibrary === "primeng") {
        this.inputGroupClass = "ui-inputgroup";
      } else {
        this.inputGroupClass = "input-group";
      }
      if (Helper.equals(this.size, "large", true) || Helper.equals(this.size, "lg", true)) {
        this.inputGroupClass += " input-group-lg";
      } else if (Helper.equals(this.size, "small", true) || Helper.equals(this.size, "sm", true)) {
        this.inputGroupClass += " input-group-sm";
      }

      // Bootstrap 5 change: Removed because input-group cannot be applied to the same div that sets
      // the column width or input-group's width: 100% style will overpower the desired width.
      //this.inputWrapperClass += " " + this.inputGroupClass;
    }

    //// Set the input type
    //if (!this.type) {
    //  this.inputType = "text";
    //} else if (this.type.equalsCaseInsensitive("text") || this.type.equalsCaseInsensitive("string")) {
    //  this.inputType = "text";
    //} else if (this.type.equalsCaseInsensitive("number") || this.type.equalsCaseInsensitive("int") || this.type.equalsCaseInsensitive("float")) {
    //  this.inputType = "number";
    //} else {
    //  this.inputType = this.type.toLowerCase(); // other html5 input control types (e.g. datetime, url, email, color, etc.)
    //}

    // Set the input size
    if (!this.size) {
      this.inputSize = "";
    } else if (Helper.equals(this.size, "large", true) || Helper.equals(this.size, "lg", true)) {
      this.inputSize = "form-control-lg";
      this.inputLabelClass += " col-form-label-lg";
    } else if (Helper.equals(this.size, "small", true) || Helper.equals(this.size, "sm", true)) {
      this.inputSize = "form-control-sm";
      this.inputLabelClass += " col-form-label-sm";
    } else {
      this.inputSize = "";
    }

    // Default our placeholder to our label
    if (!this.placeholder && this.label) {
      this.placeholder = this.label;
    }

    if (this.optionsPickList && this.optionsPickList.length > 0) {
      this.pickList = this.optionsPickList;
      this.pickListHasGroups = (this.pickList.filter(x => x.GroupText).length > 0);
      this.selectItems = StaticPickList.pickListToPrimeSelectItems(this.pickList, this.optionsIncludeNone, this.optionsNoneLabel, this.optionsValueIsInteger, this.pickListHasGroups);
      this.onPickListReady();
    }

    if (this.optionsPickListId && !Helper.equals(this.pickListId, this.optionsPickListId, true)) {
      this.loadPickList(this.optionsPickListId, this.optionsPickListFilter);
    }
    //console.error("slot", this.pickListAddButtonSlot);

    // We cannot have empty error messages... if there is an error we need to know it.  If we don't
    // want control errors then we hide the class in custom css not empty out the error messages.
    if (!this.errorRequiredMessage) {
      this.errorRequiredMessage = "This value is required.";
    }
    if (!this.errorMinimumLengthMessage) {
      this.errorMinimumLengthMessage = "This value must be at least {{MinimumLength}} characters long.";
    }
    if (!this.errorMaximumLengthMessage) {
      this.errorMaximumLengthMessage = "This value cannot be longer than {{MaximumLength}} characters but is currently {{ActualLength}} characters long.";
    }
    if (!this.errorInvalidFormatMessage) {
      this.errorInvalidFormatMessage = "This is not a valid format: {{FormatErrorMessage}}";
    }
    if (!this.errorOtherMessage) {
      this.errorOtherMessage = "This value is not valid: {{OtherErrorMessage}}";
    }

    // Call this once to get initial input information
    this.updateInputInformation();

  }

  public loadPickList(pickListId: string, pickListFilter: string = "", ignoreCache: boolean = false) {

    this.apiService.loadPickList(pickListId, pickListFilter, ignoreCache).subscribe((result: IApiResponseWrapperTyped<m5core.PickListSelectionViewModel[]>) => {
      if (!result.Data.Success) {
        console.error(result.Data);
        this.pickListId = pickListId;
        this.pickList = [];
        this.pickListHasGroups = false;
        this.selectItems = [];
      } else {
        this.pickListId = pickListId;
        this.pickList = result.Data.Data;
        this.pickListHasGroups = (this.pickList.filter(x => x.GroupText).length > 0);
        this.selectItems = StaticPickList.pickListToPrimeSelectItems(this.pickList, this.optionsIncludeNone, this.optionsNoneLabel, this.optionsValueIsInteger, this.pickListHasGroups);
      }
      this.onPickListReady();
    });

    //let apiProp = Api.InputPickList();
    //let apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.List);
    //apiCall.silent = true;
    //// Tweak caching
    //if (Helper.startsWith(pickListId, "___")) {
    //  // Don't cache type-ahead pick lists like we do others
    //  apiCall.cacheIgnore = true;
    //} else if (Helper.startsWith(pickListId, "__")) {
    //  // Static data model options don't change
    //  apiCall.cacheLevel = CacheLevel.Static;
    //}
    //let query = new Query();
    //query.Page = 1;
    //query.Size = Constants.RowsToReturn.All;
    //query.Filter = pickListFilter;
    //(<any>query).PickListId = pickListId;
    //this.apiService.execute(apiCall, query).subscribe((result: IApiResponseWrapperTyped<m5core.PickListSelectionViewModel[]>) => {
    //  if (!result.Data.Success) {
    //    console.error(result.Data);
    //    this.pickListId = pickListId;
    //    this.pickList = [];
    //    this.pickListHasGroups = false;
    //    this.selectItems = [];
    //  } else {
    //    this.pickListId = pickListId;
    //    this.pickList = result.Data.Data;
    //    this.pickListHasGroups = (this.pickList.filter(x => x.GroupText).length > 0);
    //    this.selectItems = StaticPickList.pickListToPrimeSelectItems(this.pickList, this.optionsIncludeNone, this.optionsNoneLabel, this.optionsValueIsInteger, this.pickListHasGroups);
    //  }
    //});

  }

  protected onPickListReady() {
    // So subclasses can do something after the pick list is ready for things like custom display, etc.
  }



  //The internal data model
  protected innerValue: any = '';

  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  protected onTouchedCallback: () => void = noop;
  protected onChangeCallback: (_: any) => void = noop;

  //get accessor
  get value(): any {
    //console.error(`ngModel value read out: ${this.innerValue}`);
    return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      //console.error(`ngModel value set to: ${v}`);
      // JPM try preventing this if standalone since [ngModelOptions]="{standalone: true}" seems to still tag the form as dirty
      if (!this.standalone) {
        this.onChangeCallback(v);
      } else {
        // Standalone scenarios will need to update this in change or keyup event like this:
        // (keyUp)="meta.Input1 = $event.data"
      }
    }
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }


  public fireBlur(event: any, control: NgModel) {

    let payload: EventModel = new EventModel("blur", event, this.value, new EventElementModel("input", this.inputControlId, this.name, this.label, this.placeholder));
    this.blur.emit(payload);

    // If our control status changed then fire that off
    if (InputStatusChangeHelper.isChanged(this.lastStatus, control)) {
      this.lastStatus = InputStatusChangeHelper.buildModel(this.inputControlId, this.name, this.label, control);
      let status: EventModel = new EventModel("status", this.lastStatus, this.value, payload.element);
      this.status.emit(status);
    }

    // Update input control information
    this.updateInputInformation();

    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // Since our "ib-input-*" component has this method as does the native html input control.
      // If we used different event names then it wouldn't be an issue but using these event names is more developer friendly.
      event.stopPropagation();
      event.preventDefault();
    } catch (err) {
      // Some custom components like multiselect do not have the above event method to fire.
      //Log.errorMessage(err);
    }

  }

  public fireFocus(event: any, control: NgModel) {

    let payload: EventModel = new EventModel("focus", event, this.value, new EventElementModel("input", this.inputControlId, this.name, this.label, this.placeholder));
    this.focus.emit(payload);

    // If our control status changed then fire that off
    if (InputStatusChangeHelper.isChanged(this.lastStatus, control)) {
      this.lastStatus = InputStatusChangeHelper.buildModel(this.inputControlId, this.name, this.label, control);
      let status: EventModel = new EventModel("status", this.lastStatus, this.value, payload.element);
      this.status.emit(status);
    }

    // Update input control information
    this.updateInputInformation();

    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // Since our "ib-input-*" component has this method as does the native html input control.
      // If we used different event names then it wouldn't be an issue but using these event names is more developer friendly.
      event.stopPropagation();
      event.preventDefault();
    } catch (err) {
      // Some custom components like multiselect do not have the above event method to fire.
      //Log.errorMessage(err);
    }

  }

  public fireKeyUp(event: KeyboardEvent, control: NgModel) {

    // Fire registered event handler(s)
    let payload: EventModel = new EventModel("keyUp", event, this.value, new EventElementModel("input", this.inputControlId, this.name, this.label, this.placeholder));
    this.keyUp.emit(payload);

    // If our control status changed then fire that off
    if (InputStatusChangeHelper.isChanged(this.lastStatus, control)) {
      this.lastStatus = InputStatusChangeHelper.buildModel(this.inputControlId, this.name, this.label, control);
      let status: EventModel = new EventModel("status", this.lastStatus, this.value, payload.element);
      this.status.emit(status);
    }

    // Update input control information
    this.updateInputInformation();

    let wordBreak: boolean = false;

    // See if key was enter key and, if so, fire off that event
    if (event.keyCode === 13) {
      this.keyUpIsEnter.emit(payload);
      wordBreak = true;
    } else if (Helper.isWordBreakCharacter(event.key)) {
      wordBreak = true;
    }

    // If we have a word break then emit that event
    if (wordBreak) {
      payload.cargo = { lastWord: Helper.lastWord(this.value) };
      this.keyUpIsWordBreak.emit(payload);
    }


    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // Since our "ib-input-*" component has this method as does the native html input control.
      // If we used different event names then it wouldn't be an issue but using these event names is more developer friendly.
      event.stopPropagation();
      event.preventDefault();
    } catch (err) {
      // Some custom components like multiselect do not have the above event method to fire.
      //Log.errorMessage(err);
    }

  }

  public fireChange(event: any, control: NgModel, fireOnChangeCallback: boolean = false) {

    if (fireOnChangeCallback) {
      this.onChangeCallback(this.value);
    }

    let cargo: any = {};

    // Update input control information
    this.updateInputInformation();
    cargo.inputCharacterCount = this.inputCharacterCount;
    cargo.inputInformation = this.inputInformationValues;

    // For select lists we want to provide both value and text of the select in case it's needed in our form for assigning a description property, etc.
    if (this.pickList && this.pickList.length > 0) {
      cargo.pickListItem = null;
      try {
        let matches: m5core.PickListSelectionViewModel[] = [];
        if (this.optionsValueIsInteger) {
          matches = this.pickList.filter(x => { return x.Value === this.value.toString(); });
        } else if (Helper.isArray(this.value)) {
          matches = this.pickList.filter(x => { return this.value.includes(x.Value); });
        } else {
          matches = this.pickList.filter(x => { return x.Value === this.value; });
        }
        if (matches && matches.length > 0) {
          if (Helper.isArray(this.value)) {
            cargo.pickListItems = matches;
          } else {
            cargo.pickListItem = matches[0];
          }
        }
      } catch (err) {
        Log.errorMessage(err);
      }
    }

    // Fire registered event handler(s)
    let payload: EventModel = new EventModel("change", event, this.value, new EventElementModel("input", this.inputControlId, this.name, this.label, this.placeholder), cargo);
    this.change.emit(payload);

    // If our control status changed then fire that off
    if (InputStatusChangeHelper.isChanged(this.lastStatus, control)) {
      this.lastStatus = InputStatusChangeHelper.buildModel(this.inputControlId, this.name, this.label || this.placeholder, control);
      let status: EventModel = new EventModel("status", this.lastStatus, this.value, payload.element);
      this.status.emit(status);
    }

    // Update input control information
    this.updateInputInformation();

    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // Since our "ib-input-*" component has this method as does the native html input control.
      // If we used different event names then it wouldn't be an issue but using these event names is more developer friendly.
      event.stopPropagation();
      event.preventDefault();
    } catch (err) {
      // Some custom components like multiselect do not have the above event method to fire.
      //Log.errorMessage(err);
    }

  }


  public isPrefixClickable(): boolean {
    if (!this.prefixClickEventEnabled || !this.prefixClick) {
      return false;
    }
    return (this.prefixClick.observers.length > 0);
  }

  public firePrefixClick(event) {
    if (!this.prefixClickEventEnabled || !this.prefixClick) {
      return;
    }
    const payload: EventModel = new EventModel("click", event, this.value, new EventElementModel("input-prefix", this.inputControlId, this.name, this.prefixText, this.prefixIcon));
    this.prefixClick.emit(payload);
  }

  public isSuffixClickable(): boolean {
    if (!this.suffixClickEventEnabled || !this.suffixClick) {
      return false;
    }
    return (this.suffixClick.observers.length > 0);
  }

  public fireSuffixClick(event) {
    if (this.pickListAddButtonSlot === 1) {
      this.addPickListValue(event);
      return;
    }
    if (!this.suffixClickEventEnabled || !this.suffixClick) {
      return;
    }
    const payload: EventModel = new EventModel("click", event, this.value, new EventElementModel("input-suffix", this.inputControlId, this.name, this.suffixText, this.suffixIcon));
    this.suffixClick.emit(payload);
  }

  public isSuffixClickable2(): boolean {
    if (!this.suffixClickEventEnabled2 || !this.suffixClick2) {
      return false;
    }
    return (this.suffixClick2.observers.length > 0);
  }

  public fireSuffixClick2(event) {
    if (this.pickListAddButtonSlot === 2) {
      this.addPickListValue(event);
      return;
    }
    if (!this.suffixClickEventEnabled2 || !this.suffixClick2) {
      return;
    }
    const payload: EventModel = new EventModel("click", event, this.value, new EventElementModel("input-suffix2", this.inputControlId, this.name, this.suffixText2, this.suffixIcon2));
    this.suffixClick2.emit(payload);
  }

  addPickListValue = async ($event: any) => {
    if (!this.uxService.appService.hasPermission("PickListValue", "A")) {
      Log.errorMessage("Unable to add pick list value since missing PickListValue add permissions.");
      return;
    }
    if (this.optionsPickListAddClick.observers.length > 0) {
      // optionsPickListAddClick action defined so call custom click event to handle the add since it may be
      // more complex(e.g.cascading, custom lists, etc.) than our default handling
      const payload: EventModel = new EventModel("click", event, this.value, new EventElementModel("pick-list-add", this.inputControlId, this.name, this.optionsPickListAddTooltip, "plus"));
      this.optionsPickListAddClick.emit(payload);
      return;
    }
    // No optionsPickListAddClick action so handle this ourselves
    const value = await this.uxService.pickListValueAdd(this.optionsPickListId, "", "", null, this.pickList);
    if (value) {
      this.pickList.push(Helper.pickListValueModelToSelectionModel(value));
    }
  }


  updateInputInformation() {

    if (this.value) {
      this.inputCharacterCount = this.value.toString().length;
    } else {
      this.inputCharacterCount = 0;
    }

    // Collect information here and then at the end update our class variables as appropriate
    let info: InputInformationValues = {};
    let errors: string[] = [];

    try {
      info.MinimumLength = (this.minlength || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })
      info.MaximumLength = (this.maxlength || 0).toLocaleString(undefined, { maximumFractionDigits: 0 });
      info.ActualLength = (this.inputCharacterCount || 0).toLocaleString(undefined, { maximumFractionDigits: 0 });
      info.RemainingLength = "0";
      if (this.maxlength) {
        let remaining: number = ((this.maxlength || 0) - (this.inputCharacterCount || 0));
        info.RemainingLength = (remaining || 0).toLocaleString(undefined, { maximumFractionDigits: 0 });
      }
      info.FormatErrorMessage = "";
      info.OtherErrorMessage = "";
    } catch (err) {
      Log.errorMessage(err);
    }

    if (this.lastStatus && this.lastStatus.errors) {

      try {

        if (this.lastStatus.errors.required) {
          errors.push(this.errorRequiredMessage);
        }

        if (this.lastStatus.errors.minlength) {
          info.ActualLength = (this.lastStatus.errors.minlength.actualLength || this.inputCharacterCount || 0).toLocaleString(undefined, { maximumFractionDigits: 0 });
          errors.push(this.errorMinimumLengthMessage);
        }

        if (this.lastStatus.errors.maxlength) {
          info.ActualLength = (this.lastStatus.errors.maxlength.actualLength || this.inputCharacterCount || 0).toLocaleString(undefined, { maximumFractionDigits: 0 });
          errors.push(this.errorMaximumLengthMessage);
        }

        if (this.lastStatus.errors.ngbDate) {
          info.FormatErrorMessage = this.lastStatus.errors.ngbDate.invalid;
          errors.push(this.errorInvalidFormatMessage);
        }

        if (this.lastStatus.errors.pattern) {
          info.FormatErrorMessage = JSON.stringify(this.lastStatus.errors.pattern);
          errors.push(this.errorInvalidFormatMessage);
        }

        if (!this.lastStatus.errors.required &&
          !this.lastStatus.errors.minlength &&
          !this.lastStatus.errors.maxlength &&
          !this.lastStatus.errors.pattern &&
          !this.lastStatus.errors.ngbDate) {
          info.OtherErrorMessage = JSON.stringify(this.lastStatus.errors);
          errors.push(this.errorOtherMessage);
        }

      } catch (err) {
        Log.errorMessage(err);
      }

    }

    // Collecting these values in a new array and new object will trigger translation pipe updates
    // the key to not call this method when not needed so we fire on key up and change events.
    this.errorMessages = errors;
    this.inputInformationValues = info;

  }


  //typeaheadSearch = (text$: Observable<string>) => {
  //  if (!this.typeahead) {
  //    return of([]);
  //  }
  //  if (!this.pickList || this.pickList.length === 0) {
  //    return of([]);
  //  }
  //  return text$.pipe(
  //    debounceTime(200),
  //    distinctUntilChanged(),
  //    map(term => term.length < 2 ? []
  //      : this.pickList.filter(v => Helper.contains(v.Value, term, true)).slice(0,10).map(s => s.Value))
  //  );
  //}

  typeaheadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        if (term.length === 0 && this.typeAheadShowAllWhenEmpty) {
          return this.pickList.map(s => s.Value);
        }
        else {
          return term.length < this.typeAheadMinLength ? []
            : this.pickList.filter(v => Helper.contains(v.Value, term, true)).slice(0, this.typeAheadMaxResults).map(s => s.Value)
        }
      })
    );


  trackByIndex(index, item) {
    return index; // or item.id
  }

}


// Use ProperCase to match template placeholders used server side
export interface InputInformationValues {
  MinimumLength?: string;
  MaximumLength?: string;
  ActualLength?: string;
  RemainingLength?: string;
  FormatErrorMessage?: string;
  OtherErrorMessage?: string;
}
