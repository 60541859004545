import { Pipe, PipeTransform } from '@angular/core';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import * as Enumerable from 'linq';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";

@Pipe({
  name: 'filterPropertyValue'
})
export class FilterPropertyValuePipe implements PipeTransform {

  public transform(value: any[], propertyName: string, match: any, comparisonOperator: string = "="): any[] {

    if (!value || !Helper.isArray(value) || value.length === 0) {
      return [];
    }

    if (!propertyName) {
      return value;
    }

    if (!match) {
      return value;
    }

    let matches: any[] = [];
    try {
      if (comparisonOperator === "=") {
        matches = Enumerable.from(value).where(x => x[propertyName] === match).toArray();
      } else if (comparisonOperator === "!=" || comparisonOperator === "<>") {
        matches = Enumerable.from(value).where(x => x[propertyName] !== match).toArray();
      } else if (comparisonOperator === "$") {
        matches = Enumerable.from(value).where(x => Helper.contains(x[propertyName].toString(), match.toString(), true)).toArray();
      } else {
        Log.errorMessage(`Requested filter property value pipe comparison operator ${comparisonOperator} not supported.  Supported operators are: = and $.`);
        return value;
      }
    } catch (err) {
      Log.errorMessage(err);
      return value;
    }

    return matches;

  }

  //transform(value: any, args?: any): any {
  //  return null;
  //}

}
