import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularDokaModule } from 'angular-doka';
import { AttachmentEditModalComponent } from './attachment-edit-modal.component';

@NgModule({
  imports: [
    CommonModule,
    AngularDokaModule,
  ],
  declarations: [
    AttachmentEditModalComponent
  ],
  exports: [
    AttachmentEditModalComponent
  ],
})
export class AttachmentEditModule { }
