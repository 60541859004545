import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { AssetService } from 'projects/core-lib/src/lib/services/asset.service';
import { UxService } from '../../services/ux.service';
import { FileUploadConfigurationSettings, FileUploadService, UploadCounters } from 'projects/core-lib/src/lib/services/file-upload.service';
import { takeUntil } from 'rxjs/operators';
import { FileUploadBaseClass } from '../file-upload-base/file-upload-base.component';

@Component({
  selector: 'ib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  providers: [FileUploadService]
})
export class FileUploadComponent extends FileUploadBaseClass implements FileUploadConfigurationSettings, OnInit, OnChanges {

  // Counter to increment when we should clear the upload history.  Each change results in the history being cleared.
  @Input() clearHistoryCount: number = 0;

  // Flag if we should show stats about the upload process.  Defaults to true.
  @Input() showStats: boolean = true;

  constructor(
    protected appService: AppService,
    protected uxService: UxService,
    protected apiService: ApiService,
    protected assetService: AssetService,
    protected sanitizer: DomSanitizer,
    protected fileUploadService: FileUploadService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    let uploadData = this.getConfigurationSettings();
    this.config = this.fileUploadService.configureDropZone(uploadData);
    this.subscribeToFileUploadServiceSubjects();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.clearHistoryCount) {
      this.counters.countPending = 0;
      this.counters.countStarted = 0;
      this.counters.countUploading = 0;
      this.counters.countDone = 0;
      this.counters.countSuccess = 0;
      this.counters.countError = 0;
    }

    const settings = this.getConfigurationSettings();
    this.config = this.fileUploadService.configureDropZone(settings);

  }

  // Call ngOnDestroy on BaseComponent so we can unsubscribe using this.ngUnsubscribe
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * Subscribes to the subjects on the file upload service.
   */
  subscribeToFileUploadServiceSubjects() {
    // This event can also be received via the (success) output on the dropzone component.
    this.fileUploadService.uploadSuccess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: EventModel) => {
      if (response) {
      }
    })

    // This event can also be received via the (error) output on the dropzone component.
    this.fileUploadService.uploadError$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: EventModel) => {
      if (response) {
      }
    })

    this.fileUploadService.localFilePosted$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: EventModel) => {
      if (response) {
        this.file.emit(response);
      }
    })

    this.fileUploadService.uploadCounters$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: UploadCounters) => {
      if (response) {
        //console.error("upload counter response: ", response);
        this.counters.countPending = response.countPending;
        this.counters.countStarted = response.countStarted;
        this.counters.countUploading = response.countUploading;
        this.counters.countDone = response.countDone;
        this.counters.countSuccess = response.countSuccess;
        this.counters.countError = response.countError;
      }
    })
  }


  /**
   * This method is called from the (success) output on the dropzone component signaling a
   * successful upload.
   * @param event
   */
  public fireSuccess(event: any) {
    // The event from the dropzone component is returned as an array with 3 items.
    // The second item (1st index) is what we accept as the 'data'. On success, it's
    // an api response wrapper from us where we can access the asset that was created.
    let data: any = event;
    if (Helper.isArray(event)) {
      if (event.length >= 2) {
        data = event[1];
      }
    }
    const payload: EventModel = new EventModel("success", event, data, new EventElementModel("upload"), this.counters);
    this.success.emit(payload);
    //console.error(payload);
  }


  /**
   * This method is called from the (error) output on the dropzone component signaling an error
   * with the upload.
   * @param event
   */
  public fireError(event: any) {
    // The event from the dropzone component is returned as an array with 3 items.
    // The second item (1st index) is what we accept as the response 'data'. On success, it's
    // an apiResponseWrapper from us, on error it's just a string saying what the error is.
    let data: any = event;
    if (Helper.isArray(event)) {
      if (event.length >= 2) {
        data = event[1];
      }
    }
    const payload: EventModel = new EventModel("error", event, data, new EventElementModel("upload"), this.counters);
    this.error.emit(payload);
    //console.error(payload);
  }

}
