<p-listbox [name]="name"
           [options]="selectItems"
           [(ngModel)]="value"
           [multiple]="multiple"
           [checkbox]="checkbox"
           [filter]="filter"
           [readonly]="readonly"
           [disabled]="disabled"
           [style]="{'width': listWidth}"
           [listStyle]="{'max-height':listMaxHeight, 'width': listWidth}"
           (onChange)="fireChange($event, inputControl)"
           #inputControl="ngModel">
  <ng-template *ngIf="headerIcon || headerText || headerActionButton"
               pTemplate="header">
    <div class="float-start"
         *ngIf="headerIcon || headerText">
      <ib-icon [icon]="headerIcon"
               [label]="headerText"></ib-icon>
    </div>
    <div class="float-end"
         style="margin-left:auto; order:2;"
         *ngIf="headerActionButton">
      <ib-action-button [button]="headerActionButton"
                        [data]="value"
                        [control]="inputControl"></ib-action-button>
    </div>
  </ng-template>
  <!--<p-header *ngIf="headerIcon || headerText || headerActionButton">
    <div class="float-start" *ngIf="headerIcon || headerText">
      <ib-icon [icon]="headerIcon" [label]="headerText"></ib-icon>
    </div>
    <div class="float-end" style="margin-left:auto; order:2;" *ngIf="headerActionButton">
      <ib-action-button [button]="headerActionButton" [data]="value" [control]="inputControl"></ib-action-button>
    </div>
  </p-header>-->
  <ng-template let-item
               pTemplate="item">
    <div class="d-flex flex-column">
      <div>
        <ib-icon *ngIf="display === 'DisplayText'"
                 [icon]="( item.PickListValue ? item.PickListValue.Icon : item.icon )"
                 [label]="item.label"></ib-icon>
        <ib-icon *ngIf="display === 'Value'"
                 [icon]="( item.PickListValue ? item.PickListValue.Icon : item.icon )"
                 [label]="item.value"></ib-icon>
        <ib-icon *ngIf="display === 'Both'"
                 [icon]="( item.PickListValue ? item.PickListValue.Icon : item.icon )"
                 [label]="item.label + ' (' + item.value + ')'"></ib-icon>
      </div>
      <div *ngIf="showDescription && item.PickListValue?.Description">
        <small><em>{{item.PickListValue.Description}}</em></small>
      </div>
    </div>
  </ng-template>
  <ng-template *ngIf="footerActionButton"
               pTemplate="footer">
    <div class="float-start">
      &nbsp;
    </div>
    <div class="float-end"
         style="margin-left:auto; order:2;"
         *ngIf="footerActionButton">
      <ib-action-button [button]="footerActionButton"
                        [data]="value"></ib-action-button>
    </div>
  </ng-template>
  <!--<p-footer *ngIf="footerActionButton">
    <div class="float-start">
      &nbsp;
    </div>
    <div class="float-end" *ngIf="footerActionButton">
      <ib-action-button [button]="footerActionButton" [data]="value"></ib-action-button>
    </div>
  </p-footer>-->
</p-listbox>
