import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { ButtonItem } from '../../ux-models';
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ListBoxComponent),
  multi: true
};

@Component({
  selector: 'ib-list-box',
  templateUrl: './list-box.component.html',
  styleUrls: ['./list-box.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class ListBoxComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  @Input() display: "DisplayText" | "Value" | "Both" = "DisplayText";
  @Input() headerIcon: string = "";
  @Input() headerText: string = "";
  @Input() headerActionButton: ButtonItem = null;
  @Input() footerActionButton: ButtonItem = null;

  @Input() listWidth: string = "100%";
  @Input() listMaxHeight: string = "250px";

  @Input() showDescription: boolean = false;
  @Input() multiple: boolean = true;
  @Input() checkbox: boolean = true;
  @Input() filter: boolean = true;

  selectedItems: any[] = [];

  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
  }

  ngOnInit() {
  }

  //ngOnChanges(changes: SimpleChanges) {
  //  super.ngOnChanges(changes);
  //  this.configure();
  //}

  //public configure() {
  //  // Call the base class configure method to handle a lot of this
  //  super.configure();
  //}

  //onChange($event) {
  //  this.fireChange($event, this.inputCon
  //  console.error("change", $event);
  //}

}
