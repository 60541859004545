<ng-container *ngIf="inline">
  <div class="form-group {{inputFormGroupClass}} {{outerClass}}">
    <label class="{{inputLabelClass}}"
           ngbTooltip="{{tooltip | translate}}"
           *ngIf="alignWithOtherControls">
      <span *ngIf="required && includeRequiredIcon">
        <ib-icon icon="exclamation-circle (solid)"
                 ngbTooltip="Required"></ib-icon>&nbsp;
      </span>
      {{labelPrefix}}{{( label | translate ) || label}}{{labelSuffix}}
    </label>
    <div class="{{inputWrapperClass}}">
      <div *ngFor="let option of options; let i = index"
           class="form-check form-check-inline">
        <input class="form-check-input form-check-input-inline"
               type="radio"
               name="{{name}}"
               id="{{inputControlId}}_{{i}}"
               ngbTooltip="{{tooltip | translate}}"
               [disabled]="disabled ? 'disabled' : null"
               [readonly]="readonly ? 'readonly' : null"
               (focus)="fireFocus($event, inputControl)"
               (blur)="fireBlur($event, inputControl)"
               (keyup)="fireKeyUp($event, inputControl)"
               (change)="fireChange($event, inputControl)"
               [value]="option.value"
               [(ngModel)]="value"
               [ngModelOptions]="{standalone: standalone}"
               #inputControl="ngModel">
        <label class="form-check-label form-check-label-inline"
               for="{{inputControlId}}_{{i}}"
               ngbTooltip="{{tooltip | translate}}">
          {{option.label}}
        </label>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!inline">
  <div *ngFor="let option of options; let i = index"
       class="form-group mb-0 {{inputFormGroupClass}}">
    <label *ngIf="i===0 && alignWithOtherControls"
           class="{{inputLabelClass}}"
           ngbTooltip="{{tooltip | translate}}">
      <span *ngIf="required">
        <ib-icon icon="exclamation-circle (solid)"
                 ngbTooltip="Required"></ib-icon>&nbsp;
      </span>
      {{labelPrefix}}{{label | translate}}{{labelSuffix}}
    </label>
    <label *ngIf="i!==0 && alignWithOtherControls"
           class="{{inputLabelClass}}">
      &nbsp;
    </label>
    <div class="{{inputWrapperClass}}">
      <input class="form-check-input {{inputSize}}"
             type="radio"
             name="{{name}}"
             id="{{inputControlId}}_{{i}}"
             ngbTooltip="{{tooltip | translate}}"
             [disabled]="disabled ? 'disabled' : null"
             [readonly]="readonly ? 'readonly' : null"
             (focus)="fireFocus($event, inputControl)"
             (blur)="fireBlur($event, inputControl)"
             (keyup)="fireKeyUp($event, inputControl)"
             (change)="fireChange($event, inputControl)"
             [value]="option.value"
             [(ngModel)]="value"
             [ngModelOptions]="{standalone: standalone}"
             #inputControl="ngModel">
      <label class="form-check-label"
             for="{{inputControlId}}_{{i}}"
             ngbTooltip="{{tooltip | translate}}">
        {{option.label}}
      </label>
    </div>
  </div>
</ng-container>
