<ng-template #popoverTitle>
  <div [innerHtml]="toolTipTitle | safe: 'html'"></div>
</ng-template>
<ng-template #popoverContent>
  <div [innerHtml]="toolTipText | safe: 'html'"></div>
</ng-template>
<div [ngClass]="{ 'align-right': options.align === 'right' }"
     [disableTooltip]="options.toolTipType !== 'tooltip'"
     [ngbTooltip]="toolTipText"
     [disablePopover]="options.toolTipType !== 'popover'"
     [popoverTitle]="popoverTitle"
     [ngbPopover]="popoverContent"
     popoverClass="popover-wider"
     #p="ngbPopover"
     (mouseleave)="p.close()"
     [openDelay]="100"
     [closeDelay]="25"
     [autoClose]="true"
     [container]="(options.toolTipAppendToBody ? 'body' : '')"
     [placement]="options.toolTipPlacement"
     triggers="mouseenter:mouseleave">
  <ng-container [ngSwitch]="options.dataType">
    <div *ngSwitchCase="'boolean'">
      <i class="far"
         [ngClass]="{'fa-check-square': data, 'fa-square': !data}"></i>
    </div>
    <div *ngSwitchCase="'date'">
      {{data | date : 'mediumDate'}}
    </div>
    <div *ngSwitchCase="'time'">
      {{data | date : 'mediumTime'}}
    </div>
    <div *ngSwitchCase="'datetime'">
      {{data | date : 'medium'}}
    </div>
    <div *ngSwitchCase="'currency'">
      <ng-container *ngIf="options.decimals">
        {{data | currency : supportingData : 'symbol' : '1.' + options.decimals + '-' + options.decimals }}
      </ng-container>
      <ng-container *ngIf="!options.decimals">
        {{data | currency : supportingData}}
      </ng-container>
    </div>
    <div *ngSwitchCase="'float'">
      <ng-container *ngIf="options.decimals">
        {{data | number : '1.' + options.decimals + '-' + options.decimals}}
      </ng-container>
      <ng-container *ngIf="!options.decimals">
        {{data}}
      </ng-container>
    </div>
    <div *ngSwitchCase="'picklist'">
      {{getPickListText(data)}}
    </div>
    <div *ngSwitchCase="'icon'">
      <ib-icon [icon]="data"></ib-icon>
    </div>
    <div *ngSwitchCase="'email'">
      <span [innerHtml]="data | emailLink"></span>
    </div>
    <div *ngSwitchCase="'avatar'">
      <ib-avatar [contact]="row"
                 [size]="25"></ib-avatar>
    </div>
    <div *ngSwitchCase="'function'">
      <span [innerHtml]="safeRenderOutput()"></span>
    </div>
    <div *ngSwitchCase="'pie-chart'">
      <p-chart type="pie"
               [data]="options.render(row)"
               [options]="chartOptions"></p-chart>
    </div>
    <div *ngSwitchDefault
         [ngClass]="{'text-truncate': !options.wrap}">
      <ng-container *ngIf="options.maxCharacterLength && data && data.toString().length > options.maxCharacterLength">
        <ng-container *ngIf="options.toolTipWhenMaxCharacterLengthExceeded">
          <div [ngbPopover]="data"
               triggers="mouseenter:mouseleave"
               container="body"
               popoverClass="popover-wider">
            {{data | limitTo : options.maxCharacterLength : true}}
          </div>
        </ng-container>
        <ng-container *ngIf="!options.toolTipWhenMaxCharacterLengthExceeded">
          {{data | limitTo : options.maxCharacterLength : true}}
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!options.maxCharacterLength || !data || data.toString().length <= options.maxCharacterLength">
        {{data}}
      </ng-container>
    </div>
  </ng-container>
</div>
