import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { EventModel, EventElementModel, EventModelTyped } from '../../ux-models';
import { ModalCommonOptions } from '../../modal/modal-common-options';
import { FormGroupFluentModel } from '../../form/form-fluent-model';
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { UxService } from '../../services/ux.service';
import { FormHelper } from '../../form/form-helper';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputSimpleObjectListComponent),
  multi: true
};

@Component({
  selector: 'ib-input-simple-object-list',
  templateUrl: './input-simple-object-list.component.html',
  styleUrls: ['./input-simple-object-list.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputSimpleObjectListComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  /**
   * Bind to data instead of ngModel and pick up changes via (change) event's $event.data property.
   */
  @Input() data: any[] = [];


  /**
   * If using super simple string edits we can use objectPropertyNames and objectLabels but if we want
   * something more advanced with data types, input control types, etc. then we should populate this or
   * objectPropertiesAdd and objectPropertiesEdit.
   */
  @Input() objectProperties: m.PropertyMetaDataViewModel[] = [];
  @Input() objectPropertiesAdd: m.PropertyMetaDataViewModel[] = [];
  @Input() objectPropertiesEdit: m.PropertyMetaDataViewModel[] = [];
  @Input() objectPropertiesList: m.PropertyMetaDataViewModel[] = [];

  @Input() objectPropertyNames: string[] = [];
  @Input() objectLabels: string[] = [];


  /**
   * If host is handling add then listen for this event.  If
   * add is handled internally by changing the object collection
   * then don't listen for this event.  Changes can be picked up
   * via the change event.
   */
  @Output() add: EventEmitter<EventModel> = new EventEmitter();

  /**
   * If host is handling edit then listen for this event.  If
   * edit is handled internally by changing the object collection
   * then don't listen for this event.  Changes can be picked up
   * via the change event.
   */
  @Output() edit: EventEmitter<EventModel> = new EventEmitter();

  /**
   * If host is handling delete then listen for this event.  If
   * delete is handled internally by changing the object collection
   * then don't listen for this event.  Changes can be picked up
   * via the change event.
   */
  @Output() delete: EventEmitter<EventModel> = new EventEmitter();


  /**
  Make TypeCode available in html view.
  */
  public TypeCode = m.System.TypeCode;


  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected uxService: UxService) {
    super(apiService, uxService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
    if (changes.data) {
      if (!this.data || !Helper.isArray(this.data)) {
        console.error("Input simple object list input data is not an array.");
        this.data = [];
      }
    }
    // Often we don't declare objectPropertiesList we just inherit it from objectPropertiesAdd or objectProperties
    if (changes.objectPropertiesAdd && this.objectPropertiesAdd && this.objectPropertiesAdd.length > 0 && (!this.objectPropertiesList || this.objectPropertiesList.length === 0)) {
      this.objectPropertiesList = Helper.deepCopy(this.objectPropertiesAdd);
    } else if (changes.objectProperties && this.objectProperties && this.objectProperties.length > 0 && (!this.objectPropertiesList || this.objectPropertiesList.length === 0)) {
      this.objectPropertiesList = Helper.deepCopy(this.objectProperties);
    }
  }

  public configure() {

    // Call the base class configure method to handle a lot of this
    super.configure();

    if (this.vertical) {
      this.inputWrapperClass += " ps-1";
    } else {
      this.inputWrapperClass += " form-check";
    }

    if (this.tight) {
      this.inputLabelClass += " pb-0";
    }

  }


  objectAdd() {

    const options: ModalCommonOptions = this.getModalOptions("add");
    const form: m5web.FormEditViewModel = this.getModalForm("add");
    const data: any = this.getNewObject("add");

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { Obj: any } = { Obj: data };

    const promise = this.uxService.modal.showDynamicFormModal(options, form, payload);
    promise.then((event: EventModelTyped<{ Obj: any }>) => {
      if (this.add?.observers && this.add.observers.length > 0) {
        const payloadAdd: EventModel = new EventModel("add", event, event.data.Obj, new EventElementModel("input", this.inputControlId, this.name, this.label, this.placeholder));
        this.add.emit(payloadAdd);
      } else {
        this.data.push(event.data.Obj);
        const payloadChange: EventModel = new EventModel("change", event, this.data, new EventElementModel("input", this.inputControlId, this.name, this.label, this.placeholder));
        this.change.emit(payloadChange);
      }
    }, (reason) => {
      // User hit cancel so nothing to save
    });

  }

  objectEdit(data: any, index: number) {

    const options: ModalCommonOptions = this.getModalOptions("edit");
    options.title = `Edit ${Helper.plural(this.label, 1)}`;
    const form: m5web.FormEditViewModel = this.getModalForm("edit");

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { Obj: any } = { Obj: Helper.deepCopy(data) };

    const promise = this.uxService.modal.showDynamicFormModal(options, form, payload);
    promise.then((event: EventModelTyped<{ Obj: any }>) => {
      if (this.edit?.observers && this.edit.observers.length > 0) {
        const payloadEdit: EventModel = new EventModel("edit", event, event.data.Obj, new EventElementModel("input", this.inputControlId, this.name, this.label, this.placeholder), { index: index });
        this.edit.emit(payloadEdit);
      } else {
        this.data[index] = event.data.Obj;
        const payloadChange: EventModel = new EventModel("change", event, this.data, new EventElementModel("input", this.inputControlId, this.name, this.label, this.placeholder));
        this.change.emit(payloadChange);
      }
    }, (reason) => {
      // User hit cancel so nothing to save
    });

  }

  objectDelete(data: any, index: number) {
    if (index < 0 || index > (this.data.length - 1)) {
      return;
    }
    if (this.edit?.observers && this.edit.observers.length > 0) {
      const payloadDelete: EventModel = new EventModel("delete", null, data, new EventElementModel("input", this.inputControlId, this.name, this.label, this.placeholder), { index: index });
      this.delete.emit(payloadDelete);
    } else {
      let message: string = "Delete this item?";
      if (this.objectProperties && this.objectProperties.length > 0) {
        let descriptionProperty = this.objectProperties[0];
        let matches = this.objectProperties.filter(x => x.IsDescriptionProperty);
        if (matches && matches.length > 0) {
          descriptionProperty = matches[0];
        }
        message = `Delete ${data[descriptionProperty.PropertyName]}?`;
      } else {
        message = `Delete ${data[this.objectPropertyNames[0]]}?`;
      }
      const promise = this.uxService.modal.confirmDelete(message, null);
      promise.then((answer) => {
        this.data.splice(index, 1);
        const payloadChange: EventModel = new EventModel("change", null, this.data, new EventElementModel("input", this.inputControlId, this.name, this.label, this.placeholder));
        this.change.emit(payloadChange);
      }, (cancelled) => {
        // No action
      });
    }
  }

  getModalOptions(mode: "add" | "edit"): ModalCommonOptions {
    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.size = "large";
    options.title = Helper.plural(this.label, 1);
    if (mode === "add") {
      options.title = `New ${Helper.plural(this.label, 1)}`;
    }
    return options;
  }

  getModalForm(mode: "add" | "edit"): m5web.FormEditViewModel {
    if (mode === "add" && this.objectPropertiesAdd && this.objectPropertiesAdd.length > 0) {
      return FormHelper.buildFormFromPropertyMetaData(this.objectPropertiesAdd, "Obj");
    } else if (mode === "edit" && this.objectPropertiesEdit && this.objectPropertiesEdit.length > 0) {
      return FormHelper.buildFormFromPropertyMetaData(this.objectPropertiesEdit, "Obj");
    } else if (this.objectProperties && this.objectProperties.length > 0) {
      return FormHelper.buildFormFromPropertyMetaData(this.objectProperties, "Obj");
    }
    const group = new FormGroupFluentModel("block");
    this.objectPropertyNames.forEach((propertyName, index, propertyNames) => {
      group.HasInputString(this.objectLabels[index], this.objectLabels[index], "Obj", propertyName);
    });
    const form: m5web.FormEditViewModel = new m5web.FormEditViewModel();
    form.Groups.push(group);
    return form;
  }

  getNewObject(mode: "add" | "edit"): any {
    if (mode === "add" && this.objectPropertiesAdd && this.objectPropertiesAdd.length > 0) {
      return FormHelper.buildFormDataObjectFromPropertyMetaData(this.objectPropertiesAdd);
    } else if (mode === "edit" && this.objectPropertiesEdit && this.objectPropertiesEdit.length > 0) {
      return FormHelper.buildFormDataObjectFromPropertyMetaData(this.objectPropertiesEdit);
    } else if (this.objectProperties && this.objectProperties.length > 0) {
      return FormHelper.buildFormDataObjectFromPropertyMetaData(this.objectProperties);
    }
    const data: any = {};
    this.objectPropertyNames.forEach((propertyName, index, propertyNames) => {
      data[propertyName] = "";
    });
    return data;
  }

}
