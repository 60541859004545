import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
//import { Column } from 'primeng/components/common/shared';
import { TableColumnOptions, PrimeColumn } from 'projects/common-lib/src/lib/table/table-column-options';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { IApiResponseWrapperTyped } from 'projects/core-lib/src/lib/api/ApiModels';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ChartHelpers } from 'projects/core-lib/src/lib/helpers/chart-helpers';
import { TableHelper } from '../table-helper';

@Component({
  selector: 'ib-standard-table-cell-output',
  templateUrl: './standard-table-cell-output.component.html',
  styleUrls: ['./standard-table-cell-output.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardTableCellOutputComponent implements OnInit, OnChanges {

  @Input() col: PrimeColumn;
  @Input() options: TableColumnOptions;
  @Input() data: any;

  /**
   * A value that supports the primary data.  This could be a
   * currency code for an amount, a name for an id, etc.
   */
  @Input() supportingData: any;

  @Input() row: any;

  /**
   * With ChangeDetectionStrategy.OnPush we have some scenarios where a change is
   * made that influences the cell output but is not one of the Input() properties.
   * For example, TableColumnOptions has a render function that may have properties
   * in the calling component that change how the render is done.  In those cases
   * we can increment the value present here to trigger a change.
   */
  @Input() refreshCount: number = 0;

  toolTipTitle: string = "";
  toolTipText: string = "";

  chartOptions: any = ChartHelpers.getTinyChartConfig();

  constructor(protected apiService: ApiService, protected ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadPickList();
    //console.error(this.chartOptions);
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.options && !this.col) {
      this.col = TableHelper.toPrimeColumn(this.options);
    }

    if ((changes.options && this.options) || (changes.row && this.row)) {
      this.toolTipTitle = this.options.toolTipTitle;
      this.toolTipText = this.options.toolTipText;
      if (this.options.toolTipTitleFunction && this.row) {
        this.toolTipTitle = this.options.toolTipTitleFunction(this.row);
        //console.error(this.toolTipText, this.row);
      }
      if (this.options.toolTipTextFunction && this.row) {
        this.toolTipText = this.options.toolTipTextFunction(this.row);
        //console.error(this.toolTipText, this.row);
      }
    }

    this.loadPickList();

    // Some late rendered items like text and icons from pick lists may not get displayed properly.
    // We do this when using our loadPickList method but a custom render function may be using it's
    // own pick list items for rendering.
    // If supportLateRender is true let's attempt a late refresh of the render output to accomodate for late rendered items.
    if (this.options.supportLateRender) {
      // 2 seconds is just a guess but should be adequate most of the time
      setTimeout(() => { this.ref.detectChanges(); }, 2000);
      setTimeout(() => { this.ref.detectChanges(); }, 10000);
    }

  }


  safeRenderOutput() {
    if (this.options.render) {
      try {
        return this.options.render(this.row);
      } catch (err) {
        console.error(`Property ${this.options.propertyName} with data type ${this.options.dataType} render function had an error.`, err);
        return this.data;
      }
    } else {
      // We asked to render but we don't have a render function so just
      // log a warning and return the data we were provided.
      console.warn(`Property ${this.options.propertyName} with data type ${this.options.dataType} wants to call render but the function is undefined.`);
      return this.data;
    }
  }

  getPickListText(value: string): string {

    // No value?  Return empty string
    if (Helper.isUndefinedOrNull(value)) {
      return "";
    }

    // No pick list options?  Return the value
    if (!this.options.optionsPickList || this.options.optionsPickList.length === 0) {
      return value;
    }

    // Include support for value being an array of strings
    if (Helper.isArray(value)) {
      const values: string[] = ((value as unknown) as string[]);
      if (!values || values.length === 0) {
        return value;
      }
      let output: string = "";
      values.forEach(x => {
        if (output) {
          output += ", ";
        }
        output += this.getPickListText(x);
      });
      return output;
    }

    if (typeof value !== "string") {
      // At runtime we may discover that our pick list value is a number when the pick list is a FK
      value = (<any>value).toString();
    }

    let matches: m5core.PickListSelectionViewModel[] = this.options.optionsPickList.filter(x => { return Helper.equals(x.Value, value, true); });
    if (matches && matches.length > 0) {
      return matches[0].DisplayText;
    }

    // No match then return the value
    return value;

  }

  loadPickList() {

    if (!this.options || !this.options.optionsPickListId) {
      // No options pick list id to load
      return;
    }

    if (this.options.optionsPickList && this.options.optionsPickList.length > 0) {
      // Already have options pick list data but just in case we need to update the display do detect changes here
      this.ref.detectChanges();
      return;
    }

    // Load the pick list options
    this.apiService.loadPickList(this.options.optionsPickListId).subscribe((result: IApiResponseWrapperTyped<m5core.PickListSelectionViewModel[]>) => {
      if (!result.Data.Success) {
        console.error(result.Data);
      } else {
        this.options.optionsPickList = result.Data.Data;
        // Now that we have pick list data let's fire off Change Detection
        this.ref.detectChanges();
      }
    });

  }


}
