<div class="row"
     style="padding-top:20px;">
  <div class="{{Constants.Layout.percent25Offset}} {{Constants.Layout.split2column}}">
    <div class="card">
      <h5 class="card-header">{{title}}</h5>
      <div class="card-body">
        <ng-container *ngIf="user.MultiFactorAuthenticationTarget && user.MultiFactorAuthenticationTarget.Method==='TOTP'">
          <p>
            To continue with your login request we need to verify your identity.
            Please check your authenticator application for the code to use.
          </p>
        </ng-container>
        <ng-container *ngIf="!user.MultiFactorAuthenticationTarget || user.MultiFactorAuthenticationTarget.Method!=='TOTP'">
          <p>
            To continue with your login request we need to verify your identity.
            A message containing an authentication code has been sent to you.
          </p>
          <p *ngIf="selectedOptionDescription">
            <strong>{{selectedOptionDescription}}</strong>
          </p>
        </ng-container>
        <p>
          Please enter the code in the form below to continue.
        </p>
        <ib-input-text name="Code"
                       label="Code"
                       width="medium"
                       [autofocus]="true"
                       [(ngModel)]="authenticationCode"
                       (keyUpIsEnter)="next($event)"></ib-input-text>
        <ib-input-wrapper label="">
          <ib-button color="success"
                     label="Continue"
                     [disabled]="!authenticationCode"
                     (click)="next($event)"></ib-button>
        </ib-input-wrapper>
        <div *ngIf="errorMessage"
             class="alert alert-danger"
             role="alert">
          {{errorMessage}}
        </div>
        <!-- moving registration to inside app post MFA -->
        <!-- <ib-input-wrapper label="" *ngIf="user.MultiFactorAuthenticationSupportsAppTotp">
          <div (click)="registerApp()" class="text-primary clickable underline-on-hover"><i class="fas fa-mobile-alt"></i>&nbsp;Register an authenticator application</div>
        </ib-input-wrapper> -->
        <ib-input-wrapper label=""
                          *ngIf="user.MultiFactorAuthenticationTarget && user.MultiFactorAuthenticationTarget.Method==='TOTP'">
          <div (click)="returnToStep1()"
               class="text-primary clickable underline-on-hover"><i class="far fa-redo"></i>&nbsp;Try a different method</div>
        </ib-input-wrapper>
        <ib-input-wrapper label=""
                          *ngIf="!user.MultiFactorAuthenticationTarget || user.MultiFactorAuthenticationTarget.Method!=='TOTP'">
          <div (click)="returnToStep1()"
               class="text-primary clickable underline-on-hover"><i class="far fa-redo"></i>&nbsp;Resend code</div>
        </ib-input-wrapper>
      </div>
    </div>
  </div>
</div>
