<div *ngIf="policy">
  <ng-container *ngFor="let item of status;trackBy: trackByIndex">
    <div *ngIf="item.Valid">
      <ib-icon icon="check-circle" color="green" label="{{item.Message | translate:item.Variables}}"></ib-icon>
    </div>
    <div *ngIf="!item.Valid">
      <ib-icon icon="times-circle" color="red" label="{{item.Message | translate:item.Variables}}"></ib-icon>
    </div>
  </ng-container>
</div>
