import { Injectable } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from './base.service';
import { ApiService } from '../api/api.service';
import { AppService } from './app.service';
import { AppCacheService } from './app-cache.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CodeEditorService extends BaseService {

  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected cache: AppCacheService,
    protected sanitizer: DomSanitizer,
    protected router: Router) {

    super();

  }


  getIconFromScriptLanguage(code: m5core.ScriptSourceViewModel, script: m5core.ScriptViewModel, defaultLanguage = "CSharp"): string {
    const language = this.getLanguageToUseFromScript(code, script, defaultLanguage);
    let icon: string = "code";
    // m5core.ScriptLanguage[m5core.ScriptLanguage.CSharp] // enum as string
    if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.CSharp], true)) {
      icon = "hashtag";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.JavaScript], true)) {
      icon = "js-square (brand)";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.Html], true)) {
      icon = "html5 (brand)";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.Css], true)) {
      icon = "css3 (brand)";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.Json], true)) {
      icon = "database";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.Text], true)) {
      icon = "file-lines";
    } else {
      icon = "code";
    }
    return icon;
  }


  getFileExtensionFromScriptLanguage(code: m5core.ScriptSourceViewModel, script: m5core.ScriptViewModel, defaultLanguage = "CSharp"): string {
    const language = this.getLanguageToUseFromScript(code, script, defaultLanguage);
    let extension: string = "txt";
    // m5core.ScriptLanguage[m5core.ScriptLanguage.CSharp] // enum as string
    if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.CSharp], true)) {
      extension = "cs";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.TypeScript], true)) {
      extension = "ts";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.JavaScript], true)) {
      extension = "js";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.Html], true)) {
      extension = "html";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.Css], true)) {
      extension = "css";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.Json], true)) {
      extension = "json";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.Text], true)) {
      extension = "txt";
    } else {
      extension = "txt";
    }
    return extension;
  }


  getLanguageToUseFromScript(code: m5core.ScriptSourceViewModel, script: m5core.ScriptViewModel, defaultLanguage = "CSharp"): string {
    if (!code && !script) {
      return;
    }
    let language: string = code?.Language;
    if (!language) {
      language = script?.Language;
    }
    if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.Inherited], true)) {
      language = script?.Language;
    }
    if (!language) {
      language = defaultLanguage;
    }
    return language;
  }


  getEditorModeFromScriptLanguage(code: m5core.ScriptSourceViewModel, script: m5core.ScriptViewModel, defaultLanguage = "CSharp"): string {
    const language = this.getLanguageToUseFromScript(code, script, defaultLanguage);
    let mode: string = "csharp";
    // m5core.ScriptLanguage[m5core.ScriptLanguage.CSharp] // enum as string
    if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.CSharp], true)) {
      mode = "csharp";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.JavaScript], true)) {
      mode = "javascript";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.Html], true)) {
      mode = "html";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.Css], true)) {
      mode = "css";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.Json], true)) {
      mode = "json";
    } else if (Helper.equals(language, m5core.ScriptLanguage[m5core.ScriptLanguage.Text], true)) {
      mode = "plain_text";
    } else {
      mode = "csharp";
    }
    return mode;
  }


  getEditorModeFromFileExtension(fileExtension: string): string {
    if (!fileExtension) {
      fileExtension = "txt";
    } else {
      fileExtension = fileExtension.toLowerCase();
    }
    if (Helper.startsWith(fileExtension, "htm", true)) {
      return "html";
    } else if (fileExtension === "js") {
      return "javascript";
    } else if (fileExtension === "xml") {
      return "xml";
    } else if (fileExtension === "css") {
      return "css";
    } else if (fileExtension === "cs") {
      return "csharp";
    } else if (fileExtension === "txt") {
      return "plain_text";
    } else if (fileExtension === "json") {
      return "json";
    } else if (fileExtension === "md") {
      return "markdown";
    } else if (fileExtension === "ps") {
      return "powershell";
    } else if (fileExtension === "sql") {
      return "sqlserver";
    } else if (fileExtension === "ts") {
      return "typescript";
    } else {
      Log.errorMessage(`Code editor had unrecognized file extension ${fileExtension} so defaulting to "plain_text" mode.`);
      return "plain_text";
    }
  }



  getAuditTrailCustomizationScript(tableName: string = ""): m5core.ScriptViewModel {

    const className: string = `AuditTrail_${tableName}_${Helper.formatDateTime(new Date(), "yyyy_MM_dd")}`;
    const script = this.getDefaultScript(className, `AuditTrail_${tableName}`);

    script.FullyQualifiedTypeName = `IB.Data.Service.Audit.${className}`;
    script.Interface = `IAuditTrailCustomization<TEntity>`;

    let source: string =
      `namespace IB.Data.Service.Audit
{

	public class #ClassNamePlaceHolder#<TEntity> : IAuditTrailCustomization<TEntity> where TEntity : class , new()
	{

        public Func<DatabaseInfo , AuditTrailModel , TEntity , TEntity , Result<AuditTrailModel>> AuditTrailCustomization()
        {
            return #ClassNamePlaceHolder#<TEntity>.AuditTrailCustomizationLogic;
        }

        public static Result<AuditTrailModel> AuditTrailCustomizationLogic( DatabaseInfo dataInfo , AuditTrailModel model , TEntity oldData , TEntity newData )
        {

            Result<AuditTrailModel> result = new Result<AuditTrailModel>( StandardResultCode.Success );
            result.OperationType = OperationType.Helper;
            result.Value = model;

            // oldData will be null on insert, newData will be null on delete
            // If desired we can map these entities to models for more user friendly access to complex properties that get morphed with model mapping.

            // AuditTrailModel.AuditInformation is a string that contains JSON that looks similar to this:
            /*
            {
                "Action":  "Insert | Update | Delete",
                "ActionMessage":  "Assigned to Fred.",
                "Old": { "DateOfBirth":, "1972-04-15 00:00:00", "Age": "41" } ,
                "New": { "DateOfBirth":, "1971-04-15 00:00:00", "Age": "42" } ,
                "Messages": [
	                "Did one certain thing." ,
	                "Did another thing as well."
                ]
            }
            */

            // Parse audit information into an object
            AuditInformationDynamic info = AuditTrailService.AuditInformationParser( model.AuditInformation );

            // Set this bool to true if we change anything about our audit information
            bool auditInfoChanged = false;

            // In some cases instead of having a certain property listed in Old/New collection we may
            // simply want it replaced with a message like this example:
            /*
            if ( model.TransactionType.TryEquals( "U" , true ) )
            {
                string key = "SubjectMatter";
                if ( info.Old.ContainsKey( key ) || info.New.ContainsKey( key ) )
                {
                    auditInfoChanged = true;
                    info.Old.Remove( key );
                    info.New.Remove( key );
                    info.Messages.Add( "Subject matter changed." );
                }
                key = "Notes";
                if ( info.Old.ContainsKey( key ) || info.New.ContainsKey( key ) )
                {
                    auditInfoChanged = true;
                    info.Old.Remove( key );
                    info.New.Remove( key );
                    // Changes to notes logged separately
                }
            }
            */

            // If we modified any audit information then post that back
            if ( auditInfoChanged )
            {
                result.Value.AuditInformation = info.ToJson();
            }

            return result;

        }

    }

}
`;

    source = Helper.replaceAll(source, "#ClassNamePlaceHolder#", className);
    script.Code[0].SourceCode = source;

    return script;

  }


  getTriggerScript(tableName: string = ""): m5core.ScriptViewModel {

    const triggerName: string = `${tableName}_${Helper.formatDateTime(new Date(), "yyyy_MM_dd")}_${Helper.createBase36Guid(5)}`;
    const className: string = `Triggers_${triggerName}`;
    const script = this.getDefaultScript(className, triggerName);

    script.FullyQualifiedTypeName = `IB.Data.Service.Triggers.${className}`;
    script.Interface = `ITriggerDeclaration<TModel,TEntity>`;

    const wrapperSource: string =
      `namespace IB.Data.Service.Triggers
{

	public class #ClassNamePlaceHolder#<TModel,TEntity> : ITriggerDeclaration<TModel,TEntity>
        where TModel : class , new()
        where TEntity : class , new()
	{

        // This method returns the list of triggers to be used.
        // This is appended to any existing triggers provided the trigger id values are unique.
        public List<Trigger<TModel , TEntity>> Triggers()
        {
            var triggers = new List<Trigger<TModel , TEntity>>();
            // Each trigger that is declared is added to our list here
            triggers.Add( this.SampleTrigger1() );
            return triggers;
        }

        // When there are a lot of triggers registering each trigger as its own partial code may enhance readability.
        // All partials that share the same 'Group' setting with this wrapper are combined and injected at the marker.

        /*INJECT-CODE-HERE*/

    }

}
`;

    const wrapper = this.getDefaultScriptSource("Triggers");
    wrapper.Group = "Triggers";
    wrapper.SourceCode = Helper.replaceAll(wrapperSource, "#ClassNamePlaceHolder#", className);
    script.PartialSourceCodeWrappers.push(wrapper);

    let triggerSource: string =
      `public Trigger<TModel , TEntity> SampleTrigger1()
{

    // This is a sample trigger only.  There is already a built-in trigger for checking permissions and this is only for example purposes.

    var trigger = new Trigger<TModel , TEntity>();

    // The trigger id must be unique among all triggers for a table.  To avoid conflict with build-in triggers prefix the trigger id with 'Custom'.
    trigger.Id = "Custom_#TriggerNamePlaceHolder#_PermissionCheck";
    trigger.Description = "Check Permissions";
    // Set IsAsync to true if the trigger can run async.
    trigger.IsAsync = false;
    // Set IsForgettable to true if the trigger is running async and you do not care about the results.
    trigger.IsForgettable = false;
    // Triggers are executed in priority order with the lowest priority going first.
    // Depending on what happens when a trigger fails other triggers may never be executed.
    trigger.Priority = 100;
    // Define what events this trigger will execute for.  Multiple events can be combined with |.
    // Possible values include:
    // ReadBefore, ReadAfterSuccess, ReadAfterFail
    // AddBefore, AddAfterSuccess, AddAfterFail
    // EditBefore, EditAfterSuccess, EditAfterFail
    // DeleteBefore, DeleteAfterSuccess, DeleteAfterFail
    // DeleteSoftBefore, DeleteSoftAfterSuccess, DeleteSoftAfterFail
    // DeleteSoftUndoBefore, DeleteSoftUndoAfterSuccess, DeleteSoftUndoAfterFail
    // DeleteHardBefore, DeleteHardAfterSuccess, DeleteHardAfterFail
    // WriteBefore, WriteAfterSuccess, WriteAfterFail
    // AnyBefore, AnyAfterSuccess, AnyAfterFail
    // For triggers assigned to explicit event objects (e.g. DataTableSupportModel.Triggers[*].TriggerOnAddSuccess.Triggers) this can
    // be skipped since it is known from the context (e.g. TriggerOnAddSuccess) but for shared triggers (e.g. DataTableSupportModel.Triggers[*].Triggers)
    // the event(s) the trigger applies to must be set here.
    trigger.Event = Trigger.TriggerEvent.AnyBefore;
    // Define what should happen when a trigger fails.  Multiple rules can be combined with |.
    // Possible values include:
    // Ignore - Ignore the trigger failure
    // LogWarning - Log a warning about the trigger failure
    // LogError - Log an error about the trigger failure
    // LogFatalError - Log a fatal error about the trigger failure
    // TriggeringEventResultIncludeError - Update the trigger event's result object to include the error
    // TriggeringEventResultIsPartialSuccess - Demote the trigger event's result code from Success to PartialSuccess
    // TriggeringEventResultIsFailed - Demote the trigger event's result code to failure reported by the trigger
    // TriggeringEventRollbackRequest - Request that the triggering event rollback it's operation (if possible and supported)
    // AbortRemainingTriggers - Abort any remaining triggers
    // The routine that executes the trigger handles all of the OnFail actions
    trigger.OnFail = Trigger.OnTriggerFail.AbortRemainingTriggers | Trigger.OnTriggerFail.TriggeringEventResultIsFailed;

    // The trigger action is the meat of the trigger.  It accepts 5 parameters and returns a result object.  The parameters are:
    // 1. A data access layer object that is the context the trigger is executed under.
    // 2. The triggering event enum.  This can be used to execute different logic for different event types.  e.g. triggerEvent.HasFlag( Trigger.TriggerEvent.AddAfterSuccess )
    // 3. The before entity object.  For add and read event types this is null.
    // 4. The after entity object.  For delete event types this is null.
    // 5. The triggering event's operation result object.
    // The trigger action returns a Result<object> object which indicates if the trigger action was successful or not.
    // If the trigger result indicates a failure the routine that fired the trigger will handle the OnFail actions.
    // Assigning a meaningful Id to the result object and using stopwatches can provide insight when there are performance or other unexpected problems.
    trigger.TriggerAction = ( DataAccess<TModel , TEntity> dal , Trigger.TriggerEvent triggerEvent , TEntity before , TEntity after , Result<TEntity> operationResult ) =>
    {
        Result<object> result = new Result<object>( StandardResultCode.Success );
        result.OperationType = OperationType.Trigger;
        result.Id = $"PermissionCheck-{triggerEvent}-{dal?.EntityMetaData?.EntityName}";
        Permission permission = Permission.None;
        try
        {
            permission = triggerEvent.ToPermission();
            result.Id = $"PermissionCheck-{permission}-{dal?.EntityMetaData?.EntityName}";
            result.Stopwatches.Start( "PermissionCheck" , Stopwatches.Timer.TimerType.Trigger );
            Result permissionResult = dal.HasPermission( permission );
            result.PostResultIfFailure( permissionResult );
            result.Stopwatches.Stop( "PermissionCheck" , Stopwatches.Timer.TimerType.Trigger );
        }
        catch ( Exception ex )
        {
            result.PostResult( ex , $"Checking permissions for {permission}" );
        }
        return result;
    };

    return trigger;

}
`;

    triggerSource = Helper.replaceAll(triggerSource, "#TriggerNamePlaceHolder#", triggerName);
    script.Code[0].SourceCode = triggerSource;
    script.Code[0].Partial = true;
    script.Code[0].Group = "Triggers";

    return script;

  }


  getDefaultScript(scriptName: string = "", sourceCodeName: string = "", languageType: "server" | "client" = "server", programType: string = ""): m5core.ScriptViewModel {

    const script = new m5core.ScriptViewModel();
    script.Name = scriptName || "";
    script.Enabled = true;
    script.Version = 1;
    script.UseCache = true;
    script.Properties = {};
    script.Properties.SampleString = "foobar";
    script.Properties.SampleNumber = 123;
    script.Properties.SampleBoolean = true;
    if (languageType === "client") {
      script.Language = m5core.ScriptLanguage[m5core.ScriptLanguage.JavaScript]; // Enum value as string
      script.SupportedLanguages.push(m5core.ScriptLanguage[m5core.ScriptLanguage.JavaScript]); // Enum value as string
      const jsCode = this.getDefaultScriptSource(sourceCodeName, languageType, m5core.ScriptLanguage.JavaScript);
      script.Code.push(jsCode);
      if (programType && Helper.equals(programType, "WebApp", true)) {
        script.SupportedLanguages.push(m5core.ScriptLanguage[m5core.ScriptLanguage.Html]); // Enum value as string
        script.SupportedLanguages.push(m5core.ScriptLanguage[m5core.ScriptLanguage.Css]); // Enum value as string
        script.SupportedLanguages.push(m5core.ScriptLanguage[m5core.ScriptLanguage.Json]); // Enum value as string
        script.SupportedLanguages.push(m5core.ScriptLanguage[m5core.ScriptLanguage.Text]); // Enum value as string
        script.AllowMultipleLanguages = true;
      }
    } else {
      script.Language = m5core.ScriptLanguage[m5core.ScriptLanguage.CSharp]; // Enum value as string
      script.SupportedLanguages.push(m5core.ScriptLanguage[m5core.ScriptLanguage.CSharp]); // Enum value as string
      script.ReferencedAssemblies.push("IB.Core.dll");
      script.ReferencedAssemblies.push("IB.Data.Entity.dll");
      script.ReferencedAssemblies.push("IB.Data.Model.dll");
      script.ReferencedAssemblies.push("IB.Data.Service.dll");
      const code = this.getDefaultScriptSource(sourceCodeName, languageType);
      script.Code.push(code);
    }

    return script;

  }


  getDefaultScriptSource(sourceCodeName: string = "", languageType: "server" | "client" = "server", language: m5core.ScriptLanguage = m5core.ScriptLanguage.Inherited): m5core.ScriptSourceViewModel {

    const code = new m5core.ScriptSourceViewModel();
    code.Name = sourceCodeName || "";
    code.Enabled = true;
    code.Order = 100;
    code.SourceCode = "";
    code.Language = m5core.ScriptLanguage[language]; // Enum value as string

    if (languageType === "client") {
      // Any client side language settings?
    } else {
      code.Usings.push("using System;");
      code.Usings.push("using System.Collections.Generic;");
      code.Usings.push("using System.Dynamic;");
      code.Usings.push("using System.IO;");
      code.Usings.push("using System.Linq;");
      code.Usings.push("using System.Text;");
      code.Usings.push("using System.Threading;");
      code.Usings.push("using System.Threading.Tasks;");
      //code.Usings.push("using System.Reflection;");
      //code.Usings.push("using System.Runtime.CompilerServices;");
      //code.Usings.push("using System.Xml;");
      code.Usings.push("using IB.Core;");
      code.Usings.push("using IB.Data.Entity;");
      code.Usings.push("using IB.Data.Model;");
      code.Usings.push("using IB.Data.Service;");
      code.Usings.push("using IB.Data.Service.Repository;");
      code.Usings.push("using IB.Data;");
    }

    return code;

  }



}
