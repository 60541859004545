<form #f="ngForm">
  <fieldset *ngIf="data"
            [disabled]="( disabled || mode === 'view' || ( filter && filter.FilterLocked ) ) ? 'disabled' : null">
    <ng-container *ngTemplateOutlet="filterGroup; context: {$implicit: data}"></ng-container>
  </fieldset>
</form>



<ng-template #filterGroup
             let-group
             let-parentGroup="parentGroup"
             let-gIndex="index">
  <div class="filter-group"
       style="border-color: cadetblue; border-width: 2px; border-style: solid; padding: 5px; margin: 1px 1px 1px 10px;">
    <div *ngIf="group.Conditions && group.Conditions.length > 0"
         class="filter-conditions-list">
      <!--style="border-color: darkblue; border-width: 2px; border-style: solid; padding: 5px; margin: 1px;"-->
      <div class="p-1 clearfix">
        <span class="pull-left">Where </span>
        <ib-input-select-button name="Select Bool Op"
                                label="Operator"
                                size="small"
                                class="pull-left"
                                [(ngModel)]="group.ConditionBooleanOperator"
                                [optionsIncludeNone]="false"
                                [optionsPickList]="booleanOperatorPickList"
                                (change)="formDirty()">
        </ib-input-select-button>
        <span class="pull-left"> of the following conditions apply</span>
      </div>
      <ng-container *ngFor="let condition of group.Conditions; let conditionIndex = index; trackBy: trackByIndex">
        <ng-container *ngTemplateOutlet="filterCondition; context: {$implicit: condition, group: group, index: conditionIndex}"></ng-container>
      </ng-container>
    </div>
    <div *ngIf="group.Groups && group.Groups.length > 0"
         class="filter-groups-list">
      <div class="p-1 clearfix">
        <span class="pull-left">Where </span>
        <ib-input-select-button name="Select Group Op"
                                label="Operator"
                                size="small"
                                class="pull-left"
                                [(ngModel)]="group.GroupBooleanOperator"
                                [optionsIncludeNone]="false"
                                [optionsPickList]="booleanOperatorPickList"
                                (change)="formDirty()">
        </ib-input-select-button>
        <span class="pull-left"> of the following groups apply</span>
      </div>
      <ng-container *ngFor="let childGroup of group.Groups; let groupIndex = index; trackBy: trackByIndex">
        <ng-container *ngTemplateOutlet="filterGroup; context: {$implicit: childGroup, parentGroup: group, index: groupIndex}"></ng-container>
      </ng-container>
    </div>
    <div class="clearfix">
      <ib-action-button [button]="actionButton"
                        [data]="group"
                        class="float-start me-2 mt-1"></ib-action-button>
      <ib-icon icon="times"
               color="danger"
               tooltip="Delete Group"
               class="float-start"
               (click)="onDeleteGroup($event, group, parentGroup, gIndex)"></ib-icon>
    </div>
  </div>
</ng-template>

<ng-template #filterCondition
             let-condition
             let-group="group"
             let-index="index">
  <div class="p-1 clearfix">
    <ib-input-select-button name="Select Column"
                            label="Column"
                            size="small"
                            color="success"
                            class="float-start me-2"
                            [(ngModel)]="condition.PropertyName"
                            [tooltip]="condition?.Meta?.Description"
                            [optionsIncludeNone]="false"
                            [optionsPickList]="columnPickList"
                            [allowSearch]="true"
                            (change)="onColumnSelect($event, group, index)">
    </ib-input-select-button>
    <ib-input-select-button *ngIf="condition.DataType === TypeCode.String"
                            name="Comparison Operator"
                            label="Operator"
                            size="small"
                            color="warning"
                            class="float-start me-2"
                            [(ngModel)]="condition.ComparisonOperator"
                            [optionsIncludeNone]="false"
                            [optionsPickList]="compareStringPickList"
                            (change)="onComparisonOperatorSelect($event, condition)">
    </ib-input-select-button>
    <ib-input-select-button *ngIf="condition.DataType !== TypeCode.String"
                            name="Comparison Operator"
                            label="Operator"
                            size="small"
                            color="warning"
                            class="float-start me-2"
                            width="medium"
                            [(ngModel)]="condition.ComparisonOperator"
                            [optionsIncludeNone]="false"
                            [optionsPickList]="compareNonStringPickList"
                            (change)="onComparisonOperatorSelect($event, condition)">
    </ib-input-select-button>
    <ib-input-select-plain *ngIf="condition.DataType === TypeCode.DateTime"
                           name="Date Range"
                           label="Date Range"
                           size="small"
                           class="float-start pe-1"
                           format="pull-left"
                           [plainInput]="true"
                           wrapperClasses="ps-0"
                           [tooltip]="condition?.Meta?.Description"
                           [optionsPickList]="dateRangeOptionsPickList"
                           [optionsIncludeNone]="false"
                           [(ngModel)]="condition.ValueMetaData"
                           (change)="formDirty()">
    </ib-input-select-plain>
    <ib-input-select-plain *ngIf="isContactId(condition)"
                           name="Contact"
                           label="Contact"
                           size="small"
                           class="float-start"
                           format="pull-left"
                           [plainInput]="true"
                           wrapperClasses="ps-0 pe-2"
                           [tooltip]="condition?.Meta?.Description"
                           [optionsPickList]="contactIdOptionsPickList"
                           [optionsIncludeNone]="false"
                           [(ngModel)]="condition.ValueMetaData"
                           (change)="formDirty()">
    </ib-input-select-plain>
    <ib-input-select-plain *ngIf="isSelectSingleInput(condition)"
                           size="small"
                           class="float-start"
                           format="pull-left"
                           [plainInput]="true"
                           wrapperClasses="ps-0"
                           [tooltip]="condition?.Meta?.Description"
                           [optionsPickListId]="condition.Meta.PickListId"
                           [optionsPickList]="condition.Meta.PickListOptions"
                           [optionsIncludeNone]="true"
                           [(ngModel)]="condition.Value"
                           (change)="formDirty()">
    </ib-input-select-plain>
    <ib-input-multiselect *ngIf="isMultiSelectSingleInput(condition)"
                          size="small"
                          class="float-start"
                          format="pull-left"
                          [plainInput]="true"
                          wrapperClasses="ps-0"
                          [tooltip]="condition?.Meta?.Description"
                          [optionsPickListId]="condition.Meta.PickListId"
                          [optionsPickList]="condition.Meta.PickListOptions"
                          [optionsIncludeNone]="true"
                          [modelIsCsvString]="true"
                          [(ngModel)]="condition.Value"
                          (change)="formDirty()">
    </ib-input-multiselect>
    <ib-input-plain *ngIf="isTextSingleInput(condition) && condition.DataType !== TypeCode.DateTime"
                    type="text"
                    size="small"
                    placeholder="{{( condition.ComparisonOperator === 'In' || condition.ComparisonOperator === 'NotIn' ? 'Values (csv)' : 'Value' )}}"
                    class="float-start"
                    format="pull-left"
                    [plainInput]="true"
                    wrapperClasses="ps-0"
                    [tooltip]="condition?.Meta?.Description"
                    [(ngModel)]="condition.Value"
                    (change)="formDirty()"
                    (keyUp)="formDirty()">
      <!--[wrapperStyles]="'width:300px;'"-->
    </ib-input-plain>
    <ib-input-contact *ngIf="canDisplayInputContact(condition) && condition.DataType !== TypeCode.DateTime"
                      name="ContactName"
                      class="float-start"
                      style="padding-right: 10px"
                      size="small"
                      placeholder="{{condition.ValueDescription}}"
                      [vertical]="true"
                      [data]="{ ContactId: condition.Value, ContactName: condition.ValueDescription, ContactType: '', ContactEmail: '' }"
                      [contactTypes]="[Constants.ContactType.Directory, Constants.ContactType.Group, Constants.ContactType.Customer]"
                      (change)="setContact($event, condition)">
    </ib-input-contact>
    <ib-input-date *ngIf="isTextSingleInput(condition) && condition.DataType === TypeCode.DateTime"
                   type="date"
                   size="small"
                   placeholder="Date"
                   class="float-start pe-1"
                   format="pull-left"
                   [plainInput]="true"
                   wrapperClasses="ps-0 mb-0"
                   [tooltip]="condition?.Meta?.Description"
                   [(ngModel)]="condition.Value"
                   (change)="condition.Value = $event; formDirty();"
                   (keyUp)="formDirty()">
    </ib-input-date>
    <ng-container *ngIf="isTextRangeInput(condition) && condition.DataType !== TypeCode.DateTime">
      <ib-input-plain type="text"
                      size="small"
                      placeholder="Value"
                      class="float-start"
                      format="pull-left"
                      [plainInput]="true"
                      wrapperClasses="ps-0"
                      [tooltip]="condition?.Meta?.Description"
                      [(ngModel)]="condition.Value[0]"
                      (change)="formDirty()"
                      (keyUp)="formDirty()">
      </ib-input-plain>
      <span class="float-start me-2">&nbsp;and&nbsp;</span>
      <ib-input-plain type="text"
                      size="small"
                      placeholder="Value"
                      class="float-start"
                      format="pull-left"
                      [plainInput]="true"
                      wrapperClasses="ps-0"
                      [tooltip]="condition?.Meta?.Description"
                      [(ngModel)]="condition.Value[1]"
                      (change)="formDirty()"
                      (keyUp)="formDirty()">
      </ib-input-plain>
    </ng-container>
    <ng-container *ngIf="isTextRangeInput(condition) && condition.DataType === TypeCode.DateTime">
      <ib-input-date type="date"
                     size="small"
                     placeholder="Value"
                     class="float-start pe-1"
                     format="pull-left"
                     [plainInput]="true"
                     wrapperClasses="ps-0"
                     [tooltip]="condition?.Meta?.Description"
                     [(ngModel)]="condition.Value[0]"
                     (change)="condition.Value = $event; formDirty();"
                     (keyUp)="formDirty()">
      </ib-input-date>
      <span class="float-start me-2">&nbsp;and&nbsp;</span>
      <ib-input-date type="date"
                     size="small"
                     placeholder="Value"
                     class="float-start pe-1"
                     format="pull-left"
                     [plainInput]="true"
                     wrapperClasses="ps-0"
                     [tooltip]="condition?.Meta?.Description"
                     [(ngModel)]="condition.Value[1]"
                     (change)="condition.Value = $event; formDirty();"
                     (keyUp)="formDirty()">
      </ib-input-date>
    </ng-container>
    <ib-icon icon="times"
             color="danger"
             tooltip="Delete Condition"
             class="float-start ps-1"
             (click)="onDeleteCondition($event, condition, group, index)"></ib-icon>
  </div>
</ng-template>
