import { ApplicationRef, ComponentFactoryResolver, ComponentRef, ElementRef, EmbeddedViewRef, Injectable, Injector, Provider, ReflectiveInjector, SimpleChange, SimpleChanges, StaticProvider } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from './base.service';
import { ApiService } from '../api/api.service';
import { AppService } from './app.service';
import { AppCacheService } from './app-cache.service';
import { Router } from '@angular/router';
import { StaticPickList } from '../models/model-helpers';
import { AsyncSubject, BehaviorSubject, Observable } from 'rxjs';
import { ApiCall, ApiOperationType, ApiProperties, CacheLevel, IApiResponseWrapperTyped, Query } from '../api/ApiModels';
import { ApiModuleWeb } from '../api/Api.Module.Web';
import { ApiHelper } from '../api/ApiHelper';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DynamicComponentService extends BaseService {

  protected components: Map<string, any> = new Map();

  constructor(
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected appRef: ApplicationRef,
    protected injector: Injector) {

    super();

  }


  /**
   * Registers a component with our service.
   * @param componentName
   * @param component
   * @example dynamicComponentService.componentRegister("ContactPartialAddress", ContactPartialAddress);
   */
  componentRegister(componentName: string, component: any): void {
    //console.error(`Registering dynamic component ${componentName}.`);
    this.components.set(componentName, component);
  }

  /**
   * Removes a component from the dynamic component registry.
   * @param componentName
   */
  componentRemove(componentName: string): void {
    this.components.delete(componentName);
  }

  /**
   * Gets a component from the dynamic component registry.
   * @param componentName
   */
  componentGet(componentName: string): any {
    return this.components.get(componentName);
  }


  /**
   * Get a component reference and optionally bind it to a host element in the DOM.
   * Note that the component must be registered with componentRegister() before this will work.
   * @param componentName The name of the component to get.
   * @param providers An optional array of providers for the injector to provide to the component instance upon creation.
   * @param inputs An optional object where each property is an input to the component instance.
   * @param eventBindings  Key-value pairs of events to subscribe to on the component instance and what function to call when they fire.
   * This mimics the effect of an output event binding inside of a template.
   * @param componentHostElement An optional DOM element to attach the component instance to.  This is usually tagged in the html
   * markup and then referenced in the host component class.
   * @example
   * `In html markup:
   * `<div #host></div>
   * `In ts component class:
   * `@ViewChild('host') hostElement: ElementRef;
   * @returns A ComponentRef object.  This can be used as input to componentRemoveReference() later in ngOnDestroy().
   */
  componentGetReference(componentName: string,
    providers: StaticProvider[],
    inputs: any,
    eventBindings: { [eventName: string]: (eventData: any) => void },
    componentHostElement: ElementRef): ComponentRef<any> {

    const component = this.componentGet(componentName);
    if (!component) {
      Log.warningMessage(`Cannot find component "${componentName}".  It may not be registered yet.`);
      return null;
    }

    //console.error("providers", providers);
    //const customInjector: Injector = ReflectiveInjector.resolveAndCreate(providers || [], this.injector);
    const customInjector: Injector = Injector.create({ providers: providers || [], parent: this.injector });

    const componentRef: ComponentRef<any> = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(customInjector);

    // Apply inputs
    this.componentApplyInputs(componentRef, inputs, true);

    // Inject any custom event bindings our component needs
    if (eventBindings) {
      for (const eventName in eventBindings) {
        componentRef.instance[eventName]?.subscribe((eventData: any) => {
          eventBindings[eventName]?.(eventData);
        });
      }
    }

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // Append DOM element from component to anchor
    if (componentHostElement) {
      const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      componentHostElement.nativeElement.appendChild(domElem);
    }

    return componentRef;

  }


  /**
   * Remove a component reference that was created with componentGetReference().
   * This should be done in the ngOnDestroy() method of the component that called componentGetReference()
   * or if the dynamic component is being swapped out for another component.
   * @param component
   * @returns
   */
  componentRemoveReference(component: ComponentRef<any>) {
    if (!component) {
      return;
    }
    this.appRef.detachView(component.hostView);
    component.destroy();
  }


  /***
   * If our data input changes that may impact our dynamic component inputs so
   * we can use this method to apply inputs.
   * @param component
   * @param inputs
   * @param firstChange
   */
  componentApplyInputs(component: ComponentRef<any>, inputs: any, firstChange: boolean): void {

    if (!component) {
      return;
    }
    if (!inputs) {
      return;
    }

    // Track changes
    let changes: SimpleChanges = {};

    // Step through each property in inputs
    for (const property in inputs) {
      // Add to our changes if it's our first call or if the value changed
      if (firstChange) {
        changes[property] = new SimpleChange(undefined, inputs[property], true);
      } else if (component.instance[property] !== inputs[property]) {
        changes[property] = new SimpleChange(component.instance[property], inputs[property], false);
      }
      // Assign the property
      component.instance[property] = inputs[property];
    }

    // Call ngOnChanges() manually with our changes object.
    // Changing properties on component instance won't call ngOnChanges.
    // See https://github.com/angular/angular/issues/22567
    try {
      component.instance.ngOnChanges(changes);
    } catch (err) {
      console.error("Error firing ngOnChanges() for dynamic component", err);
    }

  }


}
