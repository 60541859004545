import { Component, OnInit, OnChanges, SimpleChanges, Input, ViewChild, OnDestroy, ViewChildren, QueryList, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { EventModel, EventElementModel, EventModelTyped } from 'projects/common-lib/src/lib/ux-models';
import { NgForm, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { FormStatusModel } from 'projects/core-lib/src/lib/models/form-status-model';

@Component({
  selector: 'ib-modal-dynamic-form',
  templateUrl: './modal-dynamic-form.component.html',
  styleUrls: ['./modal-dynamic-form.component.css']
})
export class ModalDynamicFormComponent implements OnInit, OnChanges, AfterViewChecked {

  @Input() public options: ModalCommonOptions = new ModalCommonOptions();
  @Input() public disableWhenPristine: boolean = false;
  @Input() public disableWhenInvalid: boolean = true;
  @Input() public form: m5web.FormEditViewModel;
  @Input() public data: any;
  //@Output() public onOk: EventEmitter<EventModel> = new EventEmitter();
  //@Output() public onCancel: EventEmitter<EventModel> = new EventEmitter();

  public formStatus: FormStatusModel = new FormStatusModel();

  /**
   * The constructor
   * @param modal The NgbModal service will inject the active modal here so we can close or dismiss the modal from inside this component.
   */
  constructor(public modal: NgbActiveModal, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.configure();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }

  ngAfterViewChecked() {
    // Address ModalDynamicFormComponent.html:11 ERROR Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'ngIf: false'. Current value: 'ngIf: true'.
    this.cdr.detectChanges();
  }

  protected configure() {

    if (!this.options) {
      this.options = new ModalCommonOptions();
    }

    // We need text or icon on at least one of our buttons
    if (!this.options.okButtonText && !this.options.okButtonIcon && !this.options.cancelButtonText && !this.options.cancelButtonIcon) {
      this.options.okButtonText = "Ok";
    }

    //console.error(this.form);
    //console.error(this.data);

  }

  onFormStatusChange($event) {
    this.formStatus = $event.data;
  }


  onControlEvent($event: EventModelTyped<m5web.FormControlEditViewModel>) {
    //console.error("control event", $event);
    if (!$event?.data) {
      // No event object or no data object so no action we can take
      return;
    }
    // Get the click event based on the event type and the control model
    let clickEvent: string = "";
    if (Helper.equals($event.eventType, "click-prefix", true)) {
      clickEvent = $event.data.PrefixClickEvent;
    } else if (Helper.equals($event.eventType, "click-suffix", true)) {
      clickEvent = $event.data.SuffixClickEvent;
    } else if (Helper.equals($event.eventType, "click-suffix2", true)) {
      clickEvent = $event.data.SuffixClickEvent2;
    }
    // If our click event starts with "Exit:" that means our click event will trigger a modal
    // exit.  It does so with dismiss operation which is what we use with cancel button but
    // the dismiss payload will let our caller know if we dismissed with a click event.
    if (clickEvent && Helper.startsWith(clickEvent, "Exit:", true)) {
      const payload = new EventModelTyped<string>("click", $event, clickEvent, new EventElementModel("modal", null, "ClickEvent"));
      payload.cargo = { ok: false, cancel: false, actionId: clickEvent, button: null };
      this.modal.dismiss(payload);
    }
  }


}
