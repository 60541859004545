// !!! This is generated code... DO NOT modify this file !!!
import * as m from './ngCoreModels';
import * as m5core from './ngModelsCore5';


/**
 * @name Credit Card
 * @description
 * The payment method table contains information about payment methods used by customers.
 */
export class PaymentMethodCreditCardEditViewModel {

    /**
     * @name PaymentMethodId
     * @description 
     * The id of the payment method.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentMethodId: number = null;

    /**
     * @name ContactId
     * @description 
     * The contact id that this payment method belongs to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name BillingAccountId
     * @description 
     * Billing Account Id that this payment method belongs to.  May be null when shared among all billing accounts for the same
     * Contact Id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name Tokens
     * @description 
     * A json string of key value pairs where the key is the tokenization provider and the value is the corresponding token.  Tokens
     * for an unlimited number of tokenization providers are supported.
     * For example: 
     * {
     *   "Chase":"d0c70929-606f-4237-8f00-3da4bd884234",
     *   "TSYS":"3476681192357195838",
     *   "Nubill":"1gfl1jbdym2jt"
     *   "Subset":"0183"
     *   "Wallet":"Visa Checkout"
     * }
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    Tokens: {[key: string]:  string} = {};

    /**
     * @name Status
     * @description 
     * The payment method status.  Possible values include: 
     * D = Default payment method
     * C = Current/Active payment method
     * E = Expired payment method
     * R = Retired payment method
     * H = Hidden payment method
     * B = Blacklisted payment method
     * Retired payment methods are payment methods where the owner asked to have the payment method deleted.  This triggers removal
     * of all sensitive information and tokenization and retires it from use and view but maintains the scrubbed record for historical
     * and audit trail purposes.
     * Hidden payment methods are payment methods where the owner did not ask for it to be saved.  The payment engine requires it
     * be saved long enough to process the transaction after which all sensitive information and tokenization is removed and it
     * is hidden from use and view but maintains the scrubbed record for historical and audit trail purposes.
     * Blacklisted payment methods are payment methods that are black listed from use in the system.
     * @servertype String
     * @type {string}
     */
    Status: string = "C";

    /**
     * @name RetentionDays
     * @description 
     * The number of days after last use to retain payment method details.  After this number of days has elapsed the payment method
     * details can be cleared to limit security scope or tokenization provider storage fees.  The default of 0 means retention is
     * being set by system wide business rules or an external process using the remove sensitive information flag.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RetentionDays: number = 0;

    /**
     * @name Description
     * @description 
     * A description provided by the account holder (e.g. Work AmEx, Backup VISA, Checking, etc.).
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name CreditCardType
     * @description 
     * The name for the payment method card type (e.g. AmEx, MasterCard, etc.) see the Payment Method Card Type table.
     * @servertype String
     * @type {string}
     */
    CreditCardType: string = null;

    /**
     * @name CreditCardTypeId
     * @description 
     * A known static identifier that represents this payment method type in a non-volatile manner.  Possible values include:
     * 0 = Unknown
     * 101 = VISA
     * 201 = MasterCard
     * 301 = AmericanExpress
     * 401 = Discover
     * 501 = DinersClub
     * 502 = DinersClubCarteBlanche
     * 503 = DinersClubEnRoute
     * 504 = DinersClubInternational
     * 505 = DinersClubUnitedStatesAndCanada
     * 601 = JCB
     * 1201 = ChinaUnionPay
     * 1301 = InterPayment
     * 1401 = InstaPayment
     * 2201 = Maestro
     * 2301 = Dankort
     * 2401 = MIR
     * 2501 = Verve
     * 3101 = Troy
     * 3501 = BcCard
     * 6101 = Elo
     * 6201 = Aura
     * 70101 = UATP
     * 80101 = PayPal
     * 80201 = Amazon
     * 80301 = ApplePayCardTypeUnknown
     * 80401 = GooglePayCardTypeUnknown
     * 80501 = WeChatPay
     * 80601 = Alipay
     * 80701 = Venmo
     * 89101 = BankAccountChecking
     * 89201 = BankAccountSavings
     * 90101 = StoreCard
     * 91101 = Other
     * 92101 = Custom
     * @servertype Int32
     * @type {number}
     */
    CreditCardTypeId: number = 0;

    /**
     * @name CreditCardNumberLastFourDigits
     * @description 
     * The subset of the account number that can be used for a reference.  Typically the last four digits.
     * @servertype String
     * @type {string}
     */
    CreditCardNumberLastFourDigits: string = "";

    /**
     * @name CreditCardNumber
     * @description 
     * The credit card number.
     * @servertype String
     * @type {string}
     */
    CreditCardNumber: string = "";

    /**
     * @name ExpirationDate
     * @description 
     * The account expiration date.  Note that per PCI requirements this must be null unless using a tokenization provider. See
     * the expiration column for an encrypted version of the expiration date when not using a tokenization provider.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExpirationDate: Date = null;

    /**
     * @name CardId
     * @description 
     * The credit card security id (e.g. CVV2) for the credit card.
     * @servertype String
     * @type {string}
     */
    CardId: string = "";

    /**
     * @name Value001
     * @description 
     * Account value 1 for this payment method.  This will vary depending on payment method type or use case.
     * For Apple Pay this is the Apple Pay event.payment.token object stored as a JSON string.
     * For Braintree this is the customer id.
     * @servertype String
     * @type {string}
     */
    Value001: string = "";

    /**
     * @name Value002
     * @description 
     * Account value 2 for this payment method.  This will vary depending on payment method type or use case.
     * For Apple Pay if debundling was done this is the debundle result object stored as JSON string.
     * For Braintree this is the payment method token.
     * @servertype String
     * @type {string}
     */
    Value002: string = "";

    /**
     * @name Value003
     * @description 
     * Account value 3 for this payment method.  This will vary depending on payment method type or use case.
     * For Apple Pay if debundling was done this is the cryptogram included with the debundle result.
     * @servertype String
     * @type {string}
     */
    Value003: string = "";

    /**
     * @name Value004
     * @description 
     * Account value 4 for this payment method.  This will vary depending on payment method type or use case.
     * @servertype String
     * @type {string}
     */
    Value004: string = "";

    /**
     * @name Value005
     * @description 
     * Account value 5 for this payment method.  This will vary depending on payment method type or use case.
     * @servertype String
     * @type {string}
     */
    Value005: string = "";

    /**
     * @name Value006
     * @description 
     * Account value 6 for this payment method.  This will vary depending on payment method type or use case.
     * @servertype String
     * @type {string}
     */
    Value006: string = "";

    /**
     * @name Value007
     * @description 
     * Account value 7 for this payment method.  This will vary depending on payment method type or use case.
     * @servertype String
     * @type {string}
     */
    Value007: string = "";

    /**
     * @name Value008
     * @description 
     * Account value 8 for this payment method.  This will vary depending on payment method type or use case.
     * @servertype String
     * @type {string}
     */
    Value008: string = "";

    /**
     * @name Value009
     * @description 
     * Account value 9 for this payment method.  This will vary depending on payment method type or use case.
     * @servertype String
     * @type {string}
     */
    Value009: string = "";

    /**
     * @name Value010
     * @description 
     * Account value 10 for this payment method.  This will vary depending on payment method type or use case.
     * @servertype String
     * @type {string}
     */
    Value010: string = "";

    /**
     * @name BillingName
     * @description 
     * Billing name for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingName: string = "";

    /**
     * @name BillingCompanyName
     * @description 
     * Billing company name for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingCompanyName: string = "";

    /**
     * @name BillingAddress1
     * @description 
     * Billing address 1 for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingAddress1: string = "";

    /**
     * @name BillingAddress2
     * @description 
     * Billing address 2 for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingAddress2: string = "";

    /**
     * @name BillingAddress3
     * @description 
     * Billing address 3 for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingAddress3: string = "";

    /**
     * @name BillingCity
     * @description 
     * Billing city for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingCity: string = "";

    /**
     * @name BillingState
     * @description 
     * Billing state for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingState: string = "";

    /**
     * @name BillingPostalCode
     * @description 
     * Billing postal code for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingPostalCode: string = "";

    /**
     * @name BillingCountry
     * @description 
     * Billing country for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingCountry: string = "";

    /**
     * @name BillingPhone
     * @description 
     * Billing phone for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingPhone: string = "";

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Payment Transaction
 * @description
 * The Payment Transaction table contains electronic payment transactions that have are pending, succeeded or failed.
 */
export class PaymentTransactionEditViewModel {

    /**
     * @name PaymentTransactionId
     * @description 
     * The id of the payment transaction.
     * @servertype String
     * @type {string}
     */
    PaymentTransactionId: string = null;

    /**
     * @name RequestType
     * @description 
     * Flag used to indicate the type of request.  Possible values include:
     * P = Payment
     * R = Refund
     * N = Notification
     * @servertype String
     * @type {string}
     */
    RequestType: string = "P";

    /**
     * @name RequestStatus
     * @description 
     * Flag used to indicate the status of the request.  Possible values include:
     * P = Pending (only valid as starting state)
     * C = Cancelled (only valid when previous state was P)
     * R = Running (only valid when previous state was P)
     * W = Waiting (only valid when previous state was R) (submitted to payment provider and still waiting for their result or notification
     * waiting for resolution)
     * A = Authorized (only valid when previous state was R or W)
     * S = Success (only valid when previous state was R, W or A)
     * T = Success, Taxation Pending (transaction was successful; taxation is pending)
     * U = Success, Taxation Running (transaction was successful; taxation is running)
     * V = Voided (only valid when previous state was A)
     * F = Failed (only valid when previous state was R or W)
     * @servertype String
     * @type {string}
     */
    RequestStatus: string = "P";

    /**
     * @name ReportedStatus
     * @description 
     * In some configurations payment providers may report an assumed status prior knowing the actual transaction status.  When
     * that happens the reported status is recorded here so any subsequent reconciliation, reporting, etc. that is needed can be
     * performed.
     * @servertype String
     * @type {string}
     */
    ReportedStatus: string = "";

    /**
     * @name RequestResultCode
     * @description 
     * The request result code provides more specific information about the outcome of the request beyond that shown in the request
     * status.  Possible values include: 
     * 0 = Unspecified
     * 999 = Waiting
     * 1000 = Success
     * 1010 = IncludedTaxCalculationPending
     * 1998 = Cancelled
     * 1999 = Voided
     * 3000 = Failure
     * 3008 = FailureInvalidConfiguration
     * 3009 = FailureInvalidRequest
     * 3010 = FailureInvalidCardNumber
     * 3011 = FailureInvalidExpirationDate
     * 3012 = FailureInvalidCardId
     * 3013 = FailureInvalidBillingAddress
     * 3014 = FailureInvalidAmount
     * 3020 = FailureExpired
     * 3080 = FailureDeclined
     * 3081 = FailureDoNotHonor
     * 3082 = FailureLostOrStolen
     * 3083 = FailureCreditLimitExceeded
     * 3989 = FailureInternalException
     * 3996 = FailureProviderTimeout
     * 3997 = FailureProviderOffline
     * 3998 = FailureProviderError
     * 3999 = FailureUnknown
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RequestResultCode: number = 0;

    /**
     * @name RequestByContactId
     * @description 
     * The Contact Id who requested this transaction.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RequestByContactId: number = null;

    /**
     * @name RequestByContactName
     * @servertype String
     * @type {string}
     */
    RequestByContactName: string = "";

    /**
     * @name RequestDateTime
     * @description 
     * The date and time this request was initially submitted.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RequestDateTime: Date = null;

    /**
     * @name QueueDateTime
     * @description 
     * The date and time this record is queued for.  This defaults to the same as request date and time but can be adjusted in situations
     * where the record needs to be processed later.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    QueueDateTime: Date = null;

    /**
     * @name ContactId
     * @description 
     * The contact id that this payment method belongs to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name BillingAccountId
     * @description 
     * Billing Account Id that this payment method belongs to.  May be null when shared among all billing accounts for the same
     * Contact Id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name OrderReferences
     * @description 
     * A JSON dictionary of values possibly including external values.  For example: 
     * {
     *    BillingTransactionId: 381557,
     *    PackageOccurrenceId: 8714,
     *    PackageId: 41,
     *    RedirectUrlSuccess: "https://...",
     *    RedirectUrlError: "https://...",
     *    RedirectUrlCancel: "https://...",
     *    DeviceData: "....",
     *    ApplePay: { 
     *                Bundle: { 
     *                        ... 
     *                        }
     *                ... 
     *              }
     * }
     * Note that not all payment providers use these values so needs may vary based on provider.
     * Legacy values may be a csv of order references that can include billing transaction id, package id, or package occurrence
     * id values with prefix to identify which type of identifier it is.  For example: B381557,PO8712,P41 would indicate order references
     * Billing Transaction Id 381557, Package Occurrence Id 8712, and Package 41.
     * @servertype String
     * @type {string}
     */
    OrderReferences: string = "";

    /**
     * @name PaymentProviderId
     * @description 
     * The id of the payment provider used for this transaction.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentProviderId: number = null;

    /**
     * @name PaymentMethodId
     * @description 
     * The id of the payment method used for this transaction.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentMethodId: number = null;

    /**
     * @name PaymentMethodType
     * @description 
     * The payment method type.  Possible values include: 
     * C = Credit Card
     * D = Debit Card
     * B = Bank Account (Checking)
     * S = Bank Account (Savings)
     * P = PayPal
     * M = Venmo
     * A = Amazon
     * Y = Apple Pay
     * G = Google Pay
     * W = WeChat Pay
     * L = Alipay
     * V = Visa Checkout
     * O = Other
     * Note that Visa Checkout is a wallet and the type is effectively the same as Credit Card but is assigned a unique type to
     * provide some distinction for reporting purposes.
     * @servertype String
     * @type {string}
     */
    PaymentMethodType: string = "C";

    /**
     * @name PaymentMethodCardTypeId
     * @description 
     * The name for the payment method card type (e.g. AmEx, MasterCard, etc.) see the Payment Method Card Type table.
     * @servertype String
     * @type {string}
     */
    PaymentMethodCardTypeId: string = null;

    /**
     * @name PaymentMethodTypeId
     * @description 
     * A known static identifier that represents this payment method type in a non-volatile manner.  Possible values include:
     * 0 = Unknown
     * 101 = VISA
     * 201 = MasterCard
     * 301 = AmericanExpress
     * 401 = Discover
     * 501 = DinersClub
     * 502 = DinersClubCarteBlanche
     * 503 = DinersClubEnRoute
     * 504 = DinersClubInternational
     * 505 = DinersClubUnitedStatesAndCanada
     * 601 = JCB
     * 1201 = ChinaUnionPay
     * 1301 = InterPayment
     * 1401 = InstaPayment
     * 2201 = Maestro
     * 2301 = Dankort
     * 2401 = MIR
     * 2501 = Verve
     * 3101 = Troy
     * 3501 = BcCard
     * 6101 = Elo
     * 6201 = Aura
     * 70101 = UATP
     * 80101 = PayPal
     * 80201 = Amazon
     * 80301 = ApplePayCardTypeUnknown
     * 80401 = GooglePayCardTypeUnknown
     * 80501 = WeChatPay
     * 80601 = Alipay
     * 80701 = Venmo
     * 89101 = BankAccountChecking
     * 89201 = BankAccountSavings
     * 90101 = StoreCard
     * 91101 = Other
     * 92101 = Custom
     * @servertype Int32
     * @type {number}
     */
    PaymentMethodTypeId: number = 0;

    /**
     * @name AccountNumberReferenceSubset
     * @description 
     * The subset of the account number that can be used for a reference.  Typically the last four digits for credit cards and bank
     * accounts.  For payment methods like PayPal or Amazon it is the name of the payment method (e.g. PayPal).
     * @servertype String
     * @type {string}
     */
    AccountNumberReferenceSubset: string = "";

    /**
     * @name IsoCurrencyCode
     * @description 
     * The ISO Currency Code for this transaction.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Amount
     * @description 
     * Amount of authorization, payment or refund.  For notification transaction types this is the fee, if any, associated with
     * the notification type.
     * @servertype Double (nullable)
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name Tax
     * @description 
     * Tax portion of amount.
     * @servertype Double (nullable)
     * @type {number}
     */
    Tax: number = 0;

    /**
     * @name Shipping
     * @description 
     * Shipping portion of amount.
     * @servertype Double (nullable)
     * @type {number}
     */
    Shipping: number = 0;

    /**
     * @name Fee
     * @description 
     * Fee portion of amount.  This is for other fees that need to be broken out of the amount.
     * @servertype Double (nullable)
     * @type {number}
     */
    Fee: number = 0;

    /**
     * @name RefundedAmount
     * @description 
     * Amount of purchase that has been refunded.  For purchase request types this is the total of all refunds that referenced this
     * purchase for the refund.  For other request types this value is always 0.
     * @servertype Double (nullable)
     * @type {number}
     */
    RefundedAmount: number = 0;

    /**
     * @name RequestOrigin
     * @description 
     * Flag used to indicate the origin of the request.  Possible values include:
     * A = auto payment request
     * D = on demand payment request
     * @servertype String
     * @type {string}
     */
    RequestOrigin: string = "D";

    /**
     * @name Priority
     * @description 
     * The priority for this request.  A lower value has higher priority.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Priority: number = 0;

    /**
     * @name RequestAttempt
     * @description 
     * The attempt for this request.  Typically 1 but when an attempt fails it may be automatically re-queued in a new row for additional
     * attempts.  In that case, the request attempt value is incremented until it reaches the request attempt maximum.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RequestAttempt: number = 0;

    /**
     * @name RunningApplicationServer
     * @description 
     * The application server running this payment request.
     * @servertype String
     * @type {string}
     */
    RunningApplicationServer: string = "";

    /**
     * @name RunningDateTime
     * @description 
     * Date and time when the payment server started processing this payment request.  This can be used to help determine if there
     * are any transaction that should have posted success or failure but haven't and may be orphaned.  In that scenario the RunningApplicationServer
     * should be checked for logged messages.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RunningDateTime: Date = null;

    /**
     * @name TaxInformation
     * @description 
     * A json object of tax information for the transaction.  This could include things like: 
     * TaxScenarioId - Taxes that are precalculated and then submitted with a transaction may include a tax scenario id that can
     * be used to recreate the same tax details for the transaction.
     * Taxable - A Taxable true/false indicator.
     * Config - The id of the tax configuration that was used.
     * Matrices - A list of tax matrix identifiers from the taxation engine.
     * ResultCodes - A list of result codes from the taxation engine.
     * Rates - A list of simple tax rates used for calculating taxes (more complex scenarios found only in tax matrix and/or logs).
     * DisplayRequirements - A list of tax display requirement flags used for displaying taxes.
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    TaxInformation: {[key: string]:  string} = {};

    /**
     * @name Flags
     * @description 
     * A list flags related to this payment transaction.  Possible values include: 
     * T = Taxed (Tax has been calculated for this transaction)
     * TW = Tax Warnings (Tax calculation had warnings as noted in details)
     * TE = Tax Errors (Tax calculation had errors as noted in details)
     * TCP = Tax Calculation Pending (Tax calculation is pending)
     * TDP = Tax Details Pending (Tax calculation is done but details are pending)
     * TR:xxx = Tax Result xxx (Tax result code is in range 100-399)
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name TransactionDateTime
     * @description 
     * The date and time this transaction was processed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TransactionDateTime: Date = null;

    /**
     * @name TransactionContactReference
     * @description 
     * The contact reference for this transaction.  This is used in cases where a contact needs to be referenced by the payment
     * provider as may be the case when the payment provider has tokenized payment information for the contact and that tokenized
     * payment information is being used as the source of payment.  
     * For Braintree PayPal or Venmo this is the payer_id value.  
     * For Nubill Wallet this is the Nubill Wallet PaymentTransactionId and can be used to uniquely identify the transaction.
     * @servertype String
     * @type {string}
     */
    TransactionContactReference: string = "";

    /**
     * @name TransactionOrderReference
     * @description 
     * The order reference for this transaction.  This is typically a reference to the order or invoice being paid but in could
     * be some other identifier provided when the payment transaction is submitted.  
     * For Amazon this is the AmazonOrderReferenceId.
     * @servertype String
     * @type {string}
     */
    TransactionOrderReference: string = "";

    /**
     * @name TransactionBatch
     * @description 
     * The batch for this transaction.
     * @servertype String
     * @type {string}
     */
    TransactionBatch: string = "";

    /**
     * @name TransactionAuthorizationCode
     * @description 
     * The authorization code for this transaction.  
     * For PayPal this is the post-approval payer_id value.  
     * For Braintree PayPal or Venmo this is the nonce value.  
     * For Amazon this is the AmazonOrderReferenceId.
     * For payment notifications this may be a reference to the payment provider triggering the notification.
     * @servertype String
     * @type {string}
     */
    TransactionAuthorizationCode: string = "";

    /**
     * @name TransactionResultCode
     * @description 
     * The result code for this transaction.  This is typically a payment gateway specific result code.  
     * For PayPal this is the post-approval token value.
     * @servertype String
     * @type {string}
     */
    TransactionResultCode: string = "";

    /**
     * @name TransactionResultSubCode
     * @description 
     * The result sub-code for this transaction.  This is typically a payment gateway specific result code.  
     * For PayPal this is the preapproval payment id.
     * @servertype String
     * @type {string}
     */
    TransactionResultSubCode: string = "";

    /**
     * @name TransactionResultMessage
     * @description 
     * The result message for this transaction.  This is typically a payment gateway specific result message.  
     * @servertype String
     * @type {string}
     */
    TransactionResultMessage: string = "";

    /**
     * @name TransactionResultData
     * @description 
     * The result value for this transaction.  This is typically the raw results from the payment gateway.
     * @servertype String
     * @type {string}
     */
    TransactionResultData: string = "";

    /**
     * @name TransactionPaymentId
     * @description 
     * The transaction payment id used by the payment provider to identify the payment.  
     * For PayPal this is the post-execution sale id.  
     * For Braintree this is the transaction id.  
     * For Amazon this is the capture id.
     * @servertype String
     * @type {string}
     */
    TransactionPaymentId: string = "";

    /**
     * @name AddressVerificationResult
     * @description 
     * Address verification result for this transaction.
     * @servertype String
     * @type {string}
     */
    AddressVerificationResult: string = "";

    /**
     * @name CardIdVerificationResult
     * @description 
     * Card Id verification result for this transaction.
     * @servertype String
     * @type {string}
     */
    CardIdVerificationResult: string = "";

    /**
     * @name PaymentBatchId
     * @description 
     * A unique computer generated id for all transaction requests of a given batch.  For example, requesting a batch of payment
     * requests for all accounts set to auto-pay their balance will result in each of those payment requests having the same value
     * for this field.  This is NOT related to billing transaction batch id.
     * @servertype String
     * @type {string}
     */
    PaymentBatchId: string = "";

    /**
     * @name BillingTransactionId
     * @description 
     * If successful payments are submitted to the billing transaction table then this field is the billing transaction id value.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingTransactionId: number = null;

    /**
     * @name Triggers
     * @description 
     * Triggers that have been taken in relation to this transaction.
     * @servertype String
     * @type {string}
     */
    Triggers: string = "";

    /**
     * @name LinkedTransactionId
     * @description 
     * The id of the payment transaction linked to this transaction.  This is used for retries, refunds and notifications to point
     * back to the original payment transaction to assist with processing and record keeping.  Note that for retires of a refund
     * this is the payment transaction being refunded and never the original refund request being retried.
     * @servertype String
     * @type {string}
     */
    LinkedTransactionId: string = null;

    /**
     * @name LinkedTransactionRequestDateTime
     * @description 
     * The request date and time of the linked transaction.  Since transactions are stored in monthly tables this provides a pointer
     * to which monthly table the linked transaction can be found in.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LinkedTransactionRequestDateTime: Date = null;

    /**
     * @name Reason
     * @description 
     * For refunds this is the reason for refund.  For notifications this is the notification type.
     * @servertype String
     * @type {string}
     */
    Reason: string = "";

    /**
     * @name Comments
     * @description 
     * Comments entered explaining the reason for the refund or details about the notification.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PaymentMethodCardTypeEditViewModel
 * @description
 * The Payment Method Card Type table contains information about payment method card types that are supported by the system.
 */
export class PaymentMethodCardTypeEditViewModel {

    /**
     * @name PaymentMethodCardTypeSurrogateId
     * @description 
     * A surrogate id for the payment method card type.  The PaymentMethodCardTypeId value is used as the card type string identifier.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentMethodCardTypeSurrogateId: number = null;

    /**
     * @name PaymentMethodCardTypeId
     * @description 
     * A friendly but unique name for the payment method card type (e.g. AmEx, MasterCard, etc.).
     * @servertype String
     * @type {string}
     */
    PaymentMethodCardTypeId: string = "";

    /**
     * @name Description
     * @description 
     * Description for this payment method card type.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name AccountNumberStartsWith
     * @description 
     * A comma delimited list of numbers the account number of this type will start with (e.g. 30,36,38,3501-3509,39).
     * @servertype String
     * @type {string}
     */
    AccountNumberStartsWith: string = "";

    /**
     * @name AccountNumberSelectionRule
     * @description 
     * A regular expression used to select this as the card type.
     * @servertype String
     * @type {string}
     */
    AccountNumberSelectionRule: string = "";

    /**
     * @name AccountNumberLength
     * @description 
     * A comma delimited list of lengths of valid account numbers of this type (e.g. 14,16,20-24).  If the length is not important
     * or variable this should be blank.
     * @servertype String
     * @type {string}
     */
    AccountNumberLength: string = "";

    /**
     * @name AccountNumberValidationType
     * @description 
     * The type of account number validation to perform.  Possible values include: 
     * N = None
     * L = Luhn Algorithm
     * R = Regex
     * @servertype String
     * @type {string}
     */
    AccountNumberValidationType: string = "N";

    /**
     * @name AccountNumberValidationRule
     * @description 
     * When account number validation type is regex this is the regular expression used to validate the account number.
     * @servertype String
     * @type {string}
     */
    AccountNumberValidationRule: string = "";

    /**
     * @name RuleProcessingOrder
     * @description 
     * The order that rules should be processed in.  Frequently card type may be dictated by rules that are not always mutually
     * exclusive and, therefore, must be processed in a certain order.  For example, Diners Club starts with 30, 36, or 38 while
     * JCB starts with 35 and all other account numbers starting with 3 are American Express.  To avoid having an account number
     * that starts with 36 as being identified as an American Express account the rule processing order for American Express must
     * be a higher value than that used by Diners Club.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RuleProcessingOrder: number = 0;

    /**
     * @name IsoCurrencyCodeList
     * @description 
     * A comma delimited list of ISO currency codes (e.g. USD, CAD, HKD, MXN, etc.) that are allowed for this payment method.
     * @servertype string array
     * @type {string[]}
     */
    IsoCurrencyCodeList: string[] = [];

    /**
     * @name CountryCodeList
     * @description 
     * A comma delimited list of country codes (e.g. USA, BRA, etc.) that are allowed for this payment method.
     * @servertype string array
     * @type {string[]}
     */
    CountryCodeList: string[] = [];

    /**
     * @name ThirdPartyVerificationType
     * @description 
     * A third party verification type to use for this card type (e.g. 3DS, etc.)
     * @servertype String
     * @type {string}
     */
    ThirdPartyVerificationType: string = "";

    /**
     * @name MandatoryDisplayText
     * @description 
     * Any mandatory display text for transactions of this card type (e.g. 'This payment will be processed in the United States.',
     * etc.).
     * @servertype String
     * @type {string}
     */
    MandatoryDisplayText: string = "";

    /**
     * @name PaymentMethodTypeId
     * @description 
     * A known static identifier that represents this payment method card type in a non-volatile manner.  Possible values include:
     * 0 = Unknown
     * 101 = VISA
     * 201 = MasterCard
     * 301 = AmericanExpress
     * 401 = Discover
     * 501 = DinersClub
     * 502 = DinersClubCarteBlanche
     * 503 = DinersClubEnRoute
     * 504 = DinersClubInternational
     * 505 = DinersClubUnitedStatesAndCanada
     * 601 = JCB
     * 1201 = ChinaUnionPay
     * 1301 = InterPayment
     * 1401 = InstaPayment
     * 2201 = Maestro
     * 2301 = Dankort
     * 2401 = MIR
     * 2501 = Verve
     * 3101 = Troy
     * 3501 = BcCard
     * 6101 = Elo
     * 6201 = Aura
     * 70101 = UATP
     * 80101 = PayPal
     * 80201 = Amazon
     * 80301 = ApplePayCardTypeUnknown
     * 80401 = GooglePayCardTypeUnknown
     * 80501 = WeChatPay
     * 80601 = Alipay
     * 80701 = Venmo
     * 89101 = BankAccountChecking
     * 89201 = BankAccountSavings
     * 90101 = StoreCard
     * 91101 = Other
     * 92101 = Custom
     * @servertype Int32
     * @type {number}
     */
    PaymentMethodTypeId: number = 0;

    /**
     * @name PaymentNetworkTypeId
     * @description 
     * An identifier that represents the payment network for this payment method card type.  Possible values include:
     * 0 = Unknown
     * 101 = VISA
     * 201 = MasterCard
     * 301 = AmericanExpress
     * 401 = Discover
     * 501 = DinersClub
     * 502 = DinersClubCarteBlanche
     * 503 = DinersClubEnRoute
     * 504 = DinersClubInternational
     * 505 = DinersClubUnitedStatesAndCanada
     * 601 = JCB
     * 1201 = ChinaUnionPay
     * 1301 = InterPayment
     * 1401 = InstaPayment
     * 2201 = Maestro
     * 2301 = Dankort
     * 2401 = MIR
     * 2501 = Verve
     * 3101 = Troy
     * 3501 = BcCard
     * 6101 = Elo
     * 6201 = Aura
     * 70101 = UATP
     * 80101 = PayPal
     * 80201 = Amazon
     * 80301 = ApplePayCardTypeUnknown
     * 80401 = GooglePayCardTypeUnknown
     * 80501 = WeChatPay
     * 80601 = Alipay
     * 80701 = Venmo
     * 89101 = BankAccountChecking
     * 89201 = BankAccountSavings
     * 90101 = StoreCard
     * 91101 = Other
     * 92101 = Custom
     * @servertype Int32
     * @type {number}
     */
    PaymentNetworkTypeId: number = 0;

    /**
     * @name Custom
     * @description 
     * True indicates that the card type is a custom card type.
     * @servertype Boolean
     * @type {boolean}
     */
    Custom: boolean = false;

    /**
     * @name Active
     * @description 
     * True indicates that the card type is an active card type.
     * @servertype Boolean
     * @type {boolean}
     */
    Active: boolean = true;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Payment Methods Expiring
 * @description
 * The payment method table contains information about payment methods used by customers.
 */
export class PaymentMethodExpiringViewModel {

    /**
     * @name ContactId
     * @description 
     * The contact id that this payment method belongs to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactType
     * @description 
     * The type of the contact that owns this payment method.
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name ContactName
     * @description 
     * The contact name of the contact that owns this payment method.
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name FirstName
     * @description 
     * The first name of the contact that owns this payment method.
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @description 
     * The last name of the contact that owns this payment method.
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name Phone
     * @description 
     * The phone of the contact that owns this payment method.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Email
     * @description 
     * The email of the contact that owns this payment method.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name BillingAccountId
     * @description 
     * Billing Account Id that this payment method belongs to.  May be null when shared among all billing accounts for the same
     * Contact Id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name BillingAccountNumber
     * @description 
     * Account number for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingAccountNumber: string = "";

    /**
     * @name BillingAccountName
     * @description 
     * The account name of the billing account that owns this payment method.
     * @servertype String
     * @type {string}
     */
    BillingAccountName: string = "";

    /**
     * @name PaymentMethodId
     * @description 
     * The id of the payment method.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentMethodId: number = null;

    /**
     * @name Type
     * @description 
     * The payment method type.  Possible values include: 
     * C = Credit Card
     * D = Debit Card
     * B = Bank Account (Checking)
     * S = Bank Account (Savings)
     * P = PayPal
     * M = Venmo
     * A = Amazon
     * Y = Apple Pay
     * G = Google Pay
     * W = WeChat Pay
     * L = Alipay
     * V = Visa Checkout
     * O = Other
     * Note that Visa Checkout is a wallet and the type is effectively the same as Credit Card but is assigned a unique type to
     * provide some distinction for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Type: string = "C";

    /**
     * @name Status
     * @description 
     * The payment method status.  Possible values include: 
     * D = Default payment method
     * C = Current/Active payment method
     * E = Expired payment method
     * R = Retired payment method
     * H = Hidden payment method
     * B = Blacklisted payment method
     * Retired payment methods are payment methods where the owner asked to have the payment method deleted.  This triggers removal
     * of all sensitive information and tokenization and retires it from use and view but maintains the scrubbed record for historical
     * and audit trail purposes.
     * Hidden payment methods are payment methods where the owner did not ask for it to be saved.  The payment engine requires it
     * be saved long enough to process the transaction after which all sensitive information and tokenization is removed and it
     * is hidden from use and view but maintains the scrubbed record for historical and audit trail purposes.
     * Blacklisted payment methods are payment methods that are black listed from use in the system.
     * @servertype String
     * @type {string}
     */
    Status: string = "C";

    /**
     * @name PaymentMethodCardTypeId
     * @description 
     * The name for the payment method card type (e.g. AmEx, MasterCard, etc.) see the Payment Method Card Type table.
     * @servertype String
     * @type {string}
     */
    PaymentMethodCardTypeId: string = null;

    /**
     * @name Description
     * @description 
     * A description provided by the account holder (e.g. Work AmEx, Backup VISA, Checking, etc.).
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name AccountNumberReferenceSubset
     * @description 
     * The subset of the account number that can be used for a reference.  Typically the last four digits.
     * @servertype String
     * @type {string}
     */
    AccountNumberReferenceSubset: string = "";

    /**
     * @name ExpirationDate
     * @description 
     * The account expiration date.  Note that per PCI requirements this must be null unless using a tokenization provider. See
     * the expiration column for an encrypted version of the expiration date when not using a tokenization provider.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExpirationDate: Date = null;

    /**
     * @name NextValidationDateTime
     * @description 
     * The date and time when the payment method is next scanned, validated, etc.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NextValidationDateTime: Date = null;

}



/**
 * @name Payment Method List
 * @description
 * The payment method table contains information about payment methods used by customers.
 */
export class PaymentMethodListViewModel {

    /**
     * @name PaymentMethodId
     * @description 
     * The id of the payment method.
     * @servertype Int64
     * @type {number}
     */
    PaymentMethodId: number = null;

    /**
     * @name ContactId
     * @description 
     * The contact id that this payment method belongs to.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name BillingAccountId
     * @description 
     * Billing Account Id that this payment method belongs to.  May be null when shared among all billing accounts for the same
     * Contact Id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name Type
     * @description 
     * The payment method type.  Possible values include: 
     * C = Credit Card
     * D = Debit Card
     * B = Bank Account (Checking)
     * S = Bank Account (Savings)
     * P = PayPal
     * M = Venmo
     * A = Amazon
     * Y = Apple Pay
     * G = Google Pay
     * W = WeChat Pay
     * L = Alipay
     * V = Visa Checkout
     * O = Other
     * Note that Visa Checkout is a wallet and the type is effectively the same as Credit Card but is assigned a unique type to
     * provide some distinction for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Type: string = "C";

    /**
     * @name Status
     * @description 
     * The payment method status.  Possible values include: 
     * D = Default payment method
     * C = Current/Active payment method
     * E = Expired payment method
     * R = Retired payment method
     * H = Hidden payment method
     * B = Blacklisted payment method
     * Retired payment methods are payment methods where the owner asked to have the payment method deleted.  This triggers removal
     * of all sensitive information and tokenization and retires it from use and view but maintains the scrubbed record for historical
     * and audit trail purposes.
     * Hidden payment methods are payment methods where the owner did not ask for it to be saved.  The payment engine requires it
     * be saved long enough to process the transaction after which all sensitive information and tokenization is removed and it
     * is hidden from use and view but maintains the scrubbed record for historical and audit trail purposes.
     * Blacklisted payment methods are payment methods that are black listed from use in the system.
     * @servertype String
     * @type {string}
     */
    Status: string = "C";

    /**
     * @name LastUsedDateTime
     * @description 
     * The date and time when the payment method was last used.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUsedDateTime: Date = null;

    /**
     * @name PaymentMethodCardTypeId
     * @description 
     * The name for the payment method card type (e.g. AmEx, MasterCard, etc.) see the Payment Method Card Type table.
     * @servertype String
     * @type {string}
     */
    PaymentMethodCardTypeId: string = null;

    /**
     * @name Description
     * @description 
     * A description provided by the account holder (e.g. Work AmEx, Backup VISA, Checking, etc.).
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name AccountNumberReferenceSubset
     * @description 
     * The subset of the account number that can be used for a reference.  Typically the last four digits.
     * @servertype String
     * @type {string}
     */
    AccountNumberReferenceSubset: string = "";

    /**
     * @name ExpirationDate
     * @description 
     * The account expiration date.  Note that per PCI requirements this must be null unless using a tokenization provider. See
     * the expiration column for an encrypted version of the expiration date when not using a tokenization provider.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExpirationDate: Date = null;

    /**
     * @name UtcOffset
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

}



/**
 * @name PaymentMethodEditViewModel
 */
export class PaymentMethodEditViewModel {

    /**
     * @name PaymentMethodId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentMethodId: number = 0;

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name BillingAccountId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = 0;

    /**
     * @name Tokens
     * @description 
     * A key/value collection where the key is the tokenization provider and the value is the corresponding token.  Tokens for an
     * unlimited number of tokenization providers are supported.
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    Tokens: {[key: string]:  string} = {};

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Status
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name NextValidationDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NextValidationDateTime: Date = null;

    /**
     * @name LastUsedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUsedDateTime: Date = null;

    /**
     * @name TransactionCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TransactionCount: number = 0;

    /**
     * @name PurchaseCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PurchaseCount: number = 0;

    /**
     * @name RetentionDays
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RetentionDays: number = 0;

    /**
     * @name RemoveSensitiveInformation
     * @servertype Boolean
     * @type {boolean}
     */
    RemoveSensitiveInformation: boolean = false;

    /**
     * @name SensitiveInformationRemoved
     * @servertype Boolean
     * @type {boolean}
     */
    SensitiveInformationRemoved: boolean = false;

    /**
     * @name PaymentMethodCardTypeId
     * @servertype String
     * @type {string}
     */
    PaymentMethodCardTypeId: string = "";

    /**
     * @name PaymentMethodTypeId
     * @servertype Int32
     * @type {number}
     */
    PaymentMethodTypeId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name AccountNumberReferenceSubset
     * @servertype String
     * @type {string}
     */
    AccountNumberReferenceSubset: string = "";

    /**
     * @name ExpirationDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExpirationDate: Date = null;

    /**
     * @name BankName
     * @servertype String
     * @type {string}
     */
    BankName: string = "";

    /**
     * @name RoutingNumber
     * @servertype String
     * @type {string}
     */
    RoutingNumber: string = "";

    /**
     * @name AccountNumber
     * @servertype String
     * @type {string}
     */
    AccountNumber: string = "";

    /**
     * @name Expiration
     * @servertype String
     * @type {string}
     */
    Expiration: string = "";

    /**
     * @name AccountId
     * @servertype String
     * @type {string}
     */
    AccountId: string = "";

    /**
     * @name Value001
     * @servertype String
     * @type {string}
     */
    Value001: string = "";

    /**
     * @name Value002
     * @servertype String
     * @type {string}
     */
    Value002: string = "";

    /**
     * @name Value003
     * @servertype String
     * @type {string}
     */
    Value003: string = "";

    /**
     * @name Value004
     * @servertype String
     * @type {string}
     */
    Value004: string = "";

    /**
     * @name Value005
     * @servertype String
     * @type {string}
     */
    Value005: string = "";

    /**
     * @name Value006
     * @servertype String
     * @type {string}
     */
    Value006: string = "";

    /**
     * @name Value007
     * @servertype String
     * @type {string}
     */
    Value007: string = "";

    /**
     * @name Value008
     * @servertype String
     * @type {string}
     */
    Value008: string = "";

    /**
     * @name Value009
     * @servertype String
     * @type {string}
     */
    Value009: string = "";

    /**
     * @name Value010
     * @servertype String
     * @type {string}
     */
    Value010: string = "";

    /**
     * @name BillingName
     * @servertype String
     * @type {string}
     */
    BillingName: string = "";

    /**
     * @name BillingCompanyName
     * @servertype String
     * @type {string}
     */
    BillingCompanyName: string = "";

    /**
     * @name BillingAddress1
     * @servertype String
     * @type {string}
     */
    BillingAddress1: string = "";

    /**
     * @name BillingAddress2
     * @servertype String
     * @type {string}
     */
    BillingAddress2: string = "";

    /**
     * @name BillingAddress3
     * @servertype String
     * @type {string}
     */
    BillingAddress3: string = "";

    /**
     * @name BillingCity
     * @servertype String
     * @type {string}
     */
    BillingCity: string = "";

    /**
     * @name BillingState
     * @servertype String
     * @type {string}
     */
    BillingState: string = "";

    /**
     * @name BillingPostalCode
     * @servertype String
     * @type {string}
     */
    BillingPostalCode: string = "";

    /**
     * @name BillingCountry
     * @servertype String
     * @type {string}
     */
    BillingCountry: string = "";

    /**
     * @name BillingPhone
     * @servertype String
     * @type {string}
     */
    BillingPhone: string = "";

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PaymentProviderEditViewModel
 * @description
 * The Payment Provider table contains payment account information.  Every payment merchant account, payment gateway, or tokenization
 * provider will be recorded here for processing purposes.
 */
export class PaymentProviderEditViewModel {

    /**
     * @name PaymentProviderId
     * @description 
     * The id of the payment provider.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentProviderId: number = null;

    /**
     * @name ExternalPaymentProviderId
     * @description 
     * An optional external payment provider id for this payment provider.
     * @servertype String
     * @type {string}
     */
    ExternalPaymentProviderId: string = "";

    /**
     * @name Description
     * @description 
     * The description for this payment provider.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name PaymentEngineName
     * @description 
     * The payment engine name for this payment provider.  Possible values include: 
     * Simulator = Simulator
     * PayPal = PayPal
     * BraintreePayPal = Braintree PayPal
     * BraintreeVenmo = Braintree Venmo
     * Amazon = Pay with Amazon
     * ApplePayChaseOrbitalCommonWallet = Apple Pay with Chase Orbital Wallet
     * ApplePayChaseOrbitalCommonWalletV2 = Apple Pay with Chase Orbital Wallet v2
     * Adyen = Adyen
     * Cielo = Cielo eCommerce
     * Nestpay = Nestpay / Isbank / Payten / Asseco
     * NubillWallet = Nubill Wallet
     * Custom = Custom Provider Implementation
     * E-Payment Integrator = E-Payment Integrator
     * @servertype String
     * @type {string}
     */
    PaymentEngineName: string = "";

    /**
     * @name PaymentProviderName
     * @description 
     * The payment provider name for this payment provider record.  When the payment engine is anything other than E-Payment Integrator
     * this is the same value since those engines only support a single payment provider.  When the payment engine is E-Payment
     * Integrator this is the name of the gateway being used.
     * @servertype String
     * @type {string}
     */
    PaymentProviderName: string = "";

    /**
     * @name PaymentProviderPlatform
     * @description 
     * This is an optional platform indicator for the payment provider.  For example, with Chase Orbital this is the BIN number.
     * @servertype String
     * @type {string}
     */
    PaymentProviderPlatform: string = "";

    /**
     * @name TokenizationProvider
     * @description 
     * Not all payment providers provide tokenization but tokens can only be submitted a payment processor that supports a tokenization
     * provider which is often only their own.  This setting specifies the tokenization provider name to use in order to select
     * the correct payment method token.
     * @servertype String
     * @type {string}
     */
    TokenizationProvider: string = "";

    /**
     * @name MerchantNumber
     * @description 
     * The merchant number used with this payment provider.
     * @servertype String
     * @type {string}
     */
    MerchantNumber: string = "";

    /**
     * @name MerchantAccountNumber
     * @description 
     * The merchant account number used with this payment provider.
     * @servertype String
     * @type {string}
     */
    MerchantAccountNumber: string = "";

    /**
     * @name MerchantAlternateNumber
     * @description 
     * The merchant alternate number used with this payment provider.
     * @servertype String
     * @type {string}
     */
    MerchantAlternateNumber: string = "";

    /**
     * @name MerchantName
     * @description 
     * The merchant name used with this payment provider.
     * @servertype String
     * @type {string}
     */
    MerchantName: string = "";

    /**
     * @name VendorId
     * @description 
     * The vendor id used with this payment provider.
     * @servertype String
     * @type {string}
     */
    VendorId: string = "";

    /**
     * @name SoftwareId
     * @description 
     * The software id used with this payment provider.
     * @servertype String
     * @type {string}
     */
    SoftwareId: string = "";

    /**
     * @name TerminalId
     * @description 
     * The terminal id used with this payment provider.
     * @servertype String
     * @type {string}
     */
    TerminalId: string = "";

    /**
     * @name ContactId
     * @description 
     * The Contact Id represents a scope within which the payment provider should be used.  For example, a payment will first seek
     * to be processed by an account for the agent, then agents above that agent and finally by the system (null contact id).
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name Enabled
     * @description 
     * When true the payment provider is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name DefaultPaymentProvider
     * @description 
     * When true the payment provider is the default payment provider that can be used when no specific payment provider is requested
     * and no other payment provider is selected based on the scope of the transaction request (e.g. currency, package type, etc.).
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultPaymentProvider: boolean = false;

    /**
     * @name ValidForTestTransactions
     * @description 
     * True indicates the payment provider is will recognize test payment method account numbers and process them using internal
     * test logic instead of submitting to the payment provider for processing.
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForTestTransactions: boolean = false;

    /**
     * @name ValidForAutomaticPayments
     * @description 
     * True indicates the payment provider is valid for automatic payments.  Under certain circumstances different payment providers
     * might be desired for different payment scenarios.
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForAutomaticPayments: boolean = true;

    /**
     * @name ValidForOnDemandPayments
     * @description 
     * True indicates the payment provider is valid for on demand payments.  Under certain circumstances different payment providers
     * might be desired for different payment scenarios.
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForOnDemandPayments: boolean = true;

    /**
     * @name IsoCurrencyCodeList
     * @description 
     * A comma delimited list of ISO currency codes (e.g. USD, CAD, HKD, MXN, etc.) that are allowed for this payment provider.
     *  Any transactions with any other currency will not select this payment provider unless it is also the default payment provider.
     *  When no currencies are listed all currencies are allowed.
     * @servertype string array
     * @type {string[]}
     */
    IsoCurrencyCodeList: string[] = [];

    /**
     * @name PaymentProviderFlags
     * @description 
     * A list of flags sent to the payment provider.  These will differ depending on the payment engine.
     * For example: 
     * For E-Payment Integrator this could be config strings like 'AllowPartialAuths=false' or 'TerminalId=425567118'.
     * For Apple Pay this might include a flag 'debundle' if debundling should happen.
     * @servertype string array
     * @type {string[]}
     */
    PaymentProviderFlags: string[] = [];

    /**
     * @name PaymentProviderSettings
     * @description 
     * An object that contains settings sent to the payment provider.  These will differ depending on the payment engine.
     * @servertype System.Object
     * @type {any}
     */
    PaymentProviderSettings: any = {};

    /**
     * @name PaymentProcessingFlags
     * @description 
     * A list of flags used internally for payment processing.  Possible values include: 
     * Sandbox = Use Sandbox (When testing with sandbox in production the Sandbox property will be set to false so this flag is
     * used to force sandbox use.)
     * Failover = Use Failover Connection
     * @servertype string array
     * @type {string[]}
     */
    PaymentProcessingFlags: string[] = [];

    /**
     * @name PaymentProcessingSettings
     * @description 
     * An object that contains settings used internally for payment processing.
     * @servertype System.Object
     * @type {any}
     */
    PaymentProcessingSettings: any = {};

    /**
     * @name ContactInformationCapture
     * @description 
     * A flag indicating if any contact information should be captured from the payment provider.  Some payment providers can provide
     * contact information that can be used to populate the contact record that owns the payment transaction.  Possible values include:
     * N = Never
     * A = Always (will overwrite any contact information already on file)
     * W = When Needed (will only capture contact information missing)
     * @servertype String
     * @type {string}
     */
    ContactInformationCapture: string = "W";

    /**
     * @name PaymentModel
     * @description 
     * A flag indicating the payment model to use for this epayment account when a payment is requested.  Possible values include:
     * S = Sale
     * A = Authorization followed by immediate capture
     * B = Authorization followed later by batch capture
     * @servertype String
     * @type {string}
     */
    PaymentModel: string = "S";

    /**
     * @name VerificationModel
     * @description 
     * A flag indicating the verification model to use presale address and/or card id verification.  Possible values include: 
     * N = None.  No presale verification is performed.
     * Z = Zero amount authorization followed by sale or cancelled transaction based on response.
     * A = Authorization followed by capture or void based on response.
     * @servertype String
     * @type {string}
     */
    VerificationModel: string = "N";

    /**
     * @name AddressVerificationRequired
     * @description 
     * When true, address verification is required for this payment provider.
     * @servertype Boolean
     * @type {boolean}
     */
    AddressVerificationRequired: boolean = false;

    /**
     * @name AddressVerificationFailList
     * @description 
     * A comma delimited list of address verification codes that should result in a failed transaction.  Possible values include:
     * A = Street address matches, but 5-digit and 9-digit postal code do not match. (Standard domestic)
     * B = Street address matches, but postal code not verified. (Standard international)
     * C = Street address and postal code do not match. (Standard international)
     * D = Street address and postal code match. Code 'M' is equivalent. (Standard international)
     * E = AVS data is invalid or AVS is not allowed for this card type. (Standard domestic)
     * F = Card member name does not match, but billing postal code matches. (American Express only)
     * G = Non-U.S. issuing bank does not support AVS. (Standard international)
     * H = Card member name does not match. Street address and postal code match. (American Express only)
     * I = Address not verified. (Standard international)
     * J = Card member name, billing address, and postal code match. (American Express only)
     * K = Card member name matches but billing address and billing postal code do not match. (American Express only)
     * L = Card member name and billing postal code match, but billing address does not match. (American Express only)
     * M = Street address and postal code match. Code 'D' is equivalent. (Standard international)
     * N = Street address and postal code do not match. (Standard domestic)
     * O = Card member name and billing address match, but billing postal code does not match. (American Express only)
     * P = Postal code matches, but street address not verified. (Standard international)
     * Q = Card member name, billing address, and postal code match. (American Express only)
     * R = System unavailable. (Standard domestic)
     * S = Bank does not support AVS. (Standard domestic)
     * T = Card member name does not match, but street address matches. (American Express only)
     * U = Address information unavailable. Bank does not support non-U.S. AVS or the AVS is not functioning. (Standard domestic)
     * V = Card member name, billing address, and billing postal code match. (American Express only)
     * W = Street address does not match, but 9-digit postal code matches. (Standard domestic)
     * X = Street address and 9-digit postal code match. (Standard domestic)
     * Y = Street address and 5-digit postal code match. (Standard domestic)
     * Z = Street address does not match, but 5-digit postal code matches. (Standard domestic)
     * @servertype string array
     * @type {string[]}
     */
    AddressVerificationFailList: string[] = [];

    /**
     * @name CardIdVerificationRequired
     * @description 
     * When true, card id verification is required for this payment provider.
     * @servertype Boolean
     * @type {boolean}
     */
    CardIdVerificationRequired: boolean = false;

    /**
     * @name CardIdVerificationFailList
     * @description 
     * A comma delimited list of card id verification codes that should result in a failed transaction.  Possible values include:
     * D = Transaction determined suspicious by issuing bank.
     * I = Card verification number failed processor's data validation check.
     * M = Card verification number matched.
     * Y = Card verification number matched (Amex).
     * N = Card verification number not matched.
     * P = Card verification number not processed by processor for unspecified reason.
     * S = Card verification number is on the card but was not included in the request.
     * U = Card verification is not supported by the issuing bank.
     * X = Card verification is not supported by the card association.
     * 1 = Card verification is not supported for this processor or card type.
     * 2 = Unrecognized result code returned by processor for card verification response.
     * 3 = No result code returned by processor.
     * @servertype string array
     * @type {string[]}
     */
    CardIdVerificationFailList: string[] = [];

    /**
     * @name Preference
     * @description 
     * Preference indicator used when more than one payment provider for the same selection criteria.  Lower values represent more
     * preferred payment providers.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Preference: number = 0;

    /**
     * @name Encoding
     * @description 
     * The encoding to use when submitting to the payment engine.  Typically customer information is the only information that is
     * encoded (e.g. name, email, etc.).  Possible values include: 
     *  = No special encoding is performed.
     * ASCII = ASCII Encoding
     * EASCII = Extended ASCII Encoding
     * CP1252 = Code Page 1252 Encoding
     * Latin1 = ISO 8859-1 [Latin 1] Encoding
     * Note that EASCII, Latin1, and CP1252 may all use Code Page 1252 internally as it's a compatible superset of ISO 8859-1.
     * @servertype String
     * @type {string}
     */
    Encoding: string = "";

    /**
     * @name PaymentProcessingRegion
     * @description 
     * An optional region for payment processing.  Appropriate values to use here is payment engine dependent.
     * @servertype String
     * @type {string}
     */
    PaymentProcessingRegion: string = "";

    /**
     * @name TimeZoneId
     * @description 
     * Time Zone Id used by the payment provider.  When specified the time stamp on payments will be adjusted to match.
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = "";

    /**
     * @name Login1
     * @description 
     * The login 1 for this payment provider.  This is stored as an encrypted value.
     * For Nubill Wallet this is the Wallet API Key.
     * For Amazon this is the AWS Access Key Id.
     * For Braintree this is the merchant id (not merchant account id).
     * For Adyen this is the merchant account name.
     * For engine ApplePayChaseOribtalCommonWallet this is the login for the common wallet api.
     * @servertype String
     * @type {string}
     */
    Login1: string = "";

    /**
     * @name Login2
     * @description 
     * The login 2 for this payment provider.  This is stored as an encrypted value.
     * For Amazon this is the Seller Id.
     * For Braintree this is the public key.
     * For Authorize.Net this is the x_Trans_Key.
     * For engine ApplePayChaseOribtalCommonWallet if this matches the merchant id then that is used for CDPT transactions and if
     * empty then Login1 is used for OrbitalConnectionUsername.
     * @servertype String
     * @type {string}
     */
    Login2: string = "";

    /**
     * @name Password1
     * @description 
     * The password 1 for this payment provider.  This is stored as an encrypted value.
     * For Nubill Wallet this is the Wallet Payment Provider Id.
     * For Amazon this is the AWS Secret Key.
     * For Braintree this is the private key.
     * For Adyen this is the api key.
     * For engine ApplePayChaseOribtalCommonWallet this is the password for the common wallet api.
     * @servertype String
     * @type {string}
     */
    Password1: string = "";

    /**
     * @name Password2
     * @description 
     * The password 2 for this payment provider.  This is stored as an encrypted value.
     * For Amazon this is the MWS Auth Token (if any).
     * For Braintree this is an optional client token when using a stored client token.
     * For engine ApplePayChaseOribtalCommonWallet if this matches the BIN (PaymentProviderPlatform) then that is used for CDPT
     * transactions and if empty then Password1 is used for OrbitalConnectionPassword.
     * @servertype String
     * @type {string}
     */
    Password2: string = "";

    /**
     * @name Sandbox
     * @description 
     * True indicates this is a sandbox account for the payment provider.  Note that sandbox and production accounts for a payment
     * provider should be entered as two separate payment providers.  Switching between sandbox and production environments by changing
     * this flag and/or the current url will break things like refund processing, etc. and should not be done.
     * @servertype Boolean
     * @type {boolean}
     */
    Sandbox: boolean = false;

    /**
     * @name CurrentUrl
     * @description 
     * The current url used for this payment provider.  In some scenarios like testing sandbox merchant accounts in production code
     * we need the sandbox setting to be false and this current url is used to determine if the payment provider sandbox is being
     * utilized or not.  In scenarios where the url is hard coded in but we need the current url to provide a sandbox indicator
     * the text 'sandbox' should be entered.
     * @servertype String
     * @type {string}
     */
    CurrentUrl: string = "";

    /**
     * @name ProductionUrl1
     * @description 
     * The primary production url for this payment provider.  This should only be utilized in the current url property when the
     * payment provider is a production entry as providers should not typically switch between production and sandbox configurations
     * since that can break things like refund processing, etc.
     * @servertype String
     * @type {string}
     */
    ProductionUrl1: string = "";

    /**
     * @name ProductionUrl2
     * @description 
     * The secondary production url for this payment provider.  This should only be utilized in the current url property when the
     * payment provider is a production entry as providers should not typically switch between production and sandbox configurations
     * since that can break things like refund processing, etc.
     * @servertype String
     * @type {string}
     */
    ProductionUrl2: string = "";

    /**
     * @name SandboxUrl1
     * @description 
     * The primary sandbox url for this payment provider.  This is for informational purposes only and should only be utilized in
     * the current url property when the payment provider is a sandbox entry as providers should not typically switch between production
     * and sandbox configurations since that can break things like refund processing, etc.
     * @servertype String
     * @type {string}
     */
    SandboxUrl1: string = "";

    /**
     * @name SandboxUrl2
     * @description 
     * The secondary sandbox url for this payment provider.  This is for informational purposes only and should only be utilized
     * in the current url property when the payment provider is a sandbox entry as providers should not typically switch between
     * production and sandbox configurations since that can break things like refund processing, etc.
     * @servertype String
     * @type {string}
     */
    SandboxUrl2: string = "";

    /**
     * @name TransactionDescription
     * @description 
     * The description used when posting transactions with this payment provider.  In some scenarios this can be used for other
     * purposes.  For example, in an Amazon Payment Sandbox scenario it can be used to force responses from Amazon to have certain
     * state and reason code values: 
     * {"SandboxSimulation": {"State":"Declined", "ReasonCode":"InvalidPaymentMethod", "PaymentMethodUpdateTimeInMins":5}} 
     * {"SandboxSimulation": {"State":"Declined", "ReasonCode":"AmazonRejected"}} 
     * {"SandboxSimulation": {"State":"Declined", "ReasonCode":"TransactionTimedOut"}} 
     * {"SandboxSimulation": {"State":"Closed", "ReasonCode":"ExpiredUnused", "ExpirationTimeInMins":1}} 
     * {"SandboxSimulation": {"State":"Closed", "ReasonCode":"AmazonClosed"}} 
     * See https://payments.amazon.com/documentation/lpwa/201956480 for more information.
     * @servertype String
     * @type {string}
     */
    TransactionDescription: string = "";

    /**
     * @name OrderNumberType
     * @description 
     * For payment providers who accept a merchant order number or invoice number, this is an indication of what value(s) to submit
     * as that merchant order number or invoice number.  Possible values include: 
     * TransactionOrderReference = The order reference attached to the payment transaction being processed.  (This is the default
     * used when no other is specified.)
     * PaymentTransactionId = The unique payment transaction id for the payment transaction being processed.  
     * PaymentTransactionIdOfPayment = The unique payment transaction id tied to payment request.  (For payments this is the same
     * as PaymentTransactionId but for refunds, etc. this is the unique payment transaction id of the original payment record being
     * refunded, etc. not the id of the refund transaction itself.)
     * @servertype String
     * @type {string}
     */
    OrderNumberType: string = "";

    /**
     * @name PaymentMaximumAttempts
     * @description 
     * The maximum number of times to try processing a payment request.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PaymentMaximumAttempts: number = 0;

    /**
     * @name PaymentRetryIntervalSeconds
     * @description 
     * When retrying a payment this is the number of seconds to wait before retrying.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PaymentRetryIntervalSeconds: number = 0;

    /**
     * @name PaymentMonitorTimeoutSeconds
     * @description 
     * When monitoring the status of a payment request real-time this is the number of seconds after which to timeout.  Note that
     * this does not timeout the actual payment request which may still be pending or processing it simply stops the real-time monitoring
     * of the payment transaction.  The eventual success or failure of the payment transaction can still be accessed later.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PaymentMonitorTimeoutSeconds: number = 0;

    /**
     * @name PaymentProviderTimeoutReportedStatus
     * @description 
     * This only applies to authorization, capture, sale transactions and never to refund or void transactions.  When monitoring
     * of a payment transaction times out this can help the monitoring application decide how the still pending status of the transaction
     * should be reported.  Possible values include: 
     * W = Waiting (transaction pending)
     * S = Success (transaction assumed success)
     * F = Failed (transaction assumed failed)
     * In some limited real-time delivery of soft goods that have no cost of good sold it's preferred to treat a timeout as a success
     * to limit customer friction.  In those limited scenarios this should be set to success.  In all other cases this should be
     * waiting or failed.  For payment providers used in both scenarios two separate payment provider entries need to be created
     * one for each scenario.  Note that this setting does not impact the actual result of the transaction it is only used as information
     * for the monitoring process to decide how to react to the time out.  The payment provider status adjustment rules can be used
     * to decide how to handle scenarios where the reported status does not match the eventual actual status.
     * @servertype String
     * @type {string}
     */
    PaymentProviderTimeoutReportedStatus: string = "W";

    /**
     * @name PaymentProviderErrorReportedStatus
     * @description 
     * This only applies to authorization, capture, sale transactions and never to refund or void transactions.  When monitoring
     * of a payment transaction results in an error that is attributed to a payment provider error (e.g. off-line, etc. but not
     * a decline) this can help the monitoring application decide how the provider error should be reported.  Possible values include:
     * W = Waiting (transaction pending)
     * S = Success (transaction assumed success)
     * F = Failed (transaction assumed failed)
     * In some limited real-time delivery of soft goods that have no cost of good sold it's preferred to treat a provider error
     * as a success to limit customer friction.  In those limited scenarios this should be set to success.  In all other cases this
     * should be waiting (if retries are configured) or failed.  For payment providers used in both scenarios two separate payment
     * provider entries need to be created one for each scenario.  Note that this setting does not impact the actual result of the
     * transaction it is only used as information for the monitoring process to decide how to react to the provider error.  The
     * payment provider status adjustment rules can be used to decide how to handle scenarios where the reported status does not
     * match the eventual actual status.
     * @servertype String
     * @type {string}
     */
    PaymentProviderErrorReportedStatus: string = "F";

    /**
     * @name SuppressBillingTransaction
     * @description 
     * When true there is no payment billing transaction created for successful payment transactions.  This is typically false but
     * in some scenarios where no other billing transactions (e.g. invoice, adjustment) are being created there no need for posting
     * a billing transaction for the payment and the payment transaction record is used as the only accounting of a payment being
     * made.
     * @servertype Boolean
     * @type {boolean}
     */
    SuppressBillingTransaction: boolean = false;

    /**
     * @name SupportPaymentTransactionDetail
     * @description 
     * When true details for items included in a payment transaction are recorded in the billing event table when available.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportPaymentTransactionDetail: boolean = false;

    /**
     * @name RefundTransactionThresholdDays
     * @description 
     * The number of days within which a refund can be processed against a transaction.  Typically within this window no credit
     * card details are needed and refunds can be issued against the purchase transaction record.  After this threshold refunds
     * typically need to be processed as a blind credit to a credit card.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RefundTransactionThresholdDays: number = 0;

    /**
     * @name PaymentErrorTriggersNewProviderSelection
     * @description 
     * When true a payment provider error will trigger a payment transaction retry but with new payment provider selected.  The
     * new payment provider must have valid selection criteria to be selected.  This enables moving on to another payment provider
     * without disabling this provider after one error.
     * @servertype Boolean
     * @type {boolean}
     */
    PaymentErrorTriggersNewProviderSelection: boolean = false;

    /**
     * @name PaymentErrorThreshold
     * @description 
     * The maximum number of payment errors within the window to be tolerated before assuming the payment provider is off-line.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PaymentErrorThreshold: number = 0;

    /**
     * @name PaymentErrorWindow
     * @description 
     * The number of seconds for the payment error window when counting payment errors.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PaymentErrorWindow: number = 0;

    /**
     * @name PaymentErrorDuration
     * @description 
     * The number of seconds for the payment provider to be off-line due to exceeding the payment error threshold.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PaymentErrorDuration: number = 0;

    /**
     * @name UrlChangedDateTime
     * @description 
     * The date and time the provider current url was changed.  Typically this is as a result of excessive payment errors and is
     * taken as a first step before disabling the payment provider when multiple production URLs are available.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    UrlChangedDateTime: Date = null;

    /**
     * @name DisabledUntilDateTime
     * @description 
     * The date and time the provider is disabled until.  Typically this is as a result of excessive payment errors.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DisabledUntilDateTime: Date = null;

    /**
     * @name NotificationGroupIdAuthSuccess
     * @description 
     * Notification Group Id to notify when authorization epayment is successful.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdAuthSuccess: number = null;

    /**
     * @name NotificationGroupIdAuthExpired
     * @description 
     * Notification Group Id to notify when authorization epayment payment method is expired.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdAuthExpired: number = null;

    /**
     * @name NotificationGroupIdAuthDeclined
     * @description 
     * Notification Group Id to notify when authorization epayment is declined.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdAuthDeclined: number = null;

    /**
     * @name NotificationGroupIdAuthError
     * @description 
     * Notification Group Id to notify when authorization epayment attempt generates an error.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdAuthError: number = null;

    /**
     * @name NotificationGroupIdSaleSuccess
     * @description 
     * Notification Group Id to notify when sale epayment is successful.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdSaleSuccess: number = null;

    /**
     * @name NotificationGroupIdSaleExpired
     * @description 
     * Notification Group Id to notify when sale epayment payment method is expired.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdSaleExpired: number = null;

    /**
     * @name NotificationGroupIdSaleDeclined
     * @description 
     * Notification Group Id to notify when sale epayment is declined.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdSaleDeclined: number = null;

    /**
     * @name NotificationGroupIdSaleError
     * @description 
     * Notification Group Id to notify when sale epayment attempt generates an error.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdSaleError: number = null;

    /**
     * @name NotificationGroupIdCreditSuccess
     * @description 
     * Notification Group Id to notify when credit epayment is successful.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdCreditSuccess: number = null;

    /**
     * @name NotificationGroupIdCreditExpired
     * @description 
     * Notification Group Id to notify when credit epayment payment method is expired.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdCreditExpired: number = null;

    /**
     * @name NotificationGroupIdCreditDeclined
     * @description 
     * Notification Group Id to notify when credit epayment is declined.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdCreditDeclined: number = null;

    /**
     * @name NotificationGroupIdCreditError
     * @description 
     * Notification Group Id to notify when credit epayment attempt generates an error.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdCreditError: number = null;

    /**
     * @name CustomTransactionProcessingLogic
     * @description 
     * Custom transaction processing logic for this payment provider.
     * @servertype IB.Web.API.Models.v5.ScriptViewModel
     * @type {m5core.ScriptViewModel}
     */
    CustomTransactionProcessingLogic: m5core.ScriptViewModel = null;

    /**
     * @name CustomNotificationProcessingLogic
     * @description 
     * Custom notification processing logic for this payment provider.
     * @servertype IB.Web.API.Models.v5.ScriptViewModel
     * @type {m5core.ScriptViewModel}
     */
    CustomNotificationProcessingLogic: m5core.ScriptViewModel = null;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Value001
     * @description 
     * Value 1 for this payment provider.  Specific to the payment provider specified.
     * For Legacy Purposes:
     * For Amazon this is the Region (defaults to 'us').
     * For Braintree PayPal or Venmo this is the merchant id (not merchant account id that is stored in merchant number).
     * For Cielo this is the country in which payment will be processed (defaults to 'BRA').
     * For Orbital Gateway Common Wallet Service this is the vendor id.
     * For E-Payment Integrator this is a json collection of strings to submit as config strings (e.g. ["AllowPartialAuths=false","TerminalId=425567118"]).
     * @servertype String
     * @type {string}
     */
    Value001: string = "";

    /**
     * @name Value002
     * @description 
     * Value 2 for this payment provider.  Specific to the payment provider specified.
     * For Legacy Purposes:
     * For Orbital Gateway Common Wallet Service this is the software id.
     * For E-Payment Integrator this is a json object of key value pairs to submit as special fields (e.g. {"x_Trans_Key":"90223491","Method":"Standard"}).
     * @servertype String
     * @type {string}
     */
    Value002: string = "";

    /**
     * @name Value003
     * @description 
     * Value 3 for this payment provider.  Specific to the payment provider specified.
     * For Legacy Purposes:
     * For E-Payment Integrator and others who accept a merchant order number or invoice number, this is an instruction regarding
     * what value(s) to submit as that merchant order number or invoice number.  Possible values include: 
     * TransactionOrderReference = The order reference attached to the payment transaction being processed.  This is the default
     * used when no other is specified.
     * PaymentTransactionId = The unique payment transaction id for the payment transaction being processed.  
     * PaymentTransactionIdOfPayment = The unique payment transaction id tied to payment request.  For payments this is the same
     * as PaymentTransactionId but for refunds, etc. this is the unique payment transaction id of the original payment record being
     * refunded, etc. not the id of the refund transaction itself.
     * @servertype String
     * @type {string}
     */
    Value003: string = "";

    /**
     * @name Value004
     * @description 
     * Value 4 for this payment provider.  Specific to the payment provider specified.
     * For Legacy Purposes:
     * For Chase Orbital this is the BIN.
     * @servertype String
     * @type {string}
     */
    Value004: string = "";

    /**
     * @name Value005
     * @description 
     * Value 5 for this payment provider.  Specific to the payment provider specified.
     * For Legacy Purposes:
     * For Apple Pay if debundling is desired this value contains within CSV a value of 'debundle' (without the quotes).
     * For Apple Pay if sandbox should be used this value contains within CSV a value of 'sandbox' (without the quotes).
     * For Apple Pay if failover host should be used this value contains within CSV a value of 'failover' (without the quotes).
     * @servertype String
     * @type {string}
     */
    Value005: string = "";

    /**
     * @name Value006
     * @description 
     * Value 6 for this payment provider.  Specific to the payment provider specified.
     * @servertype String
     * @type {string}
     */
    Value006: string = "";

    /**
     * @name Value007
     * @description 
     * Value 7 for this payment provider.  Specific to the payment provider specified.
     * @servertype String
     * @type {string}
     */
    Value007: string = "";

    /**
     * @name Value008
     * @description 
     * Value 8 for this payment provider.  Specific to the payment provider specified.
     * @servertype String
     * @type {string}
     */
    Value008: string = "";

    /**
     * @name Value009
     * @description 
     * Value 9 for this payment provider.  Specific to the payment provider specified.
     * @servertype String
     * @type {string}
     */
    Value009: string = "";

    /**
     * @name Value010
     * @description 
     * Value 10 for this payment provider.  
     * For Legacy Purposes:
     * This is typically a CSV of values that may impact how payments are submitted or processed.  Possible values include: 
     * ASCII = Encode name, email, etc. as ASCII before submitting for processing.
     * EASCII = Encode name, email, etc. as extended ASCII before submitting for processing.
     * CP1252 = Encode name, email, etc. as Code Page 1252 before submitting for processing.
     * Latin1 = Encode name, email, etc. as ISO 8859-1 (e.g. ISO Latin 1) before submitting for processing.
     * Note that EASCII, Latin1, and CP1252 may all use Code Page 1252 internally as it's a compatible superset of ISO 8859-1.
     * @servertype String
     * @type {string}
     */
    Value010: string = "";

    /**
     * @name SelectionRules
     * @description 
     * List of selection rules for the payment provider.
     * @servertype PaymentProviderSelectionRuleEditViewModel array
     * @type {PaymentProviderSelectionRuleEditViewModel[]}
     */
    SelectionRules: PaymentProviderSelectionRuleEditViewModel[] = [];

    /**
     * @name SupportedCardTypes
     * @description 
     * List of supported card types for the payment provider.
     * @servertype PaymentProviderSupportedCardTypeEditViewModel array
     * @type {PaymentProviderSupportedCardTypeEditViewModel[]}
     */
    SupportedCardTypes: PaymentProviderSupportedCardTypeEditViewModel[] = [];

    /**
     * @name Triggers
     * @description 
     * List of triggers for the payment provider.
     * @servertype PaymentProviderTriggerEditViewModel array
     * @type {PaymentProviderTriggerEditViewModel[]}
     */
    Triggers: PaymentProviderTriggerEditViewModel[] = [];

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PaymentProviderSelectionRuleEditViewModel
 * @description
 * The payment provider selection rule table contains rules that aid in selection of a payment processor based on the scenarios
 * outlined in the rules.
 */
export class PaymentProviderSelectionRuleEditViewModel {

    /**
     * @name PaymentProviderSelectionRuleId
     * @description 
     * The id of the payment provider selection rule.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentProviderSelectionRuleId: number = null;

    /**
     * @name PaymentProviderId
     * @description 
     * The id of the payment provider.
     * @servertype Int64
     * @type {number}
     */
    PaymentProviderId: number = null;

    /**
     * @name Description
     * @description 
     * Description for this payment provider selection rule.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description 
     * When true the payment provider selection rule is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name PropertyName
     * @description 
     * This is the name of a property to use when checking if the payment provider should be selected.  Possible values include:
     * Selector = A selector value that is related to the payment transaction.
     * PackageType = The package type for a package that is related to the payment transaction.
     * PackageCode = The package code for a package that is related to the payment transaction.
     * PackageClass = The package class for a package that is related to the payment transaction.
     * PackageId = The package id for a package that is related to the payment transaction.
     * @servertype String
     * @type {string}
     */
    PropertyName: string = "";

    /**
     * @name PropertyValue
     * @description 
     * This is the value of the property described in the property name.  For example, if the property name is set to PackageType
     * this is the package type that would result in the payment provider being selected.
     * @servertype String
     * @type {string}
     */
    PropertyValue: string = "";

    /**
     * @name RuleProcessingOrder
     * @description 
     * The order that rules should be processed in.  When all selection rules are mutually exclusive this doesn't matter but since
     * the first rule that matches will result in a selection of the payment processor rules that are not mutually exclusive may
     * need to be processed in a certain order.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RuleProcessingOrder: number = 0;

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PaymentProviderSupportedCardTypeEditViewModel
 * @description
 * The payment provider supported card type table contains card types that are supported by the payment provider.
 */
export class PaymentProviderSupportedCardTypeEditViewModel {

    /**
     * @name PaymentProviderSupportedCardTypeId
     * @description 
     * The id of the payment provider supported card type record.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentProviderSupportedCardTypeId: number = null;

    /**
     * @name PaymentProviderId
     * @description 
     * The id of the payment provider.
     * @servertype Int64
     * @type {number}
     */
    PaymentProviderId: number = null;

    /**
     * @name PaymentMethodCardTypeId
     * @description 
     * The id of the payment method card type (a friendly but unique name for the payment method card type like AmEx, MasterCard,
     * etc.).
     * @servertype String
     * @type {string}
     */
    PaymentMethodCardTypeId: string = null;

    /**
     * @name IsoCurrencyCodeList
     * @description 
     * A comma delimited list of ISO currency codes (e.g. USD, CAD, HKD, MXN, etc.) that are allowed for this payment method/provider
     * combination which will override the payment method card type setting.
     * @servertype string array
     * @type {string[]}
     */
    IsoCurrencyCodeList: string[] = [];

    /**
     * @name CountryCodeList
     * @description 
     * A comma delimited list of country codes (e.g. USA, BRA, etc.) that are allowed for this payment method/provider combination
     * which will override the payment method card type setting.
     * @servertype string array
     * @type {string[]}
     */
    CountryCodeList: string[] = [];

    /**
     * @name ThirdPartyVerificationType
     * @description 
     * A third party verification type to use for this card type (e.g. 3DS, etc.).  If set, this will override the payment method
     * card type setting.
     * @servertype String
     * @type {string}
     */
    ThirdPartyVerificationType: string = "";

    /**
     * @name MandatoryDisplayText
     * @description 
     * Any mandatory display text for transactions of this card type (e.g. 'This payment will be processed in the United States.',
     * etc.).  If set, this will override the payment method card type setting.
     * @servertype String
     * @type {string}
     */
    MandatoryDisplayText: string = "";

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PaymentProviderTriggerEditViewModel
 * @description
 * The payment provider trigger table contains actions that should be taken when a triggering event (e.g. status different than
 * reported status, notification, etc.) occurs.
 */
export class PaymentProviderTriggerEditViewModel {

    /**
     * @name PaymentProviderTriggerId
     * @description 
     * The id of the payment provider trigger.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentProviderTriggerId: number = null;

    /**
     * @name PaymentProviderId
     * @description 
     * The id of the payment provider.
     * @servertype Int64
     * @type {number}
     */
    PaymentProviderId: number = null;

    /**
     * @name Description
     * @description 
     * Description for this payment provider trigger.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description 
     * When true the payment provider trigger is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name EventType
     * @description 
     * The event type that initiates this trigger.  Possible values include: 
     * N = Notification
     * R = Reported Status Mismatch
     * S = Status Change
     * @servertype String
     * @type {string}
     */
    EventType: string = "N";

    /**
     * @name EventValue
     * @description 
     * The event value that initiates this trigger.  
     * When the event type is notification this is the notification type.  
     * When the event type is reported status mismatch this is a colon separated status pair where the first value is the reported
     * status and the second value is the actual status.  
     * When the event type is status change this is a colon separated status pair where the first value is the previous status and
     * the second value is the new status.  
     * Reported Status Mismatch Examples: 
     * S:F - The reported status was success but the transaction failed.
     * F:S - The reported status was failed but the transaction succeeded.
     * Status Change Examples: 
     * W:F - The status was waiting and then was updated to failed.
     * Notification Examples: 
     * CaptureFailed
     * CaptureRejected
     * RefundFailed
     * RefundRejected
     * RefundForced
     * RequestForInformation
     * DisputeOpened
     * DisputeClosed
     * Chargeback
     * ChargebackReversed
     * Fraud
     * Other
     * @servertype String
     * @type {string}
     */
    EventValue: string = "";

    /**
     * @name EventValueLookup
     * @description 
     * Notification payload jpath query for selecting the event value when not otherwise known.
     * @servertype String
     * @type {string}
     */
    EventValueLookup: string = "";

    /**
     * @name EventFilter
     * @description 
     * Filter that can be applied against the notification payload for notification event type or applied against the payment transaction
     * record for status event type to determine if the trigger is valid.
     * @servertype String
     * @type {string}
     */
    EventFilter: string = "";

    /**
     * @name PaymentTransactionIdLookup
     * @description 
     * Notification payload jpath query for selecting the internal payment transaction id the event is related to.
     * @servertype String
     * @type {string}
     */
    PaymentTransactionIdLookup: string = "";

    /**
     * @name TransactionPaymentIdLookup
     * @description 
     * Notification payload jpath query for selecting the payment provider supplied transaction payment id the event is related
     * to.
     * @servertype String
     * @type {string}
     */
    TransactionPaymentIdLookup: string = "";

    /**
     * @name NotificationGroupId
     * @description 
     * Notification Group Id to notify.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupId: number = null;

    /**
     * @name CaseTemplateId
     * @description 
     * Case Template Id to use for creating case.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateId: number = null;

    /**
     * @name TriggerAssetId
     * @description 
     * The Asset Id to use for trigger to execute for additional processing.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggerAssetId: number = null;

    /**
     * @name RetryTransaction
     * @description 
     * When true the referenced transaction should be retried.
     * @servertype Boolean
     * @type {boolean}
     */
    RetryTransaction: boolean = false;

    /**
     * @name RetryTransactionAnyPaymentMethod
     * @description 
     * When true the referenced transaction should be retried using any available payment method.
     * @servertype Boolean
     * @type {boolean}
     */
    RetryTransactionAnyPaymentMethod: boolean = false;

    /**
     * @name ChangeAttributes
     * @description 
     * Change payment transaction attributes to those provided (in json format).
     * @servertype String
     * @type {string}
     */
    ChangeAttributes: string = "";

    /**
     * @name ChangeStatus
     * @description 
     * Change the payment transaction status to the value provided.
     * @servertype String
     * @type {string}
     */
    ChangeStatus: string = "";

    /**
     * @name TransactionMap
     * @description 
     * Mapping from notification payload to payment transaction model for the payment transaction.
     * @servertype String
     * @type {string}
     */
    TransactionMap: string = "";

    /**
     * @name NotificationTransaction
     * @description 
     * When true and event type is notification the notification is added to the payment transaction table (recommended).
     * @servertype Boolean
     * @type {boolean}
     */
    NotificationTransaction: boolean = true;

    /**
     * @name NotificationStatus
     * @description 
     * The status to set for the notification transaction.
     * @servertype String
     * @type {string}
     */
    NotificationStatus: string = "";

    /**
     * @name IsoCurrencyCode
     * @description 
     * ISO Currency Code for notification fee (if any).
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name NotificationFee
     * @description 
     * The fee to assign as the amount for the notification transaction.
     * @servertype Double (nullable)
     * @type {number}
     */
    NotificationFee: number = 0;

    /**
     * @name NotificationReason
     * @description 
     * A reason to post for the notification transaction.  If not specified the notification type is used.
     * @servertype String
     * @type {string}
     */
    NotificationReason: string = "";

    /**
     * @name NotificationComments
     * @description 
     * Comments to post with the notification transaction.
     * @servertype String
     * @type {string}
     */
    NotificationComments: string = "";

    /**
     * @name NotificationMap
     * @description 
     * Mapping from notification payload to payment transaction model for the notification.
     * @servertype String
     * @type {string}
     */
    NotificationMap: string = "";

    /**
     * @name InitiateRefund
     * @description 
     * When true a refund is initiated for the associated transaction.
     * @servertype Boolean
     * @type {boolean}
     */
    InitiateRefund: boolean = false;

    /**
     * @name RecordRefund
     * @description 
     * When true the associated transaction has a refund recorded as successfully completed.
     * @servertype Boolean
     * @type {boolean}
     */
    RecordRefund: boolean = false;

    /**
     * @name RefundPercent
     * @description 
     * The percent to refund as decimal (i.e. 0.8 for 80%).  If not specified 100% is refunded.
     * @servertype Double (nullable)
     * @type {number}
     */
    RefundPercent: number = 0;

    /**
     * @name RefundReason
     * @description 
     * A reason to post for the refund.
     * @servertype String
     * @type {string}
     */
    RefundReason: string = "";

    /**
     * @name RefundComments
     * @description 
     * Comments to post for the refund.
     * @servertype String
     * @type {string}
     */
    RefundComments: string = "";

    /**
     * @name RefundMap
     * @description 
     * Mapping from notification payload to payment transaction model for the refund.
     * @servertype String
     * @type {string}
     */
    RefundMap: string = "";

    /**
     * @name TriggerOrder
     * @description 
     * The order that triggers should be processed in for scenarios where the order matters.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TriggerOrder: number = 0;

    /**
     * @name StopProcessingTriggers
     * @description 
     * When true the trigger processing is halted and no further triggers are processed.
     * @servertype Boolean
     * @type {boolean}
     */
    StopProcessingTriggers: boolean = false;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description 
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Payment Notification
 * @description
 * This contains information about a payment notification.
 */
export class PaymentNotificationAddViewModel {

    /**
     * @name PaymentNotificationId
     * @description 
     * A sender defined id for this notification.
     * @servertype String
     * @type {string}
     */
    PaymentNotificationId: string = "";

    /**
     * @name NotificationDateTime
     * @description 
     * The date and time of the notification.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NotificationDateTime: Date = null;

    /**
     * @name NotificationType
     * @description 
     * The notification type.  Examples: CaptureFailed, CaptureRejected, RefundFailed, RefundRejected, RefundForced, RequestForInformation,
     * DisputeOpened, DisputeClosed, Chargeback, ChargebackReversed, Fraud, Report, Other, etc.  Each expected notification type
     * should be registered as a payment provider trigger.
     * @servertype String
     * @type {string}
     */
    NotificationType: string = "";

    /**
     * @name NotificationCode
     * @description 
     * A code related to this notification.
     * @servertype String
     * @type {string}
     */
    NotificationCode: string = "";

    /**
     * @name NotificationMessage
     * @description 
     * A short message regarding this notification.
     * @servertype String
     * @type {string}
     */
    NotificationMessage: string = "";

    /**
     * @name NotificationDetails
     * @description 
     * Details regarding this notification.
     * @servertype String
     * @type {string}
     */
    NotificationDetails: string = "";

    /**
     * @name IsoCurrencyCode
     * @description 
     * The ISO Currency Code if there is a fee specified with this notification.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Fee
     * @description 
     * A fee associated with the notification.  This is optional but if provided will override any fee defined in payment provider
     * triggers for this notification type.
     * @servertype Double (nullable)
     * @type {number}
     */
    Fee: number = 0;

    /**
     * @name ContactId
     * @description 
     * If the notification is regarding a contact this is the contact id.  This can be left null if the notification is not related
     * to a contact or if there is a reference to a specific payment transaction for this notification.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name BillingAccountId
     * @description 
     * If the notification is regarding a billing account this is the billing account id.  This can be left null if the notification
     * is not related to a billing account or if there is a reference to a specific payment transaction for this notification.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = 0;

    /**
     * @name PaymentProviderId
     * @description 
     * The id (internal or external) for the payment provider triggering this notification.  When using the internal id convert
     * it to a string to assign to this property.  This can be left empty if there is a reference to a specific payment transaction
     * for the notification.
     * @servertype String
     * @type {string}
     */
    PaymentProviderId: string = "";

    /**
     * @name PaymentMethodId
     * @description 
     * If the notification is regarding a payment method this is the payment method id.  This can be left null if the notification
     * is not related to a payment method or if there is a reference to a specific payment transaction for this notification.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentMethodId: number = 0;

    /**
     * @name PaymentTransactionId
     * @description 
     * If the notification is regarding a specific payment transaction this is the internal payment transaction id for that transaction.
     *  This can be left blank if the transaction can be found by the transaction order reference or payment provider issued transaction
     * payment id.
     * @servertype String
     * @type {string}
     */
    PaymentTransactionId: string = "";

    /**
     * @name TransactionDateTime
     * @description 
     * If the notification is regarding a payment transaction this is the date and time of the transaction.  This can be left null
     * if the internal payment transaction id is provided or if the transaction order reference or payment provider issued transaction
     * payment id are known to be globally unique identifiers and do not need a date time reference.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TransactionDateTime: Date = null;

    /**
     * @name TransactionOrderReference
     * @description 
     * If the notification is regarding a specific payment transaction this is the transaction order reference for that transaction.
     *  This can be left blank if the transaction can be found by the internal payment transaction id or payment provider issued
     * transaction payment id.
     * @servertype String
     * @type {string}
     */
    TransactionOrderReference: string = "";

    /**
     * @name TransactionPaymentId
     * @description 
     * If the notification is regarding a specific payment transaction this is the payment provider issued transaction payment id
     * for that transaction.  This can be left blank if the transaction can be found by the transaction order reference or internal
     * payment transaction id.
     * @servertype String
     * @type {string}
     */
    TransactionPaymentId: string = "";

    /**
     * @name Properties
     * @description 
     * Properties that contain payment provider defined information regarding the notification.  The format is dynamic and can contain
     * any information the sender desires to convey.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Attributes
     * @description 
     * Attributes applicable to this object type as defined in attribute configuration.
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name Refund
 * @description
 * This contains information for a refund request.
 */
export class PaymentTransactionRefundAddViewModel {

    /**
     * @name PaymentTransactionRequestDateTime
     * @description 
     * The date and time of the payment request being refunded.  This is optional but performance may be improved when provided.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    PaymentTransactionRequestDateTime: Date = null;

    /**
     * @name PaymentTransactionId
     * @description 
     * The id of the payment transaction being refunded.  This is optional but either the payment transaction id, transaction order
     * reference, or payment provider transaction payment id must be specified.
     * @servertype String
     * @type {string}
     */
    PaymentTransactionId: string = "";

    /**
     * @name TransactionOrderReference
     * @description 
     * The transaction order reference being refunded.  This is optional but either the payment transaction id, transaction order
     * reference, or payment provider transaction payment id must be specified.
     * @servertype String
     * @type {string}
     */
    TransactionOrderReference: string = "";

    /**
     * @name PaymentProviderTransactionPaymentId
     * @description 
     * The payment provider transaction payment id being refunded.  This is optional but either the payment transaction id, transaction
     * order reference, or payment provider transaction payment id must be specified.
     * @servertype String
     * @type {string}
     */
    PaymentProviderTransactionPaymentId: string = "";

    /**
     * @name IsoCurrencyCode
     * @description 
     * The ISO currency code for this refund request.  If not specified the currency of the payment being refunded will be used.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Amount
     * @description 
     * The amount to refund.  This cannot exceed the un-refunded portion of the specified payment transaction.  If null the entire
     * un-refunded balance of the payment will be refunded.
     * @servertype Double (nullable)
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name Reason
     * @description 
     * The reason for the refund.
     * @servertype String
     * @type {string}
     */
    Reason: string = "";

    /**
     * @name Comments
     * @description 
     * Comments regarding the refund.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @description 
     * Attributes for the refund.  If not specified these are typically copied over from the sale being refunded.
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name Sale
 * @description
 * This contains information for a sale request.
 */
export class PaymentTransactionSaleAddViewModel {

    /**
     * @name ContactId
     * @description 
     * The id of the contact for this sale.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name BillingAccountId
     * @description 
     * The id of the billing account for this sale.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = 0;

    /**
     * @name PaymentMethodId
     * @description 
     * The id of the payment method to use for this sale.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentMethodId: number = 0;

    /**
     * @name PaymentProviderId
     * @description 
     * The id of the payment provider to use for processing this sale.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentProviderId: number = 0;

    /**
     * @name TransactionOrderReference
     * @description 
     * An order reference to include with the transaction.
     * @servertype String
     * @type {string}
     */
    TransactionOrderReference: string = "";

    /**
     * @name IsoCurrencyCode
     * @description 
     * The ISO currency code for this sale request.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Amount
     * @description 
     * The amount of the sale.
     * @servertype Double (nullable)
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name Comments
     * @description 
     * Comments regarding the sale.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @description 
     * Attributes for the sale.
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @description 
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name PayPalPayNowButtonViewModel
 */
export class PayPalPayNowButtonViewModel {

    /**
     * @name Id
     * @description 
     * This a unique identifier for this button.
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name BusinessId
     * @description 
     * This is the PayPal business id.  Typically this is the official email address associated with the account.
     * @servertype String
     * @type {string}
     */
    BusinessId: string = "";

    /**
     * @name Description
     * @description 
     * This is the description to use for the pay now transaction.  {{Details}} can be used as a placeholder for transaction details
     * provided by context.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DisplayAboveMessage
     * @description 
     * This is an optional message to display above the pay now button.
     * @servertype String
     * @type {string}
     */
    DisplayAboveMessage: string = "";

    /**
     * @name DisplayBelowMessage
     * @description 
     * This is an optional message to display below the pay now button.
     * @servertype String
     * @type {string}
     */
    DisplayBelowMessage: string = "";

    /**
     * @name IsoCurrencyCode
     * @description 
     * This is the ISO currency code for transactions.  If not specified the customer currency code will be used.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name IpnUrl
     * @description 
     * This is the optional (but strongly recommended) PayPal IPN url to that PayPal should post payment information to so it can
     * be recorded.
     * @servertype String
     * @type {string}
     */
    IpnUrl: string = "";

    /**
     * @name ReturnUrl
     * @description 
     * This is the optional url to redirect to when the user completes payment.
     * @servertype String
     * @type {string}
     */
    ReturnUrl: string = "";

    /**
     * @name CancelUrl
     * @description 
     * This is the optional url to redirect to when the user cancels payment.
     * @servertype String
     * @type {string}
     */
    CancelUrl: string = "";

    /**
     * @name Comments
     * @description 
     * This is the PayPal business id.  Typically this is the official email address associated with the account.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

}



