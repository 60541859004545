<ib-input-text [name]="label"
               [label]="label"
               [labelPrefix]="labelPrefix"
               [labelSuffix]="labelSuffix"
               [placeholder]="placeholder"
               [tooltip]="tooltip"
               [width]="width"
               [outerClass]="outerClass"
               [labelClass]="labelClass"
               [controlClass]="controlClass"
               [bold]="bold"
               [tight]="tight"
               [vertical]="vertical"
               [size]="size"
               [required]="required"
               [includeRequiredIcon]="includeRequiredIcon"
               [readonly]="true"
               [disabled]="disabled"
               [autofocus]="autofocus"
               [standalone]="standalone"
               [errorRequiredMessage]="errorRequiredMessage"
               [errorMinimumLengthMessage]="errorMinimumLengthMessage"
               [errorMaximumLengthMessage]="errorMaximumLengthMessage"
               [errorInvalidFormatMessage]="errorInvalidFormatMessage"
               [errorOtherMessage]="errorOtherMessage"
               [suffixIcon2]="suffixIcon"
               [suffixText2]="suffixText"
               [suffixTooltip2]="suffixTooltip"
               [suffixClickEventEnabled2]="suffixClickEventEnabled"
               (suffixClick2)="onSuffixClick($event)"
               suffixIcon="atom-alt"
               suffixTooltip="Select Asset"
               (suffixClick)="openAssetPicker($event)"
               [ngModel]="asset.Title">
</ib-input-text>
