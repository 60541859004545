import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as Enumerable from 'linq';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { StaticPickList } from 'projects/core-lib/src/lib/models/model-helpers';
import { ButtonItem } from '../../ux-models';
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputMultiselectComponent),
  multi: true
};

@Component({
  selector: 'ib-input-multiselect',
  templateUrl: './input-multiselect.component.html',
  styleUrls: ['./input-multiselect.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputMultiselectComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  @Input() public maxSelectedLabels: number = 20;
  @Input() public selectionLimit: number = null;
  @Input() public layoutClass: string = "ui-multiselect-normal";

  @Input() headerIcon: string = "";
  @Input() headerText: string = "";
  @Input() headerActionButton: ButtonItem = null;
  @Input() footerActionButton: ButtonItem = null;

  //@Input() actionIcon: string = "";
  //@Input() actionTooltip: string = "";
  //@Output() onAction: EventEmitter<any> = new EventEmitter();

  /**
  If we want to have a badge after the label showing the count of selected items then we assign that color now.
  */
  @Input() selectionCountBadgeColor: "" | "primary" | "secondary" | "info" | "success" | "warning" | "danger" | "light" | "dark" = "";

  @Input() modelIsJsonString: boolean = false;
  @Input() modelIsCsvString: boolean = false;


  @Input() selectedItemsWordwrap: boolean = true;


  multiSelectClasses: string = "";


  list: string[] = [];

  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
    // For multi-select we default this include none value to false
    this.optionsIncludeNone = false;
    // This ui component wants SelectItem[] array
    this.mapPickListToOptions = true;
    // Flag that we're using primeng for this input
    this.inputLibrary = "primeng";
  }

  ngOnInit() {
    // For multi-select we default this include none value to false
    this.optionsIncludeNone = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    //this.configure();
    if (this.size === "small") {
      this.multiSelectClasses = "p-multiselect-small";
    } else {
      this.multiSelectClasses = "";
    }
  }

  showHeader() {
    if (this.headerIcon || this.headerText || this.headerActionButton) {
      return true;
    }
    return false;
  }

  writeValue(value: any) {
    super.writeValue(value);
    if (this.modelIsJsonString) {
      //console.error("input json value change", value);
      this.list = JSON.parse(value);
    } else if (this.modelIsCsvString) {
      //console.error("input csv value change", value);
      this.list = Helper.parseCsvString(value);
    } else if (this.optionsValueIsInteger) {
      // Multi-select needs integer values cast to strings for the pick list to work
      if (Helper.isArray(value)) {
        this.innerValue = (value as any[]).map(x => x.toString());
      }
    } else {
      //console.error("input list value change", value);
    }
  }


  fireChange($event, inputControl) {
    super.fireChange($event, inputControl);
    if (this.modelIsJsonString) {
      this.value = JSON.stringify(this.list);
    } else if (this.modelIsCsvString) {
      this.value = Helper.buildCsvString(this.list);
    }
  }

  //fireAction($event) {
  //  this.onAction.emit($event);
  //}

}
