<div class="form-group {{inputFormGroupClass}}">
  <label for="{{inputControlId}}" class="{{inputLabelClass}}" ngbTooltip="{{tooltip | translate}}">
    <span *ngIf="required && includeRequiredIcon">
      <ib-icon icon="exclamation-circle (solid)" ngbTooltip="Required"></ib-icon>&nbsp;
    </span>
    {{labelPrefix}}{{( label | translate ) || label}}{{labelSuffix}}
  </label>
  <div class="{{inputWrapperClass}}">
    <div class="{{inputGroupClass}}">
      <ib-tags class="form-control {{inputSize}} {{controlClass}} form-control-tags"
              id="{{inputControlId}}"
              name="{{name}}"
              placeholder="{{( placeholder | translate ) || placeholder}}"
              ngbTooltip="{{tooltip | translate}}"
              [removeLastOnBackspace]="true"
              [optionsPickListId]="optionsPickListId"
              [optionsPickListFilter]="optionsPickListFilter"
              [optionsPickList]="optionsPickList"
              [readonly]="readonly"
              [disabled]="disabled"
              [required]="required"
              (focus)="fireFocus($event, inputControl)"
              (blur)="fireBlur($event, inputControl)"
              (keyup)="fireKeyUp($event, inputControl)"
              (onTagsChanged)="fireTagChange($event, inputControl)"
              [(ngModel)]="value"
              [ngModelOptions]="{standalone: standalone}"
              #inputControl="ngModel">
      </ib-tags>
      <div *ngIf="inputControl.invalid && (inputControl.dirty || inputControl.touched)">
        <small *ngFor="let errorMessage of errorMessages" class="form-text input-invalid input-error-message">
          {{errorMessage | translate:inputInformationValues}}
        </small>
      </div>
    </div>
  </div>
</div>
