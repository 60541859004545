<div cdkDrag
     class=""
     style="width: 100%;">
  <div class="modal-dialog modal-{{size}}">
    <div class="modal-content">
      <ib-modal-common-title [options]="options"
                             [cancelData]="false"
                             cdkDragHandle
                             class="movable"
                             (cancel)="options.cancel">
      </ib-modal-common-title>
      <div class="modal-body">
        <span [innerHtml]="options.message | safe: 'html'"></span>
      </div>
      <ib-modal-common-buttons [options]="options"
                               [okData]="true"
                               [cancelData]="false"
                               (ok)="options.ok"
                               (cancel)="options.cancel">
      </ib-modal-common-buttons>
    </div>
  </div>
</div>
