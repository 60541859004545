<div class="form-group {{inputFormGroupClass}} {{outerClass}}">
  <label for="{{inputControlId}}"
         class="{{inputLabelClass}}">
    {{labelPrefix}}{{( label | translate ) || label}}{{labelSuffix}}
  </label>
  <div class="{{inputWrapperClass}}">
    <div [ngClass]="{'card': border }">
      <div class="{{controlClass}}"
           [ngClass]="{'card-body': border , 'p-2': border }"
           style="min-height:30px;">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
