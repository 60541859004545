{{ '' // icon with stacked text }}
<ng-container *ngIf="iconData && iconData.stackedTop && iconData.stackedTop.text">
  <ng-container *ngIf="labelFirst">
    <span class="{{labelClasses}}"
          [ngClass]="{'clickable': isClickable()}"
          [style]="stylesForLabel"
          *ngIf="label">
      {{( label | translate ) || label}}
    </span>
    <span *ngIf="iconData.stackedTop && label">&nbsp;&nbsp;</span>
  </ng-container>
  <span class="{{iconData.stackedGroupClasses}}"
        [ngClass]="{'clickable': isClickable()}"
        ngbTooltip="{{( tooltip | translate ) || tooltip}}"
        tooltipClass="z3000"
        placement="{{tooltipPlacement}}"
        container="body"
        (click)="fireClick($event)">
    <strong class="{{stackedTopClasses}} fa-stack-item fa-stack-top fa-stack-text"
            [style]="stackedTopStyles">
      {{iconData.stackedTop.text}}
    </strong>
    <i class="{{stackedBottomClasses}} fa-stack-item fa-stack-bottom"
       [style]="stackedBottomStyles"></i>
  </span>
  <ng-container *ngIf="!labelFirst">
    <span *ngIf="iconData.stackedTop && label">&nbsp;&nbsp;</span>
    <span class="{{labelClasses}}"
          [ngClass]="{'clickable': isClickable()}"
          [style]="stylesForLabel"
          *ngIf="label">
      {{( label | translate ) || label}}
    </span>
  </ng-container>
</ng-container>

{{ '' // icon with stacked image }}
<ng-container *ngIf="iconData && iconData.stackedTop && !iconData.stackedTop.text">
  <ng-container *ngIf="labelFirst">
    <span class="{{labelClasses}}"
          [ngClass]="{'clickable': isClickable()}"
          [style]="stylesForLabel"
          *ngIf="label">
      {{( label | translate ) || label}}
    </span>
    <span *ngIf="iconData.stackedTop && label">&nbsp;&nbsp;</span>
  </ng-container>
  <span class="{{iconData.stackedGroupClasses}}"
        [ngClass]="{'clickable': isClickable()}"
        ngbTooltip="{{( tooltip | translate ) || tooltip}}"
        tooltipClass="z3000"
        placement="{{tooltipPlacement}}"
        container="body"
        (click)="fireClick($event)">
    <i class="{{stackedTopClasses}} fa-stack-item fa-stack-top"
       [style]="stackedTopStyles"></i>
    <i class="{{stackedBottomClasses}} fa-stack-item fa-stack-bottom"
       [style]="stackedBottomStyles"></i>
  </span>
  <ng-container *ngIf="!labelFirst">
    <span *ngIf="iconData.stackedTop && label">&nbsp;&nbsp;</span>
    <span class="{{labelClasses}}"
          [ngClass]="{'clickable': isClickable()}"
          [style]="stylesForLabel"
          *ngIf="label">
      {{( label | translate ) || label}}
    </span>
  </ng-container>
</ng-container>

{{ '' // not file image icon }}
<ng-container *ngIf="!isFileIcon && (!iconData || !iconData.stackedTop)">
  <ng-container *ngIf="labelFirst">
    <span class="{{labelClasses}}"
          [ngClass]="{'clickable': isClickable()}"
          [style]="stylesForLabel"
          ngbTooltip="{{( tooltip | translate ) || tooltip}}"
          tooltipClass="z3000"
          placement="{{tooltipPlacement}}"
          container="body"
          *ngIf="label">
      {{( label | translate ) || label}}
    </span>
    <span *ngIf="icon && label">&nbsp;&nbsp;</span>
  </ng-container>
  <i class="{{classes}}"
     [ngClass]="{'clickable': isClickable()}"
     [style]="styles"
     ngbTooltip="{{( tooltip | translate ) || tooltip}}"
     tooltipClass="z3000"
     placement="{{tooltipPlacement}}"
     container="body"
     (click)="fireClick($event)"></i>
  <ng-container *ngIf="!labelFirst">
    <span *ngIf="icon && label">&nbsp;&nbsp;</span>
    <span class="{{labelClasses}}"
          [ngClass]="{'clickable': isClickable()}"
          [style]="stylesForLabel"
          ngbTooltip="{{( tooltip | translate ) || tooltip}}"
          tooltipClass="z3000"
          placement="{{tooltipPlacement}}"
          container="body"
          *ngIf="label">
      {{( label | translate ) || label}}
    </span>
  </ng-container>
</ng-container>

{{ '' // file image icon }}
<ng-container *ngIf="isFileIcon">
  <ng-container *ngIf="labelFirst">
    <span class="{{labelClasses}}"
          [ngClass]="{'clickable': isClickable()}"
          [style]="stylesForLabel"
          ngbTooltip="{{( tooltip | translate ) || tooltip}}"
          tooltipClass="z3000"
          placement="{{tooltipPlacement}}"
          container="body"
          *ngIf="label">
      {{( label | translate ) || label}}
    </span>
    <span *ngIf="icon && label">&nbsp;&nbsp;</span>
  </ng-container>
  <img src="{{fileIconUrl}}"
       [ngClass]="{'clickable': isClickable()}"
       [style]="styles"
       ngbTooltip="{{( tooltip | translate ) || tooltip}}"
       tooltipClass="z3000"
       placement="{{tooltipPlacement}}"
       container="body"
       (click)="fireClick($event)" />
  <ng-container *ngIf="!labelFirst">
    <span *ngIf="icon && label">&nbsp;&nbsp;</span>
    <span class="{{labelClasses}}"
          [ngClass]="{'clickable': isClickable()}"
          [style]="stylesForLabel"
          ngbTooltip="{{( tooltip | translate ) || tooltip}}"
          tooltipClass="z3000"
          placement="{{tooltipPlacement}}"
          container="body"
          *ngIf="label">
      {{( label | translate ) || label}}
    </span>
  </ng-container>
</ng-container>
