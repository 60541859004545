<div class="{{dropDownWrapperClass}} d-inline-block"
     ngbDropdown
     placement="bottom-right"
     #getHelp="ngbDropdown">
  <button class="btn {{dropDownButtonClass}}"
          ngbTooltip="Get Help"
          placement="left"
          id="dropdownManual"
          ngbDropdownAnchor
          (click)="getHelp.toggle()">
    <ib-icon icon="question-circle"
             [light]="true"
             [animation]="(helpSpotlight ? 'tada' : '')">
    </ib-icon>
  </button>
  <div ngbDropdownMenu
       aria-labelledby="dropdownManual">
    <ng-container *ngIf="helpTopic && hasHelpLinks">
      <ng-container *ngFor="let link of helpTopic.Links">
        <a *ngIf="(link.HelpLinkItemType === 'Article' || link.HelpLinkItemType === 'Url') && link.Url"
           class="dropdown-item"
           target="_blank"
           href="{{link.Url}}">
          <ib-icon icon="{{link.Icon}}"
                   [fixedWidth]="true"
                   label="{{link.Description}}"></ib-icon>
          <ib-badge *ngIf="( link.BadgeIcon || link.BadgeText ) && ( !link.BadgeUntil || link.BadgeUntil > now )"
                    class="ms-1"
                    [icon]="link.BadgeIcon"
                    [label]="link.BadgeText"
                    [color]="link.BadgeColor">
          </ib-badge>
        </a>
        <div *ngIf="link.HelpLinkItemType === 'Separator'"
             class="dropdown-divider"></div>
        <span *ngIf="link.HelpLinkItemType === 'Message' && link.Message"
              class="clickable dropdown-item"
              (click)="onMessageShow(link,$event)">
          <ib-icon icon="{{link.Icon}}"
                   [fixedWidth]="true"
                   label="{{link.Description}}"></ib-icon>
          <ib-badge *ngIf="( link.BadgeIcon || link.BadgeText ) && ( !link.BadgeUntil || link.BadgeUntil > now )"
                    class="ms-1"
                    [icon]="link.BadgeIcon"
                    [label]="link.BadgeText"
                    [color]="link.BadgeColor">
          </ib-badge>
        </span>
        <span *ngIf="link.HelpLinkItemType === 'Tour' && link.Tour"
              class="clickable dropdown-item"
              (click)="onTourShow(link,$event)">
          <ib-icon icon="{{link.Icon}}"
                   [fixedWidth]="true"
                   label="{{link.Description}}"></ib-icon>
          <ib-badge *ngIf="( link.BadgeIcon || link.BadgeText ) && ( !link.BadgeUntil || link.BadgeUntil > now )"
                    class="ms-1"
                    [icon]="link.BadgeIcon"
                    [label]="link.BadgeText"
                    [color]="link.BadgeColor">
          </ib-badge>
        </span>
      </ng-container>
      <div *ngIf="hasHelpLinks && (showHelpIndex || showHelpOtherLinks || showHelpContactSupport)"
           class="dropdown-divider"></div>
    </ng-container>
    <a *ngIf="showHelpIndex"
       class="dropdown-item"
       target="_blank"
       href="{{helpIndexLink}}">
      <ib-icon icon="question-circle (light)"
               [fixedWidth]="true"
               label="Help Index"></ib-icon>
    </a>
    <ng-container *ngIf="showHelpOtherLinks">
      <a *ngFor="let url of helpOtherLinks"
         class="dropdown-item"
         target="_blank"
         href="{{url.url}}">
        <ib-icon icon="{{url.icon || 'question-circle (light)'}}"
                 [fixedWidth]="true"
                 label="{{url.label}}"></ib-icon>
      </a>
    </ng-container>
    <a *ngIf="showHelpContactSupport"
       class="dropdown-item"
       target="_blank"
       href="{{helpContactSupportLink}}">
      <ib-icon icon="envelope (light)"
               [fixedWidth]="true"
               label="Contact Support"></ib-icon>
    </a>
    <span *ngIf="isRemoteHelpEnabled"
          class="clickable dropdown-item"
          (click)="openRemoteHelpActivationModal()">
      <ib-icon icon="window-alt"
               label="Remote Help"
               [fixedWidth]="true"></ib-icon>
    </span>
  </div>
</div>
