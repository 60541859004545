import { NgModule } from '@angular/core';

import { AngularDokaComponent } from './doka/angular-doka.component';
import { AngularDokaModalComponent } from './doka-modal/angular-doka-modal.component';
import { AngularDokaOverlayComponent } from './doka-overlay/angular-doka-overlay.component';

@NgModule({
  declarations: [
    AngularDokaComponent,
    AngularDokaModalComponent,
    AngularDokaOverlayComponent
  ],
  imports: [
  ],
  exports: [
    AngularDokaComponent,
    AngularDokaModalComponent,
    AngularDokaOverlayComponent
  ]
})

export class AngularDokaModule { }
