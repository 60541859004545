import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from '../helpers/helper';

@Pipe({
  name: 'newLines'
})
export class NewLinesPipe implements PipeTransform {

  transform(value: any, height: number = 0): any {
    if (Helper.isUndefinedOrNull(value)) {
      return "";
    }
    if (!Helper.isString(value)) {
      return value;
    }
    if (height) {
      return value.replace(/\n/g, `<span class="d-block mb-${height}"></span>`);
    } else {
      return value.replace(/\n/g, '<br/>');
    }
  }

}
